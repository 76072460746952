import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import * as CENTRE from "../../services/centre";
import * as CENTRECATEGORY from "../../services/centercategory";
import * as SHOWCASE from "../../services/showcase";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';

const typeList = [
  {
    value:"TYPE_CENTRE_CATEGORY",
    label: "Centre Category"
  },
  {
    value:"TYPE_STATE",
    label: "State"
  },
  {
    value:"TYPE_EBOOK_LIST",
    label: "e-Book"
  },
  {
    value:"TYPE_VIDEO_LIST",
    label: "Video"
  },
  {
    value:"TYPE_ALL_CENTRE_CATEGORY",
    label: "ALL"
  }
]

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    marginTop: theme.spacing(2),
    "&:before": {
      borderColor: themeColor
    },
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  descColor: {
    color: "#1E90FF",
    fontWeight: "400"
  },
  fontSizes: {
    fontSize:14
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
  inputFocused: {}
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

const inputRef1 = React.createRef();

let images = "";

export default function ShowCase(props) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [showcaseObject, setShowcaseObject] = useState([]);
  const [tempSrc, setTempSrc] = useState(""); 
  // const [urlOnCropped,setUrlOnCropped] = useState(""); 
  const [imageOnCropped,setImageOnCropped] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null);  
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [loading, setLoading] = useState(true);
  const [stateSelection, setStateSelection] = useState([]);
  const [categorySelection, setCategorySelection] = useState([]);

  const [crop, setCrop] = useState({
    unit: 'px',
    width: 200,
    aspect: 1/1 
  });

  let valid = true;
  let val=[];

  const getStateList = () => {
    CENTRE.getStateList()
      .then(res => {       
        setStateSelection(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);
            });
        }
      });
  }

  useEffect(() => {

    getStateList();
   
    CENTRECATEGORY.getAllCategoryList()
      .then(res => {       
        setCategorySelection(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);
            });
        }
      });
  }, 
  []);

  useEffect(() => {
    SHOWCASE.getShowCaseSetting()
      .then(res => {

        let a = [];
        for (let i = 0; i< res['data'].length;i++) {

          if(stateSelection.length === 0){
            getStateList();
            return;
          }

          val = stateSelection.filter(states =>{ 
            return states.name === res.data[i]['value']});

            if (!Array.isArray(val) || !val.length){
                val = res.data[i]['value'];
            }
            else{
                val = val[0].id;
            }

            let ms = "";
          if (res['data'][i]['ms_name'] == null || res['data'][i]['ms_name'] == "") {
            ms = "N/A"
          } else {
            ms = res['data'][i]['ms_name']
          }

          let zh = "";
          if (res['data'][i]['zh_name'] == null || res['data'][i]['zh_name'] == "") {
            zh = "N/A"
          } else {
            zh = res['data'][i]['zh_name']
          }

          a.push({
            id: res['data'][i]['id'],
            imagefilename:res['data'][i]['image_filename'],
            url:res['data'][i]['image_url'],
            name:res['data'][i]['name'],
            type:res['data'][i]['type'],
            order:res['data'][i]['order'],
            ms_name:ms,
            zh_name:zh,
            value:val
          })
        }

        setShowcaseObject(a);

        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);
            });
        }
      });
  },[stateSelection]);

    useEffect(() => {
      let img = images;
      const scaleX = img.naturalWidth / img.width;
      setCrop({
        aspect: 1/1,
        width: 200/scaleX
      });   
      setLoading(false);  
    }, [images]);


    const updateShowCaseName = (e,showcaseid) => {
      let a = showcaseObject.map((showcase)=>{
        if(showcase.id === showcaseid){
          showcase.name = e.target.value;
        }
        return showcase;
      });
      setShowcaseObject(a);
    }

    const updateShowCaseMSName = (e,showcaseid) => {
      let a = showcaseObject.map((showcase)=>{
        if(showcase.id === showcaseid){
          showcase.ms_name = e.target.value;
        }
        return showcase;
      });
      setShowcaseObject(a);
    }

    const updateShowCaseZHName = (e,showcaseid) => {
      let a = showcaseObject.map((showcase)=>{
        if(showcase.id === showcaseid){
          showcase.zh_name = e.target.value;
        }
        return showcase;
      });
      setShowcaseObject(a);
    }

    const updateShowCaseType = (e,showcaseid) => {
      let a = showcaseObject.map((showcase)=>{
        if(showcase.id === showcaseid){
          showcase.type = e.target.value;
        }
        return showcase;
      });
      setShowcaseObject(a);
    }

    const updateShowCaseValue = (e,showcaseid) => {
      let a = showcaseObject.map((showcase)=>{
        if(showcase.id === showcaseid){
          showcase.value = e.target.value;
        }
        return showcase;
      });
      setShowcaseObject(a);
    }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    showcaseObject.forEach(showcase=>{
      if(showcase.nameError || showcase.nameLengthError || showcase.minNameLengthError ||
        showcase.zh_nameError || showcase.zh_nameLengthError || showcase.zh_minNameLengthError ||
        showcase.ms_nameError || showcase.ms_nameLengthError || showcase.ms_minNameLengthError){
          valid = false;
          setLoading(false);
      }
    })
  
    if (valid) {
      let updateData = {
        showcase:showcaseObject
      };

      SHOWCASE.updateShowCase(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Showcase setting updated successfully"
          })
          .then(() => {
            window.location.reload();
            props.history.replace("/showcase");
          });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              setLoading(false);            
              if(err.errors.image) {
                errMsg.push(err.errors.image);
              }
              else if (err == "timeout of 60000ms exceeded") {
                errMsg.push("Request timeout. You may experience a slow network.")
              }
              else {
                errMsg.push(err)
              }
            }
            else { 
              Swal.fire({
                icon: "error",
                title: "Fail to Update Showcase Setting",
                text: errMsg
              });             
          }
          setLoading(false);
        }
        });
    }
  }

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleShow = () => setShow(true);
  const handleClose = () => {setShow(false);setTempSrc("");}

  const renderMenulist = (type,values) => {
    switch (type) {
        case "TYPE_STATE" :
            return stateSelection.map(state => {
              return <MenuItem key={state.id} value={state.id}>
                      {state.name}
                      </MenuItem>
              }); 
            break;
        case "TYPE_CENTRE_CATEGORY" : 
            return categorySelection.map(category => {
              return <MenuItem key={category.id} value={category.id}>
                      {category.name}
                     </MenuItem>
              });
            break;
        case "TYPE_EBOOK_LIST" :
            return <MenuItem key={values} value={values}>
                    {values}
                   </MenuItem>
            break;
        case "TYPE_VIDEO_LIST" :
            return <MenuItem key={values} value={values}>
                      {values}
                  </MenuItem>
            break;
        case "TYPE_ALL_CENTRE_CATEGORY" :
            return <MenuItem key={values} value={values}>
                      {values}
                  </MenuItem>
            break;
    }  
  }

  const validShowCaseName = (e,showcaseid) => {
    let a = showcaseObject.map((showcase)=>{
      if(showcase.id === showcaseid){
    
        if (e.target.value.length > 100) {
          showcase.nameError = true;
        }
        if (e.target.value.length <= 1 ) {
          showcase.minNameLengthError = true;
        }
        if (e.target.value.length > 0) { 
          showcase.namelengthError = true;
        }
        if (e.target.value.length >= 2) { 
          showcase.minNameLengthError = false;
        }
        if (e.target.value.length < 101) { 
          showcase.nameError = false;
        }
      }
      return showcase;
    });
    setShowcaseObject(a);
  }

  const validShowCaseMSName = (e,showcaseid) => {
    let a = showcaseObject.map((showcase)=>{
      if(showcase.id === showcaseid){
    
        if (e.target.value.length > 100) {
          showcase.ms_nameError = true;
        }
        if (e.target.value.length <= 1 ) {
          showcase.ms_minNameLengthError = true;
        }
        if (e.target.value.length > 0) { 
          showcase.ms_namelengthError = true;
        }
        if (e.target.value.length >= 2) { 
          showcase.ms_minNameLengthError = false;
        }
        if (e.target.value.length < 101) { 
          showcase.ms_nameError = false;
        }
      }
      return showcase;
    });
    setShowcaseObject(a);
  }

  const validShowCaseZHName = (e,showcaseid) => {
    let a = showcaseObject.map((showcase)=>{
      if(showcase.id === showcaseid){
    
        if (e.target.value.length > 100) {
          showcase.zh_nameError = true;
        }
        if (e.target.value.length <= 1 ) {
          showcase.zh_minNameLengthError = true;
        }
        if (e.target.value.length > 0) { 
          showcase.zh_namelengthError = true;
        }
        if (e.target.value.length >= 2) { 
          showcase.zh_minNameLengthError = false;
        }
        if (e.target.value.length < 101) { 
          showcase.zh_nameError = false;
        }
      }
      return showcase;
    });
    setShowcaseObject(a);
  }

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > 5000000) {
        imageErrorAlert();
      }
      else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
        imageFormatErrorAlert();
      }
      else {
        handleShow();
      }

      reader.onloadend = () => {
        setTempSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  //start of crop image function
  const onImageLoaded = image => {
    images = image;
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 200;
    const minHeight = 200 ;

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_showcaseImage.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }
  // end of crop image function

  const saveUrl = () => {
    let a = showcaseObject.map((showcase)=>{
      if(showcase.id === imageOnCropped){
        showcase.imagefile = completedCrop;
        showcase.url = cropImageUrl
      }
      return showcase;
    });
    setShowcaseObject(a);
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
   
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              onSubmit={event => handleSubmit(event)}
            >
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>ShowCase Setting</h4>
              </CardHeader>
              <CardBody> 

              <div style={{ paddingTop: 30, textAlign: "left" }}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Only accept image with .jpg, .jpeg, .png format and size less than 5MB.
                  </Typography>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Maximum characters for name field is 100 characters.
                  </Typography>
              </div>

              <GridContainer
                    style={{paddingTop:30, paddingLeft:15,alignItems:"flex-start"}}
                    direction="row"
                    alignItems="center">

                    {showcaseObject.length == 0 ? (
                      setLoading(true)
                    ):null}

                    {showcaseObject.map((showcase,index) => {
                      return (
                        <GridContainer style={{marginBottom:20+"px"}}>
                          <div>
                          <GridItem xs={12} sm={4} md={2}>
                              <div 
                              style={{
                                display: "inline-flex",
                                width: "100%",
                                height: "8rem"
                              }}>
                              <div style={{ width: "auto" }}>
                                <Box
                                  border={1}
                                  style={{
                                    textAlign: "center",
                                    bgcolor: "background.paper",
                                    width: "7.5rem",
                                    height: "7.5rem",
                                    borderColor: "text.primary",
                                    borderStyle: "dotted"
                                  }}>
                                    <img
                                      src={showcase.url}
                                      onClick={() => {
                                          setImageOnCropped(showcase.id);
                                          inputRef1.current.click();
                                        }}
                                      ali=""
                                      style={{
                                        width: "7.5rem",
                                        height: "7.5rem",
                                        cursor: "pointer"
                                      }}
                                    />
                                </Box>
                              </div>
                            </div>                    
                          </GridItem>

                          <GridItem xs={12} sm={11} md={11}>
                            <TextField
                              variant="standard"
                              margin="normal"
                              required
                              disabled={showcase.order == 1 || showcase.order == 2 || showcase.order == showcaseObject.length}
                              fullWidth
                              label="Name"
                              name="name"
                              value={showcase.name}
                              onKeyUp={(e)=> validShowCaseName(e,showcase.id)}
                              onChange={(e) => updateShowCaseName(e,showcase.id)}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input:classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused
                                }
                              }}
                            />
                            {showcase.nameError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MAXIMUM LENGTH OF <br></br>NAME IS 100 CHARACTER.</FormHelperText>
                            )}
                            {showcase.minNameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MINIMUM LENGTH OF <br></br>NAME IS 2 CHARACTER.</FormHelperText>
                            )}
                            {showcase.nameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                NAME IS REQUIRED.</FormHelperText>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={11} md={11}>
                            <TextField
                              variant="standard"
                              margin="normal"
                              required
                              disabled={showcase.order == 1 || showcase.order == 2 || showcase.order == showcaseObject.length}
                              fullWidth
                              label="Malay Name"
                              name="ms_name"
                              value={showcase.ms_name}
                              onKeyUp={(e)=> validShowCaseMSName(e,showcase.id)}
                              onChange={(e) => updateShowCaseMSName(e,showcase.id)}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input:classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused
                                }
                              }}
                            />
                            {showcase.ms_nameError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MAXIMUM LENGTH OF <br></br>NAME IS 100 CHARACTER.</FormHelperText>
                            )}
                            {showcase.ms_minNameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MINIMUM LENGTH OF <br></br>NAME IS 2 CHARACTER.</FormHelperText>
                            )}
                            {showcase.ms_nameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                NAME IS REQUIRED.</FormHelperText>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={11} md={11}>
                            <TextField
                              variant="standard"
                              margin="normal"
                              required
                              disabled={showcase.order == 1 || showcase.order == 2 || showcase.order == showcaseObject.length}
                              fullWidth
                              label="Chinese Name"
                              name="zh_name"
                              value={showcase.zh_name}
                              onKeyUp={(e)=> validShowCaseZHName(e,showcase.id)}
                              onChange={(e) => updateShowCaseZHName(e,showcase.id)}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input:classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused
                                }
                              }}
                            />
                            {showcase.zh_nameError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MAXIMUM LENGTH OF <br></br>NAME IS 100 CHARACTER.</FormHelperText>
                            )}
                            {showcase.zh_minNameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                MINIMUM LENGTH OF <br></br>NAME IS 2 CHARACTER.</FormHelperText>
                            )}
                            {showcase.zh_nameLengthError && (
                              <FormHelperText style={{ color: "#f44336"}}>
                                NAME IS REQUIRED.</FormHelperText>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={11} md={11}>
                          <TextField
                              required
                              fullWidth
                              style={{ paddding: 0, marginTop: 10 }}
                              margin="normal"
                              name="type"
                              select
                              disabled={showcase.order == 1 || showcase.order == 2 || showcase.order == showcaseObject.length}
                              label="Type"
                              value={showcase.type}
                              onChange={e => updateShowCaseType(e,showcase.id)}
                              variant="standard"
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input: classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused                          
                                }
                              }}>
                              {typeList.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))} 
                            </TextField>
                          </GridItem>

                          <GridItem xs={12} sm={11} md={11} style={{marginBottom:10+"px"}}>
                          <TextField
                              required
                              fullWidth
                              style={{ paddding: 0, marginTop: 10 }}
                              margin="normal"
                              name="value"
                              select
                              disabled={showcase.order == 1 || showcase.order == 2 || showcase.order == showcaseObject.length}
                              label="Value"
                              value={showcase.value}
                              onChange={e => updateShowCaseValue(e,showcase.id)}
                              variant="standard"
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input: classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused
                                }
                              }}>
                              {renderMenulist(showcase.type,showcase.value)}
                          </TextField>
                          </GridItem>
       
                          </div>
                          </GridContainer>
                      )
                    })}

                      <GridItem>
                          <input
                            className="fileInput"
                            type="file"
                            hidden
                            ref={inputRef1}
                            accept="image/png,image/jpeg,image/jpg"
                            style={{ marginLeft: 10 }}
                            onChange={(e) => handleImageChange(e)}
                          />
                      </GridItem>

                      <Dialog classes={{ paper: classes.dialogPaper }}
                        open={show==true}
                        onClose={handleClose}
                        maxWidth = {'lg'}
                        disableBackdropClick={true}
                      >
                      <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                      <DialogContent>
                        <ReactCrop
                          locked={true}
                          src={tempSrc}
                          crop={crop}
                          ruleOfThirds
                          imageStyle={{maxHeight:'70vh'}}
                          onImageLoaded={c => onImageLoaded(c)}
                          onChange={c => {setCrop(c)}}
                          onComplete={c => onCropComplete(c)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttons onClick={() => {handleClose();}}>
                          Close
                        </Buttons>
                        <Buttons onClick={() => {handleClose();saveUrl();}}>
                          Save
                        </Buttons>
                      </DialogActions>
                      </Dialog>

                </GridContainer>     
              </CardBody>

              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}