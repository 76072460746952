import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as CENTRECATEGORY from "../../services/centercategory";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

const inputRef = React.createRef();
let images = "";

export default function AddCentreCategory(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [show, setShow] = React.useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null); 
  const [tempSrc, setTempSrc] = useState("");  
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [ms_nameError, setMSNameError] = useState(false);
  const [ms_minNameLengthError, setMSMinNameLengthError] = useState(false);
  const [ms_nameLengthError, setMSNameLengthError] = useState(false);
  const [zh_nameError, setZHNameError] = useState(false);
  const [zh_minNameLengthError, setZHMinNameLengthError] = useState(false);
  const [zh_nameLengthError, setZHNameLengthError] = useState(false);
  // const [featuredError, setFeaturedError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const sortBy =
    props.location.state && props.location.state.sortBy ? props.location.state.sortBy : "";
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const [crop, setCrop] = useState({
      unit: 'px',
      width: 1280,
      aspect: 16 / 9, 
  });
  const [formData, setFormData] = useState({
    name: "",
    is_feature: "",
    image: "",
    image_url: "",
    zh_name:"",
    ms_name:""
  });

  let valid = true;

  useEffect(() => {
    let img = images;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });         
    setLoading(false);

  }, [images]);

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { name, is_feature, image_url, zh_name, ms_name } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    // setFeaturedError(false);
    setNameError(false);
    setNameLengthError(false);

    setLoading(true);

    // if (formData['is_feature'].length == 0) {
    //   valid = false;
    //   setFeaturedError(true);
    //   setLoading(false);
    // }

    if (nameError || nameLengthError || minNameLengthError 
      || ms_nameError || ms_nameLengthError || ms_minNameLengthError 
      || zh_nameError || zh_nameLengthError || zh_minNameLengthError) {
        valid = false;
        setLoading(false);
      }

    if (valid) {

      let cerateData = {
        ...formData,
        image:completedCrop
      };

      setLoading(true);
      CENTRECATEGORY.addCenterCategory(cerateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Centre Category created successfully"
          }).then(() => {
            props.history.replace("/center-category");
          });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              if (err.errors.image) {
                errMsg.push(err.errors.image);
              }
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
              Swal.fire({
                icon: "error",
                title: "Fail to Add Centre Category",
                text: errMsg
              });           
          }
          setLoading(false);
        });
    }
  }

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }
  const validMSNameLength = () => {
    if (ms_name.length > 100) {
      setMSNameError(true);
      setLoading(false);
      valid = false;
    }
    if (ms_name.length <= 1) {
      setMSMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (ms_name.length > 0) {
      setMSNameLengthError(false);
    }
    if (ms_name.length >= 2) {
      setMSMinNameLengthError(false);
    }
    if (ms_name.length < 101) {
      setMSNameError(false);
    }
  }

  const validZHNameLength = () => {
    if (zh_name.length > 100) {
      setZHNameError(true);
      setLoading(false);
      valid = false;
    }
    if (zh_name.length <= 1) {
      setZHMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (zh_name.length > 0) {
      setZHNameLengthError(false);
    }
    if (zh_name.length >= 2) {
      setZHMinNameLengthError(false);
    }
    if (zh_name.length < 101) {
      setZHNameError(false);
    }
  }

  const handleImageChange = e => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > 5000000) {
        imageErrorAlert();
      }
      else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
        imageFormatErrorAlert();
      }
      else {
        if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
          let img = new Image;
          reader.onload = function (e) {
            img.onload = function() { 

            if (img.width < 1080 ) {
              removeImg();
              Swal.fire ({
                  icon: "error",
                  title: "Oops",
                  text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
              })
            }
            else {
                handleShow();
            }
            
            };
            img.src = reader.result;
          };
        }
      }

      reader.onloadend = () => {
        setTempSrc(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const onImageLoaded = image => {
    images = image
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9);

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_category.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }

  const saveUrl = () => {
    setFormData({...formData, ['image_url']: cropImageUrl})
}

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({ ...formData,  ["image_url"]: null });
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/center-category",
              state: { currentPage: page, search: search, sortBy: sortBy,sortType: onSortType }
            })
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
                method="post"
                encType="multipart/form-data">
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Add Centre Category</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image (Max file size 5MB, only .jpg, .jpeg, .png)
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "20%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" , marginTop:10}}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        error={nameError,nameLengthError,minNameLengthError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="English Name (max 100 characters)"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" , marginTop:10}}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        error={ms_nameError,ms_nameLengthError,ms_minNameLengthError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Malay Name (max 100 characters)"
                        name="ms_name"
                        value={ms_name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validMSNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {ms_nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {ms_minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {ms_nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" , marginTop:0}}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        error={zh_nameError,zh_nameLengthError,zh_minNameLengthError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Chinese Name (max 100 characters)"
                        name="zh_name"
                        value={zh_name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validZHNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {zh_nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {zh_minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {zh_nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>
                  </GridContainer>

                  <Dialog classes={{ paper: classes.dialogPaper }}
                        open={show==true}
                        onClose={handleClose}
                        maxWidth = {'lg'}
                        disableBackdropClick={true}
                      >
                      <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                      <DialogContent>
                        <ReactCrop
                          locked={true}
                          src={tempSrc}
                          crop={crop}
                          ruleOfThirds
                          imageStyle={{maxHeight:'70vh'}}
                          onImageLoaded={c => onImageLoaded(c)}
                          onChange={c => {setCrop(c)}}
                          onComplete={c => onCropComplete(c)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttons onClick={() => {handleClose();}}>
                          Close
                        </Buttons>
                        <Buttons onClick={() => {handleClose();saveUrl();}}>
                          Save
                        </Buttons>
                      </DialogActions>
                  </Dialog>

                  {/* <GridContainer>
                    <GridItem xs={2} sm={3} md={2}>
                      <TextField
                        required
                        fullWidth
                        style={{ paddding: 0, marginTop: 10 }}
                        margin="normal"
                        name="is_feature"
                        select
                        label="Featured"
                        value={is_feature}
                        onChange={e => updateFormData(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: is_feature == 1 ? classes.activeColor : classes.inactiveColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Yes
                      </MenuItem>
                        <MenuItem key={0} value={0} style={{ color: "#f44336" }}>
                          No
                      </MenuItem>
                      </TextField>
                      {featuredError && (
                        <FormHelperText style={{ color: "#f44336" }}>*This field is required.</FormHelperText>
                        )}
                    </GridItem>
                  </GridContainer> */}

                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Submit
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
}