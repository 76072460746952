import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as AUTH from "../../services/auth";
import * as CATEGORY from "../../services/category";
import * as BOOK from "../../services/book";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { DropzoneDialog } from 'material-ui-dropzone';
import Buttons from '@material-ui/core/Button';
import AuthIFrame from "react-auth-iframe";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  titleColor : {
    color:"#1E90FF",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
}));

const useStyles = styles;

const inputRef = React.createRef();
const inputRefd = React.createRef();
let images = "";

export default function EBookDetail(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [showCrop, setShowCrop] = React.useState(false);
  const [token,setToken] = useState("");
  const [show, setShow] = useState(false); 
  const [tempSrc, setTempSrc] = useState(""); 
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null); 
  const [categorySelection, setCategorySelection] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFeatured, setSelectedFeatured] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [featuredError, setFeaturedError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [descLengthError, setDescLengthError] = useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);
  const [showfileName, setShowFileName] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onCategory =
    props.location.state && props.location.state.category ? props.location.state.category : null;
  const onStatus =
    props.location.state && props.location.state.status ? props.location.state.status : null;
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const [crop, setCrop] = useState({
      unit: 'px',
      width: 1280,
      aspect: 16 / 9,
  });
  const [formData, setFormData] = useState({
    id: props.match.params.id,
    name: "",
    image: "",
    image_url: "",
    image_filename:"",
    desc:"",
    pdf_file:"",
    pdf_url:"",
    pdf_filename:"",
    category:"",
    status:"",
    is_feature:""
  });

  let valid = true;
  const id = props.match.params.id;

  useEffect(() => {
    let params = {};
    params.per_page = 999;
    setLoading(false);

    AUTH.getAccessToken().
      then(accesstoken => {
        setToken(accesstoken);
      });

    CATEGORY.getAllCategoryList()
      .then(result => {
        setCategorySelection(result.data.data);

        BOOK.getBookDetail(id)
        .then(res => {

            setFormData({
                ...formData,
                ["name"]: res['data']['name'],
                ["desc"]: res['data']['desc'],
                ["is_feature"]: setSelectedFeatured(res['data']['is_feature']),
                ["image_filename"]: res['data']['image_filename'],
                ["image_url"]: res['data']['image_url'],
                ["status"]:setSelectedStatus(res['data']['status']['id']),
                ["pdf_filename"]:res['data']['ebook_filename'],
                ['category']:setSelectedCategory(res['data']['categories'][0]['id'])
                // ['category']:setSelectedCategory(Object.values(res['data']['categories'])[0])
              });
              setLoading(false);
            });
        })
        .catch(err => {
            if (err == "token expired") {
            props.history.replace("/login");
            } else {
                if (err.status == "not found") {
                    Swal.fire({
                    icon: "error",
                    title: "Opps",
                    text: "Page Not Found"
                    }).then(() => {
                    props.history.replace("/e-book");
                    });
                }
                if (err === "Unauthorized") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops",
                    text: "Session expired. Please login again"
                    })
                    .then(() => {
                      localStorage.removeItem('BZtokenData');
                      setLoggedIn(false);      
                    });
                }
            }
      });
  },
   []);

  useEffect(() => {

    let img = images;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });
          
    setLoading(false);

  }, [images]);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { 
    name, 
    image_url,
    image_filename, 
    desc, 
    pdf_file,
    pdf_url,
    pdf_filename,
    category,
    status,
    is_feature 
  } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setNameError(false);
    setNameLengthError(false);
    setMinNameLengthError(false);
    setCategoryError(false);
    setDescError(false);
    setDescLengthError(false);
    setStatusError(false);
    // setFeaturedError(false);
    setFileUploadError(false);

    setLoading(true);

    if (fileError) {
      valid = false;
      setLoading(false);
    }
    if (fileFormatError) {
        valid = false;
        setLoading(false);
    }
    if (categoryError) {
      valid = false;
      setLoading(false);
    }
    if (selectedCategory.length == 0) {
      setCategoryError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedStatus.length == 0) {
      setStatusError(true);
      setLoading(false);
      valid = false;
    }
    // if (selectedFeatured.length == 0) {
    //   setFeaturedError(true);
    //   setLoading(false);
    //   valid = false;
    // }
    if (desc.length == 0) {
        setDescLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (name.length == 0) {
      setNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (fileUploadError == true) {
      valid = false;
      setLoading(false);
      setFileUploadError(true);
    }

    if (valid) {

      let updateData = {
        ...formData,
        status:selectedStatus,
        category:selectedCategory,
        image:completedCrop
        // is_feature:selectedFeatured
      };

      setLoading(true);
      BOOK.updateBook(updateData)
        .then(res => {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "e-Book updated successfully"
          })
          // .then(() => {
          //   props.history.replace("/e-book");
          // });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              if (err.errors.image) {
                errMsg.push(err.errors.image);
              }
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
            Swal.fire({
              icon: "error",
              title: "Fail to update e-Book",
              text: errMsg
            });
          }

          setLoading(false);
        });
    }
  }

  const handleShowCrop = () => setShowCrop(true);
  const handleCloseCrop = () => {setShowCrop(false);setTempSrc("");}

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }

  const validDescLength = () => {
    if (desc.length > 1000) {
      setDescError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length > 0) {
      setDescLengthError(false);
    }
    if (desc.length < 1001) {
      setDescError(false);
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);

    if (options != null) {
      setStatusError(false);
    }
  };

  const handleFeaturedChange = event => {
    const options = event.target.value;
    setSelectedFeatured(options);

    if (options != null) {
      setFeaturedError(false);
    }
  };

  const handleCategoryChange = event => {
    const options = event.target.value;
    setSelectedCategory(options);

    if (options != null) {
      setCategoryError(false);
    }
  };

  const handleImageChange = e => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file.size > 5000000) {
            imageErrorAlert();
        }
        else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
            imageFormatErrorAlert();
        }
        else {
            if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                let img = new Image;
                reader.onload = function (e) {
                    img.onload = function() { 

                    if (img.width < 1080 ) {
                    removeImg();
                    Swal.fire ({
                        icon: "error",
                        title: "Oops",
                        text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
                    })
                    }
                    else {
                        handleShowCrop();
                    }
                    
                    };
                    img.src = reader.result;
                }
            };
        }

        reader.onloadend = () => {
            setTempSrc(reader.result);
        };

        reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const onImageLoaded = image => {
    images = image
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9);

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_ebook.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }

  const saveUrl = () => {
      setFormData({...formData, ['image_url']: cropImageUrl})
  }

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({ ...formData, 
        ['image']:null, 
        ['image_filename']:null,  
        ["image_url"]: null });
  };

  const handleFileChange = (files) => {
    setFileError(false);
    let reader = new FileReader();
    let file = files[0];

    if (file.size > 50000000) {
      setFileError(true);
    }

    if (file.type != "application/pdf" ) {
      setFileFormatError(true);
    }

    reader.onloadend = () => {
      setFormData({
        ...formData,
        ["pdf"]: file,
        ["pdf_url"]: reader.result
      });
    };

    reader.readAsDataURL(file);
    setShowFileName(true);

    if (file.name != null) {
      setFileUploadError(false);
    }
  };

  const removeFile = () => {
    inputRefd.current.value = "";
    setFormData({ ...formData,
        ['pdf_file']:null, 
        ['pdf_filename']:null,
        ["pdf_url"]: null 
    });
    setFileError(false);
    setFileFormatError(false);
    setFileUploadError(true);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/e-book",
              state: { 
                currentPage: page, 
                search: search, 
                category: onCategory,
                status: onStatus,
                sortType: onSortType}
            })
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
                method="post"
                encType="multipart/form-data">
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Edit e-Book</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image (Max file size 5MB, only .jpg, .jpeg, .png)
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted",
                              overflow: "hidden"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_url, "_blank");
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem", 
                                  cursor: "pointer"
                                }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "20%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={9} md={9}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={nameError,minNameLengthError,nameLengthError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="Name (max 100 characters)"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF NAME IS 100 CHARACTER.</FormHelperText>
                      )}
                       {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MINIMUM LENGTH OF NAME IS 2 CHARACTER.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                           NAME IS REQUIRED.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>                    
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={statusError}
                        className={classes.formControl}
                      >
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{ focused: classes.inputFocused }}>
                              Status
                        </InputLabel>
                        <Select
                          className={classes.select}
                          id="Status"
                          name="Status"
                          variant="outlined"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                          inputlabelprops={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}>
                        <MenuItem key={1} value={1} style={{ color: "#FFA500" }}>
                          Draft
                        </MenuItem>
                        <MenuItem key={2} value={2} style={{ color: "#4caf50" }}>
                          Published
                        </MenuItem>
                      </Select>
                      {statusError && (
                          <FormHelperText style={{ color: "#f44336"}}>
                             STATUS IS REQUIRED.</FormHelperText>
                        )}
                      </FormControl>
                  </GridItem>

                  {/* <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={featuredError}
                        className={classes.formControl}
                      >
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{ focused: classes.inputFocused }}>
                              Featured
                        </InputLabel>
                        <Select
                          className={classes.select}
                          id="Featured"
                          name="Featured"
                          variant="outlined"
                          value={selectedFeatured}
                          onChange={handleFeaturedChange}
                          inputlabelprops={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Yes
                        </MenuItem>
                        <MenuItem key={0} value={0} style={{ color: "#f44336" }}>
                          No
                        </MenuItem>
                      </Select>
                      {featuredError && (
                          <FormHelperText style={{ color: "#f44336"}}>
                            THIS FIELD IS REQUIRED.</FormHelperText>
                        )}
                      </FormControl>
                  </GridItem> */}
                
                    <GridItem xs={12} sm={3} md={3}>
                        <FormControl
                            required
                            fullWidth={true}
                            style={{ marginLeft: 0,marginTop: 10 }}
                            error={categoryError}
                            className={classes.formControl}>
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ focused: classes.inputFocused }}>
                            Category
                        </InputLabel>
                        <Select
                            className={classes.select}
                            id="category"
                            name="category"
                            variant="outlined"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            input={<Input id="category" />}
                            inputlabelprops={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                            >
                            {categorySelection.map(category => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {categoryError && (
                        <FormHelperText>PLEASE SELECT ONE(1) CATEGORY.</FormHelperText>
                      )}
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={9} md={9}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={descError,descLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      multiline
                      rows="4"
                      label="Description (max 1000 characters)"
                      name="desc"
                      value={desc}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validDescLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {descError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF DESCRIPTION IS 1000 CHARACTER.</FormHelperText>
                      )}
                    {descLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          DESCRIPTION IS REQUIRED.</FormHelperText>
                      )}  
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4 
                        style={{fontSize:15,color:"#1E90FF",fontWeight:"bold",marginTop:10}}>
                          File (Max file size 50MB, only .pdf is allowed)
                      </h4>
                      </GridItem>
                      
                      <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "4rem"
                        }}
                      >
                        <div style={{ width: "auto" }}>
                            {pdf_filename ? (
                              <div style={{marginTop:10}}>  
                                 Click to read :
                                 <Buttons 
                                    onClick={() => {
                                      handleShow()
                                    }}
                                    style={{
                                      fontWeight:"bold",
                                      color:"#8B008B"
                                    }}
                                >
                                  {pdf_filename}
                                 </Buttons>

                                <Dialog
                                  fullScreen
                                  open={show==true}
                                  onClose={handleClose}
                                  fullWidth={true}
                                  maxWidth = {'lg'}
                                >
                                    <DialogContent>
                                      <AuthIFrame
                                        id="content"
                                        src={process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX +'/ml-ebook-file/'+id+'#toolbar=0&navpanes=0&scrollbar=0'}
                                        token={token}
                                        frameBorder="0" 
                                        height={window.innerHeight-40} width="100%"
                                        type="application/pdf" />
                                    </DialogContent>
                                    <DialogActions>
                                      <Buttons onClick={handleClose}>
                                        Close
                                      </Buttons>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            ) : (
                              <div style={{marginTop:20}}>
                              <Button
                              id="pdfbutton" 
                              variant="contained" 
                              style={{ backgroundColor: themeColor}}
                              onClick={() => setOpen(true)}>
                                Add e-book
                              </Button>
        
                              <DropzoneDialog
                                acceptedFiles={['application/pdf']}
                                fileObjects={fileObjects}
                                cancelButtonText={"cancel"}
                                submitButtonText={"save"}
                                maxFileSize={50000000}
                                filesLimit={1}
                                open={open}
                                onClose={() => setOpen(false)}
                                onSave={(files) => {
                                  handleFileChange(files);
                                  setOpen(false);
                                  setFileObjects(files[0].name);
                                  document.getElementById("pdfbutton").style.display = "none";
                                }}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                                useChipsForPreview={true}
                                showAlerts={true}
                              />
                            {showfileName ? (
                                <GridContainer>
                                  <GridItem>
                                    <div
                                      style={{
                                        fontSize:15,
                                        color:"red"
                                      }}
                                    >
                                      File Uploaded : {fileObjects}
                                    </div>
                                    <Button
                                      id="changepdfbutton" 
                                      variant="contained" 
                                      style={{ backgroundColor: themeColor, marginTop:5}}
                                      onClick={() => setOpen(true)}
                                      >
                                        Change 
                                    </Button>
        
                                  </GridItem>
                                </GridContainer>
                            ) : null}
                              {fileUploadError && (
                                <FormHelperText style={{ color: "#f44336",fontWeight:"bold"}}>
                                  PLEASE UPLOAD A FILE.</FormHelperText>
                              )}
                            </div>
                              )}    
                        </div>                          
                       
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRefd}
                          accept="application/pdf"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleFileChange(e)}
                        />
                      </div>
                    </GridItem>

                      <GridItem>                 
                        <div style={{ paddingLeft: 0,height: "4rem" }}>
                          {pdf_filename ? (
                            <Button
                              onClick={removeFile}
                              style={{
                                backgroundColor: themeColor,
                                color: "white"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                      </GridItem>

                  </GridContainer>

                  <Dialog classes={{ paper: classes.dialogPaper }}
                        open={showCrop==true}
                        onClose={handleCloseCrop}
                        maxWidth = {'lg'}
                        disableBackdropClick={true}
                      >
                      <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                      <DialogContent>
                        <ReactCrop
                          locked={true}
                          src={tempSrc}
                          crop={crop}
                          ruleOfThirds
                          imageStyle={{maxHeight:'70vh'}}
                          onImageLoaded={c => onImageLoaded(c)}
                          onChange={c => {setCrop(c)}}
                          onComplete={c => onCropComplete(c)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttons onClick={() => {handleCloseCrop();}}>
                          Close
                        </Buttons>
                        <Buttons onClick={() => {handleCloseCrop();saveUrl();}}>
                          Save
                        </Buttons>
                      </DialogActions>
                    </Dialog>
              
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Update
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
}