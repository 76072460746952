import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import * as COMMISSION from "../../services/commission";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect, Link } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";


const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: "orange"
    }
  },
  table: {
    width: "100%"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

const useStyles = styles;

export default function Commission(props) {
  const classes = useStyles();
  const [commissionList, setCommissionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );

  const routeTo = (action, com) => {
    setRoute({ action: action, com: com });
  };

  useEffect(() => {
    COMMISSION.getCommissionType(currentPage)
      .then(result => {
        setCommissionList(result.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
      });
  }, [currentPage]);

  if (route.action == "default") {
    return (
      <Redirect
        to={{
          pathname: "/commission/" + route.com.id,
          state: { mode: "default", com: route.com }
        }}
      />
    );
  } else if (route.action == "eligibility") {
    return (
      <Redirect
        to={{
          pathname: "/commission/" + route.com.id,
          state: { mode: "eligibility", com: route.com }
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ paddingBottom: 20 }}>
          <CardBody>
            {commissionList.length > 0 ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>ID</TableCell>
                      <TableCell className={classes.th}>Name</TableCell>
                      <TableCell className={classes.th}>Max Level</TableCell>
                      <TableCell className={classes.th}>Status</TableCell>
                      <TableCell className={classes.th} style={{ textAlign: "center" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commissionList.map(commission => (
                      <TableRow className={classes.tr} key={commission.id}>
                        <TableCell className={classes.td} component="th" scope="row">
                          {commission.id}
                        </TableCell>
                        <TableCell className={classes.td}>{commission.name}</TableCell>
                        <TableCell className={classes.td}>{commission.max_level}</TableCell>
                        <TableCell
                          style={{
                            color: commission.status_id == 1 ? "#4caf50" : "#f44336",
                            fontWeight: "400"
                          }}
                          className={classes.td}>
                          {commission.status_name}
                        </TableCell>
                        <TableCell className={classes.td} style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              padding: 5,
                              textTransform: "none",
                              marginRight: 12,
                              margin: 4
                            }}
                            className={classes.submit}
                            onClick={() => routeTo("default", commission)}>
                            Default Commission Rate
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              padding: 5,
                              textTransform: "none"
                            }}
                            className={classes.submit}
                            onClick={() => routeTo("eligibility", commission)}>
                            Commission Eligibility
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div style={{ paddingTop: 30, textAlign: "center" }}>
                <Typography style={{ fontWeight: "inherit" }}>No commission found</Typography>
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
