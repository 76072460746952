import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import * as CATEGORY from "../../services/category";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import defaultCover from "assets/img/default_cover.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Swal from "sweetalert2";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function Category(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSortType, setSelectedSortType] = useState("-- Select --");
  const [selectedSortBy, setSelectedSortBy] = useState(props.location.state && props.location.state.sortBy ? props.location.state.sortBy : "-- Select --");
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [pageArr, setPageArr] = React.useState([]);

  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
      if (selectedSortType != "-- Select --") {
        params.sortType = selectedSortType;
      }

      if (selectedSortBy != "-- Select --")
        params.sortBy = selectedSortBy;
    }

    if (props.location.state) {

      if (props.location.state.search) {
        params.search = props.location.state.search;
        setSearchInput(params.search);
        props.location.state.search = null;
      }
      if (props.location.state.sortBy) {
        params.sortBy = props.location.state.sortBy;
        setSelectedSortBy(params.sortBy);
        props.location.state.sortBy = null;
      }
      if (props.location.state.sortType == "asc" || props.location.state.sortType == "desc") {
        params.sortType = props.location.state.sortType;
        setSelectedSortType(params.sortType);
        props.location.state.sortType = null;
      }
    }
    props.history.replace({
      pathname: "/category",
      state: {}
    });

    CATEGORY.getCategoryList(currentPage, params)
      .then(res => {
        if (res.data) {
          let page = [];
          for (let i = 0; i < res.data.last_page; i++) {
            page.push(i + 1);
          }
          setPageArr(page);
        }

        let i = 1; 
          res.data.data.map(datum => {
              datum.indexNumber = (currentPage - 1 ) * 10 + i;
              i++;
              return datum;
          })

        setCategoryList(res.data.data);
        setPagination(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });
  }, [currentPage, onFilter]);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const handleSortTypeChange = event => {
    const options = event.target.value;
    setSelectedSortType(options);
  };

  const handleDelete = (event, category) => {
    Swal.fire({
      title: 'Are you sure to delete this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
      .then((result) => {
        if (result.value) {
          const newCategoryList = [...categoryList];
          const index = newCategoryList.indexOf(category);
          if (index !== -1) {
            newCategoryList.splice(index, 1);
            setCategoryList(newCategoryList);
          }
          CATEGORY.deleteCategory(category.id)
            .then(res => {
              Swal.fire({
                icon: "success",
                title: "Category deleted successfully"
              }).then(() => {
                props.history.push("/category");
              });
            })
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          props.history.replace("/category");
        }
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedSortBy + selectedSortType + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSearchInput("");
    setSelectedSortBy("-- Select --");
    setSelectedSortType("-- Select --");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const handleSortByChange = event => {
    const options = event.target.value;
    setSelectedSortBy(options);
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    sort_by: selectedSortBy,
    sortType: selectedSortType

  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/category/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/category/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: themeColor, color: "white" }}
            className={classes.submit}
            onClick={() => routeTo("add")}>
            <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add Category
        </Button>
          <div
            id="table"
            ref={node => {
              myRef = node;
            }}>
            <Card>
              <CardBody>
                <GridContainer style={{ paddingBottom: 10 }}>
                  <GridItem xs={12} sm={4} md={3}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      id="search"
                      label="Search"
                      name="search"
                      placeholder={"category name"}
                      fullWidth
                      onKeyDown={keyPress}
                      value={searchInput}
                      onChange={handleSearchInput}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>

                  {/* <GridItem xs={5} sm={4} md={3} style={{ marginTop: 10 }}>
                    Sort by:
                  <Select
                      style={{ marginLeft: 10 }}
                      className={classes.select}
                      id="type"
                      name="type"
                      variant="outlined"
                      value={selectedSortBy}
                      onChange={handleSortByChange}
                      renderValue={selected =>
                        selected == 'is_feature' ? "Featured" : selected
                      }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"Unset"} />
                      </MenuItem>
                      <MenuItem key={1} value={'is_feature'}>
                        <ListItemText primary={"Featured"} />
                      </MenuItem>
                    </Select>
                  </GridItem> */}

                  <GridItem xs={12} sm={4} md={3} style={{ marginTop: 10 }}>
                  Sort Type :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="sorttype"
                    name="sorttype"
                    variant="outlined"
                    value={selectedSortType}
                    onChange={handleSortTypeChange}
                    renderValue={selected =>
                        selected == "asc" ? "Ascending" : selected == "desc" ? "Descending" : selected
                    }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={1} value={"asc"}>
                        <ListItemText primary={"Ascending"} />
                      </MenuItem>
                      <MenuItem key={2} value={"desc"}>
                        <ListItemText primary={"Descending"} />
                      </MenuItem>                     
                  </Select>
                </GridItem>

                  <GridItem xs={12} sm={4} md={3}
                    style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                      className={classes.submit}
                      onClick={filterThis}>
                      Search
                  </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: themeColor,
                        color: "white",
                        marginLeft: 8,
                        marginBottom: 8
                      }}
                      className={classes.submit}
                      onClick={clearFilter}>
                      Reset
                  </Button>
                  </GridItem>
                </GridContainer>
                {categoryList.length > 0 ? (
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.th}>#</TableCell>
                          {/* <TableCell className={classes.th}>Category ID</TableCell> */}
                          <TableCell style={{ width: 50 }} className={classes.th}></TableCell>
                          <TableCell className={classes.th}>Name</TableCell>
                          <TableCell className={classes.th}>Created At</TableCell>
                          {/* <TableCell className={classes.th}>Featured</TableCell> */}
                          <TableCell className={classes.th}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {categoryList.map(category => (
                          <TableRow
                            onClick={() => routeTo("view", category.id)}
                            className={classes.tr}
                            key={category.id}>
                            <TableCell className={classes.td} component="th" scope="row">
                              {category.indexNumber}
                            </TableCell>
                            <TableCell className={classes.td}>
                              {/* <img
                                src={
                                  category.image_url
                                    ? category.image_url
                                    : category.avatar
                                      ? category.avatar
                                      : defaultCover
                                }
                                ali=""
                                width="40"
                                height="40"
                                style={{ borderRadius: 20 }}
                              /> */}
                            </TableCell>
                            <TableCell className={classes.td}>{category.name}</TableCell>
                            <TableCell className={classes.td}>
                              {category.created_at.substring(0, 10) +
                                " " +
                                category.created_at.substring(11, 19)}
                            </TableCell>
                            {/* <TableCell
                              style={{
                                color: category.is_feature == 1 ? "#4caf50" : "#f44336",
                                fontWeight: "400"
                              }}
                              className={classes.td}>
                              {category.is_feature == 1 ? "Yes" : "No"}
                            </TableCell> */}

                            <TableCell className={classes.td}>
                              <IconButton aria-label="delete"
                                onClick={(event) => { handleDelete(event.stopPropagation(), category) }} >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {categoryList.length > 0 ? (
                      <div
                        style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                        <div style={{ fontSize: 13 }}>
                          Page :
                        <Select
                            style={{
                              marginLeft: 10,
                              width: 60,
                              fontSize: 13,
                              textAlign: "center",
                              marginTop: 12
                            }}
                            className={classes.select}
                            id="type"
                            name="type"
                            variant="outlined"
                            value={currentPage}
                            onChange={e => goToPage(e)}
                            input={<Input id="type" />}
                            renderValue={selected => selected}
                            MenuProps={MenuProps}>
                            {pageArr.map(page => (
                              <MenuItem key={page} value={page}>
                                <ListItemText primary={page} />
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <TablePagination
                          component="div"
                          rowsPerPageOptions={[]}
                          count={pagination.total}
                          rowsPerPage={10}
                          page={currentPage - 1}
                          onChangePage={handleChangePage}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                    <div style={{ paddingTop: 30, textAlign: "center" }}>
                      <Typography style={{ fontWeight: "inherit" }}>No Category found</Typography>
                    </div>
                  )}
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    );
}
