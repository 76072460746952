import * as AUTH from "./auth";
const axios = require("axios");

export async function getStateList() {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/ml-states?country_id=1")
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getCityList(stateid) {
    let url =
    process.env.REACT_APP_API_URL +
    "/" +
    "api/ml-cities?state_id=" +
    stateid ;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
  }

export async function getCentreList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-center?" +
    "page=" +
    page +
    "&per_page=10";

  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.sortType) url += "&sort_type=" + params.sortType ;
    if (params.status) url += "&status=" + params.status;

    if (params.category) {
      if (params.category == "-- Select --") {
        params.category = ""
      }
      url += "&categories=" + params.category;
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout:60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getUserList() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
    .get(
      process.env.REACT_APP_API_URL + "/" 
      + process.env.REACT_APP_API_PREFIX + 
      "/user?per_page=99999",
      {
      timeout:60000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken
      }
    }
    )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function addCenter(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();

  data.append("center_owner_id",createData.owner_id);
  data.append("name", createData.name);
  data.append("desc", createData.desc);
  data.append("contact_num", "0"+createData.mobile_number);
  data.append("street_address", createData.street_address);
  data.append("status", createData.status);
  data.append("city_name", createData.city);
  data.append("state_id", createData.state);
  data.append("postcode", createData.postcode);
  data.append("category[0][id]",createData.category); 
  data.append("latitude",createData.latitude);
  data.append("longitude",createData.longitude);

  createData.images.forEach((image, index)=>{
    data.append("image["+ index + "][file]", image.imagefile);
    data.append("image[" + index + "][is_banner]", createData.is_banner == image.id ? 1 : 0);
  });

  createData.videos.forEach((video,index)=> {
    data.append("video",video.videofile);
  })

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/" 
        + process.env.REACT_APP_API_PREFIX + "/ml-center",data,
        {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function getCentreDetail(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/ml-center/" + id, {
        timeout:60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function updateCentre(updateData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  
  data.append("name", updateData.name);
  data.append("desc", updateData.desc);
  data.append("contact_num", updateData.mobile_number);
  data.append("street_address", updateData.street_address);
  data.append("status", updateData.status);
  data.append("city_name", updateData.city);
  data.append("state_id", updateData.state);
  data.append("postcode", updateData.postcode);
  data.append("category[0][id]",updateData.category); 
  data.append("latitude",updateData.latitude);
  data.append("longitude",updateData.longitude);

  if (updateData.remark) data.append("remark",updateData.remark);

  updateData.images.forEach((image, index)=>{
    if (image.imagefile) {
      data.append("image["+ index + "][file]", image.imagefile);
      data.append("image[" + index + "][is_banner]", updateData.is_banner == image.id ? 1 : 0);
    }
    else if (image.imagefilename) {
      data.append("image["+ index + "][filename]", image.imagefilename);
      data.append("image[" + index + "][is_banner]", updateData.is_banner == image.id ? 1 : 0); 
    }
  });

  updateData.videos.forEach((video,index)=> {
    if (video.videofile) {
      data.append("video",video.videofile);
    }
    else if (video.videofilename) {
      data.append("video_filename",video.videofilename)
    }
  });

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/ml-center/" +
          updateData.id +
          "?_method=put",
        data,
        {
          timeout: 60000,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function deleteCentre(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-center/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function ExportCentreList(params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-center-export?per_page=99999" ;

  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.sortType) url += "&sort_type=" + params.sortType ;
    if (params.status) url += "&status=" + params.status;
    if (params.category) url += "&categories=" + params.category;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'blob',
        timeout:60000,
        headers: {
          "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        let newDate = new Date();
        Date.prototype.today = function () { 
          return  this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
        }   
        Date.prototype.timeNow = function () {
           return ((this.getHours() < 10)?"0":"") + this.getHours() +"-"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +"-"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
        }
        let dateTime = newDate.today() + " " + newDate.timeNow();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'center('+ dateTime + ').xlsx');
        document.body.appendChild(link);
        link.click();
        
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}