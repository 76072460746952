import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import * as REPORT from "../../services/report";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import PieChart from "react-minimal-pie-chart";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import * as moment from "moment";
import defaultCover from "assets/img/default_cover.png";
import Swal from "sweetalert2";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6"
      // cursor: 'pointer'
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:before": {
      borderColor: themeColor
    },
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const defaultLabelStyle = {
  fill: "#121212",
  fontFamily: "sans-serif",
  fontSize: "5px"
};

export default function Report(props) {
  const classes = useStyles();
  const [reportSelection, setReportSelection] = useState([]);
  const [reportContent, setReportContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pieData, setPieData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [selectedType, setSelectedType] = useState("-- Select --");
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [onFilter, setOnFilter] = useState("");

  useEffect(() => {
    REPORT.getReportSelection()
      .then(result => {
        setReportSelection(result.data);
        setSelectedType(Object.entries(result.data)[0]);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selectedType[0] !== "-") {
      let params = {};
      if (onFilter) {
        if (selectedFromDate) {
          params.from = moment(selectedFromDate).format("YYYY-MM-DD");
        }
        if (selectedToDate) {
          params.to = moment(selectedToDate).format("YYYY-MM-DD");
        }
      }
      REPORT.getReport(selectedType[0], params)
        .then(result => {
          setReportContent(result.data);
          if (selectedType[0] === "user") {
            let data = Object.entries(result.data);
            let tempList = [];
            let countTotal = 0;
            const color = ["#58508d", "#ff6361", "#ffa600", "#bc5090"];
            for (let i = 0; i < data.length; i++) {
              tempList.push({ title: data[i][0], value: data[i][1], color: color[i] });
              countTotal += data[i][1];
            }
            setTotalUser(countTotal);
            setPieData(tempList);
          } else if (selectedType[0] === "sales") {
            let data = Object.entries(result.data);
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
              tempList.push({ type: data[i][0], content: Object.entries(data[i][1]) });
            }
            setSalesData(tempList);
          }
          setLoading(false);
        })
        .catch(err => {
          if (err === "token expired") {
            props.history.replace("/login");
          }
        });
    }
  }, [onFilter, selectedType]);

  const filterThis = () => {
    if (selectedFromDate != "Invalid Date" && selectedToDate != "Invalid Date") {
      if (selectedToDate != null && selectedToDate < selectedFromDate) {
        Swal.fire({
          icon: "error",
          title: "Search Fail",
          text: "End date cannot be ealier than start date"
        });
      } else {
        setLoading(true);
        setOnFilter(selectedFromDate + selectedToDate + selectedType + new Date());
      }
    }
  };

  const renderSalesReport = () => {
    return (
      <div>
        {salesData.map(data => (
          <div key={data.type}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <div style={{ fontSize: 22, paddingTop: 20 }}>
                  {data.type.charAt(0).toUpperCase() + data.type.slice(1)} Sales
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {data.content.map((content, index) => (
                <GridItem key={index} xs={12} sm={6} md={3}>
                  <Card style={{ margin: 0, marginTop: 10, border: "2px solid" + themeColor }}>
                    <div style={{ paddingLeft: 10, paddingBottom: 8 }}>
                      {content[0].charAt(0).toUpperCase() + content[0].slice(1)}{" "}
                      {content[0] === "amount" ? " (RM)" : null}
                    </div>
                    <div style={{ paddingLeft: 10, fontSize: 25, paddingBottom: 8 }}>
                      {content[1]}
                    </div>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </div>
        ))}
      </div>
    );
  };

  const renderUserReport = () => {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <div style={{ fontSize: 22, paddingTop: 20 }}>Total User : {totalUser}</div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {pieData.map(data => (
            <GridItem key={data.title} xs={12} sm={6} md={3}>
              <Card style={{ border: "2px solid black", borderColor: data.color }}>
                <div style={{ paddingLeft: 10, paddingBottom: 8 }}>{data.title}</div>
                <div style={{ paddingLeft: 10, fontSize: 25, paddingBottom: 8 }}>{data.value}</div>
              </Card>
            </GridItem>
          ))}
          {totalUser !== 0 ? (
            <GridItem xs={12} sm={12} md={12}>
              <PieChart
                animate
                animationDuration={500}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={pieData}
                label={({ data, dataIndex }) =>
                  Math.round(data[dataIndex].percentage) + "%" !== "0%"
                    ? Math.round(data[dataIndex].percentage) + "%"
                    : ""
                }
                radius={42}
                labelPosition={110}
                labelStyle={defaultLabelStyle}
                style={{
                  height: "300px"
                }}
              />
            </GridItem>
          ) : null}
        </GridContainer>
      </div>
    );
  };

  const renderCourseReport = () => {
    return (
      <Table style={{ marginTop: 40 }} className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>ID</TableCell>
            <TableCell style={{ width: 50 }} className={classes.th}></TableCell>
            <TableCell className={classes.th}>Name</TableCell>
            <TableCell className={classes.th}>Total Learner</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportContent.map(course => (
            <TableRow className={classes.tr} key={course.id}>
              <TableCell className={classes.td} component="th" scope="row">
                {course.id}
              </TableCell>
              <TableCell className={classes.td}>
                <img
                  alt=""
                  src={
                    course.cover_image
                      ? course.cover_image
                      : course.avatar
                      ? course.avatar
                      : defaultCover
                  }
                  width="40"
                  height="40"
                  style={{ borderRadius: 20 }}
                />
              </TableCell>
              <TableCell className={classes.td}>{course.name}</TableCell>
              <TableCell className={classes.td}>{course.learner.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem style={{ marginBottom: 20 }} xs={12} sm={12} md={12}>
        Type :
        <Select
          style={{ marginLeft: 10, width: 150 }}
          className={classes.select}
          id="type"
          name="type"
          variant="outlined"
          value={Array.isArray(selectedType) ? selectedType[1] : selectedType}
          onChange={event => {
            setSelectedFromDate(null);
            setSelectedToDate(null);
            setReportContent([]);
            setSelectedType(event.target.value);
          }}
          input={<Input id="type" />}
          renderValue={selected => selected}>
          {Object.entries(reportSelection).map(type => (
            <MenuItem key={type[0]} value={type}>
              <ListItemText primary={type[1]} />
            </MenuItem>
          ))}
        </Select>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning" style={{ width: "fit-content" }}>
            <h4 className={classes.cardTitleWhite}>{selectedType[1]} Report</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={4} md={3}>
                From:
              </GridItem>
              <GridItem xs={6} sm={4} md={3}>
                To:
              </GridItem>
            </GridContainer>
            <GridContainer>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GridItem xs={6} sm={4} md={3} style={{ marginTop: 3, marginBottom: 8 }}>
                  <KeyboardDatePicker
                    clearable
                    value={selectedFromDate}
                    placeholder="Start Date"
                    onChange={date => setSelectedFromDate(date)}
                    format="dd/MM/yyyy"
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={4} md={3} style={{ marginTop: 3, marginBottom: 8 }}>
                  <KeyboardDatePicker
                    clearable
                    value={selectedToDate}
                    placeholder="End Date"
                    onChange={date => setSelectedToDate(date)}
                    format="dd/MM/yyyy"
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                  />
                </GridItem>
              </MuiPickersUtilsProvider>
              <GridItem xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: themeColor, color: "white" }}
                  className={classes.submit}
                  onClick={filterThis}>
                  Search
                </Button>
              </GridItem>
            </GridContainer>
            {selectedType[0] === "user" ? (
              renderUserReport()
            ) : selectedType[0] === "sales" ? (
              renderSalesReport()
            ) : reportContent.length > 0 ? (
              renderCourseReport()
            ) : (
              <div style={{ paddingTop: 30, textAlign: "center" }}>
                <Typography style={{ fontWeight: "inherit" }}>No course found</Typography>
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
