import * as AUTH from "./auth";
const axios = require("axios");

export async function getShowCaseSetting() {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-showcase-setting";

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}


export async function updateShowCase(updateData) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();

    updateData.showcase.forEach((showcase,index)=>{
      data.append("showcases[" + index + "][id]",showcase.id);
      data.append("showcases[" + index + "][type]",showcase.type);
      data.append("showcases[" + index + "][name]",showcase.name);
      data.append("showcases[" + index + "][zh_name]",showcase.zh_name);
      data.append("showcases[" + index + "][ms_name]",showcase.ms_name);
      data.append("showcases[" + index + "][value]",showcase.value);
      data.append("showcases[" + index + "][order]",showcase.order);
      if (showcase.imagefile) {
        data.append("showcases[" + index + "][image]",showcase.imagefile);
      }
      else {
        if (showcase.imagefilename) {
          data.append("showcases[" + index + "][image_filename]",showcase.imagefilename);
        } 
      }  
    });
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "/" +
            process.env.REACT_APP_API_PREFIX +
            "/ml-showcase-setting" +
            "?_method=PUT",
          data,
          {
          timeout: 60000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
}