import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import * as CATEGORY from "../../services/category";
import * as BOOK from "../../services/book";
import * as FEATURED from "../../services/featuredcategory";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import defaultCover from "assets/img/default_cover.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from "sweetalert2";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const listtype = [
  {
    value: "000",
    label: "All"
  },
  {
    value: "100",
    label: "Category"
  },
  {
    value: "200",
    label: "Centre Category"
  }
]

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function FeaturedCategory(props) {
  const classes = useStyles();
  const [categorySelection, setCategorySelection] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [featuredList, setFeaturedList] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("-- Select --");
  const [selectedStatus, setSelectedStatus] = useState("-- Select --");
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [pageArr, setPageArr] = React.useState([]);
  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
      if (selectedCategory != "-- Select --") {
        params.category = selectedCategory;
      }
      if (selectedStatus != "-- Select --") {
        params.status = selectedStatus;
      }
    }

    if (props.location.state) {
      if (props.location.state.search) {
        params.search = props.location.state.search;
        setSearchInput(params.search);
        props.location.state.search = null;
      }

      if (props.location.state.category) {
        params.category = props.location.state.category;
        setSelectedCategory(params.category);
        props.location.state.category = null;
      }
      if (props.location.state.status == 1 || props.location.state.status == 2 ) {
        params.status = props.location.state.status;
        setSelectedStatus(params.status);
        props.location.state.status = null;
      }
    }
    props.history.replace({
      pathname: "/featured-category",
      state: {}
    });

    CATEGORY.getCategoryList()
      .then(res => {
          setCategorySelection(res.data.data);
      })

      FEATURED.getFeaturedList()
      .then(res => {
          setFeaturedList(res.data.app_first_center_category_selected);
          console.log(res.data.app_first_center_category_selected);
          console.log(featuredList.length);
          setLoading(false);
      })

    // BOOK.getBookList(currentPage, params)
    //   .then(res => {
    //     if (res.data) {
    //       let page = [];
    //       for (let i = 0; i < res.data.last_page; i++) {
    //         page.push(i + 1);
    //       }
    //       setPageArr(page);
    //     }
    //     setBookList(res.data.data);
    //     setPagination(res.data);
    //     setLoading(false);
    //   })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
      });
  }, [currentPage, onFilter]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };
  
  const handleSortCategoryChange = event => {
    const options = event.target.value;
    setSelectedCategory(options);
  };

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleDelete = (event,book) => {
    Swal.fire({
      title: 'Are you sure to delete this ebook?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    .then((result) => {
      if (result.value) {
        const newBookList = [...bookList];
        const index = newBookList.indexOf(book);
        if (index !== -1) {
          newBookList.splice(index,1);
          setBookList(newBookList);
        }    
        BOOK.deleteBook(book.id)   
        .then(res => {
          Swal.fire({
            icon: "success",
            title: "eBook deleted successfully"
          }).then(() => {
            props.history.push("/featured-category");
          });
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        props.history.replace("/featured-category");
      }
    })
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedCategory + selectedStatus + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSearchInput("");
    setSelectedCategory("-- Select --");
    setSelectedStatus("-- Select --");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    category: selectedCategory,
    status: selectedStatus
  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/featured-category/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/featured-category/" + route.id,
          state: paramPass
        }}
      />
    );
  }
console.log(featuredList);
  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem style={{ marginBottom: 20 }} xs={12} sm={12} md={12}>
        Type :
        {/* <Select
          style={{ marginLeft: 10, width: 150 }}
          className={classes.select}
          id="type"
          name="type"
          variant="outlined"
          value={selectedType}
          onChange={event => {
            setSelectedType(event.target.value);
          }}
          input={<Input id="type" />}
        >
          {listtype.map(option => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
           ))}
        </Select> */}
      </GridItem>
       
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
               <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem xs={6} sm={6} md={3}>
                {/*  <TextField
                    variant="standard"
                    margin="normal"
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"name"}
                    fullWidth
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={5} sm={5} md={3} style={{ marginTop: 10 }}>
                  Category :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="category"
                    name="category"
                    variant="outlined"
                    value={selectedCategory}
                    onChange={handleSortCategoryChange}
                    >
                     <MenuItem key={"all"} value={"-- Select --"}>
                      -- Select --
                      </MenuItem>
                    {categorySelection.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                          {category.name}
                      </MenuItem>
                      ))}
                  </Select>
                </GridItem>

                <GridItem xs={5} sm={5} md={3} style={{ marginTop: 10 }}>
                  Status :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="status"
                    name="status"
                    variant="outlined"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    renderValue={selected =>
                        selected == 1 ? "Draft" : selected == 2 ? "Published" : selected
                      }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        <ListItemText primary={"Draft"} />
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        <ListItemText primary={"Published"} />
                      </MenuItem>
                     
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={3}
                  style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 8
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button> */}
                </GridItem>
              </GridContainer> 
              {/* {featuredList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>ID</TableCell>
                        <TableCell style={{ width: 80 }} className={classes.th}></TableCell>
                        <TableCell className={classes.th}>Name</TableCell>
                        <TableCell className={classes.th}>Status</TableCell>                        
                        <TableCell className={classes.th}>Category</TableCell>
                        <TableCell className={classes.th}>Feature</TableCell>
                        <TableCell className={classes.th}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {featuredList.map(book => (
                        <TableRow
                          onClick={() => routeTo("view", book.id)}
                          className={classes.tr}
                          key={book.id}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {book.id}
                          </TableCell>
                          <TableCell className={classes.td}>
                            <img
                              src={
                                book.image_url
                                  ? book.image_url
                                  : book.avatar
                                  ? book.avatar
                                  : defaultCover
                              }
                              ali=""
                              width="40"
                              height="40"
                              style={{ borderRadius: 20 }}
                            />
                          </TableCell>
                          <TableCell className={classes.td}>{book.name}</TableCell>
                          <TableCell
                            style={{
                              color: 
                              book.status.id == 1 ? "#FFA500" : "#4caf50" ,
                              fontWeight: "400"
                            }}
                            className={classes.td}>
                            {book.status.name}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {book.categories.length > 0 
                              ? book.categories.map((categories,i) => 
                                categories[i + 1] ? categories.name + ", "
                                : categories.name) : ""}
                          </TableCell>
                          <TableCell
                              style={{
                                color: book.is_feature == 1 ? "#4caf50" : "#f44336",
                                fontWeight: "400"
                              }}
                              className={classes.td}>
                              {book.is_feature == 1 ? "Yes" : "No"}
                            </TableCell>
                          <TableCell>
                              <IconButton aria-label="delete"
                                  onClick={(event) => {handleDelete(event.stopPropagation(),book) }} >
                                  <DeleteIcon />
                              </IconButton> 
                          </TableCell> 
                        </TableRow>
                      ))}
                    </TableBody> */}
                  {/* </Table> */}
                  {/* {bookList.length > 0 ? (
                    <div
                      style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                      <div style={{ fontSize: 13 }}>
                        Page :
                        <Select
                          style={{
                            marginLeft: 10,
                            width: 60,
                            fontSize: 13,
                            textAlign: "center",
                            marginTop: 12
                          }}
                          className={classes.select}
                          id="type"
                          name="type"
                          variant="outlined"
                          value={currentPage}
                          onChange={e => goToPage(e)}
                          input={<Input id="type" />}
                          renderValue={selected => selected}
                          MenuProps={MenuProps}>
                          {pageArr.map(page => (
                            <MenuItem key={page} value={page}>
                              <ListItemText primary={page} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={pagination.total}
                        rowsPerPage={10}
                        page={currentPage - 1}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  ) : null} */}
                {/* </div> */}
              {/* ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No eBook found</Typography>
                </div>
              )} */}
            </CardBody>
          </Card>
        </div>
      {/* </GridItem> */}
    </GridContainer>
  );
}