import * as AUTH from "./auth";
const axios = require("axios");

export async function getUserList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/user?" +
    "page=" +
    page +
    "&per_page=10";
  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.type) url += "&user_type=" + params.type;
    if (params.status) url += "&status=" + params.status;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
      .finally();
  });
}

export async function getUserSelection() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/edit-user-selection",
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getUser(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/user/" + id, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getDealerList(params) {
  let accessToken = await AUTH.getAccessToken();
  let url = process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/dealer";
  if (params) {
    url += "?" + params;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function updateUser(updateData) {
  let accessToken = await AUTH.getAccessToken();
  let data = {};
  if (updateData.password.length > 0) {
    data.password = updateData.password;
  }
  data.first_name = updateData.first_name;
  data.last_name = updateData.last_name;
  data.email = updateData.email;
  data.mobile_number_prefix = updateData.mobile_number_prefix;
  data.mobile_number = updateData.mobile_number;
  data.status = updateData.status;
  data.user_type = updateData.user_type;
  return new Promise((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/user/" +
          updateData.id,
        data,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function addUser(data) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/user",
        {
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          mobile_number_prefix: data.mobile_number_prefix,
          mobile_number: data.mobile_number,
          user_type: data.user_type,
          status: data.status
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}
