import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import * as CLASS from "../../services/class";
import * as CATEGORY from "../../services/category";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import * as moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";

const dayCode = [
  {
    value:"1",
    label: "One (1)"
  },
  {
    value:"2",
    label: "Two (2)"
  },
  {
    value:"3",
    label: "Three (3)"
  },
  {
    value:"4",
    label: "Four (4)"
  },
  {
    value:"5",
    label: "Five (5)"
  },
  {
    value:"6",
    label: "Six (6)"
  },
  {
    value:"7",
    label: "Seven (7)"
  }
]

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    marginTop: theme.spacing(2),
    "&:before": {
      borderColor: themeColor
    },
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
  inputFocused: {}
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

const inputRef1 = React.createRef();
let images = "";

export default function AddClass(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [centreSelection, setCentreSelection] = useState([]);
  const [teacherSelection, setTeacherSelection] = useState([]);
  const [coordinatorSelection, setCoordinatorSelection] = useState([]);
  const [categorySelection, setCategorySelection] = useState([]);
  const [selectedClassPerWeek, setSelectedClassPerWeek] = useState("");
  const [selectedCentre, setSelectedCentre] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCoordinator, setSelectedCoordinator] = useState([]);
  const [selectedCoordinatorID, setSelectedCoordinatorID] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [imageObject, setImageObject] = useState([]);
  const [itemid, setItemid] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [tempSrc, setTempSrc] = useState(""); 
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null);  
  const [loading, setLoading] = useState(true);
  const [featuredError, setFeaturedError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [centreError, setCentreError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [teacherError, setTeacherError] = useState(false);
  const [coordinatorError, setCoordinatorError] = useState(false);
  const [coordinatorLengthError, setCoordinatorLengthError] = useState(false);
  const [weekError, setWeekError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [descLengthError, setDescLengthError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [value, setValue] = React.useState('');

  const [crop, setCrop] = useState({
    unit: 'px',
    width: 1280,
    aspect: 16/9 
  });

  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const sortBy =
    props.location.state && props.location.state.sortBy
      ? props.location.state.sortBy
      : "";
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;

  const onSortType =
    props.location.state && props.location.state.sortType 
      ? props.location.state.sortType : null;

  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    is_feature:"",
    is_banner:"",   
    status: "",
    category:"",
    coordinator:[],
    start_date:"",
    end_date:"",
    teacher:"",
    centre:"",
    type:"",
    retail_price:"",
    promotion_price:"",
    week:""
  });

  const {
    name,
    desc,
    // is_feature,
    status,
    category,
    coordinator,
    start_date,
    end_date,
    teacher,
    centre,
    type,
    retail_price,
    promotion_price,
    week
  } = formData;
  
  let valid = true;
  useEffect(() => {
    CLASS.getUserList()
      .then(res => {       
        setTeacherSelection(res.data);
        setCoordinatorSelection(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
            props.history.replace("/login");
            });
        }
      });

    CLASS.getCentreList()
      .then(res => {       
        setCentreSelection(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
            props.history.replace("/login");
            });
        }
      });  
    
    CATEGORY.getCategoryList()
      .then(res => {       
        setCategorySelection(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
            props.history.replace("/login");
            });
        }
      });
  }, 
  []);

  useEffect(() => {
    let img = images;
    const scaleX = img.naturalWidth / img.width;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });   
    setLoading(false);  
  }, [images]);

  const updateFormData = event => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setStatusError(false);
    setTypeError(false);
    setCategoryError(false);
    setCentreError(false);
    // setFeaturedError(false);
    setTeacherError(false);
    setCoordinatorError(false);
    setNameError(false);
    setNameLengthError(false);
    setMinNameLengthError(false);
    setWeekError(false);

    setLoading(true);
  
    if (selectedToDate != null && selectedToDate < selectedFromDate) {
      Swal.fire({
        icon: "error",
        title: "End Date cannot be earlier than Start Date"
      });
      valid = false;
      setLoading(false);
    }
    // if (is_feature.length == 0) {
    //   setFeaturedError(true);
    //   setLoading(false);
    //   valid = false;
    // }
    if (selectedType.length == 0) {
      setTypeError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedStatus.length == 0) {
      setStatusError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedCentre.length == 0) {
      setCentreError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedTeacher.length == 0) {
      setTeacherError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedType == 1){
      if (selectedClassPerWeek.length == 0) {
        setWeekError(true);
        setLoading(false);
        valid = false;
      }
    }
    if (selectedCategory.length == 0) {
      setCategoryError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedCoordinatorID.length < 1) {
      setCoordinatorError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length == 0) {
      setNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length == 0) {
      setDescLengthError(true);
      setLoading(false);
      valid = false;
    }

    let startdate="",enddate="",classperweek="";
    if (selectedFromDate != null) {
       startdate = moment(selectedFromDate).format("YYYY-MM-DD");
    }  else {
      startdate = "";
    }

    if (selectedToDate != null) {
       enddate = moment(selectedToDate).format("YYYY-MM-DD");
    } else {
      enddate = "";
    }

    if (selectedClassPerWeek.length != 0) {
      classperweek = selectedClassPerWeek;
    } else {
      classperweek = "";
    }

    if (valid) {
      let createData = {
        ... formData,
        images: imageObject,
        status:selectedStatus,
        teacher:selectedTeacher,
        centre:selectedCentre,
        type:selectedType,
        week:classperweek,
        category:selectedCategory,
        is_banner:value,
        start_date:startdate,
        end_date:enddate
      };

      CLASS.addClass(createData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Class created successfully"
          });
          props.history.replace("/class");
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              setLoading(false);            
              if(err.errors.image) {
                errMsg.push(err.errors.image);
              }
              else if (err == "timeout of 60000ms exceeded") {
                errMsg.push("Request timeout. You may experience a slow network.")
              }
              else {
                errMsg.push(err)
              }
            }
            else { 
              Swal.fire({
                icon: "error",
                title: "Fail to Add Class",
                text: errMsg
              });             
          }
          setLoading(false);
        }
        });
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => {setShow(false);setTempSrc("");}

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const validDescLength = () => {
    if (desc.length > 1000) {
      setDescError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length > 0) {
      setDescLengthError(false);
    }
    if (desc.length < 1001) {
      setDescError(false);
    }
  }

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }

  const validCoordinatorLength = () => {
    if (selectedCoordinatorID.length > 3) {
      setCoordinatorLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (selectedCoordinatorID.length <= 3) {
      setCoordinatorLengthError(false);
    }
  }

  // const validPrice = () => {
  //   if (promotion_price > retail_price) {
  //     setPriceError(true);
  //     setLoading(false);
  //     valid = false;
  //   }
  //   if (promotion_price < retail_price) {
  //     setPriceError(false);
  //   }
  //   console.log("promo " + promotion_price.value);
  //   console.log("retail " + retail_price.value);
  // }

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleTypeChange = event => {
    const options = event.target.value;
    setSelectedType(options);
  };

  const handleCentreChange = event => {
    const options = event.target.value;
    setSelectedCentre(options);
  };

  const handleTeacherChange = event => {
    const options = event.target.value;
    setSelectedTeacher(options);
  };

  const handleClassChange = event => {
    const options = event.target.value;
    setSelectedClassPerWeek(options);
  };

  const handleCategoryChange = event => {
    const options = event.target.value;
    setSelectedCategory(options);
  };

  const handleCoordinatorChange = event => {
    let c_options = event.target.value;
    let selectedCoor = [];
    let C_ID = [];
    for (let i = 0, l = c_options.length; i < l; i += 1) {
      if (c_options[i]) {
        C_ID.push(c_options[i].id);
        selectedCoor.push(c_options[i]);
        setSelectedCoordinator(selectedCoor);
        setSelectedCoordinatorID(C_ID);        
      }
    }
    setFormData({ ...formData, ["coordinator"]: C_ID });
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > 5000000) {
        imageErrorAlert();
      }
      else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
        imageFormatErrorAlert();
      }
      else {
        if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
          let img = new Image;
          reader.onload = function (e) {
            img.onload = function() { 
    
            if (img.width < 1080 ) {
              removeImg();
              Swal.fire ({
                  icon: "error",
                  title: "Oops",
                  text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
              })
            }
            else {
                handleShow();
            }
            
            };
            img.src = reader.result;
          };
        }
      }

      reader.onloadend = () => {
        setTempSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  //start of crop image function
  const onImageLoaded = image => {
    images = image;
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9) ;

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_centreImage.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }
  // end of crop image function

  const saveUrl = () => {
    setImageObject([...imageObject, {
        id : itemid,
        imagefile: completedCrop,
        url : cropImageUrl
    }]);
    setItemid(itemid+1);
  }

  const removeImg = (id) => {
    setImageObject(
      imageObject.filter(image => {
        return image.id !== id;
      })
    );
    setTempSrc("");
  };

  const handleCheckbox = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        // color="primary"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/class",
            state: {
              currentPage: page,
              search: search,
              sortBy: sortBy,
              status: onStatus,
              sortType: onSortType
            }
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={11}>
          <Card>
            <form
              className={classes.form}
              onSubmit={event => handleSubmit(event)}
            >
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Add Class</h4>
              </CardHeader>
              <CardBody>  
                
                <GridContainer style={{ paddingTop: 10 }}>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={nameError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Class Name"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        autoFocus
                        // inputProps={{ maxLength: 50 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                       {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF NAME IS 100 CHARACTER.</FormHelperText>
                      )}
                       {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MINIMUM LENGTH OF NAME IS 2 CHARACTER.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                           NAME IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0,marginTop: 15 }}
                      error={categoryError}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Category
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="category"
                        name="category"
                        variant="outlined"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        input={<Input id="category" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                        >
                        {categorySelection.map(category => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {categoryError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          CATEGORY IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={descError,descLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      rows="4"
                      label="Description"
                      name="desc"
                      value={desc}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validDescLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {descError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF DESCRIPTION IS 1000 CHARACTER.</FormHelperText>
                      )}
                    {descLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          DESCRIPTION IS REQUIRED.</FormHelperText>
                      )}  
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={6} sm={4} md={4}>                   
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label={"Retail Price (RM)"}
                      name="retail_price"
                      type="number"
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                        min: 0,
                        max: 10000,
                        step: ".10"
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>

                  <GridItem xs={6} sm={4} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label={"Promotion Price (RM)"}
                      name="promotion_price"
                      type="number"
                      onChange={e => updateFormData(e)}
                      // onClick={validPrice}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                        min: 0,
                        max: 10000,
                        step: ".10"
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {priceError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          PROMOTION PRICE MUST BE LESS THAN RETAIL PRICE.</FormHelperText>
                      )}
                  </GridItem>
                </GridContainer>

                <GridContainer style={{marginTop:0}}>
                  <GridItem xs={5} sm={5} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 8 }}
                      error={centreError}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Centre
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="centre"
                        name="centre"
                        variant="outlined"
                        value={selectedCentre}
                        onChange={handleCentreChange}
                        input={<Input id="owner" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {centreSelection.map(centre => (
                          <MenuItem key={centre.id} value={centre.id}>
                            {centre.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {centreError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          CENTRE IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem> 

                  <GridItem xs={5} sm={4} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={teacherError}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Teacher
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="teacher"
                        name="teacher"
                        variant="outlined"
                        value={selectedTeacher}
                        onChange={handleTeacherChange}
                        input={<Input id="teacher" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {teacherSelection.map(teacher => (
                          <MenuItem key={teacher.id} value={teacher.id}>
                            {teacher.first_name + " " + teacher.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {teacherError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          TEACHER IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={5} sm={5} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={coordinatorError}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Coordinator
                      </InputLabel>
                      <Select
                        multiple={true}
                        className={classes.select}
                        id="coordinator"
                        name="coordinator"
                        variant="outlined"
                        value={selectedCoordinator}
                        onChange={handleCoordinatorChange}
                        onClick={validCoordinatorLength}
                        MenuProps={MenuProps}
                        input={<Input id="coordinator" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                        renderValue={selected =>
                          selected.length > 0
                            ? selected.map((coordinator, i) =>
                                selected[i + 1] ? coordinator.first_name + " " + coordinator.last_name + ", " 
                                : coordinator.first_name + " " + coordinator.last_name
                              )
                            : ""
                        }>
                        {coordinatorSelection.map(coordinator => (
                          <MenuItem key={coordinator.id} value={coordinator}>
                            <Checkbox
                              style={{
                                color: themeColor
                              }}
                              checked={
                                selectedCoordinator &&
                                selectedCoordinator.find(selected => selected.id == coordinator.id)
                                  ? true
                                  : false
                              }
                            />
                            <ListItemText primary={coordinator.first_name + " " + coordinator.last_name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {coordinatorError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          PLEASE SELECT AT LEAST ONE(1) COORDINATOR.</FormHelperText>
                      )}
                      {coordinatorLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          MAXIMUM THREE(3) COORDINATOR CAN BE SELECTED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>   

                <GridContainer>
                  {/* <GridItem xs={5} sm={3} md={3}>
                      <TextField
                        required
                        fullWidth
                        style={{ paddding: 0, marginTop: 15 }}
                        margin="normal"
                        name="is_feature"
                        select
                        label="Featured"
                        value={is_feature}
                        onChange={e => updateFormData(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Yes
                      </MenuItem>
                        <MenuItem key={0} value={0} style={{ color: "#f44336" }}>
                          No
                      </MenuItem>
                      </TextField>
                      {featuredError && (
                        <FormHelperText style={{ color: "#f44336" }}>
                        THIS FIELD IS REQUIRED.</FormHelperText>
                        )}
                  </GridItem> */}

                  <GridItem xs={5} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      error={statusError}
                      className={classes.formControl}
                    >
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Status
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="Status"
                        name="Status"
                        variant="outlined"
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                      <MenuItem key={1} value={1} style={{ color: "#FFA500" }}>
                        Pending
                      </MenuItem>
                      <MenuItem key={2} value={2} style={{ color: "#4caf50" }}>
                        Published
                      </MenuItem>
                      </Select>
                    {statusError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          STATUS IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={5} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      error={typeError}
                      className={classes.formControl}
                    >
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Class Type
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="class_type"
                        name="class_type"
                        variant="outlined"
                        value={selectedType}
                        onChange={handleTypeChange}
                        input={<Input id="type" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                      <MenuItem key={1} value={1} style={{ color: "#1E90FF" }}>
                        Monthly
                      </MenuItem>
                      <MenuItem key={2} value={2} style={{ color: "#7B68EE" }}>
                        Daily
                      </MenuItem>
                    </Select>
                    </FormControl>
                    {typeError && (
                        <FormHelperText style={{ color: "#f44336" }}>
                          CLASS TYPE IS REQUIRED.</FormHelperText>
                    )}
                  </GridItem>
                </GridContainer>  

              {(selectedType == 1) ? (
                <GridContainer>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GridItem xs={6} sm={3} md={3} style={{ marginTop: 8}}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedFromDate}
                        label="Start Date"
                        onChange={date => setSelectedFromDate(date)}
                        format="dd-MM-yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={3} md={3} style={{ marginTop: 8 }}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedToDate}
                        label="End Date"
                        onChange={date => setSelectedToDate(date)}
                        format="dd-MM-yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                  </MuiPickersUtilsProvider>

                  <GridItem xs={6} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0}}
                      error={weekError}
                      className={classes.formControl}
                    >
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Session
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="class_per_week"
                        name="class_per_week"
                        variant="outlined"
                        value={selectedClassPerWeek}
                        onChange={handleClassChange}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {dayCode.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}  
                    </Select>
                    {weekError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THIS FIELD IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                    </GridItem>
                </GridContainer>
                ) : null}   

                <RadioGroup aria-label="banner" 
                    name="banner" 
                    value={value} 
                    onChange={(e) => handleCheckbox(e)}
                >

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography style={{ fontWeight: "inherit" }}>
                  Image (Max 5MB, only accept .jpg, .jpeg and .png format)
                  </Typography>
                </div>

                 <GridContainer
                    direction="row"
                    alignItems="center">

                  {imageObject.map((image, index) => {
                    return (
                    <GridItem xs={12} sm={7} md={7}>
                      Image 
                        <div 
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                              <img
                                src={image.url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem",
                                  cursor: "pointer"
                                }}
                              />
                          </Box>
                        </div>

                          <div style={{ paddingLeft: 5 }}>
                            <div>

                            <div>
                              <Radio
                                name="banner"
                                style={{
                                  color: themeColor,
                                  padding: 5
                                }}
                                checked={value == image.id}
                                value={image.id}
                                inputProps={{ "aria-label": "1" }}
                              />
                                Set as banner?
                            </div>

                            <div>
                              <Button
                                onClick={() => {
                                  removeImg(image.id);
                                }}
                                style={{
                                    backgroundColor: themeColor,
                                    color: "white",
                                    marginLeft: 5,
                                    marginTop: "15%"
                                }}
                                variant="contained">
                                  Remove Image 
                              </Button>
                            </div>
                            
                            </div>
                        </div>
                      </div>                    
                    </GridItem>
                  )})}

                    {imageObject.length < 3 ? (
                    <GridItem xs={12} sm={5} md={5}>
                      <div>
                        <Button id="showbutton1"
                            onClick={() => {
                              inputRef1.current.click();
                            }}
                            style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 5,
                                marginTop: "10%"
                            }}
                            variant="contained">
                              Add Image 
                        </Button>
                              
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef1}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                    ):null}

                </GridContainer>     
              </RadioGroup>

              <Dialog classes={{ paper: classes.dialogPaper }}
                  open={show==true}
                  onClose={handleClose}
                  maxWidth = {'lg'}
                  disableBackdropClick={true}
              >
              <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
              <DialogContent>
                  <ReactCrop
                      locked={true}
                      src={tempSrc}
                      crop={crop}
                      ruleOfThirds
                      imageStyle={{maxHeight:'70vh'}}
                      onImageLoaded={c => onImageLoaded(c)}
                      onChange={c => {setCrop(c)}}
                      onComplete={c => onCropComplete(c)}
                  />
              </DialogContent>
              <DialogActions>
                  <Buttons onClick={() => {handleClose();}}>
                      Close
                  </Buttons>
                  <Buttons onClick={() => {handleClose();saveUrl();}}>
                      Save
                  </Buttons>
              </DialogActions>
              </Dialog>

              </CardBody>

              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}