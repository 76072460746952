import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import * as VOUCHER from "../../services/voucher";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect, Link } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: "orange"
    }
  },
  table: {
    width: "100%"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function Voucher(props) {
  const classes = useStyles();
  const [voucherList, setVoucherList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [pageArr, setPageArr] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
  };

  useEffect(() => {
    VOUCHER.getVoucherList(currentPage)
      .then(result => {
        let page = [];
        for (let i = 0; i < result.pagination.last_page; i++) {
          page.push(i + 1);
        }
        props.history.replace({
          pathname: "/coupon",
          state: {}
        });
        setPageArr(page);
        setVoucherList(result.data.data);
        setPagination(result.pagination);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
      });
  }, [currentPage]);

  const paramPass = {
    page: currentPage
  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/coupon/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/coupon/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("add")}>
          <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add Coupon
        </Button>
        <Card>
          <CardBody>
            {voucherList.length > 0 ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>ID</TableCell>
                      <TableCell className={classes.th}>Name</TableCell>
                      <TableCell className={classes.th}>Quantity</TableCell>
                      <TableCell className={classes.th}>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {voucherList.map(voucher => (
                      <TableRow
                        onClick={() => routeTo("view", voucher.id)}
                        className={classes.tr}
                        key={voucher.id}>
                        <TableCell className={classes.td} component="th" scope="row">
                          {voucher.id}
                        </TableCell>
                        <TableCell className={classes.td}>{voucher.name}</TableCell>
                        <TableCell className={classes.td}>{voucher.quantity}</TableCell>
                        <TableCell className={classes.td}>{voucher.created_at}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div style={{ paddingTop: 30, textAlign: "center" }}>
                <Typography style={{ fontWeight: "inherit" }}>No coupon found</Typography>
              </div>
            )}
            {voucherList.length > 0 ? (
              <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                <div style={{ fontSize: 13 }}>
                  Page :
                  <Select
                    style={{
                      marginLeft: 10,
                      width: 60,
                      fontSize: 13,
                      textAlign: "center",
                      marginTop: 12
                    }}
                    className={classes.select}
                    id="page"
                    name="page"
                    variant="outlined"
                    value={currentPage}
                    MenuProps={MenuProps}
                    onChange={e => goToPage(e)}
                    input={<Input id="page" />}
                    renderValue={selected => selected}>
                    {pageArr.map(page => (
                      <MenuItem key={page} value={page}>
                        <ListItemText primary={page} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={pagination.total}
                  rowsPerPage={10}
                  page={currentPage - 1}
                  onChangePage={handleChangePage}
                />
              </div>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
