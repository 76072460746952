import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as VOUCHER from "../../services/voucher";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert2";
import { defaultFont } from "assets/jss/material-dashboard-react.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    paddingBottom: 4,
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 4,
    paddingBottom: 4
  }
};

const useStyles = makeStyles(styles);

export default function VoucherDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [voucher, setVoucher] = useState(true);
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentCodes, setCurrentCodes] = React.useState(1);
  const onPage = props.location.state && props.location.state.page ? props.location.state.page : 1;

  useEffect(() => {
    const id = props.match.params.id;
    VOUCHER.getVoucher(id)
      .then(result => {
        setVoucher(result.data);
        const indexOfLastCode = currentPage * 10;
        const indexOfFirstCode = indexOfLastCode - 10;
        const currentCodes = result.data.code.slice(indexOfFirstCode, indexOfLastCode);
        setCurrentCodes(currentCodes);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        } else {
          if (err.status == "not found") {
            if (err.status == "not found") {
              Swal.fire({
                icon: "error",
                title: "Opps",
                text: "Page Not Found"
              }).then(() => {
                props.history.replace("/coupon");
              });
            }
          }
        }
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    const indexOfLastCode = (newPage + 1) * 10;
    const indexOfFirstCode = indexOfLastCode - 10;
    const currentCodes = voucher.code.slice(indexOfFirstCode, indexOfLastCode);
    setCurrentCodes(currentCodes);
    setLoading(false);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/coupon",
            state: { currentPage: onPage }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" style={{ width: "fit-content" }}>
              <h4 className={classes.cardTitleWhite}>{voucher.name}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={9} sm={4} md={4} style={{ marginTop: 5 }}>
                  <Typography
                    style={{ textTransform: "none", fontWeight: "400" }}
                    component="h6"
                    variant="subtitle2">
                    Course:
                  </Typography>
                  {voucher.bundle.map(bundle => (
                    <div key={bundle.course.id}>{bundle.course.name}</div>
                  ))}
                </GridItem>
                <GridItem xs={3} sm={2} md={2} style={{ marginTop: 5 }}>
                  <Typography
                    style={{ textTransform: "none", fontWeight: "400" }}
                    component="h6"
                    variant="subtitle2">
                    Status:
                  </Typography>
                  <div
                    style={{
                      color: voucher.status_id == 1 ? "#4caf50" : "#f44336",
                      fontWeight: "400"
                    }}>
                    {voucher.status_name}
                  </div>
                </GridItem>
                <GridItem xs={7} sm={3} md={3} style={{ marginTop: 5 }}>
                  <Typography
                    style={{ textTransform: "none", fontWeight: "400" }}
                    component="h6"
                    variant="subtitle2">
                    Issued By:
                  </Typography>
                  <div>{voucher.issued_by}</div>
                </GridItem>
                <GridItem xs={5} sm={3} md={3} style={{ marginTop: 5 }}>
                  <Typography
                    style={{ textTransform: "none", fontWeight: "400" }}
                    component="h6"
                    variant="subtitle2">
                    Created at:
                  </Typography>
                  {voucher.created_at.substring(0, 10) + " " + voucher.created_at.substring(11, 19)}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem style={{ marginTop: 20, overflowX: "auto" }} xs={12} sm={12} md={12}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>ID</TableCell>
                        <TableCell className={classes.th}>Code</TableCell>
                        <TableCell className={classes.th}>Redeemed By</TableCell>
                        <TableCell className={classes.th}>Redeemed At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentCodes.map(code => (
                        <TableRow className={classes.tr} key={code.id}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {code.id}
                          </TableCell>
                          <TableCell className={classes.td}>{code.code}</TableCell>
                          <TableCell className={classes.td}>
                            {code.redeemer ? code.redeemer.username : "-"}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {code.redeemed_at ? code.redeemed_at : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={voucher.code.length}
                    rowsPerPage={10}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
