import * as AUTH from "./auth";
const axios = require("axios");

export async function getBookList(page, params) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-ebook?" +
      "page=" +
      page +
      "&per_page=10";
    if (params) {
      if (params.search) url += "&search=" + params.search;

      if (params.category) {
        if (params.category == "-- Select --") {
          params.category = ""
        }
        url += "&categories=" + params.category;
      }

      if (params.sortType) url += "&sort_type=" + params.sortType ;
      if (params.status) url += "&status=" + params.status;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
}

export async function addBook(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("name", createData.name);
  data.append("desc", createData.desc);
  data.append("status", createData.status);
  // data.append("is_feature",createData.is_feature);
  data.append("category[0][id]",createData.category); 

  if (createData.image) {
    data.append("image", createData.image);
  }

  if (createData.pdf) 
  data.append("ebook",createData.pdf);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/" 
        + process.env.REACT_APP_API_PREFIX + "/ml-ebook",data,
        {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function getBookDetail(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/ml-ebook/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function updateBook(updateData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("name", updateData.name);
  data.append("desc", updateData.desc);
  data.append("status", updateData.status);
  // data.append("is_feature",updateData.is_feature);
  data.append("category[0][id]",updateData.category); 

  if (updateData.image) {
    data.append("image", updateData.image);
  } else {
    if (updateData.image_filename) {
      data.append("image_filename", updateData.image_filename);
    };
  }

  if(updateData.pdf_filename) {
    data.append("ebook_filename",updateData.pdf_filename);
  } 
  else {
    if (updateData.pdf) {
      data.append("ebook",updateData.pdf);
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/ml-ebook/" +
          updateData.id +
          "?_method=put",
        data,
        {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function deleteBook(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-ebook/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}