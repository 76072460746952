import * as AUTH from "./auth";
const axios = require("axios");

export async function getFavouriteList(page, params,type) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-favourite?" +
      "page=" +
      page +
      "&per_page=10" + "&type=" + type
    if (params) {
      if (params.search) url += "&user=" + params.search;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }

  export async function getUserList() {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
      .get(
        process.env.REACT_APP_API_URL + "/" 
        + process.env.REACT_APP_API_PREFIX + 
        "/user?per_page=99999" ,
        {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }

  export async function getListDetail(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/" + 
        process.env.REACT_APP_API_PREFIX + 
        "/ml-favourite/" + id, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            if (error.response) {
              if (error.response.data) {
                reject(error.response.data);
              }
            }
          }
        })
        .finally();
    });
  }

  export async function getUserData(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
      .get(
        process.env.REACT_APP_API_URL + "/" 
        + process.env.REACT_APP_API_PREFIX + 
        "/user/" +  id,
        {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }