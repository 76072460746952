import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import * as USER from "../../services/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import logo from "assets/img/logo_ant-internet_web.png";


const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function UserManagement(props) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [userSelection, setUserSelection] = useState([]);
  const [selectedType, setSelectedType] = useState("-- Select --");
  const [selectedStatus, setSelectedStatus] = useState("-- Select --");
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [pagination, setPagination] = React.useState([]);
  const [pageArr, setPageArr] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (Array.isArray(selectedStatus)) {
        params.status = selectedStatus[0];
      }
      if (Array.isArray(selectedType)) {
        params.type = selectedType[0];
      }
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
    }
    setLoading(false);
    // USER.getUserSelection()
    //   .then(res => {
    //     setUserSelection(res.data);
    //   })
    //   .then(() => {
        // if (props.location.state) {
        //   if (props.location.state.search) {
        //     params.search = props.location.state.search;
        //     setSearchInput(params.search);
        //     props.location.state.search = null;
        //   }
        //   if (props.location.state.type) {
        //     params.type = props.location.state.type[0];
        //     setSelectedType(props.location.state.type);
        //     props.location.state.type = null;
        //   }
        //   if (props.location.state.status) {
        //     params.status = props.location.state.status[0];
        //     setSelectedStatus(props.location.state.status);
        //     props.location.state.status = null;
        //   }
        // }
        // props.history.replace({
        //   pathname: "/dashboard",
        //   state: {}
        // });
        // USER.getUserList(currentPage, params)
        //   .then(result => {
        //     let page = [];
        //     for (let i = 0; i < result.pagination.last_page; i++) {
        //       page.push(i + 1);
        //     }
        //     setPageArr(page);
        //     setPagination(result.pagination);
        //     setUserList(result.data);
        //     setLoading(false);
        //   })
        //   .catch(err => {
        //     if (err == "token expired") {
        //       props.history.replace("/login");
        //     }
        //   });
      // });
  }, [onFilter, currentPage]);

  const handleTypeChange = event => {
    const options = event.target.value;
    setSelectedType(options);
  };

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedStatus + selectedType + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSelectedStatus("-- Select --");
    setSelectedType("-- Select --");
    setSearchInput("");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

//   const paramPass = {
//     page: currentPage,
//     search: searchInput,
//     type: Array.isArray(selectedType) ? selectedType : null,
//     status: Array.isArray(selectedStatus) ? selectedStatus : null
//   };

//   if (route.action == "add") {
//     return (
//       <Redirect
//         to={{
//           pathname: "/dashboard/add",
//           state: paramPass
//         }}
//       />
//     );
//   } else if (route.action == "view") {
//     return (
//       <Redirect
//         to={{
//           pathname: "/dashboard/" + route.id,
//           state: paramPass
//         }}
//       />
//     );
//   }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer id="table">
      <GridItem xs={12} sm={12} md={12}>
        {/* <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("add")}>
          <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add User
        </Button> */}
        <Card>
          <CardBody>

            <div style={{ paddingTop: 30, textAlign: "center", paddingBottom: 30 }}>
                <Typography style={{ fontWeight: "inherit" }}>Welcome to Ant Internet Ecard Daacaa Admin Panel</Typography>
            </div>
            
            <div style={{ flexDirection: "row" , height:"auto", textAlign:"center"}}>
              <GridItem>
              <img
                src={logo}
                style={{ width: "70%", height: "auto", marginBottom: 20 }}
              />
              </GridItem>
            </div>
            {/* <GridContainer style={{ alignItems: "center", paddingBottom: 10 }}>
              <GridItem xs={7} sm={6} md={3}>
                <TextField
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="search"
                  label="Search"
                  name="search"
                  placeholder={"name/mobile number"}
                  onKeyDown={keyPress}
                  value={searchInput}
                  onChange={handleSearchInput}
                  InputProps={{
                    classes: {
                      underline: classes.underline,
                      disabled: classes.disabled
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={5} sm={6} md={3}>
                Type :
                <Select
                  style={{ marginLeft: 10 }}
                  className={classes.select}
                  id="type"
                  name="type"
                  variant="outlined"
                  value={Array.isArray(selectedType) ? selectedType[1] : selectedType}
                  onChange={handleTypeChange}
                  input={<Input id="type" />}
                  renderValue={selected => selected}>
                  <MenuItem key={"all"} value={"-- Select --"}>
                    <ListItemText primary={"All"} />
                  </MenuItem>
                  {Object.entries(userSelection.user_type).map(type => (
                    <MenuItem key={type[0]} value={type}>
                      <ListItemText primary={type[1]} />
                    </MenuItem>
                  ))}
                </Select>
              </GridItem>
              <GridItem xs={6} sm={5} md={3}>
                Status :
                <Select
                  style={{ marginLeft: 10 }}
                  className={classes.select}
                  id="status"
                  name="status"
                  variant="outlined"
                  value={Array.isArray(selectedStatus) ? selectedStatus[1] : selectedStatus}
                  onChange={handleStatusChange}
                  input={<Input id="status" />}
                  renderValue={selected => selected}>
                  <MenuItem key={"all"} value={"-- Select --"}>
                    <ListItemText primary={"All"} />
                  </MenuItem>
                  {Object.entries(userSelection.status).map(status => (
                    <MenuItem key={status[0]} value={status}>
                      <ListItemText primary={status[1]} />
                    </MenuItem>
                  ))}
                </Select>
              </GridItem>
              <GridItem xs={4} sm={4} md={3} style={{ display: "inline-flex", marginTop: 10 }}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: themeColor, color: "white" }}
                  className={classes.submit}
                  onClick={filterThis}>
                  Search
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: themeColor, color: "white", marginLeft: 8 }}
                  className={classes.submit}
                  onClick={clearFilter}>
                  Reset
                </Button>
              </GridItem>
            </GridContainer> */}
            {/* {userList.length > 0 ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>ID</TableCell>
                      <TableCell className={classes.th}>Name</TableCell>
                      <TableCell className={classes.th}>Email</TableCell>
                      <TableCell className={classes.th}>Mobile Number</TableCell>
                      <TableCell className={classes.th}>Type</TableCell>
                      <TableCell className={classes.th}>Created At</TableCell>
                      <TableCell className={classes.th}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.map(user => (
                      <TableRow
                        onClick={() => routeTo("view", user.id)}
                        className={classes.tr}
                        key={user.id}>
                        <TableCell className={classes.td} component="th" scope="row">
                          {user.id}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.first_name} {user.last_name}
                        </TableCell>
                        <TableCell className={classes.td}>{user.email}</TableCell>
                        <TableCell className={classes.td}>
                          {user.full_mobile_number ? user.full_mobile_number : "-"}
                        </TableCell>
                        <TableCell className={classes.td}>{user.user_type_name}</TableCell>
                        <TableCell className={classes.td}>
                          {user.created_at.substring(0, 10) +
                            " " +
                            user.created_at.substring(11, 19)}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              user.status == 1 ? "#4caf50" : user.status == 2 ? "#f44336" : "black",
                            fontWeight: "400"
                          }}
                          className={classes.td}>
                          {user.status_name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div style={{ paddingTop: 30, textAlign: "center" }}>
                <Typography style={{ fontWeight: "inherit" }}>No user found</Typography>
              </div>
            )} */}
            {/* {userList.length > 0 ? (
              <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                <div style={{ fontSize: 13 }}>
                  Page :
                  <Select
                    style={{
                      marginLeft: 10,
                      width: 60,
                      fontSize: 13,
                      textAlign: "center",
                      marginTop: 12
                    }}
                    className={classes.select}
                    id="page"
                    name="page"
                    variant="outlined"
                    value={currentPage}
                    MenuProps={MenuProps}
                    onChange={e => goToPage(e)}
                    input={<Input id="page" />}
                    renderValue={selected => selected}>
                    {pageArr.map(page => (
                      <MenuItem key={page} value={page}>
                        <ListItemText primary={page} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={pagination.total}
                  rowsPerPage={10}
                  page={currentPage - 1}
                  onChangePage={handleChangePage}
                />
              </div>
            ) : null} */}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
