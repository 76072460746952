import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as CATEGORY from "../../services/category";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Router, Route, Redirect } from "react-router-dom";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  }
}));

const useStyles = styles;

const inputRef = React.createRef();

export default function CategoryDetail(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [ms_nameError, setMSNameError] = useState(false);
  const [ms_minNameLengthError, setMSMinNameLengthError] = useState(false);
  const [ms_nameLengthError, setMSNameLengthError] = useState(false);
  const [zh_nameError, setZHNameError] = useState(false);
  const [zh_minNameLengthError, setZHMinNameLengthError] = useState(false);
  const [zh_nameLengthError, setZHNameLengthError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const sortBy =
    props.location.state && props.location.state.sort_by ? props.location.state.sort_by : "";
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const [formData, setFormData] = useState({
    id: props.match.params.id,
    name: "",
    // is_feature: "",
    image: "",
    image_url: "",
    zh_name:"",
    ms_name:""
  });

  let valid = true;
  useEffect(() => {
    const id = props.match.params.id;

    CATEGORY.getClassCategory(id)
      .then(result => {

        let ms = "";
        if (result['data']['ms_name'] == null || result['data']['ms_name'] == "") {
          ms = "N/A"
        } else {
          ms = result['data']['ms_name']
        }

        let zh = "";
        if (result['data']['zh_name'] == null || result['data']['zh_name'] == "") {
          zh = "N/A"
        } else {
          zh = result['data']['zh_name']
        }


        setFormData({
          ...formData,
          ["name"]: result['data']['name'],
          ["zh_name"]:zh,
          ["ms_name"]:ms,
          ["is_feature"]: result['data']['is_feature'],
          ["image_filename"]: result['data']['image_filename'],
          ["image_url"]: result['data']['image_url']
        });
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
        else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found"
            }).then(() => {
              props.history.replace("/category");
            });
          }
        }
      });
  }, []);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { name, is_feature, image_url, ms_name, zh_name } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setImageError(false);
    setImageFormatError(false);
    setNameError(false);
    setNameLengthError(false);

    if (imageError) {
      valid = false;
      setLoading(false);
    }

    if (imageFormatError) {
      valid = false;
      setLoading(false);
    }

    if (nameError || nameLengthError || minNameLengthError 
      || ms_nameError || ms_nameLengthError || ms_minNameLengthError 
      || zh_nameError || zh_nameLengthError || zh_minNameLengthError) {
        valid = false;
        setLoading(false);
      }

    if (hasError) setHasError(false);

    if (valid) {

      let updateData = formData;
      updateData = {
        ...updateData
      }

      setLoading(true);
      CATEGORY.updateClassCategory(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Category update successful"
          })
          // .then(() => {
          //   props.history.replace("/category");
          // });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                }).then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              // if (err.errors.cover) errMsg.push(err.errors.cover);
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
          
              Swal.fire({
                icon: "error",
                title: "Fail to update category details",
                text: errMsg
              });
          }
            setLoading(false);
        })
    }
  }

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }

  const validMSNameLength = () => {
    if (ms_name.length > 100) {
      setMSNameError(true);
      setLoading(false);
      valid = false;
    }
    if (ms_name.length <= 1) {
      setMSMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (ms_name.length > 0) {
      setMSNameLengthError(false);
    }
    if (ms_name.length >= 2) {
      setMSMinNameLengthError(false);
    }
    if (ms_name.length < 101) {
      setMSNameError(false);
    }
  }
  const validZHNameLength = () => {
    if (zh_name.length > 100) {
      setZHNameError(true);
      setLoading(false);
      valid = false;
    }
    if (zh_name.length <= 1) {
      setZHMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (zh_name.length > 0) {
      setZHNameLengthError(false);
    }
    if (zh_name.length >= 2) {
      setZHMinNameLengthError(false);
    }
    if (zh_name.length < 101) {
      setZHNameError(false);
    }
  }

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size > 4000000) {
      setImageError(true);
    }

    if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
      setImageFormatError(true);
    }

    reader.onloadend = () => {
      setFormData({
        ...formData,
        ["image"]: file,
        ["image_url"]: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({
      ...formData,
      ["image_filename"]: null,
      ["image"]: null,
      ["image_url"]: null
    });
    setImageError(false);
    setImageFormatError(false);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/category",
              state: { currentPage: page, search: search, sortBy: sortBy,sortType: onSortType }
            })
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
              >
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Edit Category</h4>
                </CardHeader>
                <CardBody>

                  {/* <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_url, "_blank");
                                  inputRef.current.click();
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "32%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>
                        {imageError && (
                          <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                            }}>*FILE SIZE MUST BELOW 4MB.</FormHelperText>
                        )}
                        {imageFormatError && (
                          <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                            }}>*Invalid format.Only accept image with jpg, jpeg, png format.</FormHelperText>
                        )}
                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer> */}

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" }}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="English Name (max 100 characters)"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                       {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" , marginTop:0}}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Malay Name (max 100 characters)"
                        name="ms_name"
                        value={ms_name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validMSNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {ms_nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {ms_minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {ms_nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} style={{ display: "block" , marginTop:0}}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Chinese Name (max 100 characters)"
                        name="zh_name"
                        value={zh_name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validZHNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {zh_nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of name is 100 character.</FormHelperText>
                      )}
                      {zh_minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The minimum length of name is 2 character.</FormHelperText>
                      )}
                      {zh_nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Name is required.</FormHelperText>
                      )}
                    </GridItem>
                  </GridContainer>

                  {/* <GridContainer>
                    <GridItem xs={2} sm={3} md={2}>
                      <TextField
                        required
                        fullWidth
                        style={{ paddding: 0, marginTop: 10 }}
                        margin="normal"
                        name="is_feature"
                        select
                        label="Featured"
                        value={is_feature}
                        onChange={e => updateFormData(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: is_feature == 1 ? classes.activeColor : classes.inactiveColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Yes
                      </MenuItem>
                        <MenuItem key={0} value={0} style={{ color: "#f44336" }}>
                          No
                      </MenuItem>
                      </TextField>
                      
                    </GridItem>
                  </GridContainer> */}
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Update
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
}