import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import * as VOUCHER from "../../services/mlVoucher";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import defaultCover from "assets/img/default_cover.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from "sweetalert2";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function Mlvoucher(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true);
//   const [indexNumber, setIndexNumber] = useState([]);
//   const [categorySelection, setCategorySelection] = useState([]);
  const [voucherList, setVoucherList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSortType, setSelectedSortType] = useState("-- Select --");
  const [selectedSortby, setSelectedSortby] = useState("-- Select --");
  const [selectedStatus, setSelectedStatus] = useState("-- Select --");
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [pageArr, setPageArr] = React.useState([]);
  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
      if (selectedSortby != "-- Select --") {
        params.sortby = selectedSortby;
      }
      if (selectedStatus != "-- Select --") {
        params.status = selectedStatus;
      }
      if (selectedSortType != "-- Select --") {
        params.sortType = selectedSortType;
      }
    }

    if (props.location.state) {
      if (props.location.state.search) {
        params.search = props.location.state.search;
        setSearchInput(params.search);
        props.location.state.search = null;
      }

      if (props.location.state.sortby == "start_datetime" || props.location.state.sortby == "end_datetime" || props.location.state.sortby == "time" ) {
        params.sortby = props.location.state.sortby;
        setSelectedSortby(params.sortby);
        props.location.state.sortby = null;
      }
      if (props.location.state.status == 1 || props.location.state.status == 0 ) {
        params.status = props.location.state.status;
        setSelectedStatus(params.status);
        props.location.state.status = null;
      }
      if (props.location.state.sortType == "asc" || props.location.state.sortType == "desc") {
        params.sortType = props.location.state.sortType;
        setSelectedSortType(params.sortType);
        props.location.state.sortType = null;
      }
    }
    props.history.replace({
      pathname: "/voucher",
      state: {}
    });

    VOUCHER.getVoucherList(currentPage, params)
      .then(res => {
        if (res.data) {
          let page = [];
          for (let i = 0; i < res.data.last_page; i++) {
            page.push(i + 1);
          }
          setPageArr(page);
        }

        let i = 1; 
          res.data.data.map(datum => {
              datum.indexNumber = (currentPage - 1 ) * 10 + i;
              i++;
              return datum;
          })

        setVoucherList(res.data.data);
        setPagination(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });
  }, [currentPage, onFilter]);
  
  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const currentTime = () => {
    let newDate = new Date();
    Date.prototype.today = function () { 
      return  this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
    }   
    Date.prototype.timeNow = function () {
       return ((this.getHours() < 10)?"0":"") + this.getHours() +"-"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +"-"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    let dateTime = newDate.today() + " " + newDate.timeNow();
    return dateTime;
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };
  
  const handleSortByChange = event => {
    const options = event.target.value;
    setSelectedSortby(options);
  };

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleSortTypeChange = event => {
    const options = event.target.value;
    setSelectedSortType(options);
  };

//   const handleDelete = (event,book) => {
//     Swal.fire({
//       title: 'Are you sure to delete this ebook?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes',
//       cancelButtonText: 'No'
//     })
//     .then((result) => {
//       if (result.value) {
//         const newBookList = [...voucherList];
//         const index = newBookList.indexOf(book);
//         if (index !== -1) {
//           newBookList.splice(index,1);
//           setVoucherList(newBookList);
//         }    
//         BOOK.deleteBook(book.id)   
//         .then(res => {
//           Swal.fire({
//             icon: "success",
//             title: "eBook deleted successfully"
//           }).then(() => {
//             props.history.push("/voucher");
//           });
//         })
//       } 
//       else if (result.dismiss === Swal.DismissReason.cancel) {
//         props.history.replace("/voucher");
//       }
//     })
//     .catch(err => {
//       if (err === "token expired") {
//         props.history.replace("/login");
//       }
//       if (err === "Unauthorized") {
//         Swal.fire({
//           icon: "error",
//           title: "Oops",
//           text: "Session expired. Please login again"
//           })
//           .then(() => {
//             localStorage.removeItem('BZtokenData');
//             setLoggedIn(false);      
//           });
//       }
//     });
//   };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedSortType + selectedSortby + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSearchInput("");
    setSelectedSortby("-- Select --");
    setSelectedStatus("-- Select --");
    setSelectedSortType("-- Select --");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    sortby: selectedSortby,
    status: selectedStatus,
    sortType: selectedSortType

  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/voucher/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/voucher/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
            variant="contained"
            style={{ backgroundColor: themeColor, color: "white" }}
            className={classes.submit}
            onClick={() => routeTo("add")}>
            <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add Voucher
        </Button>
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
              <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem xs={12} sm={6} md={5}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"voucher name"}
                    fullWidth
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>

                 <GridItem xs={12} sm={5} md={5} style={{ marginTop: 10 }}>
                  Sort By :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="sortby"
                    name="sortby"
                    variant="outlined"
                    value={selectedSortby}
                    onChange={handleSortByChange}
                    renderValue={selected =>
                        selected == "start_datetime" ? "Valid Start Date" : 
                        selected == "end_datetime" ? "Valid End Date" : 
                        selected == "time" ? "Created Date" : 
                        selected
                    }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={1} value={"start_datetime"}>
                        <ListItemText primary={"Valid Start Date"} />
                      </MenuItem>
                      <MenuItem key={2} value={"end_datetime"}>
                        <ListItemText primary={"Valid End Date"} />
                      </MenuItem>
                      <MenuItem key={3} value={"time"}>
                        <ListItemText primary={"Created Date"} />
                      </MenuItem>
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={4} style={{ marginTop: 10 }}>
                  Status :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="status"
                    name="status"
                    variant="outlined"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    renderValue={selected =>
                        selected == 0 ? "Active" : selected == 1 ? "Expired/Deleted" : selected
                      }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <ListItemText primary={"Active"} />
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        <ListItemText primary={"Expired/Deleted"} />
                      </MenuItem>
                     
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={4} style={{ marginTop: 10 }}>
                  Sort Type :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="sorttype"
                    name="sorttype"
                    variant="outlined"
                    value={selectedSortType}
                    onChange={handleSortTypeChange}
                    renderValue={selected =>
                        selected == "asc" ? "Ascending" : selected == "desc" ? "Descending" : selected
                    }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={1} value={"asc"}>
                        <ListItemText primary={"Ascending"} />
                      </MenuItem>
                      <MenuItem key={2} value={"desc"}>
                        <ListItemText primary={"Descending"} />
                      </MenuItem>                     
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={4}
                  style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 8
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
              {voucherList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>#</TableCell>
                        <TableCell style={{ width: 80 }} className={classes.th}></TableCell>
                        <TableCell className={classes.th}>Title</TableCell>
                        <TableCell className={classes.th}>Valid From</TableCell>
                        <TableCell className={classes.th}>Expired At</TableCell>                                                
                        <TableCell className={classes.th}>Quantity</TableCell>
                        <TableCell className={classes.th}>Claimed</TableCell>
                        <TableCell className={classes.th}>Status</TableCell>
                        <TableCell className={classes.th}>Created At</TableCell>
                        {/* <TableCell className={classes.th}>Action</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {voucherList.map(voucher => (
                        <TableRow
                          onClick={() => routeTo("view", voucher.id)}
                          className={classes.tr}
                          key={voucher.id}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {voucher.indexNumber}
                          </TableCell>
                          <TableCell className={classes.td}>
                          <div style={{ borderRadius: 5, backgroundColor: '#ebebeb', overflow: 'hidden', width:70 }}>
                            <img
                              src={
                                voucher.image_url
                                  ? voucher.image_url
                                  : voucher.avatar
                                  ? voucher.avatar
                                  : defaultCover
                              }
                              ali=""
                              // width="40"
                              height="40"
                              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                              />
                          </div>
                          </TableCell>
                          <TableCell className={classes.td}>{voucher.title}</TableCell>
                          <TableCell className={classes.td}>{voucher.start_datetime.substring(0, 10)}</TableCell>
                          <TableCell className={classes.td}>{voucher.end_datetime.substring(0, 10)}</TableCell>
                          <TableCell className={classes.td}>{voucher.quantity}</TableCell>
                          <TableCell className={classes.td}>{voucher.claimed_quantity}</TableCell>
                          <TableCell className={classes.td}>{voucher.deleted_at != null ? "Deleted": voucher.end_datetime < currentTime() ? "Expired" : "Active"}</TableCell>
                          <TableCell className={classes.td}>{voucher.created_at}</TableCell>
                          {/* <TableCell className={classes.td}>
                              <IconButton aria-label="delete"
                                  onClick={(event) => {handleDelete(event.stopPropagation(),voucher) }} >
                                  <DeleteIcon />
                              </IconButton> 
                          </TableCell>  */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {voucherList.length > 0 ? (
                    <div
                      style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                      <div style={{ fontSize: 13 }}>
                        Page :
                        <Select
                          style={{
                            marginLeft: 10,
                            width: 60,
                            fontSize: 13,
                            textAlign: "center",
                            marginTop: 12
                          }}
                          className={classes.select}
                          id="type"
                          name="type"
                          variant="outlined"
                          value={currentPage}
                          onChange={e => goToPage(e)}
                          input={<Input id="type" />}
                          renderValue={selected => selected}
                          MenuProps={MenuProps}>
                          {pageArr.map(page => (
                            <MenuItem key={page} value={page}>
                              <ListItemText primary={page} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={pagination.total}
                        rowsPerPage={10}
                        page={currentPage - 1}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No voucher found</Typography>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
}