import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as VOUCHER from "../../services/mlVoucher";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  titleColor : {
    color:"#1E90FF",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  }
}));

const useStyles = styles;

const inputRef = React.createRef();
let images = "";

export default function MlAddVoucher(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [voucherObject, setVoucherObject] = useState([]);
  const [voucherFormat, setVoucherFormat] = React.useState("");
  const [generateWay, setGenerateWay] = React.useState("");
  const [uniqueVoucher, setUniqueVoucher] = React.useState("");
  const [tempSrc, setTempSrc] = useState(""); 
  const [show, setShow] = React.useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null);  
  const [centreSelection, setCentreSelection] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [centreError, setCentreError] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [uniqueError, setUniqueError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [descLengthError, setDescLengthError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onSortby =
    props.location.state && props.location.state.sortby ? props.location.state.sortby : null;
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const onStatus =
    props.location.state && props.location.state.status ? props.location.state.status : null;
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 1280,
    aspect: 16 / 9, 
  });
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    image_url: "",
    image_filename:"",
    desc:"",
    center_id:"",
    is_manual:"",
    is_unique:"",
    quantity:"",
    startdate:"",
    enddate:"",
    claimed:"",
    voucherlength:"",
    vouchercode:""
  });

  let valid = true;

  useEffect(() => {

    let img = images;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });
          
    setLoading(false);

  }, [images]);

  useEffect(()=>{

    let currentQuantity = voucherObject.length;
    let a = voucherObject.map(x => x)

    if (quantity > currentQuantity){
        for(let i = 0; i< quantity - currentQuantity; i++){
          a.push({
            value : null
          })
        }
        setVoucherObject(a);
    }
    else{
        for(let i = 0; i< currentQuantity - quantity; i++){
          a.pop({
            value : null
          })
        }
        setVoucherObject(a);
    }
  }, [formData]);

  useEffect(() => {
    let params = {};
    params.per_page = 999;
    setLoading(false);

    VOUCHER.getCentreList()
      .then(result => {
        setCentreSelection(result.data);
        setLoading(false);
        })
        .catch(err => {
            if (err == "token expired") {
            props.history.replace("/login");
            } else {
                if (err.status == "not found") {
                    Swal.fire({
                    icon: "error",
                    title: "Opps",
                    text: "Page Not Found"
                    }).then(() => {
                    props.history.replace("/voucher");
                    });
                }
                if (err === "Unauthorized") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops",
                    text: "Session expired. Please login again"
                    })
                    .then(() => {
                      localStorage.removeItem('BZtokenData');
                      setLoggedIn(false);      
                    });
                }
            }
      });
  },
   []);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { 
    title, 
    image_url,
    image_filename, 
    desc, 
    center_id,
    is_manual,
    is_unique,
    quantity,
    start_date,
    end_date,
    claimed,
    voucherlength,
    vouchercode
  } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setNameError(false);
    setNameLengthError(false);
    setMinNameLengthError(false);
    setDescError(false);
    setDescLengthError(false);

    setLoading(true);

    if (selectedCenter.length == 0) {
        setCentreError(true);
        setLoading(false);
        valid = false;
      }
    if (selectedToDate != null && selectedToDate < selectedFromDate) {
        Swal.fire({
          icon: "error",
          title: "End Date cannot be earlier than Start Date"
        });
        valid = false;
        setLoading(false);
      }
    if (desc.length == 0) {
        setDescLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (title.length == 0) {
      setNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (generateWay == 0) {
      if (voucherFormat.length == 0) {
        setFormatError(true);
        setLoading(false);
        valid = false;
      }
    }
    if (generateWay == 1) {
      if (uniqueVoucher.length == 0) {
        setUniqueError(true);
        setLoading(false);
        valid = false;
      }
    }

    let startdate="",enddate="",voucherformat="",voucherCode="",voucherunique="";
    if (selectedFromDate != null) {
        startdate = moment(selectedFromDate).format("YYYY-MM-DD");
     }  else {
       startdate = "";
     }
 
     if (selectedToDate != null) {
        enddate = moment(selectedToDate).format("YYYY-MM-DD");
     } else {
       enddate = "";
     }

     if (voucherFormat.length != 0) {
        voucherformat = voucherFormat 
     } else {
        voucherformat = ""
     }

     if (vouchercode.length != 0) {
      voucherCode = vouchercode 
     } else {
      voucherCode = ""
     }

     if (uniqueVoucher.length != 0) {
      voucherunique = uniqueVoucher 
     } else {
      voucherunique = ""
     }
    
    if (valid) {

      let createData = {
        ...formData,
        uniquecode:voucherObject,
        start_date:startdate,
        end_date:enddate,
        centre:selectedCenter,
        image:completedCrop,
        generatevoucher:generateWay,
        format:voucherformat,
        unique:voucherunique,
        samecode:voucherCode
      };

      setLoading(true);
      VOUCHER.addVoucher(createData)
        .then(res => {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "Voucher created successfully"
          })
          .then(() => {
            props.history.replace("/voucher");
          });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              if (err.errors.image) {
                errMsg.push(err.errors.image);
              }
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
            Swal.fire({
              icon: "error",
              title: "Fail to add voucher",
              text: errMsg
            });
          }

          setLoading(false);
        });
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const validNameLength = () => {
    if (title.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (title.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (title.length > 0) {
      setNameLengthError(false);
    }
    if (title.length >= 2) {
      setMinNameLengthError(false);
    }
    if (title.length < 101) {
      setNameError(false);
    }
  }

  const validDescLength = () => {
    if (desc.length > 1000) {
      setDescError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length > 0) {
      setDescLengthError(false);
    }
    if (desc.length < 1001) {
      setDescError(false);
    }
  }

  const handleCentreChange = event => {
    const options = event.target.value;
    setSelectedCenter(options);

    if (options != null) {
      setCentreError(false);
    }
  };

  const handleGenerateChange = (event) => {
    setGenerateWay(event.target.value);
  };

  const handleFormatChange = (event) => {
    setVoucherFormat(event.target.value);

    if (event.target.value.length != null) {
      setFormatError(false);
    }
  };

  const handleUniqueChange = (event) => {
    setUniqueVoucher(event.target.value);

    if (event.target.value.length != null) {
      setUniqueError(false);
    }
  };

  const handleVoucherCodeChange = (e,index) => {
    let a = voucherObject.map(x => x) ;
    a.splice(index, 1, {value : e.target.value}) 
    setVoucherObject(a);
  }

  const handleImageChange = e => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file.size > 5000000) {
            imageErrorAlert();
        }
        else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
            imageFormatErrorAlert();
        }
        else {
            if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                let img = new Image;
                reader.onload = function (e) {
                    img.onload = function() { 

                    if (img.width < 1080 ) {
                    removeImg();
                    Swal.fire ({
                        icon: "error",
                        title: "Oops",
                        text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
                    })
                    }
                    else {
                        handleShow();
                    }
                    
                    };
                    img.src = reader.result;
                }
            };
        }

        reader.onloadend = () => {
            setTempSrc(reader.result);
        };

        reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const onImageLoaded = image => {
    images = image
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9);

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_voucher.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }

  const saveUrl = () => {
      setFormData({...formData, ['image_url']: cropImageUrl})
  }

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({ ...formData,  ["image_url"]: null });
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/voucher",
              state: { 
                currentPage: page, 
                search: search, 
                sortby: onSortby,
                sortType: onSortType,
                status: onStatus
            }})
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
                method="post"
                encType="multipart/form-data">
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Add Voucher</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image (Max file size 5MB, only .jpg, .jpeg, .png)
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted",
                              overflow: "hidden"
                            }}>
                            {image_url ? (
                              <img
                                src={image_url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem",
                                  cursor: "pointer"
                              }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "20%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>

                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={nameError,minNameLengthError,nameLengthError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="Voucher Name (max 100 characters)"
                        name="title"
                        value={title}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF NAME IS 100 CHARACTER.</FormHelperText>
                      )}
                       {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MINIMUM LENGTH OF NAME IS 2 CHARACTER.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                           NAME IS REQUIRED.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>      

                     <GridItem xs={12} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        type="number"
                        label="Voucher Quantity"
                        name="quantity"
                        value={quantity}
                        onChange={e => updateFormData(e)}
                        inputProps={{
                          maxLength: 100,
                          minLength: 1,
                          min: 1,
                          max: 10000,
                          step: "1"
                        }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>              
                </GridContainer>

                <GridContainer>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GridItem xs={12} sm={4} md={4} style={{ marginTop: 8}}>
                      <KeyboardDatePicker
                        clearable
                        required
                        value={selectedFromDate}
                        label="Valid From"
                        onChange={date => setSelectedFromDate(date)}
                        format="dd-MM-yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} style={{ marginTop: 8 }}>
                      <KeyboardDatePicker
                        clearable
                        required
                        value={selectedToDate}
                        label="Expired At"
                        onChange={date => setSelectedToDate(date)}
                        format="dd-MM-yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                  </MuiPickersUtilsProvider>
                  </GridContainer>

                  <GridContainer>
                      <GridItem xs={12} sm={9} md={9}>
                        <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 15 }}
                        error={centreError}
                        className={classes.formControl}
                        >
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ focused: classes.inputFocused }}>
                                Centre
                        </InputLabel>
                        <Select
                            className={classes.select}
                            id="centre"
                            name="centre"
                            variant="outlined"
                            value={selectedCenter}
                            onChange={handleCentreChange}
                            input={<Input id="owner" />}
                            inputlabelprops={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}>
                            {centreSelection.map(centre => (
                            <MenuItem key={centre.id} value={centre.id}>
                                {centre.name}
                            </MenuItem>
                            ))}
                        </Select>
                        {centreError && (
                            <FormHelperText style={{ color: "#f44336"}}>
                            CENTRE IS REQUIRED.</FormHelperText>
                        )}
                        </FormControl>
                      </GridItem>
                  </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={9} md={9}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={descError,descLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      multiline
                      rows="4"
                      label="Description (max 1000 characters)"
                      name="desc"
                      value={desc}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validDescLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {descError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF DESCRIPTION IS 1000 CHARACTER.</FormHelperText>
                      )}
                    {descLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          DESCRIPTION IS REQUIRED.</FormHelperText>
                      )}  
                    </FormControl>
                  </GridItem>

                      <Dialog classes={{ paper: classes.dialogPaper }}
                        open={show==true}
                        onClose={handleClose}
                        maxWidth = {'lg'}
                        disableBackdropClick={true}
                      >
                      <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                      <DialogContent>
                        <ReactCrop
                          locked={true}
                          src={tempSrc}
                          crop={crop}
                          ruleOfThirds
                          imageStyle={{maxHeight:'70vh'}}
                          onImageLoaded={c => onImageLoaded(c)}
                          onChange={c => {setCrop(c)}}
                          onComplete={c => onCropComplete(c)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttons onClick={() => {handleClose();}}>
                          Close
                        </Buttons>
                        <Buttons onClick={() => {handleClose();saveUrl();}}>
                          Save
                        </Buttons>
                      </DialogActions>
                    </Dialog>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{marginTop:20}}>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Ways to generate voucher code : </FormLabel>
                      <RadioGroup aria-label="voucher" name="voucher" value={generateWay} onChange={handleGenerateChange} row>
                        <FormControlLabel value="0" style={{color:"#696969"}} control={<Radio required/>} label="Generate by system" />
                        <FormControlLabel value="1" style={{color:"#696969"}} control={<Radio required/>} label="Self-defined voucher code" />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </GridContainer>

                {generateWay == "0" ? (
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{marginTop:20}}>
                    <FormControl component="fieldset" className={classes.formControl} error={formatError}>
                      <FormLabel component="legend">Desired Voucher format : </FormLabel>
                      <RadioGroup aria-label="voucher" name="voucher" value={voucherFormat} onChange={handleFormatChange} row>
                        <FormControlLabel value="0" style={{color:"#696969"}} control={<Radio required/>} label="numeric (eg: 22334)" />
                        <FormControlLabel value="1" style={{color:"#696969"}} control={<Radio required/>} label="alphanumeric (eg: NUWE12)" />
                      </RadioGroup>
                      {formatError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          PLEASE SELECT ONE OF THESE OPTIONS.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={5} md={5}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Voucher code length (max 100)"
                        name="voucherlength"
                        type="number"
                        value={voucherlength}
                        onChange={e => updateFormData(e)}
                        inputProps={{
                          maxLength: 100,
                          minLength: 1,
                          min: 2,
                          max: 100,
                          step: "1"
                        }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>       
                </GridContainer>
                ):null}

                {generateWay == "1" ? (
                   <GridContainer>
                   <GridItem xs={12} sm={12} md={12} style={{marginTop:20}}>
                   <FormControl component="fieldset" className={classes.formControl} error={uniqueError}>
                     <FormLabel component="legend">Type of Voucher code? (Before select please ensure that the voucher quantity field is filled)</FormLabel>
                     <RadioGroup aria-label="voucher" name="voucher" value={uniqueVoucher} onChange={handleUniqueChange} row>
                       <FormControlLabel value="0" style={{color:"#696969"}} control={<Radio />} disabled={quantity.length == 0 || quantity == 0} label="Non-unique" />
                       <FormControlLabel value="1" style={{color:"#696969"}} control={<Radio />} disabled={quantity.length == 0 || quantity == 0} label="Unique" />
                     </RadioGroup>
                     {uniqueError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          PLEASE SELECT ONE OF THESE OPTIONS.</FormHelperText>
                      )}
                   </FormControl>
                 </GridItem>
                 </GridContainer>
                ):null}

                {uniqueVoucher == "0" && generateWay == "1" ? (
                  <div>
                  <div style={{ paddingTop: 30 }}>
                    <Typography style={{ fontWeight: "bold" }}>Voucher list</Typography>
                  </div>

                  <GridContainer>
                    <GridItem xs={12} sm={8} md={5}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Voucher code (max 100 characters)"
                        name="vouchercode"
                        value={vouchercode}
                        onChange={e => updateFormData(e)}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>     
                  </GridContainer>
                  </div>
                ):null}

                {uniqueVoucher == "1" && generateWay == "1" ? (
                <div>
                  <div style={{ paddingTop: 30 }}>
                    <Typography style={{ fontWeight: "bold" }}>Voucher list</Typography>
                  </div>
                {voucherObject.map((vouchers,index) => {
                  return (
                    <GridContainer>
                        <GridItem xs={12} sm={8} md={5}>
                            <TextField
                              variant="standard"
                              margin="normal"
                              required
                              fullWidth
                              label="Voucher code (max 100 characters)"
                              name="codes"
                              value={vouchers.value}
                              onChange={e => handleVoucherCodeChange(e,index)}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                  disabled: classes.disabled,
                                  input:classes.fontSizes
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused
                                }
                              }}
                            />
                        </GridItem>
                    </GridContainer>
                  )
                })}
                </div>
                ):null} 
                
                           
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Submit
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
}