import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as CATEGORY from "../../services/category";
import * as CLASS from "../../services/class";
import * as CENTRE from "../../services/centre";
import * as FAVOURITE from "../../services/favouriteList";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { DropzoneDialog } from 'material-ui-dropzone';
import Buttons from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';

// import centreDetail from "../Centre/centreDetail";
// import  bookDetail  from "../FavouriteList/bookDetail";
// import 

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  yellowColor: {
    color: "#FFA500",
    fontWeight: "400"
  }
}));

const useStyles = styles;

const inputRef = React.createRef();
const inputRefd = React.createRef();

export default function ListDetail(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [categorySelection, setCategorySelection] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFeatured, setSelectedFeatured] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategoryv, setSelectedCategoryv] = useState("");
  const [selectedFeaturedv, setSelectedFeaturedv] = useState("");
  const [selectedStatusv, setSelectedStatusv] = useState("");
  const [selectedCategoryc, setSelectedCategoryc] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStatusc, setSelectedStatusc] = useState("");
  const [selectedCenterOwner, setSelectedCenterOwner] = useState("");
  const [selectedCoordinator, setSelectedCoordinator] = useState([]);
  const [selectedCoordinatorID, setSelectedCoordinatorID] = useState([]);
  const [selectedClassPerWeek, setSelectedClassPerWeek] = useState("");
  const [selectedCentre, setSelectedCentre] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);

  const [stateSelection, setStateSelection] = useState([]);
  const [citySelection, setCitySelection] = useState([]);
  const [centreSelection, setCentreSelection] = useState([]);
  const [teacherSelection, setTeacherSelection] = useState([]);
  const [coordinatorSelection, setCoordinatorSelection] = useState([]);
  const [addVideo2, setAddVideo2] = useState(false);
  const [addVideo3, setAddVideo3] = useState(false);
  const [addVideo4, setAddVideo4] = useState(false);
  const [addVideo5, setAddVideo5] = useState(false);
  const [addVideo6, setAddVideo6] = useState(false);
  const [addVideo7, setAddVideo7] = useState(false);
  const [addVideo8, setAddVideo8] = useState(false);
  const [addVideo9, setAddVideo9] = useState(false);
  const [addVideo10, setAddVideo10] = useState(false);
  const [showVideo2, setShowVideo2] = useState(false);
  const [showVideo3, setShowVideo3] = useState(false);
  const [showVideo4, setShowVideo4] = useState(false);
  const [showVideo5, setShowVideo5] = useState(false);
  const [showVideo6, setShowVideo6] = useState(false);
  const [showVideo7, setShowVideo7] = useState(false);
  const [showVideo8, setShowVideo8] = useState(false);
  const [showVideo9, setShowVideo9] = useState(false);
  const [showVideo10, setShowVideo10] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showImage1, setShowImage1] = useState(false);
  const [value, setValue] = React.useState('1');
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);


  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onType =
    props.location.state && props.location.state.type ? props.location.state.type : null;
  const [userdata, setUserData] = useState({ user_status:"", user_name:"" });
  
  const [bookData, setBookData] = useState({
    id: props.match.params.id,
    name: "",
    image: "",
    image_url: "",
    image_filename:"",
    desc:"",
    pdf:"",
    pdf_url:"",
    pdf_filename:"",
    category:"",
    status:"",
    is_feature:""
  });

  const [centerData, setCenterData] = useState({
    id : props.match.params.id,
    name_c: "",
    desc_c: "",
    mobile_number_prefix: "",
    mobile_number: "",
    street_address:"",
    city: "",
    state: "",
    postcode: "",
    status_c: "",
    image_1_url:"",
    image_2_url:"",
    image_3_url:"",
    is_banner:"",
    image_1_file:"",
    image_2_file:"",
    image_3_file:"",
    video_url:"",
    video:"",
    remark:"",
    image_1_filename:"",
    image_2_filename:"",
    image_3_filename:"",
    category_c:""
  });

  const [classData, setClassData] = useState({
    name_cl: "",
    desc_cl: "",
    is_feature_cl:"",
    image_1_file_cl:"",
    image_2_file_cl:"",
    image_3_file_cl:"",
    image_1_url_cl:"", 
    image_2_url_cl:"", 
    image_3_url_cl:"", 
    is_banner_cl:"",   
    status_cl: "",
    category_cl:"",
    coordinator_cl:[],
    start_date_cl:"",
    end_date_cl:"",
    teacher_cl:"",
    centre_cl:"",
    type_cl:"",
    retail_price_cl:"",
    promotion_price_cl:"",
    week_cl:""
  });

  const [videoData, setVideoData] = useState({
    id: props.match.params.id, v_name: "", v_image: "", v_image_url: "", v_image_filename:"",
    v_desc:"", v_category:"", v_status:"", v_is_feature:""
  });

  const [eduIdData, setEduIdData] = useState({
    educationalid1: "", educationalid2: "", educationalid3: "", educationalid4: "", educationalid5: "",
    educationalid6: "", educationalid7: "", educationalid8: "", educationalid9: "", educationalid10: ""
  });
  const [idData, setIdData] = useState({
    id1: "", id2: "", id3: "", id4: "", id5: "",
    id6: "", id7: "", id8: "", id9: "", id10: ""
  });
  const [ordersData, setOrdersData] = useState({
    order1: "", order2: "", order3: "", order4: "", order5: "",
    order6: "", order7: "", order8: "", order9: "", order10: ""
  });
  const [namesData, setNamesData] = useState({
    name1: "", name2: "", name3: "", name4: "", name5: "",
    name6: "", name7: "", name8: "", name9: "", name10: ""
  });
  const [urlsData, setUrlsData] = useState({
    url1: "", url2: "", url3: "", url4: "", url5: "",
    url6: "", url7: "", url8: "", url9: "", url10: ""
  });
  
  let centreid = "";
  let ebookid = "";
  let classid = "";
  let videoid = "";

  useEffect(() => {
    let params = {};
    params.per_page = 999;
    setLoading(false);
    const id = props.match.params.id;

    CATEGORY.getCategoryList()
      .then(result => {
        setCategorySelection(result.data.data);

        FAVOURITE.getListDetail(id)
        .then(res => {
          console.log(res.data);

          FAVOURITE.getUserData(res.data.user_id)
          .then(result => {
              setUserData({
                ...userdata,
                ['user_status']:result['data']['status_name'],
                ['user_name']:result['data']['first_name'] + " " + result['data']['last_name']
              });
          })
          
            ebookid = res.data.ebook_id;
            centreid = res.data.center_id;
            classid = res.data.class_id;
            videoid = res.data.educational_video_id;
            
            if (ebookid != null) {
            setBookData({
                ...bookData,
                ["name"]: res['data']['ebook_detail']['name'],
                ["desc"]: res['data']['ebook_detail']['desc'],
                ["is_feature"]: setSelectedFeatured(res['data']['ebook_detail']['is_feature']),
                ["image_filename"]: res['data']['ebook_detail']['image_filename'],
                ["image_url"]: res['data']['ebook_detail']['image_url'],
                ["status"]:setSelectedStatus(res['data']['ebook_detail']['status']['name']),
                ["pdf_filename"]:res['data']['ebook_detail']['ebook_filename'],
                ["pdf_url"]:res['data']['ebook_detail']['ebook_url'],
                ['category']:setSelectedCategory(res['data']['ebook_detail']['categories'][0]['name'])
              });
              setLoading(false);
            }

            else if (centreid != null) {

              CENTRE.getStateList()
              .then(res => {
                setStateSelection(res.data);
                setLoading(false);
              })

              CENTRE.getCityList(res.data.center_detail.state_id)
              .then(response => {
                setCitySelection(response.data);
                setLoading(false);
              })  

              let datadetails = {
                ["name_c"]:res['data']['center_detail']['name'],
                ['desc_c']:res['data']['center_detail']['desc'],
                ['street_address']:res['data']['center_detail']['street_address'],
                ['postcode']:res['data']['center_detail']['postcode'],
                ['mobile_number']:res['data']['center_detail']['contact_num'],
                ['Status']:setSelectedStatusc(res['data']['center_detail']['status']['name']),
                ['state']:setSelectedState(res['data']['center_detail']['state_id']),
                ['city']:setSelectedCity(res['data']['center_detail']['city_id']),
                ['video_filename']:res['data']['center_detail']['video_filename'],
                ['video_url']:res['data']['center_detail']['video_url'],
                ['owner']:setSelectedCenterOwner(res['data']['center_detail']['center_owner_detail'][0]['user_detail']['first_name'] + " " + res['data']['center_detail']['center_owner_detail'][0]['user_detail']['last_name']),
                ['remark']:res['data']['center_detail']['remark'],
                ['category_c']:setSelectedCategoryc(res['data']['center_detail']['categories'][0]['name'])
              }
    
            switch(res['data']['center_detail']['images'].length) {
                case 3:
                    setCenterData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['center_detail']['images'][0]['filename'],
                        ['image_1_url']:res['data']['center_detail']['images'][0]['url'],
                        ['image_2_filename']:res['data']['center_detail']['images'][1]['filename'],
                        ['image_2_url']:res['data']['center_detail']['images'][1]['url'],
                        ['image_3_filename']:res['data']['center_detail']['images'][2]['filename'],
                        ['image_3_url']:res['data']['center_detail']['images'][2]['url']
                      });
                      setShowImage2(true);
                      setShowImage3(true);
                  break;
    
                case 2:
                  setCenterData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['center_detail']['images'][0]['filename'],
                        ['image_1_url']:res['data']['center_detail']['images'][0]['url'],
                        ['image_2_filename']:res['data']['center_detail']['images'][1]['filename'],
                        ['image_2_url']:res['data']['center_detail']['images'][1]['url'],
                      });
                      setShowImage2(true);
                  break;
    
                case 1:
                  setCenterData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['center_detail']['images'][0]['filename'],
                        ['image_1_url']:res['data']['center_detail']['images'][0]['url'],
                      });
                  break;
            }
            setLoading(false);
            }

            else if (classid != null) {
              CLASS.getCentreList()
              .then(res => {       
                setCentreSelection(res.data);
              })

              CLASS.getUserList()
              .then(resu => {
                setTeacherSelection(resu.data);
                setCoordinatorSelection(resu.data);
              

              // let listc = [];
              // let coordinatorObj = [];
              // for (let i = 0; i < res.data.coordinators.length; i++) {
              // listc.push(res.data.coordinators[i].id);
              // if (resu.data.find(coordinator => coordinator.id === res.data.coordinators[i].id))
              //     coordinatorObj.push(resu.data.find(coordinator => coordinator.id === res.data.coordinators[i].id));
              // }

              // setSelectedCoordinator(coordinatorObj);
              // setSelectedCoordinatorID(listc);
            
              let datadetails = {
                ['centre']:setSelectedCentre(res['data']['center']['id']),
                ['type']:setSelectedType(res['data']['type']['id']),
                ['name']:res['data']['name'],
                ['desc']:res['data']['desc'],
                ['retail_price']:res['data']['retail_price'],
                ['promotion_price']:res['data']['promotion_price'],
                ['startdate']:setSelectedFromDate(res['data']['start_date']),
                ['enddate']:setSelectedToDate(res['data']['end_date']),
                ['class_per_week']:setSelectedClassPerWeek(res['data']['class_per_week']),
                ['status']:setSelectedStatus(res['data']['status']['id']),
                ['is_feature']:res['data']['is_feature'],
                ['teacher']:setSelectedTeacher(res['data']['teacher_id']),
                ['category']:setSelectedCategory(res['data']['categories'][0]['id'])
              }
  
              switch(res['data']['class_detail']['images'].length) {
                case 3:
                    setClassData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['images'][0]['filename'],
                        ['image_1_url']:res['data']['images'][0]['url'],
                        ['image_2_filename']:res['data']['images'][1]['filename'],
                        ['image_2_url']:res['data']['images'][1]['url'],
                        ['image_3_filename']:res['data']['images'][2]['filename'],
                        ['image_3_url']:res['data']['images'][2]['url']
                      });
                      setShowImage(true);
                      setShowImage1(true);
                  break;
    
                case 2:
                  setClassData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['images'][0]['filename'],
                        ['image_1_url']:res['data']['images'][0]['url'],
                        ['image_2_filename']:res['data']['images'][1]['filename'],
                        ['image_2_url']:res['data']['images'][1]['url'],
                      });
                      setShowImage(true);
                  break;
    
                case 1:
                  setClassData({
                        ...datadetails,
                        ['image_1_filename']:res['data']['images'][0]['filename'],
                        ['image_1_url']:res['data']['images'][0]['url'],
                      });
                  break;
            }
            setLoading(false);

            })
            }

            else if (videoid != null) {
              setVideoData({
                ...videoData,
                ["v_name"]: res['data']['educational_video_detail']['name'],
                ["v_desc"]: res['data']['educational_video_detail']['desc'],
                ["v_is_feature"]: setSelectedFeaturedv(res['data']['educational_video_detail']['is_feature']),
                ["v_image_filename"]: res['data']['educational_video_detail']['image_filename'],
                ["v_image_url"]: res['data']['educational_video_detail']['image_url'],
                ["v_status"]:setSelectedStatusv(res['data']['educational_video_detail']['status']['name']),
                ['v_category']:setSelectedCategoryv(res['data']['educational_video_detail']['categories'][0]['name'])
              }); 

              let eduItems = {};
              let idItems = {};
              let orderItems = {};
              let nameItems = {};
              let urlItems = {};
              for (let i = 0; i<res['data']['educational_video_detail']['video_urls'].length; i++) {  
                eduItems['educationalid'+(i+1)] = res['data']['educational_video_detail']['video_urls'][i]['educational_video_id'];
                idItems['id'+(i+1)] = res['data']['educational_video_detail']['video_urls'][i]['id'];
                orderItems['order'+(i+1)] = res['data']['educational_video_detail']['video_urls'][i]['order'];
                nameItems['name'+(i+1)] = res['data']['educational_video_detail']['video_urls'][i]['video_name'];
                urlItems['url'+(i+1)] = res['data']['educational_video_detail']['video_urls'][i]['admin_video_url'];
              
                let controlShow = {
                  2 : setShowVideo2,
                  3 : setShowVideo3,
                  4 : setShowVideo4,
                  5 : setShowVideo5,
                  6 : setShowVideo6,
                  7 : setShowVideo7,
                  8 : setShowVideo8,
                  9 : setShowVideo9,
                  10 : setShowVideo10
                }     
                let controlAdd = {
                  2 : setAddVideo2,
                  3 : setAddVideo3,
                  4 : setAddVideo4,
                  5 : setAddVideo5,
                  6 : setAddVideo6,
                  7 : setAddVideo7,
                  8 : setAddVideo8,
                  9 : setAddVideo9,
                  10 : setAddVideo10
                } 
                if(i>=1) controlShow[i+1](true);
                if(i>=1) controlAdd[i+1](true);
              }
              setEduIdData(eduItems);
              setIdData(idItems);            
              setOrdersData(orderItems);
              setNamesData(nameItems);
              setUrlsData(urlItems);

              setLoading(false);
            }
            setLoading(false);
        })
        .catch(err => {
            if (err == "token expired") {
            props.history.replace("/login");
            } else {
                if (err.status == "not found") {
                    Swal.fire({
                    icon: "error",
                    title: "Opps",
                    text: "Page Not Found"
                    }).then(() => {
                    props.history.replace("/favourite-list");
                    });
                }
            }
        });
    }); 
  },
   []);

  const { name, image_url, image_filename, desc, pdf_url, pdf_filename, category, status, is_feature } = bookData;

  const {user_status, user_name} = userdata;

  const { v_name, v_image_url, v_image_filename, v_desc, v_category, v_status, v_is_feature } = videoData;

  const {
    educationalid1, educationalid2, educationalid3, educationalid4, educationalid5,
    educationalid6, educationalid7, educationalid8, educationalid9, educationalid10
  } = eduIdData;

  const { id1, id2, id3, id4, id5, id6, id7, id8, id9, id10 } = idData;  

  const { order1, order2, order3, order4, order5, order6, order7, order8, order9, order10 } = ordersData;

  const { name1, name2, name3, name4, name5, name6, name7, name8, name9, name10 } = namesData;

  const { url1, url2, url3, url4, url5, url6, url7, url8, url9, url10 } = urlsData;

  const {
    name_c,
    desc_c,
    mobile_number_prefix,
    mobile_number,
    street_address,
    city,
    state,
    postcode,
    status_c,
    image_1_url, 
    image_2_url,
    image_3_url,
    is_banner,
    video_url,
    video,
    image_1_file, 
    image_2_file,
    image_3_file,
    image_1_filename, 
    image_2_filename,
    image_3_filename,
    remark,
    category_c
  } = centerData;

  const {
    name_cl,
    desc_cl,
    is_feature_cl,
    image_1_url_cl, 
    image_2_url_cl,
    image_3_url_cl,   
    status_cl,
    category_cl,
    coordinator_cl,
    start_date_cl,
    end_date_cl,
    teacher_cl,
    centre_cl,
    type_cl,
    retail_price_cl,
    promotion_price_cl,
    week_cl
  } = classData;

  let typetitle = "";
  if (onType == 300) {
      typetitle = "e-book";
  }
  else if (onType == 100) {
      typetitle = "Centre";
  }
  else if (onType == 200) {
    typetitle = "Class";
  }
  else if (onType == 400) {
    typetitle = "Educational Video";
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/favourite-list",
              state: { currentPage: page, search: search, type: onType }
            })
          }>
          Back
        </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                >
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Favourite {typetitle} detail</h4>
                </CardHeader>
                <CardBody>

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                    <h4 style={{fontSize:15,color:"#1E90FF",fontWeight:"bold"}}>User detail</h4>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="User Name"
                        name="user_name"
                        value={user_name}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem> 

                    <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="User Status"
                        name="user_status"
                        value={user_status}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: user_status == "Inactive" ? classes.inactiveColor : classes.activeColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>
                  </GridContainer>

                  {/* {onType == 300 ? (
                    bookDetail()
                  )} */}

                  {onType == 300 ? (
                  <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                    <h4 style={{fontSize:15,color:"#1E90FF",fontWeight:"bold"}}>Favourite e-book</h4>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>  
                    <GridItem xs={12} sm={6} md={4}>
                      Image
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_url, "_blank");
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : null}
                          </Box>
                        </div>
                    </div>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6}>
                    <div style={{ width: "auto" }}>
                        E-book
                            {pdf_filename ? (
                              <div style={{marginTop:10}}>  
                                 Click to read :
                                 <Buttons 
                                    onClick={() => {
                                      window.open(pdf_url, "_blank");
                                    }}
                                    style={{
                                      fontWeight:"bold",
                                      color:"#8B008B"
                                    }}
                                >
                                  {pdf_filename}
                                </Buttons>
                              </div>
                            ) : null}
                        </div>
                    </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={6} md={9}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        value={name}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>                    
                </GridContainer>

                <GridContainer>
                  <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Status"
                        name="Status"
                        value={selectedStatus}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: selectedStatus == "Draft" ? classes.yellowColor : classes.activeColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>

                  <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Featured"
                        name="Featured"
                        value={selectedFeatured == 1 ? "Yes": "No"}                        
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: selectedFeatured == 1 ? classes.activeColor : classes.inactiveColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                  </GridItem>
                
                    <GridItem xs={12} sm={3} md={3}>
                        <FormControl
                            required
                            fullWidth={true}
                            style={{ marginLeft: 0,marginTop: 0 }}
                            className={classes.formControl}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Category"
                            name="Category"
                            value={selectedCategory}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                      />
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      multiline
                      rows="4"
                      label="Description"
                      name="desc"
                      value={desc}
                      inputProps={{ readOnly: true }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                </div>
                ): null}

                {onType == 400 ? (
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                      <h4 style={{fontSize:15,color:"#1E90FF",fontWeight:"bold"}}>Favourite educational video</h4>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {v_image_url ? (
                              <img
                                onClick={() => {
                                  window.open(v_image_url, "_blank");
                                }}
                                src={v_image_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : null}
                          </Box>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={11} md={10}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="Name"
                        name="v_name"
                        value={v_name}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>                    
                </GridContainer>

                <GridContainer>
                    <GridItem xs={5} sm={4} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Status"
                        name="Status"
                        value={selectedStatusv}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: selectedStatus == "Draft" ? classes.yellowColor : classes.activeColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 0 }}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Featured"
                        name="Featured"
                        value={selectedFeaturedv == 1 ? "Yes": "No"}                        
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input: selectedFeaturedv == 2 ? classes.inactiveColor : classes.activeColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />                  
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                            required
                            fullWidth={true}
                            style={{ marginLeft: 0,marginTop: 0 }}
                            className={classes.formControl}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Category"
                            name="Category"
                            value={selectedCategoryv}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                        />
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={10}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      multiline
                      rows="4"
                      label="Description"
                      name="v_desc"
                      value={v_desc}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    </FormControl>
                  </GridItem>
                </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={11} md={10}>
                      <h4 style={{fontSize:15,color:"#1E90FF",fontWeight:"bold"}}>Video</h4>           
                    </GridItem>
                 
                    <GridItem xs={12} sm={11} md={10}>
                      Video 1
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name1"
                        value={name1}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>
                 
                    <GridItem xs={12} sm={8} md={8}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url1"
                        value={url1}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={2}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order1"
                        value={order1}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={3} md={3}>
                      {url1 ? (
                      <Buttons id="viewvideo1"
                        onClick={() => {
                            window.open(url1, "_blank");
                        }}
                        style={{
                          fontWeight:"bold",
                          color:"#8B008B",
                          marginTop:"5%"                        
                        }}
                        variant="contained">
                        View Video 1
                      </Buttons>
                      ): null}
                    </GridItem>
                  </GridContainer>

                {addVideo2 ? (
                  <div>
                 <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>              
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 2
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name2"
                        value={name2}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>
  
                  <GridItem xs={12} sm={8} md={8}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url2"
                        value={url2}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order2"
                        value={order2}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />                                  
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  </div>
                ) : null }

                <GridContainer>
                  {showVideo2 && url2 ? (
                  <GridItem xs={12} sm={3} md={3}>
                    <Buttons id="viewvideo2"
                      onClick={() => {
                          window.open(url2, "_blank");
                      }}
                      style={{
                        fontWeight:"bold",
                        color:"#8B008B",
                        marginTop:"5%"                        
                      }}
                      variant="contained">
                      View Video 2
                    </Buttons>
                  </GridItem>
                  ): null}
                </GridContainer>

                {addVideo3 ? (
                 <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 3
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name3"
                        value={name3}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={8}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url3"
                        value={url3}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order3"
                        value={order3}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                 </div>
                ) : null }

                  <GridContainer>
                    {showVideo3 && url3 ? (
                      <GridItem xs={12} sm={3} md={3}>
                        <Buttons id="viewvideo3"
                          onClick={() => {
                              window.open(url3, "_blank");
                          }}
                          style={{
                            fontWeight:"bold",
                            color:"#8B008B",
                            marginTop:"5%"                        
                          }}
                          variant="contained">
                          View Video 3
                        </Buttons>
                      </GridItem>
                    ): null}
                  </GridContainer>

                  {addVideo4 ? (
                  <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 4
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name4"
                        value={name4}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem> 

                  <GridItem xs={12} sm={8} md={8}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url4"
                        value={url4}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />                     
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order4"
                        value={order4}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem> 
                  </GridContainer>
                  </div>
                ) : null }

                <GridContainer>
                  {showVideo4 && url4 ? (
                  <GridItem xs={12} sm={3} md={3}>
                    <Buttons id="viewvideo4"
                      onClick={() => {
                          window.open(url4, "_blank");
                      }}
                      style={{
                        fontWeight:"bold",
                        color:"#8B008B",
                        marginTop:"5%"                        
                      }}
                      variant="contained">
                      View Video 4
                    </Buttons>
                  </GridItem>
                  ): null}
                </GridContainer>

                {addVideo5 ? (
                  <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 5
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name5"
                        value={name5}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                  <GridItem xs={12} sm={8} md={8}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url5"
                        value={url5}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order5"
                        value={order5}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />                   
                      </FormControl>
                    </GridItem>  
                  </GridContainer>
                  </div>
                ) : null }

                <GridContainer>
                  {showVideo5 && url5 ? (
                  <GridItem xs={12} sm={3} md={3}>
                    <Buttons id="viewvideo5"
                      onClick={() => {
                          window.open(url5, "_blank");
                      }}
                      style={{
                        fontWeight:"bold",
                        color:"#8B008B",
                        marginTop:"5%"                        
                      }}
                      variant="contained">
                      View Video 5
                    </Buttons>
                  </GridItem>
                  ): null}
                </GridContainer>

                {addVideo6 ? (
                  <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 6
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name6"
                        value={name6}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                  <GridItem xs={12} sm={8} md={8}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url6"
                        value={url6}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order6"
                        value={order6}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>  
                  </GridContainer>
                  </div>
                ) : null }

                <GridContainer>
                  {showVideo6 && url6 ? (
                  <GridItem xs={12} sm={3} md={3}>
                    <Buttons id="viewvideo6"
                      onClick={() => {
                          window.open(url6, "_blank");
                      }}
                      style={{
                        fontWeight:"bold",
                        color:"#8B008B",
                        marginTop:"5%"                        
                      }}
                      variant="contained">
                      View Video 6
                    </Buttons>
                  </GridItem>
                  ): null}
                </GridContainer>

                {addVideo7 ? (
                  <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 7
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name7"
                        value={name7}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                  <GridItem xs={12} sm={8} md={8}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url7"
                        value={url7}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order7"
                        value={order7}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>  
                  </GridContainer>
                  </div>
                ) : null }

                <GridContainer>
                  {showVideo7 && url7 ? (
                  <GridItem xs={12} sm={3} md={3}>
                    <Buttons id="viewvideo7"
                      onClick={() => {
                          window.open(url7, "_blank");
                      }}
                      style={{
                        fontWeight:"bold",
                        color:"#8B008B",
                        marginTop:"5%"                        
                      }}
                      variant="contained">
                      View Video 7
                    </Buttons>
                  </GridItem>
                  ): null}
                </GridContainer>

                {addVideo8 ? (
                  <div>
                  <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                  <GridContainer>                 
                    <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                      Video 8
                     <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Name"
                        name="name8"
                        value={name8}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={8}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video URL"
                        name="url8"
                        value={url8}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 ,marginTop:0}}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        label="Video Order"
                        name="order8"
                        value={order8}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  </div>
                ) : null }

                  <GridContainer>
                    {showVideo8 && url8 ? (
                      <GridItem xs={12} sm={3} md={3}>
                        <Buttons id="viewvideo8"
                          onClick={() => {
                              window.open(url8, "_blank");
                          }}
                          style={{
                            fontWeight:"bold",
                            color:"#8B008B",
                            marginTop:"5%"                        
                          }}
                          variant="contained">
                          View Video 8
                        </Buttons>
                      </GridItem>
                    ): null}
                  </GridContainer>

                  {addVideo9 ? (
                    <div>
                    <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                    <GridContainer>                 
                      <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                        Video 9
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                        <TextField
                          variant="standard"
                          margin="normal"
                          required
                          fullWidth
                          label="Video Name"
                          name="name9"
                          value={name9}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                        />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={8} md={8}>
                        <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0 }}
                          className={classes.formControl}>
                          <TextField
                          variant="standard"
                          margin="normal"
                          required
                          fullWidth
                          label="Video URL"
                          name="url9"
                          value={url9}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                          />
                        </FormControl>
                      </GridItem> 

                      <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                        <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0 ,marginTop:0}}
                          className={classes.formControl}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Video Order"
                            name="order9"
                            value={order9}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                              classes: {
                                underline: classes.underline,
                                disabled: classes.disabled,
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                              }
                            }}
                          />                   
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    </div>
                  ) : null }

                  <GridContainer>
                    {showVideo9 && url9 ? (
                      <GridItem xs={12} sm={3} md={3}>
                        <Buttons id="viewvideo9"
                          onClick={() => {
                              window.open(url9, "_blank");
                          }}
                          style={{
                            fontWeight:"bold",
                            color:"#8B008B",
                            marginTop:"5%"                        
                          }}
                          variant="contained">
                          View Video 9
                        </Buttons>
                      </GridItem>
                    ): null}
                  </GridContainer>

                  {addVideo10 ? (
                    <div>
                    <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                    <GridContainer>                 
                      <GridItem xs={12} sm={11} md={10} style={{marginTop:10}}>
                        Video 10
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                        <TextField
                          variant="standard"
                          margin="normal"
                          required
                          fullWidth
                          label="Video Name"
                          name="name10"
                          value={name10}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                        />                       
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={8} md={8}>
                        <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0 }}
                          className={classes.formControl}>
                          <TextField
                          variant="standard"
                          margin="normal"
                          required
                          fullWidth
                          label="Video URL"
                          name="url10"
                          value={url10}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                          />                      
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={3} md={2} style={{marginTop:16}}>
                        <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0 ,marginTop:0}}
                          className={classes.formControl}>
                          <TextField
                          variant="standard"
                          margin="normal"
                          required
                          fullWidth
                          label="Video Order"
                          name="order10"
                          value={order10}
                          inputProps={{ readOnly: true }}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled,
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                        />                
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    </div>
                  ) : null }

                    <GridContainer>
                      {showVideo10 && url10 ? (
                        <GridItem xs={12} sm={3} md={3}>
                          <Buttons id="viewvideo10"
                            onClick={() => {
                                window.open(url10, "_blank");
                            }}
                            style={{
                              fontWeight:"bold",
                              color:"#8B008B",
                              marginTop:"5%"                        
                            }}
                            variant="contained">
                            View Video 10
                          </Buttons>
                        </GridItem>
                      ): null}
                    </GridContainer>
                  </div>
                ):null}

                {onType == 100 ? (
                  <div>

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <h4 style={{fontSize:15,color:"#1E90FF",fontWeight:"bold"}}>Favourite Center</h4>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Centre Name"
                        name="name_c"
                        value={name_c}
                        autoFocus
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem item xs={4} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Contact Number"
                        name="mobile_number"
                        value={mobile_number}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={5} sm={4} md={4}>
                    <FormControl
                      required
                      disabled
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      className={classes.formControl}
                    >
                      <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Centre Owner"
                            name="owner"
                            value={selectedCenterOwner}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                        />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0,marginTop: 0 }}
                      className={classes.formControl}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Category"
                            name="Category"
                            value={selectedCategoryc}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                        />
                    </FormControl>
                  </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="street_address"
                        label="Address"
                        name="street_address"
                        value={street_address}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="postcode"
                      label="Postcode"
                      name="postcode"
                      value={postcode}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    /> 
                  </FormControl> 
                  </GridItem>

                  <GridItem xs={5} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        State
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="state"
                        name="State"
                        variant="outlined"
                        value={selectedState}
                        inputProps={{ readOnly: true }}
                        input={<Input id="state" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {stateSelection.map(state => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>                      
                    </FormControl>
                  </GridItem>

                  <GridItem xs={5} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            City
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="city"
                        name="City"
                        variant="outlined"
                        value={selectedCity}
                        inputProps={{ readOnly: true }}
                        input={<Input id="city" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {citySelection.map(city => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="City"
                            name="City"
                            value={selectedCity}
                            // inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                        >
                        {citySelection.map(city => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                        </TextField> */}
                    </FormControl>
                  </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <GridItem xs={5} sm={3} md={3}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 35 }}
                      className={classes.formControl}
                      disabled={selectedStatus==3}
                    >
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            label="Status"
                            name="Status"
                            value={selectedStatusc}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                            classes: {
                                underline: classes.underline,
                                disabled: classes.disabled,
                                input: selectedStatusc == "Published" ? classes.activeColor : classes.inactiveColor
                            }
                            }}
                            InputLabelProps={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                        />
                    </FormControl>
                  </GridItem>

                  {(selectedStatusc == 3) ? ( 
                    <GridItem xs={7} sm={5} md={7}>
                      <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0, marginTop: 0 }}
                          className={classes.formControl}
                      >
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      id="remark_field"
                      rows="2"
                      label="Remark"
                      name="remark"
                      value={remark}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    </FormControl>
                  </GridItem>
                    ) : null }
                  </GridContainer>                 

                  <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      rows="4"
                      label="Description"
                      name="desc_c"
                      value={desc_c}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    /> 
                    </FormControl>
                  </GridItem>
                  </GridContainer>

                  <GridContainer>
                  <div>
                  <GridItem xs={12} sm={12} md={12}>
                    Image
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "7.5rem",
                            height: "7.5rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted"
                          }}>
                          {image_1_url ? (
                            <img
                              onClick={() => {
                                window.open(image_1_url, "_blank");
                              }}
                              src={image_1_url}
                              ali=""
                              style={{
                                width: "7.5rem",
                                height: "7.5rem",
                                cursor: "pointer"
                              }}
                            />
                          ) : null}
                        </Box>
                      </div>

                      <div style={{ paddingLeft: 20 }}>
                        {image_1_url ? (
                          <div>
                            <Radio
                            name="banner"
                            style={{
                              color: themeColor,
                              padding: 0
                            }}
                            checked={value == 1}
                            value="1"
                            inputProps={{ "aria-label": "1" }}
                          />
                            Set as banner?
                          </div>
                        ) : null} 
                      </div>
                    </div>
                  </GridItem>

                    {showImage2 ? (
                      <GridItem xs={12} sm={12} md={12} style={{marginTop : "5px"}}>
                      Image
                      <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_2_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_2_url, "_blank");
                                }}
                                src={image_2_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : null}
                          </Box>
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {image_2_url ? (
                            <div>
                              <Radio
                                name="banner2"
                                style={{
                                  color: themeColor,
                                  padding: 0
                                }}
                                checked={value ==2}
                                value="2"
                                inputProps={{ "aria-label": "1" }}
                              />
                                Set as banner?
                            </div>
                          ) : null} 
                        </div>
                      </div>
                      </GridItem>
                    ) : null}

                    {showImage3 ? (
                      <GridItem xs={12} sm={12} md={12} style={{marginTop : "5px"}}>
                      Image
                      <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_3_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_3_url, "_blank");
                                }}
                                src={image_3_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : null}
                          </Box>
                        </div>
                
                        <div style={{ paddingLeft: 20 }}>
                          {image_3_url ? (
                            <div>
                                <Radio
                                  name="banner3"
                                  style={{
                                    color: themeColor,
                                    padding: 0
                                  }}
                                  checked={value == 3}
                                  value="3"
                                  inputProps={{ "aria-label": "1" }}
                                />
                                Set as banner?
                            </div>
                          ) : null} 
                        </div>
                      </div>
                      </GridItem>
                    ) : null}
                      </div> 
                  </GridContainer> 
                            
                  {video_url ? (
                  <div>
                  <GridContainer>
                  <GridItem xs={12} sm={6} md={5} style={{marginTop : "5px"}}>
                    Video
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "12rem",
                            height: "7.5rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted"
                          }}>
                          {video_url ? (
                            <video
                              onClick={() => {
                                window.open(video_url, "_blank");
                              }}
                              src={video_url}
                              ali=""
                              style={{
                                width: "7.5rem",
                                height: "7.5rem",
                                cursor: "pointer"
                              }}
                            />
                          ) : null}
                        </Box>
                      </div>
                    </div>
                  </GridItem> 
                  </GridContainer>
                  </div>
                  ):null}
                  </div>
                ):null}

              
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
}