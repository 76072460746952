import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import * as COURSE from "../../services/course";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import * as VOUCHER from "../../services/voucher";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  underline: {
    "&:hover:not($disabled):before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {}
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function AddVoucher(props) {
  const classes = useStyles();
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCourseID, setSelectedCourseID] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;

  useEffect(() => {
    let params = {};
    params.per_page = 999;
    COURSE.getAvailableCourse(params)
      .then(result => {
        setCourseList(result.data);
        setLoading(false);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
      });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let name = event.target.name.value;
    let qty = event.target.quantity.value;
    setQtyError(false);
    setHasError(false);

    if (selectedCourse.length === 0) {
      setHasError(true);
    } else {
      if (isNaN(qty) || qty <= 0) {
        setQtyError(true);
      } else {
        let createData = {
          name: name,
          quantity: qty,
          course: selectedCourseID
        };

        setLoading(true);
        VOUCHER.addVoucher(createData)
          .then(res => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Voucher create successful"
            });
          })
          .then(() => {
            props.history.replace("/coupon");
          });
      }
    }
  }

  const handleChange = event => {
    let options = event.target.value;
    let selectedList = [];
    let ID = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i]) {
        ID.push(options[i].id);
        selectedList.push(options[i]);
        setSelectedCourse(selectedList);
        setSelectedCourseID(ID);
      }
    }
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/coupon",
            state: { currentPage: page }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form className={classes.form} onSubmit={event => handleSubmit(event)}>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Add Coupon</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      autoFocus
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Quantity"
                      id="quantity"
                      name="quantity"
                      type="number"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      inputProps={{
                        min: 1,
                        max: 100
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {qtyError && <FormHelperText>The quantity input is invalid.</FormHelperText>}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ paddingTop: 15 }}>
                  <GridItem xs={12} sm={12} md={10}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={hasError}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Course
                      </InputLabel>
                      <Select
                        multiple={true}
                        className={classes.select}
                        id="course"
                        name="course"
                        variant="outlined"
                        value={selectedCourse}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        input={<Input id="coruse" />}
                        renderValue={selected =>
                          selected.length > 0
                            ? selected.map((course, i) =>
                                selected[i + 1] ? course.name + ", " : course.name
                              )
                            : ""
                        }>
                        {courseList.map(course => (
                          <MenuItem key={course.id} value={course}>
                            <Checkbox
                              style={{
                                color: themeColor
                              }}
                              checked={
                                selectedCourse &&
                                selectedCourse.find(selected => selected.id === course.id)
                                  ? true
                                  : false
                              }
                            />
                            <ListItemText primary={course.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {hasError && <FormHelperText>This is required</FormHelperText>}
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
