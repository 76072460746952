import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as COMMISSION from "../../services/commission";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;

export default function CommissionDetails(props) {
  const classes = useStyles();
  const [userType, setUserType] = useState([]);
  const [levelArr, setLevelArr] = useState({ lv: [] });
  const [calculationType, setCalculationType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commission, setCommission] = useState([]);
  const [defaultComm, setDefaultComm] = useState([]);
  const [maxLevel, setMaxLevel] = useState(0);
  const [typeError, setTypeError] = useState([]);
  const [calculationTypeErr, setCalculationTypeErr] = useState([]);
  const mode = props.location.state && props.location.state.mode ? props.location.state.mode : null;
  const chooseCom =
    props.location.state && props.location.state.com ? props.location.state.com : null;
  const [eligibilityCommission, setEligibilityCommission] = useState([
    {
      user_type: "",
      sales_count: 0,
      sales_volume: 0
    }
  ]);
  const id = props.match.params.id;

  useEffect(() => {
    if (mode == "default") {
      COMMISSION.getDefaultRate(id)
        .then(result => {
          setCommission(result.data);
          setDefaultComm(result.data);
          setMaxLevel(result.max_level);
          let count = [];
          let maxLv = 1;
          //find the max level and set level
          if (result.data.length > 0) {
            for (let i = 0; i < result.data.length; i++) {
              maxLv = result.data[i].level;
              if (result.data[i - 1] && result.data[i - 1].level > maxLv)
                maxLv = result.data[i - 1].level;
            }
            for (let i = 0; i < maxLv; i++) {
              count.push(i + 1);
            }
            setLevelArr({ lv: count });
          } else {
            setLevelArr({ lv: [] });
          }

          setUserType(result.user_type);
          setCalculationType(result.calculation_type);
          setLoading(false);
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err.status == "not found") {
              Swal.fire({
                icon: "error",
                title: "Opps",
                text: "Page Not Found"
              }).then(() => {
                props.history.replace("/commission");
              });
            }
          }
        });
    } else {
      COMMISSION.getEligibility(id)
        .then(result => {
          setCommission(result.data);

          setEligibilityCommission(result.data);
          setUserType(result.user_type);
          setLoading(false);
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err.status == "not found") {
              Swal.fire({
                icon: "error",
                title: "Opps",
                text: "Page Not Found"
              }).then(() => {
                props.history.replace("/commission");
              });
            }
          }
        });
    }
  }, []);

  const updateCommission = (event, type) => {
    let commList = [];
    if (mode == "default") commList = defaultComm;
    else commList = eligibilityCommission;

    for (var i in commList) {
      if (mode == "default") {
        if (commList[i].level + "" + commList[i].user_type == type) {
          if (event.target.name == "user_type") {
            commList[i].user_type = event.target.value;
            break;
          }
          if (event.target.name == "calculation_type") {
            commList[i].calculation_type = event.target.value;
            if (event.target.value == "1") {
              commList[i].amount = "";
            } else if (event.target.value == "2") {
              commList[i].percent = "";
            }
            break;
          }
          if (event.target.name == "percent") {
            commList[i].percent = event.target.value;
            break;
          }
          if (event.target.name == "amount") {
            commList[i].amount = event.target.value;
            break;
          }
        } else if (commList[i].level == type) {
          if (event.target.name == "user_type") {
            commList[i].user_type = event.target.value;
            break;
          }
        }
      } else {
        if (commList[i].user_type == type) {
          if (event.target.name == "sales_count") {
            commList[i].sales_count = event.target.value;
            break;
          }
          if (event.target.name == "sales_volume") {
            commList[i].sales_volume = event.target.value;
            break;
          }
        }
      }
    }
    setLevelArr({ ...levelArr, date: new Date() });
    setEligibilityCommission(commList);
  };

  const addNewLevel = () => {
    const inputOptions = new Map();
    for (let i = 0; i < maxLevel; i++) {
      if (defaultComm.length > 0) {
        if (!defaultComm.find(comm => comm.level == i + 1)) inputOptions.set(i + 1, i + 1);
      } //havent add level before
      else inputOptions.set(i + 1, i + 1);
    }
    Swal.fire({
      title: "Add new level",
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a level",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        let tempLv = levelArr.lv;
        let tempCom = defaultComm;
        tempCom.push({
          user_type: "",
          level: value.value,
          percent: "",
          amount: "",
          calculation_type: ""
        });
        setDefaultComm(tempCom.sort((a, b) => (a.level > b.level ? 1 : -1)));
        tempLv.push(value.value);
        setLevelArr({ lv: tempLv.sort(), date: new Date() });
      }
    });
  };

  const addNewType = lv => {
    setTypeError([]);

    let thisCommission = defaultComm.filter(comm => comm.level == lv);
    //userType ArrObj of the selected commission
    const inputOptions = new Map();
    let userArr = Object.entries(userType);
    for (let i = 0; i < userArr.length; i++) {
      if (!thisCommission.find(comm => comm.user_type == userArr[i][0]))
        inputOptions.set(userArr[i][0], userArr[i][1]);
    }
    Swal.fire({
      title: "Add User Type to Level " + lv,
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a type",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        if (thisCommission.length == 1 && thisCommission[0].user_type.length == 0) {
          updateCommission({ target: { name: "user_type", value: value.value } }, lv);
        } else {
          let comm = {
            user_type: value.value,
            level: lv,
            percent: "",
            amount: "",
            calculation_type: ""
          };
          setDefaultComm([...defaultComm, comm]);
        }
      }
    });
  };

  const removeThisLv = lv => {
    let tempLv = levelArr.lv.filter(level => level != lv);
    let tempCom = defaultComm.filter(comm => comm.level != lv);
    setDefaultComm(tempCom);
    setLevelArr({ lv: tempLv, date: new Date() });
  };

  function handleSubmit(event) {
    event.preventDefault();
    let valid = true;
    if (mode == "default") {
      setTypeError([]);
      let typeErr = [];

      if (calculationTypeErr.length > 0) setCalculationTypeErr([]);

      let errList = [];
      let calErr = defaultComm.filter(comm => comm.calculation_type.length == 0);
      if (calErr.length > 0) {
        valid = false;
        for (let n = 0; n < calErr.length; n++) {
          errList.push(calErr[n].level + calErr[n].user_type);
        }
      }
      setCalculationTypeErr(errList);

      if (levelArr.lv.length == 0) {
        valid = false;
      }
      for (let m = 0; m < levelArr.lv.length; m++) {
        if (!defaultComm.find(comm => comm.level == levelArr.lv[m]).user_type) {
          valid = false;
          typeErr.push(levelArr.lv[m]);
        }
      }
      setTypeError(typeErr);

      if (valid) {
        setLoading(true);
        defaultComm.forEach(function(comm) {
          delete comm.updated_at;
        });
        COMMISSION.updateDefaultRate(defaultComm, id)
          .then(res => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Default commission rate update successful"
            });
          })
          .catch(err => {
            if (err.errors) {
              setLoading(false);
            }
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      eligibilityCommission.forEach(function(comm) {
        delete comm.updated_at;
      });
      COMMISSION.updateEligibility(eligibilityCommission, id)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Commission eligibility update successful"
          });
        })
        .catch(err => {
          if (err.errors) {
            setLoading(false);
          }
          setLoading(false);
        });
    }
  }

  const compare = ( a, b )=> {    // sort user type
    if ( a.user_type < b.user_type ){
      return -1;
    }
    if ( a.user_type > b.user_type ){
      return 1;
    }
    return 0;
  }

  const defaultCommRate = () => {
    return levelArr.lv.map(lv => (
      <div key={lv} style={{ marginBottom: 16 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ display: "inline-flex" }}>
            <div
              style={{
                fontSize: 17,
                marginTop: 8,
                color: themeColor,
                fontWeight: "400",
                marginRight: 10
              }}>
              Level {lv}
            </div>
            {defaultComm.filter(comm => comm.level == lv && comm.user_type).length <
            Object.entries(userType).length ? (
              <div style={{ display: "inline-flex" }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: themeColor,
                    color: "white",
                    marginBottom: 10,
                    padding: 7,
                    alignItems: "center"
                  }}
                  className={classes.submit}
                  onClick={() => addNewType(lv)}>
                  Add User Type
                </Button>
              </div>
            ) : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: themeColor,
                color: "white",
                marginBottom: 10,
                padding: 7,
                alignItems: "center"
              }}
              className={classes.submit}
              onClick={() => removeThisLv(lv)}>
              Remove
            </Button>
            {typeError.find(errLv => errLv == lv) ? (
              <div
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 8,
                  marginLeft: 2
                }}>
                * must have at least one user type
              </div>
            ) : null}
          </GridItem>
        </GridContainer>
        {defaultComm.sort(compare)
          .filter(comm => comm.level == lv && comm.user_type)
          .map(comm => (
            <div key={comm.user_type}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ fontSize: 15, fontWeight: "400" }}>
                  {Object.entries(userType).find(user => user[0] == comm.user_type)[1]}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={5} md={5}>
                  <FormControl
                    required
                    fullWidth={true}
                    style={{ marginLeft: 0, marginTop: 3 }}
                    error={
                      calculationTypeErr.find(id => id == lv + "" + comm.user_type) ? true : false
                    }
                    className={classes.formControl}>
                    <InputLabel
                      className={classes.inputLabel}
                      classes={{
                        focused: classes.inputFocused
                      }}>
                      Calculation Type
                    </InputLabel>
                    <Select
                      className={classes.select}
                      name="calculation_type"
                      variant="outlined"
                      value={comm.calculation_type}
                      onChange={e => updateCommission(e, lv + "" + comm.user_type)}
                      input={<Input id={lv + "" + comm.user_type} />}
                      inputlabelprops={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      {Object.entries(calculationType).map(type => (
                        <MenuItem key={type[0]} value={type[0]}>
                          {type[1]}
                        </MenuItem>
                      ))}
                    </Select>
                    {calculationTypeErr &&
                      calculationTypeErr.find(id => id == lv + "" + comm.user_type) && (
                        <FormHelperText>This is required</FormHelperText>
                      )}
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={3} md={3}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    style={{ marginTop: 3 }}
                    disabled={comm.calculation_type == 2}
                    label={"Percent(%)"}
                    name="percent"
                    type="number"
                    defaultValue={comm.percent ? comm.percent : ""}
                    onChange={e => updateCommission(e, lv + "" + comm.user_type)}
                    inputProps={{
                      maxLength: 100,
                      minLength: 1,
                      min: 0.01,
                      max: 100,
                      step: ".01"
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={3} md={3}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    disabled={comm.calculation_type == 1}
                    label={"Amount(RM)"}
                    name="amount"
                    type="number"
                    defaultValue={comm.amount ? comm.amount : ""}
                    style={{ marginTop: 3 }}
                    onChange={e => updateCommission(e, lv + "" + comm.user_type)}
                    inputProps={{
                      maxLength: 100,
                      minLength: 1,
                      min: 0.01,
                      max: 10000,
                      step: ".01"
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
          ))}
      </div>
    ));
  };

  const commissionEligibility = () => {
    return commission.map(comm => (
      <div key={comm.user_type} style={{ marginBottom: 10 }}>
        <GridContainer style={{ fontSize: 16, fontWeight: "400" }}>
          <GridItem xs={6} sm={12} md={12}>
            {Object.entries(userType).find(user => user[0] == comm.user_type)[1]}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              type="number"
              label={"Sales Count"}
              name="sales_count"
              defaultValue={comm.sales_count}
              onChange={e => updateCommission(e, comm.user_type)}
              inputProps={{
                maxLength: 100,
                minLength: 1,
                min: 0,
                max: 1000000
              }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  disabled: classes.disabled
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              type="number"
              label={"Sales Amount"}
              name="sales_volume"
              defaultValue={comm.sales_volume}
              onChange={e => updateCommission(e, comm.user_type)}
              inputProps={{
                maxLength: 100,
                minLength: 1,
                min: 0,
                max: 10000000,
                step: ".01"
              }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  disabled: classes.disabled
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    ));
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/commission"
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={mode == "default" ? 10 : 9}>
          <Card>
            <form className={classes.form} onSubmit={event => handleSubmit(event)}>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>
                  {mode == "default" ? "Default Commission Rate" : "Commission Eligibility"}
                </h4>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "right", fontSize: 15 }}>
                  {commission.length > 0 && commission[0].updated_at
                    ? "Updated at " +
                      commission[0].updated_at.substring(0, 10) +
                      " " +
                      commission[0].updated_at.substring(11, 19)
                    : null}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    paddingBottom: 10,
                    display: "inline-flex"
                  }}>
                  <div style={{ paddingTop: 10, marginRight: 10 }}>{chooseCom.name}</div>
                  {mode == "default" && levelArr.lv.length < maxLevel ? (
                    <div style={{ display: "inline-flex" }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: themeColor,
                          color: "white",
                          marginBottom: 10,
                          padding: 7,
                          alignItems: "center"
                        }}
                        className={classes.submit}
                        onClick={() => addNewLevel()}>
                        Add Level
                      </Button>
                      {levelArr.lv.length == 0 ? (
                        <div
                          style={{
                            fontSize: 13,
                            color: "red",
                            marginTop: 8,
                            marginLeft: 2
                          }}>
                          * must have at least one level
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {mode == "default" ? defaultCommRate() : commissionEligibility() 
                }
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
