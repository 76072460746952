import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import * as ORDER from "../../services/order";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect, Link } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import * as moment from "moment";
import Swal from "sweetalert2";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  select: {
    // marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function OrderManagement(props) {
  const classes = useStyles();
  const [orderList, setOrderList] = useState([]);
  const [orderSelection, setOrderSelection] = useState([]);
  const [tabSelected, setTabSelected] = useState(
    props.location.state && props.location.state.sales ? props.location.state.sales : 0
  ); //0 is online, 1 is offfline
  const [tabLoad, setTabLoad] = useState(false);
  const [selectedUser, setSelectedUser] = useState("-- Select --");
  const [selectedPayment, setSelectedPayment] = useState("-- Select --");
  const [selectedStatus, setSelectedStatus] = useState("-- Select --");
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    props.location.state && props.location.state.fromDate ? props.location.state.fromDate : null
  );
  const [selectedToDate, setSelectedToDate] = React.useState(
    props.location.state && props.location.state.toDate ? props.location.state.toDate : null
  );
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [pagination, setPagination] = React.useState([]);
  const [pageArr, setPageArr] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  const handleChangeTab = tab => {
    setTabLoad(true);
    clearFilter();
    setTabSelected(tab);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (Array.isArray(selectedStatus)) {
        params.status = selectedStatus[0];
      }
      if (selectedUser !== "-- Select --") {
        params.user = selectedUser.id;
      }
      if (selectedFromDate) {
        params.from = moment(selectedFromDate).format("YYYY-MM-DD");
      }
      if (selectedToDate) {
        params.to = moment(selectedToDate).format("YYYY-MM-DD");
      }
      if (selectedPayment) {
        params.payment = selectedPayment.id;
      }
      if (searchInput.length > 0) {
        params.order_number = searchInput;
      }
    }
    if (tabSelected == 1) {
      params.tab = "offline";
    } else {
      params.tab = "online";
    }
    ORDER.getOrderListSelection(params.tab)
      .then(res => {
        setOrderSelection(res.data);
      })
      .then(() => {
        if (props.location.state) {
          if (props.location.state.search) {
            params.order_number = props.location.state.search;
            setSearchInput(params.order_number);
            props.location.state.search = null;
          }
          if (props.location.state.user) {
            params.user = props.location.state.user.id;
            setSelectedUser(props.location.state.user);
            props.location.state.user = null;
          }
          if (props.location.state.status) {
            params.status = props.location.state.status[0];
            setSelectedStatus(props.location.state.status);
            props.location.state.status = null;
          }
          if (props.location.state.payment) {
            params.payment = props.location.state.payment.id;
            setSelectedPayment(props.location.state.payment);
            props.location.state.payment = null;
          }
          if (props.location.state.fromDate) {
            params.from = props.location.state.fromDate;
            setSelectedFromDate(props.location.state.fromDate);
            props.location.state.fromDate = null;
          }
          if (props.location.state.toDate) {
            params.to = props.location.state.toDate;
            setSelectedToDate(props.location.state.toDate);
            props.location.state.toDate = null;
          }
        }
        props.history.replace({
          pathname: "/order",
          state: {}
        });
        ORDER.getOrderList(currentPage, params)
          .then(result => {
            let page = [];
            for (let i = 0; i < result.pagination.last_page; i++) {
              page.push(i + 1);
            }
            setPageArr(page);
            setPagination(result.pagination);
            setOrderList(result.data);
            setLoading(false);
            setTabLoad(false);
          })
          .catch(err => {
            if (err == "token expired") {
              props.history.replace("/login");
            }
          });
      });
  }, [onFilter, currentPage, tabSelected]);

  const handleTypeChange = event => {
    const options = event.target.value;
    setSelectedUser(options);
  };

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    if (selectedFromDate != "Invalid Date" && selectedToDate != "Invalid Date") {
      if (selectedToDate != null && selectedToDate < selectedFromDate) {
        Swal.fire({
          icon: "error",
          title: "Search Fail",
          text: "End date cannot be ealier than start date"
        });
      } else {
        setLoading(true);
        setCurrentPage(1);
        setOnFilter(searchInput + selectedStatus + selectedUser + selectedPayment + new Date());
      }
    }
  };

  const clearFilter = () => {
    setLoading(true);
    setSelectedStatus("-- Select --");
    setSelectedUser("-- Select --");
    setSelectedPayment("-- Select --");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setSearchInput("");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    fromDate: selectedFromDate,
    toDate: selectedToDate,
    user: selectedUser !== "-- Select --" ? selectedUser : null,
    payment: selectedPayment !== "-- Select --" ? selectedPayment : null,
    status: Array.isArray(selectedStatus) ? selectedStatus : null,
    sales: tabSelected
  };

  if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/order/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          variant="contained"
          style={{
            backgroundColor: tabSelected == 0 ? themeColor : "white",
            color: tabSelected == 0 ? "white" : themeColor,
            marginRight: 10
          }}
          className={classes.submit}
          onClick={() => handleChangeTab(0)}>
          Online Sales
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: tabSelected == 1 ? themeColor : "white",
            color: tabSelected == 1 ? "white" : themeColor
          }}
          className={classes.submit}
          onClick={() => handleChangeTab(1)}>
          Offline Sales
        </Button>
        {tabLoad ? (
          <div className={classes.load}>
            <CircularProgress style={{ fontSize: 30, color: themeColor }} />
          </div>
        ) : (
          <Card>
            <CardBody>
              <GridContainer style={{ paddingBottom: 8 }}>
                <GridItem xs={12} sm={8} md={6}>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      From:
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      To:
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ paddingBottom: 10 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <GridItem xs={6} sm={6} md={6}>
                        <KeyboardDatePicker
                          clearable
                          fullWidth
                          value={selectedFromDate}
                          minDate={orderList.length>0?orderList[0].purchased_at:null}
                          placeholder="Start Date"
                          onChange={date => setSelectedFromDate(date)}
                          format="dd/MM/yyyy"
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <KeyboardDatePicker
                          clearable
                          fullWidth
                          value={selectedToDate}
                          placeholder="End Date"
                          onChange={date => setSelectedToDate(date)}
                          format="dd/MM/yyyy"
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              disabled: classes.disabled
                            }
                          }}
                        />
                      </GridItem>
                    </MuiPickersUtilsProvider>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={4} md={6}>
                  <GridContainer>
                    <GridItem xs={6} sm={12} md={6}>
                      {tabSelected == 0 ? "Payment Method: " : null}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={12} md={6}>
                      {tabSelected == 0 ? (
                        <Select
                          className={classes.select}
                          id="type"
                          name="type"
                          variant="outlined"
                          fullWidth
                          value={selectedPayment}
                          onChange={e => setSelectedPayment(e.target.value)}
                          input={<Input id="type" />}
                          renderValue={selected => (selected.name ? selected.name : selected)}>
                          <MenuItem key={"all"} value={"-- Select --"}>
                            <ListItemText primary={"All"} />
                          </MenuItem>
                          {orderSelection.payment.map(payment => (
                            <MenuItem key={payment.id} value={payment}>
                              <ListItemText primary={payment.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={8} md={6}>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      Purchased By:
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      Status:
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ alignItems: "center", paddingBottom: 8 }}>
                    <GridItem xs={6} sm={6} md={6}>
                      <Select
                        className={classes.select}
                        id="user"
                        name="user"
                        variant="outlined"
                        fullWidth
                        value={selectedUser}
                        onChange={handleTypeChange}
                        input={<Input id="user" />}
                        renderValue={selected =>
                          selected.username ? selected.username : selected
                        }>
                        <MenuItem key={"all"} value={"-- Select --"}>
                          <ListItemText primary={"All"} />
                        </MenuItem>
                        {orderSelection.user.map(user => (
                          <MenuItem key={user.id} value={user}>
                            <ListItemText primary={user.username} />
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Select
                        className={classes.select}
                        id="status"
                        name="status"
                        fullWidth
                        variant="outlined"
                        value={Array.isArray(selectedStatus) ? selectedStatus[1] : selectedStatus}
                        onChange={handleStatusChange}
                        input={<Input id="status" />}
                        renderValue={selected => selected}>
                        <MenuItem key={"all"} value={"-- Select --"}>
                          <ListItemText primary={"All"} />
                        </MenuItem>
                        {Object.entries(orderSelection.status).map(status => (
                          <MenuItem key={status[0]} value={status}>
                            <ListItemText primary={status[1]} />
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {tabSelected == 0 ? (
                  <GridItem xs={12} sm={4} md={3}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth
                      id="search"
                      label="Search"
                      name="search"
                      placeholder={"order number"}
                      style={{ marginTop: 8 }}
                      onKeyDown={keyPress}
                      value={searchInput}
                      onChange={handleSearchInput}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                ) : null}
                <GridItem
                  xs={12}
                  sm={tabSelected == 0 ? 12 : 4}
                  md={tabSelected == 0 ? 3 : 6}
                  style={{ textAlign: "right", marginBottom: 10 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginTop: 12 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginTop: 12
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
              {orderList.length > 0 ? (
                <div id="table" style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>ID</TableCell>
                        {tabSelected == 0 ? (
                          <TableCell className={classes.th}>Order Number</TableCell>
                        ) : (
                          <TableCell className={classes.th}>Coupon Code</TableCell>
                        )}
                        <TableCell className={classes.th}>Course</TableCell>
                        {tabSelected == 0 ? (
                          <>
                            <TableCell className={classes.th}>Amount(RM)</TableCell>
                            <TableCell className={classes.th}>Payment Method</TableCell>
                          </>
                        ) : null}
                        <TableCell className={classes.th}>Purchased At</TableCell>
                        <TableCell className={classes.th}>Purchased By</TableCell>
                        <TableCell className={classes.th}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderList.map(order => (
                        <TableRow
                          onClick={() => routeTo("view", order.id)}
                          className={classes.tr}
                          key={order.id}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {order.id}
                          </TableCell>
                          {tabSelected == 0 ? (
                            <TableCell className={classes.td}>{order.order_number}</TableCell>
                          ) : (
                            <TableCell className={classes.td}>{order.voucher}</TableCell>
                          )}
                          <TableCell className={classes.td}>
                            {order.course.map((course, i, arr) => {
                              if (arr.length - 1 === i) {
                                return arr[i].name;
                              } else {
                                return (
                                  <div key={i}>
                                    {arr[i].name}
                                    {"\n"}
                                  </div>
                                );
                              }
                            })}
                          </TableCell>
                          {tabSelected == 0 ? (
                            <>
                              <TableCell className={classes.td}>{order.amount}</TableCell>
                              <TableCell className={classes.td}>
                                {order.payment_method ? order.payment_method : "voucher"}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell className={classes.td}>
                            {order.purchased_at.substring(0, 10) +
                              " " +
                              order.purchased_at.substring(11, 19)}
                          </TableCell>
                          <TableCell className={classes.td}>{order.username}</TableCell>
                          <TableCell
                            // style={{color:order.status==1?'#4caf50':order.status==2?'#f44336':'black',fontWeight:'400'}}
                            className={classes.td}>
                            {order.status}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>
                    No {tabSelected == 0 ? "online" : "offline"} order record found
                  </Typography>
                </div>
              )}
              {orderList.length > 0 ? (
                <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                  <div style={{ fontSize: 13 }}>
                    Page :
                    <Select
                      style={{
                        marginLeft: 10,
                        width: 60,
                        fontSize: 13,
                        textAlign: "center",
                        marginTop: 12
                      }}
                      className={classes.select}
                      id="page"
                      name="page"
                      variant="outlined"
                      value={currentPage}
                      MenuProps={MenuProps}
                      onChange={e => goToPage(e)}
                      input={<Input id="page" />}
                      renderValue={selected => selected}>
                      {pageArr.map(page => (
                        <MenuItem key={page} value={page}>
                          <ListItemText primary={page} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={pagination.total}
                    rowsPerPage={10}
                    page={currentPage - 1}
                    onChangePage={handleChangePage}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
