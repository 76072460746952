import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import * as CENTRE from "../../services/centre";
import * as CENTRECATEGORY from "../../services/centercategory";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Geocode from "react-geocode";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    marginTop: theme.spacing(2),
    "&:before": {
      borderColor: themeColor
    },
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
  inputFocused: {}
}));

const useStyles = styles;

const inputRef1 = React.createRef();
const inputRefv = React.createRef();
let images = "";
let newaddress = "";
let val = "";
let lat = null;
let long = null;

export default function CentreDetail(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [stateSelection, setStateSelection] = useState([]);
  const [imageObject, setImageObject] = useState([]); 
  const [videoObject, setVideoObject] = useState([]);   
  const [ownerSelection, setOwnerSelection] = useState([]);
  const [videoid, setVideoid] = React.useState(1);
  const [itemid, setItemid] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [tempSrc, setTempSrc] = useState(""); 
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null);  
  const [categorySelection, setCategorySelection] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCenterOwner, setSelectedCenterOwner] = useState("");
  const [loading, setLoading] = useState(true);
  const [descError, setDescError] = useState(false);
  const [descLengthError, setDescLengthError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);
  const [remarkLengthError, setRemarkLengthError] = useState(false);
  const [postcodeError, setPostcodeError] = useState(false);
  const [postcodeLengthError, setPostcodeLengthError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [mobileNumError, setMobileNumError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [streetLengthError, setStreetLengthError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [value, setValue] = React.useState('1');

  const [crop, setCrop] = useState({
    unit: 'px',
    width: 1280,
    aspect: 16/9 
  });
  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;
  const onCategory =
    props.location.state && props.location.state.category 
      ? props.location.state.category 
      : null;  
  const onSortType =
    props.location.state && props.location.state.sortType 
      ? props.location.state.sortType : null;

  const [formData, setFormData] = useState({
    id : props.match.params.id,
    name: "",
    desc: "",
    mobile_number_prefix: "",
    mobile_number: "",
    street_address:"",
    city: "",
    state: "",
    postcode: "",
    status: "",
    is_banner:"",
    remark:"",
    category:""
  });

  const {
    name,
    desc,
    mobile_number_prefix,
    mobile_number,
    street_address,
    city,
    state,
    postcode,
    status,
    is_banner,
    remark,
    category
  } = formData;

  const re = /^[0-9\b]+$/;
  let valid = true;
  useEffect(() => {
    const id = props.match.params.id;
    CENTRECATEGORY.getAllCategoryList()
    .then(result => { 
      setCategorySelection(result.data.data);

      CENTRE.getCentreDetail(id)
      .then(res => { 

          let mobile = "";
          if (res['data']['contact_num'] == null) {
            mobile = "N/A"
          } else {
            mobile = res['data']['contact_num']
          }

        let datadetails = {
            ["name"]:res['data']['name'],
            ['desc']:res['data']['desc'],
            ['street_address']:res['data']['street_address'],
            ['postcode']:res['data']['postcode'],
            ['mobile_number']:mobile,
            ['Status']:setSelectedStatus(res['data']['status']['id']),
            ['state']:setSelectedState(res['data']['state_id']),
            ['city']:res['data']['city_name'],
            ['owner']:setSelectedCenterOwner(res['data']['center_owner_detail'][0]['user_id']),
            ['remark']:res['data']['remark'],
            ['category']:setSelectedCategory(res['data']['categories'][0]['id'])
            // ['category']:setSelectedCategory(Object.values(res['data']['categories'])[0])
        }
        setFormData({
          ...datadetails
        }); 

        let tempid = itemid;
        let a=[];
        for (let i = 0; i< res['data']['images'].length;i++) {
          a.push({
            id:tempid,
            imagefilename:res['data']['images'][i]['filename'],
            url:res['data']['images'][i]['url'],
            value:res['data']['images'][i]['is_banner']
          })
          tempid=tempid+1;
        }
        setItemid(tempid);
        setImageObject(a);

        let tempvid = videoid;
        let b=[];
        if (res['data']['video_filename'] != null) {
          for (let j = 0; j < 1; j++) {
            b.push({
              id:tempvid,
              videofilename:res['data']['video_filename'],
              url:res['data']['video_url']
            })
            tempvid=tempvid+1;
          }
          setVideoid(tempvid);
          setVideoObject(b);
      }
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        } else {
            if (err.status == "not found") {
                Swal.fire({
                  icon: "error",
                  title: "Opps",
                  text: "Page Not Found"
                }).then(() => {
                  props.history.replace("/centre");
                });
            }
            if (err === "Unauthorized") {
                Swal.fire({
                  icon: "error",
                  title: "Oops",
                  text: "Session expired. Please login again"
                  })
                  .then(() => {
                    localStorage.removeItem('BZtokenData');
                    setLoggedIn(false);      
                  });
            }
        }
      });  
      
      CENTRE.getStateList()
      .then(res => {
        setStateSelection(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });

      CENTRE.getUserList()
      .then(res => {       
        setOwnerSelection(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });
    });
  }, 
  []);

  useEffect(() => {
    let img = images;
    const scaleX = img.naturalWidth / img.width;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });   
    setLoading(false);  
  }, [images]);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const updateFormData = event => {
    if (event.target.name === "mobile_number_select") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        ["mobile_number_prefix"]: event.target.value
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setStatusError(false);
    setMobileNumError(false);
    setStreetError(false);
    setNameError(false);
    setStateError(false);
    setCityError(false);
    setOwnerError(false);
    setCategoryError(false);
    setDescLengthError(false);
    setPostcodeLengthError(false);
    setRemarkLengthError(false);
    setStreetLengthError(false);

    setLoading(true);

    videoObject.forEach(video=>{
      if(video.error || video.formatError){
          valid = false;
          setLoading(false);
      }
    })

    if (categoryError) {
        valid = false;
        setLoading(false);
        setCategoryError(true);
    }
    if (selectedCategory.length == 1) {
        setCategoryError(true);
        setLoading(false);
        valid = false;
    }
    if (postcode.length == 0) {
        setPostcodeError(true);
        setLoading(false);
        valid = false;
    }
    if (selectedStatus.length == 0) {
        setStatusError(true);
        setLoading(false);
        valid = false;
    }
    if (selectedState.length == 0) {
        setStateError(true);
        setLoading(false);
        valid = false;
    }
    if (city.length == 0) {
        setCityError(true);
        setLoading(false);
        valid = false;
    }
    if (desc.length == 0) {
        setDescLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (mobile_number.length == 0) {
        setMobileNumError(true);
        setLoading(false);
        valid = false;
    }
    if (postcode.length == 0) {
        setPostcodeLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (selectedStatus == 3) {
      if (remark.length == 0) {
        setRemarkLengthError(true);
        setLoading(false);
        valid = false;
      }
    }
    if (name.length == 0) {
        setNameLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (!re.test(event.target.mobile_number.value)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }

    let remarks = "";
    if (selectedStatus == 3) {
        remarks = remark;
    } 

    val = stateSelection.filter(state =>{ 
      return state.id === selectedState });

      if (!Array.isArray(val) || !val.length){
          val = selectedState
      }
      else{
          val = val[0].name;
      }

    newaddress = street_address + "," + postcode + "," + city + "," + val;
    
    if (newaddress !== null) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.fromAddress(newaddress)
      .then(response => {
        lat = response.results[0].geometry.location.lat;
        long = response.results[0].geometry.location.lng;


    if (valid) {
      let updateData = {
        ... formData,
        images: imageObject,
        videos:videoObject,
        id : props.match.params.id,
        status:selectedStatus,
        category:selectedCategory,
        state:selectedState,
        is_banner:value,
        remark:remarks,
        latitude:lat,
        longitude:long
      }

      setLoading(true);
      CENTRE.updateCentre(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Centre details updated successfully"
          })
          // .then(() => {
          //   props.history.replace("/centre");
          // });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              // if (err.errors.cover) errMsg.push(err.errors.cover);
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
              Swal.fire({
                icon: "error",
                title: "Fail to update centre details",
                text: errMsg
              });
          }
            setLoading(false);
        });
      }
    });
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => {setShow(false);setTempSrc("");}

  const validContactNum = event => {
    event.preventDefault();
    if (mobile_number.length !== -1) {
      if (mobile_number.length > 10 
        || (!Number.isInteger(Number(mobile_number)))) {
        setMobileNumError(true);
        setLoading(false);
        valid = false;
      }
      if (mobile_number.length < 11 && (Number.isInteger(Number(mobile_number)))) {
        setMobileNumError(false);
      }
    }
  }

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }

  const validPostcodeChange = event => {
    event.preventDefault();
    if (postcode.length !== -1){
      if (postcode.length > 5){
        setPostcodeLengthError(true);
        setLoading(false);
        valid = false;
      }
      if (!Number.isInteger(Number(postcode))) {
        setPostcodeError(true);
        setLoading(false);
        valid = false;
      }
      if (postcode.length < 6 && Number.isInteger(Number(postcode))) {
        setPostcodeLengthError(false);
      }
      if (Number.isInteger(Number(postcode))) {
        setPostcodeError(false);
      }
    }
  }

  const validDescLength = () => {
    if (desc.length > 999) {
      setDescError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length > 0) {
      setDescLengthError(false);
    }
    if (desc.length < 1000) {
      setDescError(false);
    }
  }

  const validStreetLength = () => {
    if (street_address.length > 120) {
      setStreetLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (street_address.length <=120) {
      setStreetLengthError(false);
    }
  }

  const validRemarkLength = () => {
    if (remark.length > 0) {
    if (remark.length > 121) {
      setRemarkError(true);
      setLoading(false);
      valid = false;
    }
  }
    if (remark.length > 0) {
      setRemarkLengthError(false);
    }
    if (remark.length <= 120) {
      setRemarkError(false);
    }
  }

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);

    if (options != null) {
      setStatusError(false);
    }
  };

  const handleStateChange = event => {
    const options = event.target.value;
    setSelectedState(options);

      if (options != null) {
        setStateError(false);
      } 
  };

  const handleOwnerChange = event => {
    const options = event.target.value;
    setSelectedCenterOwner(options);

    if (options != null) {
      setOwnerError(false);
    }
  };

  const handleCategoryChange = event => {
    const options = event.target.value;
    setSelectedCategory(options);

    if (options != null) {
      setCategoryError(false);
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > 5000000) {
        imageErrorAlert();
      }
      else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
        imageFormatErrorAlert();
      }
      else {
        if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
          let img = new Image;
          reader.onload = function (e) {
            img.onload = function() { 
    
            if (img.width < 1080 ) {
              removeImg();
              Swal.fire ({
                  icon: "error",
                  title: "Oops",
                  text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
              })
            }
            else {
                handleShow();
            }
            
            };
            img.src = reader.result;
          };
        }
      }

      reader.onloadend = () => {
        setTempSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  //start of crop image function
  const onImageLoaded = image => {
    images = image;
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9) ;

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_centreImage.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }
  // end of crop image function

  const saveUrl = () => {
    setImageObject([...imageObject, {
        id : itemid,
        imagefile: completedCrop,
        url : cropImageUrl
    }]);
    setItemid(itemid+1);
  }

  const removeImg = (id) => {
    setImageObject(
      imageObject.filter(image => {
        return image.id !== id;
      })
    );
    setTempSrc("");
  };

  const handleVideoChange = e => {
    e.preventDefault();
    let videofile = null;
    let url = "";
    let verror = false;
    let vformatError = false;

    if (e.target.files.length >= 1) {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size > (Math.pow(2,20))*50) {
      verror = true;
    }

    if (file.type != "video/mp4") {
      vformatError = true;
    }

    if (file.type == "video/mp4") {
      reader.onload = function() {
        var media = new Audio(reader.result);
        media.onloadedmetadata = function(){

            if (media.duration > 76) {
                removeVid();
                Swal.fire ({
                    icon: "error",
                    title: "Oops",
                    text: "Maximum video duration is 75 seconds."
                })
            }
        };    
    };
  }

    videofile = file;

    reader.onloadend = () => {
      url = reader.result;
      setVideoObject([...videoObject, {
        id:videoid,
        videofile:videofile,
        url:url,
        error:verror,
        formatError:vformatError
      }]);
    };
    reader.readAsDataURL(file);
    setVideoid(videoid+1)
  }
  };

  const removeVid = (id) => {
    setVideoObject(
      videoObject.filter(video => {
        return video.id !== id;
      })
    );
  };

  const handleCheckbox = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/centre",
            state: {
              currentPage: page,
              search: search,
              category: onCategory, 
              status: onStatus,
              sortType: onSortType
            }
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <form
              className={classes.form}
              onSubmit={event => handleSubmit(event)}
            >
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Edit Centre</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={nameError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Centre Name (max 100 characters)"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        autoFocus
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF NAME IS 100 CHARACTER.</FormHelperText>
                      )}
                       {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MINIMUM LENGTH OF NAME IS 2 CHARACTER.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                           NAME IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem item xs={12} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={mobileNumError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Contact Number"
                        onChange={e => updateFormData(e)}
                        onKeyUp={validContactNum}
                        name="mobile_number"
                        value={mobile_number}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          MOBILE NUMBER IS INVALID.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      disabled
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      error={ownerError}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Centre Owner
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="owner"
                        name="owner"
                        variant="outlined"
                        value={selectedCenterOwner}
                        onChange={handleOwnerChange}
                        input={<Input id="owner" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {ownerSelection.map(owner => (
                          <MenuItem key={owner.id} value={owner.id}>
                            {owner.first_name + " " + owner.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {ownerError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          CENTRE OWNER IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0,marginTop: 15 }}
                      error={categoryError}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Centre Category
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="category"
                        name="category"
                        variant="outlined"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        input={<Input id="category" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                        >
                        {categorySelection.map(category => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {categoryError && (
                        <FormHelperText>PLEASE SELECT ONE(1) CATEGORY.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={streetError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="street_address"
                        label="Address (max 120 characters)"
                        name="street_address"
                        onChange={e => updateFormData(e)}
                        onKeyUp={validStreetLength}
                        value={street_address}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {streetError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          STREET INFO IS REQUIRED.</FormHelperText>
                      )}
                      {streetLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF STREET INFO ALLOWED IS 120.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={postcodeError,postcodeLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="postcode"
                      label="Postcode"
                      name="postcode"
                      onChange={e => updateFormData(e)}
                      onKeyUp={validPostcodeChange}
                      value={postcode}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {postcodeError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          INVALID FORMAT.THIS FIELD ONLY ACCEPT INTEGER.</FormHelperText>
                      )}
                    {postcodeLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF POSTCODE IS 5.</FormHelperText>
                      )}  
                  </FormControl> 
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 25 }}
                      error={stateError}
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        State
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="state"
                        name="State"
                        variant="outlined"
                        value={selectedState}
                        onChange={handleStateChange}
                        input={<Input id="state" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {stateSelection.map(state => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {stateError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          STATE IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      error={cityError}
                      className={classes.formControl}
                    >
                      <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      onChange={e => updateFormData(e)}
                      value={city}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                      {cityError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          CITY IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 35 }}
                      error={statusError}
                      className={classes.formControl}
                      disabled={selectedStatus==3}
                    >
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                            Status
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="Status"
                        name="Status"
                        variant="outlined"
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                      <MenuItem key={1} value={1} style={{ color: "#FFA500" }}>
                        Pending
                      </MenuItem>
                      <MenuItem key={2} value={2} style={{ color: "#4caf50" }}>
                        Published
                      </MenuItem>
                      <MenuItem key={3} value={3} style={{ color: "#FF0000" }}>
                        Rejected
                      </MenuItem>
                      <MenuItem key={4} value={4} style={{ color: "#000000" }}>
                        Removed
                      </MenuItem>
                    </Select>
                    {statusError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          STATUS IS REQUIRED.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>

                  {(selectedStatus == 3) ? ( 
                    <GridItem xs={7} sm={5} md={7}>
                       <FormControl
                          required
                          fullWidth={true}
                          style={{ marginLeft: 0, marginTop: 0 }}
                          error={remarkError}
                          className={classes.formControl}
                       >
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      id="remark_field"
                      rows="2"
                      label="Remark (max 120 characters)"
                      name="remark"
                      value={remark}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validRemarkLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {remarkError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF REMARK IS 120 CHARACTER.</FormHelperText>
                      )}
                    {remarkLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          REMARK IS REQUIRED.</FormHelperText>
                      )} 
                    </FormControl>
                  </GridItem>
                    ) : null }
                  </GridContainer>                 

                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={descError,descLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      rows="4"
                      label="Description (max 1000 characters)"
                      name="desc"
                      value={desc}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validDescLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {descError && (
                      <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF DESCRIPTION IS 1000 CHARACTER.</FormHelperText>
                      )}
                    {descLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          DESCRIPTION IS REQUIRED.</FormHelperText>
                      )}  
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <RadioGroup aria-label="banner" 
                    name="banner" 
                    value={value} 
                    onChange={handleCheckbox}>

                <div style={{ paddingTop: 10, textAlign: "left" ,paddingBottom:10}}>
                  <Typography style={{ fontWeight: "inherit" }}>
                  Image (Max 5MB, only accept .jpg, .jpeg and .png format)
                  </Typography>
                </div>

                <GridContainer
                    direction="row"
                    alignItems="center">

                  {imageObject.map((image, index) => {
                    return (
                    <GridItem xs={12} sm={8} md={7}>
                      Image 
                        <div 
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                              <img
                                src={image.url}
                                onClick={() => window.open(image.url, "_blank")}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem",
                                  cursor: "pointer"
                                }}
                              />
                          </Box>
                        </div>

                          <div style={{ paddingLeft: 5 }}>
                            <div>

                            <div>
                              <Radio
                                name="banner"
                                style={{
                                  color: themeColor,
                                  padding: 5
                                }}
                                checked={value == image.id}
                                value={image.id}
                                inputProps={{ "aria-label": "1" }}
                              />
                                Set as banner?
                            </div>

                            <div>
                              <Button
                                onClick={() => {
                                  removeImg(image.id);
                                }}
                                style={{
                                    backgroundColor: themeColor,
                                    color: "white",
                                    marginLeft: 5,
                                    marginTop: "15%"
                                }}
                                variant="contained">
                                  Remove Image 
                              </Button>
                            </div>
                            
                            </div>
                        </div>
                      </div>                    
                    </GridItem>
                  )})}

                    {imageObject.length < 8 ? (
                    <GridItem xs={12} sm={5} md={5}>
                      <div>
                        <Button id="showbutton1"
                            onClick={() => {
                              inputRef1.current.click();
                            }}
                            style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 5,
                                marginTop: "10%"
                            }}
                            variant="contained">
                              Add Image 
                        </Button>
                              
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef1}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                    ):null}
                
                </GridContainer>    
              </RadioGroup>

              <div style={{ paddingTop: 40, textAlign: "left" ,paddingBottom:10}}>
                <Typography style={{ fontWeight: "inherit" }}>
                  Video (Max 50MB and 75 seconds, only .mp4 allowed)
                </Typography>
              </div>

              {videoObject.map((video,index) => {
                return (
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    Video
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "12rem",
                            height: "7.5rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted"
                          }}>
                            <video
                              onClick={() => {
                                window.open(video.url, "_blank");
                              }}
                              src={video.url}
                              ali=""
                              style={{
                                width: "12rem",
                                height: "7.5rem",
                                cursor: "pointer"
                              }}
                            />                         
                        </Box>
                      </div>
                      {video.error && (
                        <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                        }}>*FILE SIZE MUST BELOW OR EQUAL TO 50MB.</FormHelperText>
                      )}
                      {video.formatError && (
                        <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                        }}>*INVALID FORMAT.ONLY ACCEPT VIDEO WITH MP4 FORMAT.</FormHelperText>
                      )}
                      
                        <div style={{ paddingLeft: 20 }}>
                          <Button
                            onClick={() => {
                                removeVid(video.id);
                            }}                            
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              marginLeft: 10,
                              marginTop: "65%"
                            }}
                            variant="contained">
                            Remove
                          </Button>
                          
                       </div>
                    </div>                 
                  </GridItem>
                  </GridContainer> 
                )})}

            {videoObject.length < 1 ? (
              <GridContainer>
              <GridItem xs={12} sm={5} md={5}>              
               <div>
                <Button id="showbutton1"
                    onClick={() => {
                        inputRefv.current.click();
                    }}
                    style={{
                        backgroundColor: themeColor,
                        color: "white",
                        marginLeft: 5
                    }}
                    variant="contained">
                      Add Video 
                </Button>
             
                    <input
                      className="fileInput"
                      type="file"
                      hidden
                      ref={inputRefv}
                      accept="video/mp4"
                      style={{ marginLeft: 10 }}
                      onChange={e => handleVideoChange(e)}
                    />
                </div>
              </GridItem>
              </GridContainer>
            ):null}

                <Dialog classes={{ paper: classes.dialogPaper }}
                    open={show==true}
                    onClose={handleClose}
                    maxWidth = {'lg'}
                    disableBackdropClick={true}
                >
                <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                <DialogContent>
                  <ReactCrop
                      locked={true}
                      src={tempSrc}
                      crop={crop}
                      ruleOfThirds
                      imageStyle={{maxHeight:'70vh'}}
                      onImageLoaded={c => onImageLoaded(c)}
                      onChange={c => {setCrop(c)}}
                      onComplete={c => onCropComplete(c)}
                  />
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={() => {handleClose();}}>
                      Close
                    </Buttons>
                    <Buttons onClick={() => {handleClose();saveUrl();}}>
                      Save
                    </Buttons>
                </DialogActions>
                </Dialog>
                            
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}