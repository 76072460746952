import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import * as COURSE from "../../services/course";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import defaultCover from "assets/img/default_cover.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function CourseList(props) {
  const classes = useStyles();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("-- Select --");
  const [bundleOnly, setBundleOnly] = useState(false);
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [pageArr, setPageArr] = React.useState([]);
  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
      if (selectedType != "-- Select --") params.is_bundle = selectedType;
    }

    if (props.location.state) {
      if (props.location.state.search) {
        params.search = props.location.state.search;
        setSearchInput(params.search);
        props.location.state.search = null;
      }
      if (props.location.state.bundle == 1 || props.location.state.bundle == 0) {
        params.is_bundle = props.location.state.bundle;
        setSelectedType(params.is_bundle);
        props.location.state.bundle = null;
      }
    }
    props.history.replace({
      pathname: "/course",
      state: {}
    });

    COURSE.getCourseList(currentPage, params)
      .then(res => {
        if (res.pagination) {
          let page = [];
          for (let i = 0; i < res.pagination.last_page; i++) {
            page.push(i + 1);
          }
          setPageArr(page);
        }
        setCourseList(res.data);
        setPagination(res.pagination);
        setLoading(false);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
      });
  }, [currentPage, onFilter]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedType + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSearchInput("");
    setBundleOnly(false);
    setSelectedType("-- Select --");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const handleTypeChange = event => {
    const options = event.target.value;
    setSelectedType(options);
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    bundle: selectedType
  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/course/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/course/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("add")}>
          <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add Bundle
        </Button>
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
              <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem xs={6} sm={4} md={3}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"course name"}
                    fullWidth
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={5} sm={4} md={3} style={{ marginTop: 10 }}>
                  Type :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="type"
                    name="type"
                    variant="outlined"
                    value={selectedType}
                    onChange={handleTypeChange}
                    input={<Input id="type" />}
                    renderValue={selected =>
                      selected == 1 ? "Bundle" : selected == 0 ? "Not Bundle" : selected
                    }>
                    <MenuItem key={"all"} value={"-- Select --"}>
                      <ListItemText primary={"All"} />
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      <ListItemText primary={"Bundle"} />
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      <ListItemText primary={"Not Bundle"} />
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={4}
                  md={6}
                  style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 8
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
              {courseList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>ID</TableCell>
                        <TableCell style={{ width: 50 }} className={classes.th}></TableCell>
                        <TableCell className={classes.th}>Name</TableCell>
                        <TableCell className={classes.th}>Category</TableCell>
                        <TableCell className={classes.th}>Price (RM)</TableCell>
                        <TableCell className={classes.th}>Created At</TableCell>
                        <TableCell className={classes.th}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseList.map(course => (
                        <TableRow
                          onClick={() => routeTo("view", course.id)}
                          className={classes.tr}
                          key={course.id}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {course.id}
                          </TableCell>
                          <TableCell className={classes.td}>
                            <img
                              src={
                                course.cover_url
                                  ? course.cover_url
                                  : course.avatar
                                  ? course.avatar
                                  : defaultCover
                              }
                              ali=""
                              width="40"
                              height="40"
                              style={{ borderRadius: 20 }}
                            />
                          </TableCell>
                          <TableCell className={classes.td}>{course.name}</TableCell>
                          <TableCell className={classes.td}>{course.category_name}</TableCell>
                          <TableCell className={classes.td}>{course.retail_price}</TableCell>
                          <TableCell className={classes.td}>
                            {course.created_at.substring(0, 10) +
                              " " +
                              course.created_at.substring(11, 19)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: course.sales_status == 1 ? "#4caf50" : "#f44336",
                              fontWeight: "400"
                            }}
                            className={classes.td}>
                            {course.sales_status_name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {courseList.length > 0 ? (
                    <div
                      style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                      <div style={{ fontSize: 13 }}>
                        Page :
                        <Select
                          style={{
                            marginLeft: 10,
                            width: 60,
                            fontSize: 13,
                            textAlign: "center",
                            marginTop: 12
                          }}
                          className={classes.select}
                          id="type"
                          name="type"
                          variant="outlined"
                          value={currentPage}
                          onChange={e => goToPage(e)}
                          input={<Input id="type" />}
                          renderValue={selected => selected}
                          MenuProps={MenuProps}>
                          {pageArr.map(page => (
                            <MenuItem key={page} value={page}>
                              <ListItemText primary={page} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={pagination.total}
                        rowsPerPage={10}
                        page={currentPage - 1}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No course found</Typography>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
}
