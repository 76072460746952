import * as AUTH from "./auth";
const axios = require("axios");

export async function getUserList() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
    .get(
      process.env.REACT_APP_API_URL + "/" 
      + process.env.REACT_APP_API_PREFIX + 
      "/user?per_page=99999",
      {
      timeout: 60000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken
      }
    }
    )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getCentreList() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
    .get(
      process.env.REACT_APP_API_URL + "/api" + 
      "/ml-center?per_page=1000",
      {
      timeout: 60000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken
      }
    }
    )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getClassList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-class?" +
    "page=" +
    page +
    "&per_page=10";

  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.sortType) url += "&sort_type=" + params.sortType ;
    if (params.sortBy == 1) {
      url += "&is_feature=" + params.sortBy
    }
    
    if (params.status) url += "&status=" + params.status;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function addClass(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();

  data.append("center_id",createData.centre);
  data.append("type", createData.type);
  data.append("name", createData.name);
  data.append("desc", createData.desc);
  data.append("retail_price", createData.retail_price);
  data.append("promotion_price", createData.promotion_price);
  data.append("status", createData.status);
  // data.append("is_feature", createData.is_feature);
  data.append("teacher_id", createData.teacher); 
  data.append("category[0][id]",createData.category); 

  for (let i = 0; i < createData.coordinator.length; i++) {
    data.append("coordinator[" + i + "][user_id]", createData.coordinator[i]);
  }

  createData.images.forEach((image, index)=>{
    data.append("image["+ index + "][file]", image.imagefile);
    data.append("image[" + index + "][is_banner]", createData.is_banner == image.id ? 1 : 0);
  });

  if (createData.type == 1) {
    data.append("start_date", createData.start_date);
    data.append("end_date", createData.end_date);
    data.append("class_per_week", createData.week);
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/" 
        + process.env.REACT_APP_API_PREFIX + "/ml-class",data,
        {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function getClassDetail(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/ml-class/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function updateClass(updateData) {
  
}

export async function deleteClass(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-class/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}