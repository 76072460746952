import React,{useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-5.jpg";
import logo from "assets/img/ant-internet.png";
import UserDetails from "views/User/userDetails.js";
import AddVoucher from "views/Voucher/addVoucher.js";
import AddUser from "views/User/addUser.js";
import VoucherDetails from "views/Voucher/voucherDetails.js";
import TransferActivity from "views/TransferVoucher/transferActivity";
import TransferDetails from "views/TransferVoucher/transferDetails";
import AddBundle from "views/Course/addBundle";
import CourseDetails from "views/Course/courseDetails";
import OrderDetails from "views/Order/orderDetails";
import Commission from "views/Commission/commissionDetails";
import addCategory from "views/Category/addCategory";
import categoryDetail from "views/Category/categoryDetail";
import addCentre from "views/Centre/addCentre";
import centreDetail from "views/Centre/centreDetail";
import addClass from "views/Class/addClass";
import classDetail from "views/Class/classDetail";
import addBanner from "views/MarketingBanner/addBanner";
import addCentreCategory from "views/CentreCategory/addCentreCategory";
import CentreCategoryDetail from "views/CentreCategory/CentreCategoryDetail";
import addBook from "views/Ebook/addeBook";
import bookDetail from "views/Ebook/eBookDetail";
import addVideo from "views/VideoTeaching/addVideo";
import videoDetail from "views/VideoTeaching/videoDetail";
import listDetail from "views/FavouriteList/listDetail";
import centreOwnerDetail from "views/CentreOwner/centreOwnerDetail";
import addVoucher from "views/ml-voucher/ml-addVoucher";
import voucherDetail from "views/ml-voucher/ml-voucherDetail";
import addBusinessCard from "views/BusinessCard/businessCardDetail";
import businessCardDetail from "views/BusinessCard/businessCardDetail";
import addBannerVideo from "views/MarketingBanner/addBannerVideo";

let ps;

const switchRoutes = (
  <Switch>
    <Route path="/user/add" component={AddUser} />
    <Route path="/user/:id" component={UserDetails} />
    <Route path="/course/add" component={AddBundle} />
    <Route path="/course/:id" component={CourseDetails} />
    <Route path="/coupon/add" component={AddVoucher} />
    <Route path="/coupon/:id" component={VoucherDetails} />
    <Route path="/order/:id" component={OrderDetails} />
    <Route path="/commission/:id" component={Commission} />
    <Route path="/transferCoupon/history" component={TransferActivity} />
    <Route path="/transferCoupon/:id" component={TransferDetails} />
    <Route path="/category/add" component={addCategory} />
    <Route path="/category/:id" component={categoryDetail} />
    <Route path="/centre/add" component={addCentre} />
    <Route path="/centre/:id" component={centreDetail} />
    <Route path="/class/add" component={addClass} />
    <Route path="/class/:id" component={classDetail} />
    <Route path="/marketing-banner/add" component={addBanner} />
    <Route path="/center-category/add" component={addCentreCategory} />
    <Route path="/center-category/:id" component={CentreCategoryDetail} />
    <Route path="/e-book/add" component={addBook} />
    <Route path="/e-book/:id" component={bookDetail} />
    <Route path="/video-class/add" component={addVideo} />
    <Route path="/video-class/:id" component={videoDetail} />
    <Route path="/favourite-list/:id" component={listDetail} />
    <Route path="/centerowner/:id" component={centreOwnerDetail} />
    <Route path="/voucher/add" component={addVoucher} />
    <Route path="/voucher/:id" component={voucherDetail} />
    <Route path="/miCard/add" component={addBusinessCard} />
    <Route path="/miCard/:id" component={businessCardDetail} />
    <Route path="/market-banner-video/add" component={addBannerVideo} />

    {routes.map((prop, key) => {
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const image = bgImage 
  const color = "blue"
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
 // initialize and destroy the PerfectScrollbar plugin
 useEffect(() => {
  if (navigator.platform.indexOf("Win") > -1) {
    ps = new PerfectScrollbar(mainPanel.current, {
      suppressScrollX: true,
      suppressScrollY: false
    });
    document.body.style.overflow = "hidden";
  }
  window.addEventListener("resize", resizeFunction);
  // Specify how to clean up after this effect:
  return function cleanup() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", resizeFunction);
  };
}, [mainPanel]);


  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Ecard Daacaa"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
          <Footer />
        {/* {getRoute() ? <Footer /> : null} */}
      </div>
    </div>
  );
}
