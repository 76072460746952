import * as AUTH from "./auth";
const axios = require("axios");

export async function getCategoryList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-center-category?" +
    "page=" +
    page +
    "&per_page=10";

  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.sortType) url += "&sort_type=" + params.sortType ;
    if (params.sortBy == 1) {
      url += "&sort_type=desc&is_feature=" + params.sortBy;
    }
    // if (params.sortBy == "is_feature") {
    //   url += "&sort_by=is_feature&sort_type=desc";
    // }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout:60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {

        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getAllCategoryList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-center-category?" +
    "per_page=9999" ;
    
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout:60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {

        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function addCenterCategory(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();

  data.append("name", createData.name);
  data.append("zh_name",createData.zh_name);
  data.append("ms_name",createData.ms_name);
  // data.append("is_feature", createData.is_feature);
  if (createData.image) {
    data.append("image", createData.image)
  }

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/ml-center-category", data, {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function getCenterCategory(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX +
        "/ml-center-category/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function updateCentreCategory(updateData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("name", updateData.name);
  data.append("zh_name",updateData.zh_name);
  data.append("ms_name",updateData.ms_name);
  // data.append("is_feature", updateData.is_feature);
  if (updateData.image) {
    data.append("image", updateData.image);
  } else {
    if (updateData.image_filename) {
      data.append("image_filename", updateData.image_filename);
    };
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/ml-center-category/" +
        updateData.id +
        "?_method=put",
        data,
        {
          timeout: 60000,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function deleteCentreCategory(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-center-category/" + id, {
        timeout: 60000,  
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}