import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as USER from "../../services/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const countryCode = [
  {
    value: "673",
    label: "Brunei (+673)"
  },
  {
    value: "855",
    label: "Cambodia (+855)"
  },
  {
    value: "86",
    label: "China (+86)"
  },
  {
    value: "852",
    label: "Hong Kong (+852)"
  },
  {
    value: "62",
    label: "Indonesia (+62)"
  },
  {
    value: "856",
    label: "Lao People's Democratic Republic (+856)"
  },
  {
    value: "60",
    label: "Malaysia (+60)"
  },
  {
    value: "95",
    label: "Myanmar (+95)"
  },
  {
    value: "63",
    label: "Philippines (+63)"
  },
  {
    value: "65",
    label: "Singapore (+65)"
  },
  {
    value: "886",
    label: "Taiwan (+886)"
  },
  {
    value: "66",
    label: "Thailand (+66)"
  },
  {
    value: "84",
    label: "Viet Nam (+84)"
  },
  {
    value: "Others",
    label: "Others"
  }
];

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function UserDetails(props) {
  const classes = useStyles();
  const [userSelection, setUserSelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(true);
  const [mobileNumError, setMobileNumError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileNumPrefixError, setMobileNumPrefixError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onType =
    props.location.state && props.location.state.type ? props.location.state.type : null;
  const onStatus =
    props.location.state && props.location.state.status ? props.location.state.status : null;

  const [formData, setFormData] = useState({
    id: props.match.params.id,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    hasChangePass: false,
    mobile_number_select: "60",
    mobile_number_prefix: "60",
    mobile_number: "",
    user_type: "",
    status: ""
  });

  useEffect(() => {
    const id = props.match.params.id;
    USER.getUserSelection()
      .then(res => {
        setUserSelection(res.data);
      })
      .then(() => {
        USER.getUser(id)
          .then(result => {
            setUser(result.data);
            setFormData({
              ...formData,
              ["first_name"]: result.data.first_name,
              ["last_name"]: result.data.last_name,
              ["email"]: result.data.email,
              ["mobile_number"]: result.data.mobile_number,
              ["mobile_number_prefix"]: result.data.mobile_number_prefix
                ? result.data.mobile_number_prefix
                : "60",
              ["mobile_number_select"]: result.data.mobile_number_prefix
                ? countryCode.find(cc => cc.value == result.data.mobile_number_prefix) !== undefined
                  ? result.data.mobile_number_prefix
                  : "Others"
                : "60",
              ["user_type"]: result.data.user_type_name,
              ["status"]: result.data.status_name
            });
            setLoading(false);
          })
          .catch(err => {
            if (err == "token expired") {
              props.history.replace("/login");
            } else {
              if (err.status == "not found") {
                Swal.fire({
                  icon: "error",
                  title: "Opps",
                  text: "Page Not Found"
                }).then(() => {
                  props.history.replace("/user");
                });
              }
            }
          });
      });
  }, []);

  const updateFormData = event => {
    if (event.target.name === "mobile_number_select") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        ["mobile_number_prefix"]: event.target.value
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const {
    first_name,
    last_name,
    email,
    password,
    mobile_number_select,
    mobile_number_prefix,
    mobile_number,
    user_type,
    status
  } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setMobileNumError(false);
    setEmailError(false);
    let valid = true;
    if (!re.test(mobile_number)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }
    if (isNaN(event.target.mobile_number_prefix.value)) {
      setMobileNumPrefixError(true);
      setLoading(false);
      valid = false;
    }

    if (valid) {
      let updateData = formData;
      updateData = {
        ...updateData,
        status: Object.entries(userSelection.status).find(
          status => status[1] == formData.status
        )[0],
        user_type: Object.entries(userSelection.user_type).find(
          type => type[1] == formData.user_type
        )[0]
      };
      setLoading(true);
      USER.updateUser(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "User update successful"
          });
        })
        .catch(err => {
          if (err.errors) {
            setLoading(false);
            if (err.errors.email) {
              setEmailError(true);
              setEmailErrorMsg(err.errors.email[0]);
            }
          }
          setLoading(false);
        });
    }
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/user",
            state: { currentPage: page, search: search, type: onType, status: onStatus }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form className={classes.form} onSubmit={event => handleSubmit(event)}>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Edit User</h4>
              </CardHeader>
              <CardBody>
                <GridContainer style={{ paddingBottom: 20 }}>
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ fontSize: 15 }}>
                      {"Joined Since " +
                        user.joined_since.substring(0, 10) +
                        " " +
                        user.joined_since.substring(11, 19)}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ fontSize: 15 }}>
                      {"Last Login at " +
                        user.joined_since.substring(0, 10) +
                        " " +
                        user.joined_since.substring(11, 19)}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="First Name"
                      name="first_name"
                      value={first_name}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={emailError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        onChange={e => updateFormData(e)}
                        value={email}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {emailError && <FormHelperText>{emailErrorMsg}</FormHelperText>}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth
                      placeholder={"reset password"}
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={e => updateFormData(e)}
                      inputProps={{ minLength: 8 }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem item xs={12} sm={12} sm={5}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="mobile_number_select"
                      select
                      label="Country"
                      value={mobile_number_select}
                      onChange={e => updateFormData(e)}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      {countryCode.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                  <GridItem item xs={6} sm={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={mobileNumPrefixError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        margin="normal"
                        label="Country Code"
                        disabled={mobile_number_select === "Others" ? false : true}
                        name="mobile_number_prefix"
                        value={
                          mobile_number_select === "Others"
                            ? mobile_number_prefix === "Others"
                              ? ""
                              : mobile_number_prefix
                            : mobile_number_select
                        }
                        onChange={e => updateFormData(e)}
                        inputProps={{ maxLength: 4 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumPrefixError && (
                        <FormHelperText>mobile number prefix is invalid.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={12} sm={5} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={mobileNumError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        name="mobile_number"
                        value={mobile_number}
                        inputProps={{ maxLength: 20, minLength: 6 }}
                        onChange={e => updateFormData(e)}
                        InputProps={{
                          maxLength: 20,
                          minLength: 6,
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumError && <FormHelperText>mobile number is invalid.</FormHelperText>}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="user_type"
                      select
                      label="Type"
                      value={user_type}
                      onChange={e => updateFormData(e)}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      {Object.entries(userSelection.user_type).map(type => (
                        <MenuItem key={type[0]} value={type[1]}>
                          {type[1]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="status"
                      select
                      label="Status"
                      value={status}
                      onChange={e => updateFormData(e)}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled,
                          input:
                            status == "Active"
                              ? classes.activeColor
                              : status == "Inactive"
                              ? classes.inactiveColor
                              : classes.bannedColor
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      {Object.entries(userSelection.status).map(status => (
                        <MenuItem
                          key={status[0]}
                          value={status[1]}
                          style={{
                            color: status[0] == 1 ? "#4caf50" : status[0] == 2 ? "#f44336" : "black"
                          }}>
                          {status[1]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
