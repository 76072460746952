import * as AUTH from "./auth";
const axios = require("axios");

export async function getBannerList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-marketing-banner?" +
    "page=" +
    page +
    "&per_page=10";

  if (params) {
    // if (params.type) url += "&user_type=" + params.type;
    if (params.status) url += "&status=" + params.status;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function addBanner(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("status", createData.status);
  if (createData.image) {
    data.append("image", createData.image)
  }

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/" 
      + process.env.REACT_APP_API_PREFIX + 
      "/ml-marketing-banner", data, 
      {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function updateBanner(banner_id,update_id) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  if (banner_id == 1) {
    data.append("status", 2);
  } 
  else {
    data.append("status", 1);
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/ml-marketing-banner/" +
        update_id +
        "?_method=put",
        data,
        {
          timeout: 60000,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function deleteBanner(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-marketing-banner/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function getBannerVideoList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/ml-marketing-banner-video?" +
    "page=" +
    page +
    "&per_page=10";

  if (params) {
    // if (params.type) url += "&user_type=" + params.type;
    if (params.status) url += "&status=" + params.status;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function addBannerVideo(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();

  data.append("status", createData.status);
  if (createData.video_url) data.append("video",createData.video_url);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/" 
      + process.env.REACT_APP_API_PREFIX + 
      "/ml-marketing-banner-video", data, 
      {
        timeout: 60000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
				reject(error.message);
			})
      .finally();
  });
}

export async function updateBannerVideo(banner_id,update_id) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  if (banner_id == 1) {
    data.append("status", 2);
  } 
  else {
    data.append("status", 1);
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/ml-marketing-banner-video/" +
        update_id +
        "?_method=put",
        data,
        {
          timeout: 60000,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}

export async function deleteBannerVideo(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/"
        + process.env.REACT_APP_API_PREFIX
        + "/ml-marketing-banner-video/" + id, {
        timeout: 60000,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          reject(error.message)
        }
      })
      .finally();
  });
}
