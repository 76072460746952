import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "@material-ui/core/Button";
import * as USER from "../../services/user";
import * as TRANSFER from "../../services/transferCode";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import * as moment from "moment";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  select: {
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function TransferActivity(props) {
  const classes = useStyles();
  const [historyList, setHistoryList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [onFilter, setOnFilter] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [selectedDealerFrom, setSelectedDealerFrom] = useState("------ Select ------");
  const [selectedDealerTo, setSelectedDealerTo] = useState("------ Select ------");
  const [pageArr, setPageArr] = React.useState([]);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const dealer =
    props.location.state && props.location.state.dealer ? props.location.state.dealer : null;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  var myRef;

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (selectedDealerFrom != "------ Select ------") {
        params.transfer_from = dealerList.find(dealer => dealer.username == selectedDealerFrom).id;
      }
      if (selectedDealerTo != "------ Select ------") {
        params.transfer_to = dealerList.find(dealer => dealer.username == selectedDealerTo).id;
      }
      if (selectedFromDate) {
        params.from_time = moment(selectedFromDate).format("YYYY-MM-DD");
      }
      if (selectedToDate) {
        params.to_time = moment(selectedToDate).format("YYYY-MM-DD");
      }
    }
    USER.getDealerList()
      .then(res => {
        setDealerList(res.data.filter(dealer => dealer.user_type !== 1));
      })
      .then(() => {
        TRANSFER.getTransferList(currentPage, params)
          .then(result => {
            let page = [];
            for (let i = 0; i < result.pagination.last_page; i++) {
              page.push(i + 1);
            }
            setPageArr(page);
            setPagination(result.pagination);
            setHistoryList(result.data);
            setLoading(false);
          })
          .catch(err => {
            if (err == "token expired") {
              props.history.replace("/login");
            }
          });
      });
  }, [onFilter, currentPage]);

  const handleTypeChange = (event, type) => {
    const options = event.target.value;
    if (type == "from") setSelectedDealerFrom(options);
    else setSelectedDealerTo(options);
  };

  const filterThis = () => {
    if (selectedFromDate != "Invalid Date" && selectedToDate != "Invalid Date") {
      if (selectedToDate != null && selectedToDate < selectedFromDate) {
        Swal.fire({
          icon: "error",
          title: "Search Fail",
          text: "End date cannot be ealier than start date"
        });
      } else {
        setLoading(true);
        setCurrentPage(1);
        setOnFilter(searchInput + selectedDealerFrom + selectedDealerTo + new Date());
      }
    }
  };

  const clearFilter = () => {
    setLoading(true);
    setSelectedDealerFrom("------ Select ------");
    setSelectedDealerTo("------ Select ------");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  const returnMinEndDate = () =>{
    let date = new Date(historyList[0].transfer_at)
    const newDate = addDays(date, 1);
    return newDate
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/transferCoupon",
            state: { currentPage: page, search: search, dealer: dealer }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div
            id="table"
            ref={node => {
              myRef = node;
            }}>
            <Card>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Transfer Activity</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={3}>
                    From:
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    To:
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GridItem xs={4} sm={4} md={3}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedFromDate}
                        placeholder="Start Date"
                        minDate={historyList.length>0?historyList[0].transfer_at:null}
                        onChange={date => setSelectedFromDate(date)}
                        format="dd/MM/yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={3}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedToDate}
                        placeholder="End Date"
                        minDate={historyList.length>0?returnMinEndDate():null}
                        onChange={date => setSelectedToDate(date)}
                        format="dd/MM/yyyy"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                      />
                    </GridItem>
                  </MuiPickersUtilsProvider>
                  <GridItem xs={4} sm={4} md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                      className={classes.submit}
                      onClick={filterThis}>
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: themeColor,
                        color: "white",
                        marginLeft: 8,
                        marginBottom: 8
                      }}
                      className={classes.submit}
                      onClick={clearFilter}>
                      Reset
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={3}>
                    From:
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    To:
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ paddingBottom: 15 }}>
                  <GridItem xs={4} sm={4} md={3}>
                    <Select
                      className={classes.select}
                      id="type"
                      name="type"
                      variant="outlined"
                      fullWidth
                      value={selectedDealerFrom}
                      onChange={event => handleTypeChange(event, "from")}
                      input={<Input id="dealer" />}
                      MenuProps={MenuProps}
                      renderValue={selected => selected}>
                      <MenuItem key={"all"} value={"------ Select ------"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      {dealerList.map(dealer =>
                        selectedDealerTo !== dealer.username ? (
                          <MenuItem key={dealer.id} value={dealer.username}>
                            <ListItemText primary={dealer.username} />
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <Select
                      className={classes.select}
                      id="type"
                      name="type"
                      variant="outlined"
                      fullWidth
                      value={selectedDealerTo}
                      onChange={event => handleTypeChange(event, "to")}
                      input={<Input id="dealer" />}
                      MenuProps={MenuProps}
                      renderValue={selected => selected}>
                      <MenuItem key={"all"} value={"------ Select ------"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      {dealerList.map(dealer =>
                        selectedDealerFrom !== dealer.username ? (
                          <MenuItem key={dealer.id} value={dealer.username}>
                            <ListItemText primary={dealer.username} />
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </GridItem>
                </GridContainer>
                {historyList.length > 0 ? (
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.th}>ID</TableCell>
                          <TableCell className={classes.th}>Coupon Code</TableCell>
                          <TableCell className={classes.th}>Course</TableCell>
                          <TableCell className={classes.th}>Transfer From</TableCell>
                          <TableCell className={classes.th}>Transfer To</TableCell>
                          <TableCell className={classes.th}>Transfer At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historyList.map(user => (
                          <TableRow className={classes.tr} key={user.id}>
                            <TableCell className={classes.td}>{user.id}</TableCell>
                            <TableCell className={classes.td}>{user.code}</TableCell>
                            <TableCell className={classes.td}>
                              {user.course.map((course, i, arr) => {
                                if (arr.length - 1 === i) {
                                  return arr[i].name;
                                } else {
                                  return (
                                    <div key={i}>
                                      {arr[i].name}
                                      {"\n"}
                                    </div>
                                  );
                                }
                              })}
                            </TableCell>
                            <TableCell
                              style={{ fontStyle: user.transfer_from ? "Normal" : "Italic" }}
                              className={classes.td}>
                              {user.transfer_from ? user.transfer_from_name : "Admin"}
                            </TableCell>
                            <TableCell className={classes.td}>{user.transfer_to_name}</TableCell>
                            <TableCell className={classes.td}>
                              {user.transfer_at.substring(0, 10) +
                                " " +
                                user.transfer_at.substring(11, 19)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <div style={{ paddingTop: 30, paddingBottom: 20, textAlign: "center" }}>
                    <Typography style={{ fontWeight: "inherit" }}>
                      No transfer activity found
                    </Typography>
                  </div>
                )}
                {historyList.length > 0 ? (
                  <div
                    style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                    <div style={{ fontSize: 13 }}>
                      Page :
                      <Select
                        style={{
                          marginLeft: 10,
                          width: 60,
                          fontSize: 13,
                          textAlign: "center",
                          marginTop: 12
                        }}
                        className={classes.select}
                        id="type"
                        name="type"
                        variant="outlined"
                        value={currentPage}
                        MenuProps={MenuProps}
                        onChange={e => goToPage(e)}
                        input={<Input id="type" />}
                        renderValue={selected => selected}>
                        {pageArr.map(page => (
                          <MenuItem key={page} value={page}>
                            <ListItemText primary={page} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[]}
                      count={pagination.total}
                      rowsPerPage={10}
                      page={currentPage - 1}
                      onChangePage={handleChangePage}
                    />
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
