import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as AUTH from "../../services/auth";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import logo from "assets/img/logo_ant-internet_web.png";
import logo_small from "assets/img/ant-internet.png";
import Footer from "components/Footer/Footer.js";
import {useWindowSize} from "../../helper/general-helper";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: themeColor
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(3),
    marginBottom:"125px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: themeColor + "!important"
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {}
}));

export default function Login(Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [failMsg, setFailMsg] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const size = useWindowSize();
  function redirectToAdmin() {
    Props.history.replace("/dashboard");
  }

  function handleLogin(event) {
    setLoading(true);
    event.preventDefault();
    let username = event.target.username.value;
    let pass = event.target.password.value;
    let loginData = {
      username: username,
      password: pass
    };
    AUTH.login(loginData)
      .then(result => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Login Successful",
          showConfirmButton: false,
          timer: 1500
        });
        redirectToAdmin();
      })
      .catch(res => {
        setLoading(false);
        setModalVisible(true);
        if (res.status == "unauthorized") {
          setFailMsg(res.message);
          // alert(
          //   res.status.charAt(0).toUpperCase() +
          //     res.status.slice(1) +
          //     "\n" +
          //     res.message
          // );
        } else {
          if (res.errors) {
            let failMsg = [];
            if (res.errors.email) {
              failMsg.push(res.errors.email);
            }
            if (res.errors.password) {
              failMsg.push(res.errors.password);
            }
            setFailMsg(failMsg);
            // alert(
            //   res.message.charAt(0).toUpperCase() +
            //     res.message.slice(1) +
            //     "\n" +
            //     failMsg
            // );
          }
        }
      });
  }

  useEffect(() => {
    let todayDate = new Date();
    if (localStorage.getItem("BZtokenData") != null) {
      if (new Date(localStorage.getItem("BZexpireAt")) > todayDate) {
        redirectToAdmin();
      }
    }
  }, []);

  return (
    <div style={{display:"flex"}}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress style={{ fontSize: 30, color: themeColor }} />
        </div>
      ) : (
        <Container component="main" maxWidth="xs" style={{ marginTop: 60 }}>
          <CssBaseline />
          <div className={classes.paper}>
            <div style={{ flexDirection: "row" , width:300 , marginTop:20, textAlign:"center"}}>
              <img
                src={size.width < 375 ? logo_small : logo}
                style={size.width < 375 ? {width: '40%', height: "auto", marginBottom: 20} : {width: '100%', height: "auto", marginBottom: 20}}
              />
            </div>
            <Typography component="h1" variant="h5">
              ADMIN PANEL
            </Typography>
            <form
              className={classes.form}
              onSubmit={event => handleLogin(event)}
            >
              <div style={{ color: "red" }}>{failMsg}</div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                  }
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                  }
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: themeColor, color: "white" }}
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>

            <div style={{ position:"absolute",bottom:0,right:0}}>
              <Footer />
            </div>

          </div>
        </Container>
      )}
    </div>  
  );
}
