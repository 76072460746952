import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Assignment from "@material-ui/icons/Assignment";
import ListAlt from "@material-ui/icons/ListAlt";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import ConfirmationNumber from "@material-ui/icons/ConfirmationNumber";
import Category from "@material-ui/icons/Category";
import Business from "@material-ui/icons/Business";
import Classs from "@material-ui/icons/Class";
import Apps from "@material-ui/icons/Apps";
import Book from "@material-ui/icons/ImportContacts";
import Video from "@material-ui/icons/VideoLibrary";
import People from "@material-ui/icons/Group";
import Dashboard from "@material-ui/icons/Dashboard";
import NameCard from "@material-ui/icons/CreditCard";
import Favorite from "@material-ui/icons/Favorite";
import Star from "@material-ui/icons/Star";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import PageView from "@material-ui/icons/Pageview";
import Voucher from "views/Voucher/Voucher.js";
import TransferVoucher from "views/TransferVoucher/TransferVoucher.js";
import Report from "views/Report/Report.js";
import User from "views/User/User.js";
import Course from "views/Course/Course.js";
import Order from "views/Order/Order.js";
import Commission from "views/Commission/Commission";
import Categorys from "views/Category/Category";
import Centre from "views/Centre/Centre";
import mlvoucher from "views/ml-voucher/ml-voucher";
import Class from "views/Class/Class";
import CentreCategory from "views/CentreCategory/centreCategory";
import Ebook from "views/Ebook/Ebook";
import VideoTeaching from "views/VideoTeaching/videoTeaching";
import Users from "views/Users/Users";
import Owners from "views/CentreOwner/centreOwner";
import HomePage from "views/Dashboard/dashboard";
import favouriteList from "views/FavouriteList/favouriteList";
import featuredCategory from "views/FeaturedCategory/featuredCategory";
import Market from "views/MarketingBanner/Banner";
import MarketVideo from "views/MarketingBanner/Video";
import ShowCase from "views/ShowCase/showCase";
import businessCard from "views/BusinessCard/businessCard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: HomePage,
    layout: ""
  },
  {
    path: "/users",
    name: "User Management",
    icon: People,
    component: Users,
    layout: ""
  },
  {
    path: "/centerowner",
    name: "Centre Owner Management",
    icon: People,
    component: Owners,
    layout: ""
  },
  {
    path: "/center-category",
    name: "Centre Category",
    icon: Apps,
    component: CentreCategory,
    layout: ""
  },
  {
    path: "/centre",
    name: "Centre Management",
    icon: Business,
    component: Centre,
    layout: ""
  }, 
  {
    path: "/voucher",
    name: "Voucher Management",
    icon: ConfirmationNumber,
    component: mlvoucher,
    layout: ""
  }, 
  {
    path: "/miCard",
    name: "MiCard",
    icon: NameCard,
    component: businessCard,
    layout: ""
  }, 
  {
    path: "/category",
    name: "Category",
    icon: Category,
    component: Categorys,
    layout: ""
  },
  // {
  //   path: "/class",
  //   name: "Class Management",
  //   icon: Classs,
  //   component: Class,
  //   layout: ""
  // },
  {
    path: "/e-book",
    name: "eBook Management",
    icon: Book,
    component: Ebook,
    layout: ""
  },
  {
    path: "/video-class",
    name: "Video Class Management",
    icon: Video,
    component: VideoTeaching,
    layout: ""
  },
  {
    path: "/marketing-banner",
    name: "Marketing Banner",
    icon: ViewCarousel,
    component: Market,
    layout: ""
  },
  {
    path: "/market-banner-video",
    name: "Marketing Banner Video",
    icon: ViewCarousel,
    component: MarketVideo,
    layout: ""
  },
  {
    path: "/showcase",
    name: "Showcase Management",
    icon: PageView,
    component: ShowCase,
    layout: ""
  },
  // {
  //   path: "/favourite-list",
  //   name: "Favourite List Management",
  //   icon: Favorite,
  //   component: favouriteList,
  //   layout: ""
  // },
  // {
  //   path: "/featured-category",
  //   name: " Featured Category Management",
  //   icon: Star,
  //   component: featuredCategory,
  //   layout: ""
  // },

  // {
  //   path: "/coupon",
  //   name: "Coupon Management",
  //   icon: ConfirmationNumber,
  //   component: Voucher,
  //   layout: ""
  // },
  // {
  //   path: "/transferCoupon",
  //   name: "Transfer Coupon Code",
  //   icon: SwapHoriz,
  //   component: TransferVoucher,
  //   layout: ""
  // },
  // {
  //   path: "/user",
  //   name: "User Management",
  //   icon: Person,
  //   component: User,
  //   layout: ""
  // },
  // {
  //   path: "/course",
  //   name: "Course Management",
  //   icon: LibraryBooks,
  //   component: Course,
  //   layout: ""
  // },
  // {
  //   path: "/order",
  //   name: "Order Management",
  //   icon: ListAlt,
  //   component: Order,
  //   layout: ""
  // },
  // {
  //   path: "/commission",
  //   name: "Commission",
  //   icon: AttachMoney,
  //   component: Commission,
  //   layout: ""
  // },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: Assignment,
  //   component: Report,
  //   layout: ""
  // }
];

export default dashboardRoutes;
