import * as AUTH from "./auth";
const axios = require("axios");

export async function getFeaturedList() {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-dashboard-setting" 
    //   +
    //   "page=" +
    //   page +
    //   "&per_page=10"
    // if (params) {
    //   if (params.search) url += "&search=" + params.search;

    //   if (params.category) {
    //     if (params.category == "-- Select --") {
    //       params.category = ""
    //     }
    //     url += "&categories=" + params.category;
    //   }

    //   if (params.sortby) url += "&sort_by=" + params.sortby ;
    // }
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }