import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as BANNER from "../../services/banner";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import { Router, Route, Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

const inputRef = React.createRef();

export default function AddBannerVideo(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true);
  const [selectedStatus,setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [VideoError, setVideoError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const [formData, setFormData] = useState({
    status: "",
    video: "",
    video_url: ""
  });
    
  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { status, video, video_url } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setStatusError(false);
    setVideoError(false);

    setLoading(true);
    let valid = true;

    if (selectedStatus.length == 0) {
      valid = false;
      setStatusError(true);
      setLoading(false);
    }
    
    if (valid) {

      let cerateData = {
        ...formData,
        status:selectedStatus,
        video_url:video
      };

      setLoading(true);
      BANNER.addBannerVideo(cerateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "New banner video added successfully"
          }).then(() => {
            props.history.replace("/market-banner-video");
          });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              // if (err.errors.cover) errMsg.push(err.errors.cover);
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
              Swal.fire({
                icon: "error",
                title: "Fail to Add Banner Video",
                text: errMsg
              });
          }
          setLoading(false);
        });
    }
  }

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);

    if (options != null) {
      setStatusError(false);
    }
  };
  
  const handleVideoChange = e => {
        e.preventDefault();
    
        if (e.target.files.length >= 1 ) {
          let reader = new FileReader();
          let file = e.target.files[0];

          if (file.size > (Math.pow(2,20))*50) {
            VideoErrorAlert();
          }
    
          else if (file.type != "video/mp4" ) {
            VideoFormatErrorAlert();
          }
    
          else {
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    ["video"]: file,
                    ["video_url"]: reader.result
                });
            };
            reader.readAsDataURL(file);
          }
        }
  };

  const removeVid = () => {
    inputRef.current.value = "";
    setFormData({ ...formData, ["video_url"]: null });
  }    

  const VideoErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept video with size less than 50MB."
    })
    .then(() => {
      inputRef.current.value = "";
      setFormData({ ...formData, ["video_url"]: null });
    });
  };

  const VideoFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept video with mp4 format."
    })
    .then(() => {
      inputRef.current.value = ""
    });
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/market-banner-video",
              state: { currentPage: page, search: search, sortType: onSortType }
            })
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
                method="post"
                encType="multipart/form-data">
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Add Marketing Banner Video</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      Video (Max file size 50MB, only .mp4 format accepted)
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {video_url ? (
                              <video
                                src={video_url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "20%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                          {VideoError && (
                            <FormHelperText style={{ color: "#f44336"}}>
                                THIS FIELD IS REQUIRED.</FormHelperText>
                           )}
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {video_url ? (
                            <Button
                              onClick={removeVid}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="video/mp4"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleVideoChange(e)}
                          />
                      </div>
                    </GridItem>
                  </GridContainer>
    
                  <GridContainer>
                    <GridItem xs={12} sm={3} md={3}>
                      <TextField
                        required
                        fullWidth
                        style={{ paddding: 0, marginTop: 40 }}
                        margin="normal"
                        name="status"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={e => handleStatusChange(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Active
                        </MenuItem>
                        <MenuItem key={2} value={2} style={{ color: "#f44336" }}>
                          Inactive
                      </MenuItem>
                      </TextField>
                      {statusError && (
                         <FormHelperText style={{ color: "#f44336"}}>
                            STATUS IS REQUIRED.</FormHelperText>
                      )}
                    </GridItem>
                  </GridContainer>

                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Submit
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
}