import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as CATEGORY from "../../services/category";
import * as VIDEO from "../../services/video";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Buttons from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Typography from "@material-ui/core/Typography";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  dividerColor: {
    backgroundColor: themeColor
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  descColor: {
    color: "#1E90FF",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  }
}));

const useStyles = styles;

const inputRef = React.createRef();
let images = "";
let id = 1;

export default function VideoDetail(props) {
  const classes = useStyles();
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [videoObjectList, setVideoObjectList] = useState([]);
  const [videoObject, setVideoObject] = useState([]);
  const [videoid, setVideoid] = React.useState(1);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false); 
  const [tempSrc, setTempSrc] = useState(""); 
  const [completedCrop, setCompletedCrop] = useState(null);  
  const [cropImageUrl, setCropImageUrl] = useState(null); 
  const [numberOfVideo, setNumberOfVideo] = React.useState(1);
  const [menulist, setMenulist] = React.useState([1]);
  const [categorySelection, setCategorySelection] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFeatured, setSelectedFeatured] = useState("");
  const [nameError, setNameError] = useState(false);
  const [minNameLengthError, setMinNameLengthError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [featuredError, setFeaturedError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [descLengthError, setDescLengthError] = useState(false);

  const [formData, setFormData] = useState({
    id: props.match.params.id, name: "", image: "", image_url: "", image_filename:"",
    desc:"", category:"", status:"", is_feature:""
  });

  const [crop, setCrop] = useState({
    unit: 'px',
    width: 1280,
    aspect: 16 / 9,
  });

  let valid = true;
  useEffect(() => {
    let params = {};
    params.per_page = 999;
    setLoading(false);
    const id = props.match.params.id;

    CATEGORY.getAllCategoryList()
      .then(result => {
        setCategorySelection(result.data.data);

        VIDEO.getVideoDetail(id)
        .then(res => {

          let menuItems = [];
          for (let i = 0; i<res['data']['video_urls'].length; i++) {  
            menuItems.push(i+1);
          }
          setMenulist(menuItems);
          setNumberOfVideo(res['data']['video_urls'].length);

          let datadetails = {
                ["name"]: res['data']['name'],
                ["desc"]: res['data']['desc'],
                ["is_feature"]: setSelectedFeatured(res['data']['is_feature']),
                ["image_filename"]: res['data']['image_filename'],
                ["image_url"]: res['data']['image_url'],
                ["status"]:setSelectedStatus(res['data']['status']['id']),
                ['category']:setSelectedCategory(res['data']['categories'][0]['id'])
                // ['category']:setSelectedCategory(Object.values(res['data']['categories'])[0])
              }

              setFormData({
                ...datadetails                  
              }); 

              let a = [];

              for (let i = 0; i< res['data']['video_urls'].length;i++) {
                a.push({
                  edu_id:res['data']['video_urls'][i]['educational_video_id'],
                  id: res['data']['video_urls'][i]['id'],
                  url:res['data']['video_urls'][i]['admin_video_url'],
                  name:res['data']['video_urls'][i]['video_name'],
                  order:res['data']['video_urls'][i]['order'],
                })
              }
    
              setVideoObjectList(a);
    
              setLoading(false);
        })
        .catch(err => {
            if (err == "token expired") {
            props.history.replace("/login");
            } else {
                if (err.status == "not found") {
                    Swal.fire({
                    icon: "error",
                    title: "Opps",
                    text: "Page Not Found"
                    }).then(() => {
                    props.history.replace("/video-class");
                    });
                }
                if (err === "Unauthorized") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops",
                    text: "Session expired. Please login again"
                    }).
                    then(() => {
                      localStorage.removeItem('BZtokenData');
                      setLoggedIn(false);      
                    });
                }
            }
        });
    })  
  },
   []);

  useEffect(() => {

    let img = images;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setCrop({
      aspect: 16/9,
      width: 1280/scaleX
    });
          
    setLoading(false);

  }, [images]);

  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onCategory =
    props.location.state && props.location.state.category ? props.location.state.category : null;
  const onSortby =
    props.location.state && props.location.state.sortby ? props.location.state.sortby : null;
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateVideoOrder = (e,videoid) => {
    let a = videoObjectList.map((video)=>{
      if(video.id === videoid){
        video.order = e.target.value;
      }
      return video;
    });
    setVideoObject(a);
  }

  const updateVideoName = (e,videoid) => {
    let a = videoObjectList.map((video)=>{
      if(video.id === videoid){
        video.name = e.target.value;
      }
      return video;
    });
    setVideoObject(a);
  }

  const updateVideoUrl = (e,videoid) => {
    let a = videoObjectList.map((video)=>{
      if(video.id === videoid){
        video.url = e.target.value;
      }
      return video;
    });
    setVideoObject(a);
  }

  const { name, image_url, image_filename, desc, category, status, is_feature } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setNameError(false);
    setNameLengthError(false);
    setMinNameLengthError(false);
    setCategoryError(false);
    setDescError(false);
    setDescLengthError(false);
    setStatusError(false);
    setFeaturedError(false);

    setLoading(true);

    if (categoryError) {
        valid = false;
        setLoading(false);
    }
    if (selectedCategory.length == 0) {
        setCategoryError(true);
        setLoading(false);
        valid = false;
    }
    if (selectedStatus.length == 0) {
        setStatusError(true);
        setLoading(false);
        valid = false;
    }
    // if (selectedFeatured.length == 0) {
    //     setFeaturedError(true);
    //     setLoading(false);
    //     valid = false;
    // }
    if (desc.length == 0) {
        setDescLengthError(true);
        setLoading(false);
        valid = false;
    }
    if (name.length == 0) {
        setNameLengthError(true);
        setLoading(false);
        valid = false;
    }

    videoObjectList.forEach(video=>{
      if (video.order == null) {
        video.orderError = true;
      }

      if(video.nameError || video.nameLengthError || video.minNameLengthError ||
        video.urlLengthError || video.urlError || video.orderError) {
          valid = false;
          setLoading(false);
      }      
    })

    if (valid) {

      let updateData = {
        ...formData,
        id : props.match.params.id,
        videos:videoObjectList,
        status:selectedStatus,
        category:selectedCategory,
        image:completedCrop
        // is_feature:selectedFeatured
      };

      setLoading(true);

      VIDEO.updateVideo(updateData)
        .then(res => {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "Video Class updated successfully"
          })
          // .then(() => {
          //   props.history.replace("/video-class");
          // });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }

            let errMsg = [];
            if (err.errors) {
              if (err.errors.image) {
                errMsg.push(err.errors.image);
              }
            }
            else if (err == "timeout of 60000ms exceeded") {
              errMsg.push("Request timeout. You may experience a slow network.")
            }
            else {
              errMsg.push(err)
            }
            Swal.fire({
              icon: "error",
              title: "Fail to update video class",
              text: errMsg
            });
          }

          setLoading(false);
        });
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => {setShow(false);setTempSrc("");}


  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const validNameLength = () => {
    if (name.length > 100) {
      setNameError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length <= 1) {
      setMinNameLengthError(true);
      setLoading(false);
      valid = false;
    }
    if (name.length > 0) {
      setNameLengthError(false);
    }
    if (name.length >= 2) {
      setMinNameLengthError(false);
    }
    if (name.length < 101) {
      setNameError(false);
    }
  }

  const validDescLength = () => {
    if (desc.length > 999) {
      setDescError(true);
      setLoading(false);
      valid = false;
    }
    if (desc.length > 0) {
      setDescLengthError(false);
    }
    if (desc.length < 1000) {
      setDescError(false);
    }
  }

  const validVideoName = (e,videoid) => {
    let a = videoObject.map((video)=>{
      if(video.id === videoid){
    
        if (e.target.value.length > 100) {
          video.nameError = true;
        }
        if (e.target.value.length <= 1 ) {
          video.minNameLengthError = true;
        }
        if (e.target.value.length > 0) { 
          video.namelengthError = false;
        }
        if (e.target.value.length >= 2) { 
          video.minNameLengthError = false;
        }
        if (e.target.value.length < 101) { 
          video.nameError = false;
        }
      }
      return video;
    });
    setVideoObject(a);
  }

  const validurlLength = (e,videoid) => {
    let a = videoObject.map((video)=>{
      if(video.id === videoid){
    
        if (e.target.value.length > 255) {
          video.urlLengthError = true;
        }
        if (e.target.value.length <= 255) {
          video.urlLengthError = false;
        }
        if (e.target.value.length > 0) { 
          video.urlError = false;
        }      
      }
      return video;
    });
    setVideoObject(a);
  }

  const AddObjectToArray = () => {
    setVideoObjectList([
      ...videoObjectList, 
      {id: id, name: '' , url:'', order : null}
    ]);
    id++;
  }

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);

    if (options != null) {
      setStatusError(false);
    }
  };

  const numberOfVideoIncrement = e =>{
    e.preventDefault();
    if (numberOfVideo >= 10) {
      alert("max reached");
    } else {
      setMenulist([...menulist, numberOfVideo + 1]);
      setNumberOfVideo(numberOfVideo + 1);
    }
  }

  const numberOfVideoDecrement = e =>{
      e.preventDefault();
      setMenulist(
          menulist.filter(e => {
            return e !== numberOfVideo;
          })
      );
      setNumberOfVideo(numberOfVideo - 1);
  }

  const handleFeaturedChange = event => {
    const options = event.target.value;
    setSelectedFeatured(options);

    if (options != null) {
      setFeaturedError(false);
    }
  };

  const handleCategoryChange = event => {
    const options = event.target.value;
    setSelectedCategory(options);

    if (options != null) {
      setCategoryError(false);
    }
  };

  const handleImageChange = e => {
    e.preventDefault();

    if (e.target.files.length >= 1 ) {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file.size > 5000000) {
            imageErrorAlert();
        }
        else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
            imageFormatErrorAlert();
        }
        else {
            if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                let img = new Image;
                reader.onload = function (e) {
                    img.onload = function() { 

                    if (img.width < 1080 ) {
                    removeImg();
                    Swal.fire ({
                        icon: "error",
                        title: "Oops",
                        text: "The image has invalid dimension. Only accept image with minimum width of 1080px or higher."
                    })
                    }
                    else {
                        handleShow();
                    }
                    
                    };
                    img.src = reader.result;
                }
            };
        }

        reader.onloadend = () => {
            setTempSrc(reader.result);
        };

        reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const imageFormatErrorAlert = () => {
    Swal.fire ({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
    .then(() => {
      setTempSrc("");
    });
  };

  const onImageLoaded = image => {
    images = image
  }

  const onCropComplete = crop => {
    if ( images && crop.width && crop.height) {
      getCroppedImg( images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    const minWidth = 1280;
    const minHeight = 1280 / (16/9);

    let targetWidth = originWidth;
    let targetHeight = originHeight;

    if (originWidth < minWidth || originHeight < minHeight) {
      if (originWidth / originHeight < minWidth / minHeight) {
        targetWidth = minWidth;
        targetHeight = Math.round(minWidth * (originHeight / originWidth));
      } 
      else {
        targetHeight = minHeight;
        targetWidth = Math.round(minHeight * (originWidth / originHeight));
      }
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped_video.jpeg')
            setCropImageUrl(reader.result);
          }
    },'image/jpeg', 1)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
      setCompletedCrop(croppedImage);
  }

  const saveUrl = () => {
      setFormData({...formData, ['image_url']: cropImageUrl})
  }

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({ ...formData, 
        ['image']:null, 
        ['image_filename']:null,  
        ["image_url"]: null });
  };


  const resetVideo = (id) => {
    setVideoObjectList(
      videoObjectList.filter(video => {
        return video.id !== id;
      })
    );
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: themeColor,
            color: "white",
            marginBottom: 10,
            alignItems: "center"
          }}
          className={classes.submit}
          onClick={() =>
            props.history.replace({
              pathname: "/video-class",
              state: { currentPage: page, search: search, category: onCategory, sortby: onSortby, sortType: onSortType }
            })
          }>
          Back
      </Button>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <form
                className={classes.form}
                onSubmit={event => handleSubmit(event)}
                method="post"
                encType="multipart/form-data">
                <CardHeader color="warning" style={{ width: "fit-content" }}>
                  <h4 className={classes.cardTitleWhite}>Edit Video Class</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image (Max file size 5MB, only .jpg, .jpeg, .png)
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "16rem",
                              height: "9rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted",
                              overflow: "hidden"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_url, "_blank");
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "16rem",
                                  height: "9rem", 
                                  cursor: "pointer"
                                }} 
                                /> 
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "20%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={11} md={10}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={nameError}
                        className={classes.formControl}
                      >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        label="Name (max 100 characters)"
                        name="name"
                        value={name}
                        onChange={e => updateFormData(e)}
                        onKeyUp={validNameLength}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {nameError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MAXIMUM LENGTH OF NAME IS 100 CHARACTER.</FormHelperText>
                      )}
                       {minNameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          THE MINIMUM LENGTH OF NAME IS 2 CHARACTER.</FormHelperText>
                      )}
                      {nameLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                           NAME IS REQUIRED.</FormHelperText>
                      )}
                      </FormControl>
                    </GridItem>                    
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={4} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={statusError}
                        className={classes.formControl}
                      >
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{ focused: classes.inputFocused }}>
                              Status
                        </InputLabel>
                        <Select
                          className={classes.select}
                          id="Status"
                          name="Status"
                          variant="outlined"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                          inputlabelprops={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}>
                        <MenuItem key={1} value={1} style={{ color: "#FFA500" }}>
                          Draft
                        </MenuItem>
                        <MenuItem key={2} value={2} style={{ color: "#4caf50" }}>
                          Published
                        </MenuItem>
                      </Select>
                      {statusError && (
                          <FormHelperText style={{ color: "#f44336"}}>
                            Status is required.</FormHelperText>
                        )}
                      </FormControl>
                    </GridItem>

                    {/* <GridItem xs={5} sm={3} md={3}>
                      <FormControl
                        required
                        fullWidth={true}
                        style={{ marginLeft: 0, marginTop: 10 }}
                        error={featuredError}
                        className={classes.formControl}
                      >
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{ focused: classes.inputFocused }}>
                              Featured
                        </InputLabel>
                        <Select
                          className={classes.select}
                          id="Featured"
                          name="Featured"
                          variant="outlined"
                          value={selectedFeatured}
                          onChange={handleFeaturedChange}
                          inputlabelprops={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}>
                        <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                          Yes
                        </MenuItem>
                        <MenuItem key={0} value={0} style={{ color: "#f44336" }}>
                          No
                        </MenuItem>
                      </Select>
                        {featuredError && (
                          <FormHelperText style={{ color: "#f44336"}}>
                            THIS FIELD IS REQUIRED.</FormHelperText>
                        )}
                      </FormControl>
                    </GridItem> */}

                    <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                            required
                            fullWidth={true}
                            style={{ marginLeft: 0,marginTop: 10 }}
                            error={categoryError}
                            className={classes.formControl}>
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ focused: classes.inputFocused }}>
                            Category
                        </InputLabel>
                        <Select
                            className={classes.select}
                            id="category"
                            name="category"
                            variant="outlined"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            input={<Input id="category" />}
                            inputlabelprops={{
                            classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused
                            }
                            }}
                            >
                            {categorySelection.map(category => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                            ))}
                        </Select>
                      {categoryError && (
                        <FormHelperText>Please select one category.</FormHelperText>
                      )}
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={10}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={descError,descLengthError}
                      className={classes.formControl}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      multiline
                      rows="4"
                      label="Description (max 1000 characters)"
                      name="desc"
                      value={desc}
                      onChange={e => updateFormData(e)}
                      onKeyUp={validDescLength}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {descError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          The maximum length of description is 1000 character.</FormHelperText>
                      )}
                    {descLengthError && (
                        <FormHelperText style={{ color: "#f44336"}}>
                          Description is required.</FormHelperText>
                      )}  
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <div style={{ paddingTop: 10, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                      Video List
                  </Typography>
                  </div>

                      {videoObjectList.map((video,index) => {
                        return (
                          <div>
                        <GridContainer>
                            <GridItem xs={12} sm={11} md={10}>
                              <Typography style={{ paddingTop:10, fontWeight: "inherit" }}>
                                  Video
                              </Typography>
                                <FormControl
                                    required
                                    fullWidth={true}
                                    style={{ marginLeft: 0 }}
                                    className={classes.formControl}>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Video Name (max 100 characters)"
                                    name="name"
                                    value={video.name}
                                    onChange={e => updateVideoName(e,video.id)}
                                    onKeyUp={(e) => validVideoName(e,video.id)}
                                    InputProps={{
                                    classes: {
                                        underline: classes.underline,
                                        disabled: classes.disabled
                                    }
                                    }}
                                    InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                    }
                                    }}
                                />
                                {video.nameError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    THE MAXIMUM LENGTH OF VIDEO NAME IS 100 CHARACTER.</FormHelperText>
                                )}
                                {video.minNameLengthError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    THE MINIMUM LENGTH OF VIDEO NAME IS 2 CHARACTER.</FormHelperText>
                                )}
                                {video.nameLengthError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    VIDEO NAME IS REQUIRED.</FormHelperText>
                                )}
                                </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={8}>
                                <FormControl
                                    required
                                    fullWidth={true}
                                    style={{ marginLeft: 0 }}
                                    className={classes.formControl}>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Video URL"
                                    name="url"
                                    value={video.url}
                                    onChange={e => updateVideoUrl(e,video.id)}
                                    onKeyUp={(e) => validurlLength(e,video.id)}
                                    InputProps={{
                                    classes: {
                                        underline: classes.underline,
                                        disabled: classes.disabled
                                    }
                                    }}
                                    InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                    }
                                    }}
                                />
                                {video.urlLengthError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    THE MAXIMUM LENGTH OF URL IS 255 CHARACTER.</FormHelperText>
                                )}
                                {video.urlError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    VIDEO URL IS REQUIRED.</FormHelperText>
                                )}
                                </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={3} md={2}>
                                <FormControl
                                    required
                                    fullWidth={true}
                                    style={{ marginLeft: 0 ,marginTop:16}}
                                    className={classes.formControl}>
                                <InputLabel
                                    className={classes.inputLabel}
                                    classes={{ focused: classes.inputFocused }}>
                                        Video Order
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    id="order"
                                    name="order"
                                    variant="outlined"
                                    value={video.order}
                                    onChange={e => updateVideoOrder(e,video.id)}
                                    inputlabelprops={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                    }
                                    }}> 
                                    {menulist.map(menu => {
                                        return <MenuItem key={menu} value={menu}>{menu}</MenuItem>;
                                    })}
                                </Select>                                       
                                {video.orderError && (
                                    <FormHelperText style={{ color: "#f44336"}}>
                                    THIS FIELD IS REQUIRED.</FormHelperText>
                                )}
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={3} md={3}>
                              <Button 
                                onClick={(e) => {
                                  resetVideo(video.id);
                                  numberOfVideoDecrement(e);
                                }}
                                style={{
                                  backgroundColor: themeColor,
                                  color: "white",
                                  marginLeft: 5,
                                  marginTop: 10
                                }}
                                variant="contained">
                                  Remove Video 
                              </Button> 
                          </GridItem>
                        </GridContainer>

                        <Divider variant="middle" style={{marginTop:20,marginBottom:20}} classes={{root: classes.dividerColor}}/>    
                        </div>
                        )})} 

                  {videoObjectList.length < 10 ? (
                    <GridContainer>
                      <GridItem xs={12} sm={5} md={5}>
                        <Button id="showbutton1"
                            onClick={(e) => {
                              AddObjectToArray();
                              numberOfVideoIncrement(e);
                            }}
                            style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 5,
                                marginTop: 10
                            }}
                            variant="contained">
                            Add Video 
                        </Button>
                      </GridItem>
                    </GridContainer>
                    ):null}

                      <Dialog classes={{ paper: classes.dialogPaper }}
                        open={show==true}
                        onClose={handleClose}
                        maxWidth = {'lg'}
                        disableBackdropClick={true}
                      >
                      <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                      <DialogContent>
                        <ReactCrop
                          locked={true}
                          src={tempSrc}
                          crop={crop}
                          ruleOfThirds
                          imageStyle={{maxHeight:'70vh'}}
                          onImageLoaded={c => onImageLoaded(c)}
                          onChange={c => {setCrop(c)}}
                          onComplete={c => onCropComplete(c)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttons onClick={() => {handleClose();}}>
                          Close
                        </Buttons>
                        <Buttons onClick={() => {handleClose();saveUrl();}}>
                          Save
                        </Buttons>
                      </DialogActions>
                    </Dialog>


                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Update
                </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
}