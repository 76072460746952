import * as AUTH from "./auth";
const axios = require("axios");

export async function getCentreList() {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-center?" +
      "&per_page=99999";
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
  }

  export async function getUserList() {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/user?" +
      "&per_page=99999";
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
  }

export async function getVoucherList(page, params) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-center-promotion?" +
      "page=" +
      page +
      "&per_page=10";
  
    if (params) {
      if (params.search) url += "&search=" + params.search;
      if (params.sortType) url += "&sort_type=" + params.sortType ;
      if (params.status) url += "&status=" + params.status;
  
      if (params.sortby) url += "&sort_by=" + params.sortby;
    }
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
  }

  export async function addVoucher(createData) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();
  
    data.append("title", createData.title);
    data.append("description", createData.desc);
    data.append("center_id", createData.centre);
    data.append("quantity", createData.quantity);
    data.append("start_datetime",createData.start_date);
    data.append("end_datetime",createData.end_date); 
    data.append("is_manual",createData.generatevoucher);
    
    if (createData.generatevoucher == 0) {
      data.append("format",createData.format);
      data.append("length",createData.voucherlength);  
    }
    else {
      if (createData.generatevoucher == 1) {
        data.append("is_unique",createData.unique);
      }
    }

    if (createData.unique == 0) {
      data.append("code",createData.samecode);
    }
    else {
      if (createData.unique == 1) {
        createData.uniquecode.forEach((codes,index)=>{
          data.append("codes[" + index + "][code]",codes.value);
        });
      }
    }

    if (createData.image) {
        data.append("image", createData.image);
    }
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/" 
          + process.env.REACT_APP_API_PREFIX + 
          "/ml-center-promotion",data,
          {
          timeout: 60000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
  }

  export async function getVoucherDetail(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/" 
            + process.env.REACT_APP_API_PREFIX + 
            "/ml-center-promotion/" + id, {
            timeout: 60000,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
  }

  export async function updateVoucher(updateData) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();

    data.append("title", updateData.title);
    data.append("description", updateData.desc);
    data.append("center_id", updateData.centre);

    if (updateData.image) {
        data.append("image", updateData.image);
    }
    else {
        if(updateData.image_filename) {
            data.append("image_filename",updateData.image_filename);
        } 
    }
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "/" +
            process.env.REACT_APP_API_PREFIX +
            "/ml-center-promotion/" +
            updateData.id +
            "?_method=put",
          data,
          {
          timeout: 60000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
  }