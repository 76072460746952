import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import * as TRANSFER from "../../services/transferCode";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    },
    fontStyle: "Normal"
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function CodeDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [transferDetails, setTransferDetails] = useState([]);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const dealer =
    props.location.state && props.location.state.dealer ? props.location.state.dealer : null;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";

  useEffect(() => {
    const id = props.match.params.id;
    TRANSFER.getHistoryDetails(id)
      .then(result => {
        if (result.data.length > 0) {
          setTransferDetails(result.data);
          setLoading(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Opps",
            text: "Page Not Found"
          }).then(() => {
            props.history.replace("/transferCoupon");
          });
        }
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        } else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found"
            }).then(() => {
              props.history.replace("/transferCoupon");
            });
          }
        }
      });
  }, []);

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/transferCoupon",
            state: { currentPage: page, search: search, dealer: dealer }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={7} md={7}>
          <Card>
            <CardHeader color="warning" style={{ width: "fit-content" }}>
              <h4 className={classes.cardTitleWhite}>Transfer History</h4>
            </CardHeader>
            <CardBody>
              <div style={{ fontSize: 18, paddingBottom: 20 }}>
                {transferDetails[0].voucher_code}
              </div>
              {transferDetails.map(history => (
                <div style={{ paddingBottom: 20 }} key={history.transfer_at}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {"Transfer at " +
                        history.transfer_at.substring(0, 10) +
                        " " +
                        history.transfer_at.substring(11, 19)}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        disabled
                        label="Transfer From"
                        name="transfer_from"
                        style={{ fontStyle: history.transfer_from ? "Normal" : "Italic" }}
                        value={history.transfer_from ? history.transfer_from_name : "Admin"}
                        inputProps={{
                          maxLength: 100,
                          minLength: 1
                        }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        disabled
                        label="Transfer To"
                        name="transfer_to"
                        value={history.transfer_to_name}
                        inputProps={{
                          maxLength: 100,
                          minLength: 1
                        }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              ))}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem></GridItem>
      </GridContainer>
    </div>
  );
}
