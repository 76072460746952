import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as COURSE from "../../services/course";
import * as COMMISSION from "../../services/commission";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Input from "@material-ui/core/Input";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

const inputRef = React.createRef();

export default function AddBundle(props) {
  const classes = useStyles();
  const [courseSelection, setCourseSelection] = useState([]);
  const [categorySelection, setCategorySelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCourseID, setSelectedCourseID] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [commissionErr, setCommissionErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [calculationTypeErr, setCalculationTypeErr] = useState([]);
  const [allComSelected, setAllComSelected] = useState(false);
  const [customCommission, setCustomCommission] = useState(false);
  const [commissionType, setCommissionType] = useState([]);
  const [calculationType, setCalculationType] = useState([]);
  const [selectedCom, setSelectedCom] = useState({ type: [], level: [] });
  const [allLevelSelected, setAllLevelSelected] = useState([]);
  const [allUserTypeSelected, setAllUserTypeSelected] = useState([]);
  const [levelError, setLevelError] = useState([]);
  const [typeError, setTypeError] = useState([]);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const bundle =
    props.location.state && props.location.state.bundle ? props.location.state.bundle : false;
  const [formData, setFormData] = useState({
    bundle: 1,
    name: "",
    category: "",
    description: "",
    selling_price: "",
    retail_price: "",
    sales_status: "",
    cover: "",
    course: [],
    cover_url: "",
    commission_amount: ""
  });
  const [commission, setCommission] = useState([]);

  useEffect(() => {
    let params = {};
    params.is_bundle = 0;
    params.per_page = 999;
    COURSE.getAvailableCourse(params)
      .then(res => {
        console.log(res)
        setCourseSelection(res.data);
      })
      .then(() => {
        COURSE.getCategory().then(result => {
          setCategorySelection(result.data);
          COMMISSION.getCommissionType().then(res => {
            setCommissionType(res.data);
            setCalculationType(res.calculation_type);
            setFormData({
              ...formData
            });
            setLoading(false);
          });
        });
      });
  }, []);

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateCommission = (event, id) => {
    let commList = commission;
    for (var i in commList) {
      if (commList[i].id == id) {
        if (event.target.name == "user_type") {
          commList[i].user_type = event.target.value;
          break;
        }
        if (event.target.name == "calculation_type") {
          commList[i].calculation_type = event.target.value;
          if (event.target.value == "1") {
            commList[i].amount = "";
          } else if (event.target.value == "2") {
            commList[i].percent = "";
          }
          break;
        }
        if (event.target.name == "percent") {
          commList[i].percent = event.target.value;
          break;
        }
        if (event.target.name == "amount") {
          commList[i].amount = event.target.value;
          break;
        }
      }
    }
    setCommission(commList);
    setSelectedCom({
      type: selectedCom.type,
      level: selectedCom.level,
      date: new Date()
    });
  };

  const { name, category, description, sales_status, cover_url } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setTypeError([]);
    setLevelError([]);
    setCommissionErr(false);
    let valid = true;

    if (hasError) setHasError(false);

    if (categoryErr) setCategoryErr(false);

    if (calculationTypeErr.length > 0) setCalculationTypeErr([]);

    if (selectedCourseID.length < 2) {
      setHasError(true);
      valid = false;
    }
    if (!category) {
      setCategoryErr(true);
      valid = false;
    }

    if (customCommission && selectedCom.type.length == 0) {
      setCommissionErr(true);
      valid = false;
    }

    let errList = [];
    let calErr = commission.filter(
      comm => comm.calculation_type.length == 0 && comm.user_type.length != 0
    );
    if (calErr.length > 0) {
      valid = false;
      for (let n = 0; n < calErr.length; n++) {
        errList.push(calErr[n].id);
      }
    }
    setCalculationTypeErr(errList);
    let lvErr = [];
    let typeErr = [];
    for (let i = 0; i < selectedCom.type.length; i++) {
      if (!commission.find(comm => comm.commission_type == selectedCom.type[i])) {
        valid = false;
        lvErr.push(selectedCom.type[i]);
      }
    }

    for (let m = 0; m < selectedCom.level.length; m++) {
      if (
        !commission.find(
          comm =>
            comm.commission_type == selectedCom.level[m].split(",")[0] &&
            comm.level == selectedCom.level[m].split(",")[1]
        ).user_type
      ) {
        valid = false;
        typeErr.push(selectedCom.level[m]);
      }
    }
    setLevelError(lvErr);
    setTypeError(typeErr);

    for (let i = 0; i < selectedCom.type.length; i++) {
      //check level
      if (selectedCom.level.filter(lv => lv[0] == selectedCom.type[i]).length < 1) {
      } else {
        //check user type
        for (
          let n = 0;
          n < selectedCom.level.filter(lv => lv[0] == selectedCom.type[i]).length;
          n++
        ) {
          if (
            commission.filter(
              commission =>
                commission.commission_type == selectedCom.type[i] &&
                commission.level == selectedCom.level[n].split(",")[1]
            ).user_type == ""
          ) {
            break;
          }
        }
      }
    }

    if (valid) {
      commission.forEach(function(comm) {
        delete comm.id;
      });
      let cerateData = {
        ...formData,
        custom_commission: customCommission ? 1 : 0,
        commission: commission
      };

      setLoading(true);
      COURSE.addCourseBundle(cerateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Course bundle has been created successful"
          }).then(() => {
            props.history.replace("/course");
          });
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          } else {
            let errMsg = [];
            if (err.errors) {
              if (err.errors.cover) errMsg.push(err.errors.cover);
            }

            if (errMsg.length > 0) {
              Swal.fire({
                icon: "error",
                title: "Fail to Add Course",
                text: errMsg
              });
            }
          }
          setLoading(false);
        });
    }
  }

  const handleChange = event => {
    let options = event.target.value;
    let selectedList = [];
    let ID = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i]) {
        ID.push(options[i].id);
        selectedList.push(options[i]);
        setSelectedCourse(selectedList);
        setSelectedCourseID(ID);
      }
    }
    setFormData({ ...formData, ["course"]: ID });
  };

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFormData({
        ...formData,
        ["cover"]: file,
        ["cover_url"]: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({ ...formData, ["cover_url"]: null });
  };

  const handleCustomCommission = e => {
    setCustomCommission(!customCommission);
  };

  const removeThisCommission = type => {
    setSelectedCom({
      type: selectedCom.type.filter(comm => comm != type),
      level: selectedCom.level.filter(lvl => lvl.split(",")[0] != type)
    });
    setCommission(commission.filter(comm => comm.commission_type != type));
    setAllComSelected(false);
    setAllLevelSelected(allLevelSelected.filter(allLv => allLv.type != type));
    setAllUserTypeSelected(allUserTypeSelected.filter(allLv => allLv.type != type));
  };

  const addLevel = type => {
    setLevelError([]);
    let thisCommission = commission.filter(comm => comm.commission_type == type);
    let thisMaxLevel = commissionType.find(comm => comm.id == type).max_level;
    const inputOptions = new Map();
    for (let i = 0; i < thisMaxLevel; i++) {
      if (thisCommission.length > 0) {
        if (!thisCommission.find(comm => comm.level == i + 1)) inputOptions.set(i + 1, i + 1);
      } //havent add level before
      else inputOptions.set(i + 1, i + 1);
    }

    Swal.fire({
      title: "Add Level to Commmission",
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a level",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        let lvArr = selectedCom.level;
        let typeArr = selectedCom.type;

        lvArr.push(type + "," + value.value);
        setSelectedCom({ type: [...typeArr], level: [...lvArr] });
        let count = 1;
        for (let i = 0; i < thisMaxLevel; i++) {
          if (thisCommission && thisCommission.find(comm => comm.level == i + 1)) {
            count++;
          }
        }
        if (count == thisMaxLevel)
          setAllLevelSelected([...allLevelSelected, { type: type, allLevel: true }]);

        let comm = {};
        comm = {
          id: type + value.value + "0",
          commission_type: type,
          level: value.value,
          user_type: "",
          calculation_type: "",
          percent: "",
          amount: ""
        };
        setCommission([...commission, comm]);
        setSelectedCom({
          type: selectedCom.type,
          level: selectedCom.level,
          date: new Date()
        });
      }
    });
  };

  const addCommission = () => {
    setAllComSelected(false);
    const inputOptions = new Map();
    for (let i = 0; i < commissionType.length; i++) {
      if (selectedCom.type.length > 0) {
        if (!selectedCom.type.find(comm => comm == commissionType[i].id))
          inputOptions.set(commissionType[i].id, commissionType[i].name);
      } else inputOptions.set(commissionType[i].id, commissionType[i].name);
    }
    Swal.fire({
      title: "Add custom commission",
      text: "Select a commission type",
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a type",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        let lvArr = selectedCom.level;
        let typeArr = selectedCom.type;
        typeArr.push(value.value);
        setSelectedCom({ type: [...typeArr], level: [...lvArr] });

        let count = 0;
        for (let i = 0; i < commissionType.length; i++) {
          if (selectedCom.type && selectedCom.type.find(comm => comm == commissionType[i].id)) {
            count++;
          }
        }
        if (count == commissionType.length) setAllComSelected(true);
      }
    });
  };

  const addUserType = (type, lv) => {
    setTypeError([]);
    let thisCommission = commission.filter(
      comm => comm.commission_type == type && comm.level == lv
    );
    //userType ArrObj of the selected commission
    let thisUserType = commissionType.find(comm => comm.id == type).user_type;
    const inputOptions = new Map();
    for (let i = 0; i < thisUserType.length; i++) {
      if (!thisCommission.find(comm => comm.user_type == thisUserType[i].id))
        inputOptions.set(thisUserType[i].id, thisUserType[i].name);
    }
    Swal.fire({
      title: "Add User Type to Level " + lv,
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a type",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        let comm = commission;
        let ID = commission.find(comm => comm.commission_type == type && comm.level == lv).id;
        if (thisCommission.length == 1 && thisCommission[0].user_type.length == 0) {
          updateCommission({ target: { name: "user_type", value: value.value } }, ID);
          if (thisUserType.length == 1)
            setAllUserTypeSelected([
              ...allUserTypeSelected,
              { type: type, level: lv, userType: true }
            ]);
        } else {
          comm = {
            id: type + lv + value.value,
            commission_type: type,
            level: lv,
            user_type: value.value,
            calculation_type: "",
            percent: "",
            amount: ""
          };
          setCommission([...commission, comm]);
          let count = 1;
          for (let i = 0; i < thisUserType.length; i++) {
            if (
              thisCommission &&
              thisCommission.find(comm => comm.user_type == thisUserType[i].id)
            ) {
              count++;
            }
          }
          if (count == thisUserType.length)
            setAllUserTypeSelected([
              ...allUserTypeSelected,
              { type: type, level: lv, userType: true }
            ]);
        }
      }
    });
  };

  const removeThisLv = lv => {
    setLevelError([]);
    let tempCom = {
      type: selectedCom.type,
      level: selectedCom.level.filter(level => level != lv)
    };
    let removeID = commission.find(
      comm => comm.commission_type == lv.split(",")[0] && comm.level == lv.split(",")[1]
    ).id;
    setCommission(commission.filter(comm => comm.id != removeID));
    setSelectedCom(tempCom);
    setAllUserTypeSelected(allUserTypeSelected.filter(comm => comm.level != lv.split(",")[1]));
    setAllLevelSelected(allLevelSelected.filter(allLv => allLv.type != lv.split(",")[0]));
  };

  const compare = ( a, b )=> {    // sort user type
    if ( a.user_type < b.user_type ){
      return -1;
    }
    if ( a.user_type > b.user_type ){
      return 1;
    }
    return 0;
  }

  const customizationSection = () => {
    return (
      <div>
        {selectedCom.type.map(comm => (
          <Box
            border={0.6}
            key={comm}
            style={{
              borderColor: "#61696F",
              marginTop: 16,
              padding: 4
            }}>
            <GridContainer>
              <GridItem xs={9} sm={10} md={10} style={{ fontSize: 16, display: "inline-flex" }}>
                <div style={{ marginTop: 5 }}>
                  {commissionType.find(com => com.id == comm)
                    ? commissionType.find(com => com.id == comm).name
                    : ""}
                </div>
                <Button
                  style={{
                    backgroundColor: themeColor,
                    marginTop: 2,
                    padding: 7,
                    marginLeft: 10,
                    textTransform: "none"
                  }}
                  disabled={
                    allLevelSelected.find(levelCom => levelCom.type == comm)
                      ? allLevelSelected.find(levelCom => levelCom.type == comm).allLevel
                      : false
                  }
                  onClick={() => addLevel(comm)}>
                  Add Level
                </Button>
                {levelError.find(errType => errType == comm) ? (
                  <div
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 5,
                      marginLeft: 2
                    }}>
                    * must have at least one level
                  </div>
                ) : null}
              </GridItem>
              <GridItem xs={3} sm={2} md={2} style={{ textAlign: "right" }}>
                <Button
                  style={{
                    backgroundColor: themeColor,
                    marginTop: 2,
                    padding: 7,
                    marginLeft: 10
                  }}
                  onClick={() => removeThisCommission(comm)}>
                  <Icon style={{ fontSize: 17 }}>close</Icon>
                </Button>
              </GridItem>
            </GridContainer>
            {selectedCom.level.length > 0
              ? selectedCom.level
                  .filter(lv => lv.split(",")[0] == comm)
                  .map(level => (
                    <div key={level}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ fontSize: 16 }}>
                          <div style={{ display: "inline-flex" }}>
                            <div style={{ marginTop: 10 }}>Level {level.split(",")[1]}</div>
                            <Button
                              style={{
                                backgroundColor: themeColor,
                                padding: 7,
                                textTransform: "none",
                                marginLeft: 15
                              }}
                              onClick={() => addUserType(comm, level.split(",")[1])}
                              disabled={
                                allUserTypeSelected.find(
                                  userTypeCom =>
                                    userTypeCom.type == comm &&
                                    userTypeCom.level == level.split(",")[1]
                                )
                                  ? allUserTypeSelected.find(
                                      userTypeCom =>
                                        userTypeCom.type == comm &&
                                        userTypeCom.level == level.split(",")[1]
                                    ).userType
                                  : false
                              }>
                              Add User Type
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: themeColor,
                                padding: 7,
                                textTransform: "none"
                              }}
                              className={classes.submit}
                              onClick={() => removeThisLv(comm + "," + level.split(",")[1])}>
                              Remove
                            </Button>
                            {typeError.find(
                              errLv =>
                                errLv.split(",")[0] == comm &&
                                errLv.split(",")[1] == level.split(",")[1]
                            ) ? (
                              <div
                                style={{
                                  fontSize: 13,
                                  color: "red",
                                  marginTop: 8,
                                  marginLeft: 2
                                }}>
                                * must have at least one user type
                              </div>
                            ) : null}
                          </div>
                        </GridItem>
                      </GridContainer>
                      {commission.filter(
                        commission =>
                          commission.commission_type == comm &&
                          commission.level == level.split(",")[1]
                      )
                        ? commission.sort(compare)
                            .filter(
                              commission =>
                                commission.commission_type == comm &&
                                commission.level == level.split(",")[1]
                            )
                            .map((commissionInfo, i) => (
                              <div key={i}>
                                <GridContainer>
                                  <GridItem
                                    xs={5}
                                    sm={5}
                                    md={5}
                                    style={{
                                      fontSize: 16,
                                      marginTop: 10
                                    }}>
                                    {commissionType
                                      .find(com => com.id == comm)
                                      .user_type.find(com => com.id == commissionInfo.user_type)
                                      ? commissionType
                                          .find(com => com.id == comm)
                                          .user_type.find(com => com.id == commissionInfo.user_type)
                                          .name
                                      : ""}
                                  </GridItem>
                                </GridContainer>
                                {commissionInfo.user_type ? (
                                  <GridContainer>
                                    <GridItem xs={5} sm={5} md={5}>
                                      <FormControl
                                        required
                                        fullWidth={true}
                                        style={{
                                          marginLeft: 0,
                                          marginTop: 3
                                        }}
                                        error={
                                          calculationTypeErr.find(id => id == commissionInfo.id)
                                            ? true
                                            : false
                                        }
                                        className={classes.formControl}>
                                        <InputLabel
                                          className={classes.inputLabel}
                                          classes={{
                                            focused: classes.inputFocused
                                          }}>
                                          Calculation Type
                                        </InputLabel>
                                        <Select
                                          className={classes.select}
                                          id={commissionInfo.id}
                                          name="calculation_type"
                                          variant="outlined"
                                          value={commissionInfo.calculation_type}
                                          onChange={e => updateCommission(e, commissionInfo.id)}
                                          input={<Input id={commissionInfo.id} />}
                                          inputlabelprops={{
                                            classes: {
                                              root: classes.cssLabel,
                                              focused: classes.cssFocused
                                            }
                                          }}>
                                          {Object.entries(calculationType).map(type => (
                                            <MenuItem key={type[0]} value={type[0]}>
                                              {type[1]}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {calculationTypeErr &&
                                          calculationTypeErr.find(
                                            id => id == commissionInfo.id
                                          ) && <FormHelperText>This is required</FormHelperText>}
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={3}>
                                      <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        style={{ marginTop: 3 }}
                                        disabled={commissionInfo.calculation_type == 2}
                                        label={"Percent(%)"}
                                        name="percent"
                                        type="number"
                                        onChange={e => updateCommission(e, commissionInfo.id)}
                                        inputProps={{
                                          maxLength: 100,
                                          minLength: 1,
                                          min: 0.01,
                                          max: 100,
                                          step: ".01"
                                        }}
                                        InputProps={{
                                          classes: {
                                            underline: classes.underline,
                                            disabled: classes.disabled
                                          }
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.cssLabel,
                                            focused: classes.cssFocused
                                          }
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={4}>
                                      <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        disabled={commissionInfo.calculation_type == 1}
                                        label={"Amount(RM)"}
                                        name="amount"
                                        type="number"
                                        style={{ marginTop: 3 }}
                                        onChange={e => updateCommission(e, commissionInfo.id)}
                                        inputProps={{
                                          maxLength: 100,
                                          minLength: 1,
                                          min: 0.01,
                                          max: 10000,
                                          step: ".01"
                                        }}
                                        InputProps={{
                                          classes: {
                                            underline: classes.underline,
                                            disabled: classes.disabled
                                          }
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.cssLabel,
                                            focused: classes.cssFocused
                                          }
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                ) : null}
                              </div>
                            ))
                        : null}
                    </div>
                  ))
              : null}
          </Box>
        ))}
      </div>
    );
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/course",
            state: { currentPage: page, search: search, bundle: bundle }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <form
              className={classes.form}
              onSubmit={event => handleSubmit(event)}
              method="post"
              encType="multipart/form-data">
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Add Course Bundle</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    Cover
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "7.5rem",
                            height: "7.5rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted"
                          }}>
                          {cover_url ? (
                            <img
                              onClick={() => {
                                inputRef.current.click();
                              }}
                              src={cover_url}
                              ali=""
                              style={{
                                width: "7.5rem",
                                height: "7.5rem",
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <div>
                              <Icon
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                                style={{
                                  marginTop: "32%",
                                  fontSize: 45,
                                  cursor: "pointer"
                                }}>
                                add
                              </Icon>
                            </div>
                          )}
                        </Box>
                      </div>
                      <div style={{ paddingLeft: 20 }}>
                        {cover_url ? (
                          <Button
                            onClick={removeImg}
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              marginLeft: 10,
                              marginTop: "65%"
                            }}
                            variant="contained">
                            Remove
                          </Button>
                        ) : null}
                      </div>
                      <input
                        className="fileInput"
                        type="file"
                        hidden
                        ref={inputRef}
                        accept="image/png,image/jpeg,image/jpg"
                        style={{ marginLeft: 10 }}
                        onChange={e => handleImageChange(e)}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} style={{ display: "block" }}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      autoFocus
                      fullWidth
                      label="Name"
                      name="name"
                      value={name}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={categoryErr}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Category
                      </InputLabel>
                      <Select
                        className={classes.select}
                        id="category"
                        name="category"
                        variant="outlined"
                        value={category}
                        onChange={e => updateFormData(e)}
                        input={<Input id="category" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {categorySelection.map(category => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {categoryErr && <FormHelperText>This is required</FormHelperText>}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ paddingTop: 6 }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={hasError}
                      className={classes.formControl}>
                      <InputLabel
                        className={classes.inputLabel}
                        classes={{ focused: classes.inputFocused }}>
                        Course
                      </InputLabel>
                      <Select
                        multiple={true}
                        className={classes.select}
                        id="course"
                        name="course"
                        variant="outlined"
                        value={selectedCourse}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        input={<Input id="coruse" />}
                        inputlabelprops={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                        renderValue={selected =>
                          selected.length > 0
                            ? selected.map((course, i) =>
                                selected[i + 1] ? course.name + ", " : course.name
                              )
                            : ""
                        }>
                        {courseSelection.map(course => (
                          <MenuItem key={course.id} value={course}>
                            <Checkbox
                              style={{
                                color: themeColor
                              }}
                              checked={
                                selectedCourse &&
                                selectedCourse.find(selected => selected.id == course.id)
                                  ? true
                                  : false
                              }
                            />
                            <ListItemText primary={course.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {hasError && (
                        <FormHelperText>Please select at least two course.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={4} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label={"Retail Price (RM)"}
                      name="retail_price"
                      type="number"
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                        min: 0,
                        max: 10000,
                        step: ".01"
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label={"Selling Price (RM)"}
                      name="selling_price"
                      type="number"
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                        min: 0,
                        max: 10000,
                        step: ".01"
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label={"Commission Amount (RM)"}
                      name="commission_amount"
                      type="number"
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                        min: 0,
                        max: 10000,
                        step: ".01"
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      rows="4"
                      label="Description"
                      name="description"
                      value={description}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 999,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={4} md={3}>
                    <TextField
                      required
                      fullWidth
                      style={{ paddding: 0, marginTop: 10 }}
                      margin="normal"
                      name="sales_status"
                      select
                      label="Sales Status"
                      value={sales_status}
                      onChange={e => updateFormData(e)}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled,
                          input: sales_status == 1 ? classes.activeColor : classes.inactiveColor
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      <MenuItem key={1} value={1} style={{ color: "#4caf50" }}>
                        Active
                      </MenuItem>
                      <MenuItem key={2} value={2} style={{ color: "#f44336" }}>
                        Inactive
                      </MenuItem>
                    </TextField>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={4}
                    md={4}
                    style={{
                      fontSize: 15,
                      fontWeight: "300",
                      marginTop: 30,
                      display: "inline-block"
                    }}>
                    <Checkbox
                      name="commission"
                      style={{
                        color: themeColor,
                        padding: 0,
                        paddingRight: 6
                      }}
                      value={customCommission}
                      onChange={e => handleCustomCommission(e)}
                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                    />
                    Custom Commission
                  </GridItem>
                  {customCommission ? (
                    <GridItem xs={6} sm={4} md={4}>
                      <Button
                        style={{
                          backgroundColor: themeColor,
                          padding: 10,
                          marginTop: 20,
                          marginRight: 30
                        }}
                        onClick={() => addCommission()}>
                        Add Custom Commission
                      </Button>
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer style={{ textAlign: "right", paddingRight: 30 }}>
                  <GridItem xs={6} sm={11} md={11}>
                    {commissionErr ? (
                      <div style={{ fontSize: 13, color: "red", marginTop: 5 }}>
                        * Please set up custom commission
                      </div>
                    ) : null}
                  </GridItem>
                </GridContainer>
                {/*custom commission */}
                {customCommission && selectedCom.type.length > 0 ? customizationSection() : null}
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
