import * as AUTH from "./auth";

const axios = require("axios");

export async function getReport(type, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/view-report?type=" +
    type;
  if (params) {
    if (params.from) url += "&from=" + params.from;
    if (params.to) url += "&to=" + params.to;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getReportSelection() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/report-selection",
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}
