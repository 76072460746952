import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as ORDER from "../../services/order";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function OrderDetails(props) {
  const classes = useStyles();
  const [statusSelection, setStatusSelection] = useState([]);
  const [paymentSelection, setPaymentSelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(true);
  const sales =
    props.location.state && props.location.state.sales ? props.location.state.sales : "";
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onUser =
    props.location.state && props.location.state.user ? props.location.state.user : null;
  const onPayment =
    props.location.state && props.location.state.payment ? props.location.state.payment : null;
  const onStatus =
    props.location.state && props.location.state.status ? props.location.state.status : null;
  const from =
    props.location.state && props.location.state.fromDate ? props.location.state.fromDate : null;
  const to =
    props.location.state && props.location.state.toDate ? props.location.state.toDate : null;

  const [formData, setFormData] = useState({
    id: "",
    payment_method: "",
    reference: "",
    status: ""
  });

  useEffect(() => {
    const id = props.match.params.id;
    ORDER.getOrder(id)
      .then(result => {
        setOrder(result.data);
        setStatusSelection(result.status_list);
        setPaymentSelection(result.payment_method_list);
        setFormData({
          ...formData,
          ["orderNum"]: result.data.order_number,
          ["reference"]: result.data.reference,
          ["payment_method"]: result.data.payment_method,
          ["status"]: result.data.status
        });
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        } else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found"
            }).then(() => {
              props.history.replace("/order");
            });
          }
        }
      });
  }, []);

  const updateFormData = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { reference, payment_method, status } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    let valid = true;

    if (valid) {
      let updateData = formData;
      updateData = {
        ...updateData,
        status: Object.entries(statusSelection).find(status => status[1] == formData.status)[0],
        payment_method: paymentSelection.find(pay => pay.name == formData.payment_method).id,
        orderType: sales
      };
      setLoading(true);
      ORDER.updateOrder(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Order update successful"
          });
        })
        .catch(err => {
          if (err.errors) {
            setLoading(false);
          }
          setLoading(false);
        });
    }
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/order",
            state: {
              currentPage: page,
              search: search,
              user: onUser,
              status: onStatus,
              sales: sales,
              payment: onPayment,
              fromDate: from,
              toDate: to
            }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <form className={classes.form} onSubmit={event => handleSubmit(event)}>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>
                  {sales == 0 ? "Edit Order" : "Order Details"}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer style={{ paddingBottom: 16 }}>
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ fontSize: 15 }}>
                      {"Purchased at " +
                        order.purchased_at.substring(0, 10) +
                        " " +
                        order.purchased_at.substring(11, 19)}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ fontSize: 15 }}>
                      {"Updated at " +
                        order.updated_at.substring(0, 10) +
                        " " +
                        order.updated_at.substring(11, 19)}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ paddingBottom: 16 }}>
                  {sales == 1 || order.voucher != null ? (
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ fontSize: 15 }}>{"Coupon Code : " + order.voucher}</div>
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ fontSize: 15 }}>{"Order Number : " + order.order_number}</div>
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ fontSize: 15 }}>{"Purchased by " + order.username}</div>
                  </GridItem>
                </GridContainer>
                {sales == 0 && order.order_number !== null ? (
                  <GridContainer style={{ paddingBottom: 16 }}>
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ fontSize: 15 }}>
                        {"Retail Amount : RM " + order.retail_amount}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ fontSize: 15 }}>
                        {"Final Amount : RM " + order.final_amount}
                      </div>
                    </GridItem>
                  </GridContainer>
                ) : null}
                <GridContainer style={{ paddingBottom: 16 }}>
                  <GridItem xs={12} sm={6} md={12}>
                    <div style={{ fontSize: 15 }}>
                      {"Course purchased : "}
                      {order.course.map((course, i, arr) => {
                        if (arr.length - 1 === i) {
                          return arr[i].name;
                        } else {
                          return arr[i].name + ", ";
                        }
                      })}
                    </div>
                  </GridItem>
                </GridContainer>
                {sales == 0 && order.order_number !== null ? (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Reference"
                        name="reference"
                        value={reference ? reference : ""}
                        onChange={e => updateFormData(e)}
                        inputProps={{
                          maxLength: 100,
                          minLength: 1
                        }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        name="payment_method"
                        label="Payment Method"
                        select={sales == 0 ? true : false}
                        value={sales == 0 ? payment_method : "-"}
                        onChange={e => updateFormData(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {paymentSelection.map(payment => (
                          <MenuItem
                            key={payment.id}
                            value={payment.name}
                            style={{
                              color:
                                status[0] == 1 ? "#4caf50" : status[0] == 2 ? "#f44336" : "black"
                            }}>
                            {payment.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                ) : null}
                {sales == 0 && order.order_number !== null ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        name="status"
                        select
                        label="Status"
                        value={status}
                        onChange={e => updateFormData(e)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}>
                        {Object.entries(statusSelection).map(status => (
                          <MenuItem
                            key={status[0]}
                            value={status[1]}
                          >
                            {status[1]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ fontSize: 15 }}>{"Status : " + order.status}</div>
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
              {sales == 0 && order.order_number !== null ? (
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ backgroundColor: themeColor }}>
                    Update
                  </Button>
                </CardFooter>
              ) : null}
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
