import * as AUTH from "./auth";
const axios = require("axios");

export async function getUserList(page, params) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/user?" +
      "page=" +
      page +
      "&per_page=10";
    if (params) {
      if (params.search) url += "&search=" + params.search;
    //  if (params.is_feature) url += "&is_feature=" + params.is_feature;
      if (params.status) url += "&status=" + params.status;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
  }

