import * as AUTH from "./auth";
const axios = require("axios");

export async function getUserList() {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
    .get(
      process.env.REACT_APP_API_URL + "/" 
      + process.env.REACT_APP_API_PREFIX + 
      "/user?per_page=99999",
      {
      timeout:60000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken
      }
    }
    )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getBusinessList(page, params) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/ml-namecard?" +
      "page=" +
      page +
      "&per_page=10";
  
    if (params) {
      if (params.search) url += "&search=" + params.search;
      if (params.sortType) url += "&sort_type=" + params.sortType ;
    //   if (params.status) url += "&status=" + params.status;
      if (params.sortby) url += "&sort_by=" + params.sortby;
    }
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout:60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject({
                http_code: error.response.status,
                data: error.response.data,
              });
            } else {
              reject(error.response.data);
            }
          } else {
            reject(error.message);
          }
      })
      .finally();
    });
  }

  export async function addBusinessCard(createData) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();
    data.append("user_id",createData.user);
    data.append("name", createData.name);
    data.append("position", createData.position);
    data.append("email", createData.email);
    data.append("contact_num", createData.mobile_number);
    data.append("company_name",createData.company_name);
    data.append("company_description",createData.company_description); 
    data.append("background_color_hex",createData.backgroundColor);
    if (createData.slug) {
      data.append("slug",createData.slug);
    }
    if(createData.company_address && createData.state_name && createData.city_name && createData.postcode){
      if(createData.company_address){
        data.append("company_address",createData.company_address);
      }
      if(createData.state_name){
        data.append("state_name",createData.state_name);
      }
      if(createData.city_name){
        data.append("city_name",createData.city_name);
      }
      if(createData.postcode){
        data.append("postcode",createData.postcode);
      }
      if(createData.latitude !== null){
        data.append("company_latitude",createData.latitude);
      }
      if(createData.longitude !== null){
        data.append("company_longitude",createData.longitude);
      }
    }

    if (createData.image) {
        data.append("profile_image", createData.image);
    }

    if (createData.company_logo) {
        data.append("company_logo",createData.company_logo);
    }

    createData.images.forEach((image, index)=>{
        data.append("images["+ index + "][file]", image.imagefile);
        data.append("images[" + index + "][is_banner]", createData.is_banner == image.id ? 1 : 0);
    });

    createData.videos.forEach((video,index)=> {
        data.append("video",video.videofile);
    });
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/" 
          + process.env.REACT_APP_API_PREFIX + 
          "/ml-namecard",data,
          {
          timeout: 60000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
            if (error.response) {
              if (error.response.data) {
                reject({
                  http_code: error.response.status,
                  data: error.response.data,
                });
              } else {
                reject(error.response.data);
              }
            } else {
              reject(error.message);
            }
        })
        .finally();
    });
  }

  export async function getBusinessCardDetail(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/api" +
            // + process.env.REACT_APP_API_PREFIX + 
            "/ml-namecard/" + id, {
            timeout:60000,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject({
                http_code: error.response.status,
                data: error.response.data,
              });
            } else {
              reject(error.response.data);
            }
          } else {
            reject(error.message);
          }
        })
        .finally();
    });
  }

  export async function updateBusinessCard(updateData,id) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();

    data.append("name", updateData.name);
    data.append("position", updateData.position);
    data.append("email", updateData.email);
    data.append("contact_num", updateData.mobile_number);
    data.append("company_name",updateData.company_name);
    data.append("company_description",updateData.company_description); 
    data.append("background_color_hex",updateData.backgroundColor);
    if (updateData.slug) {
      data.append("slug",updateData.slug);
    }
    if(updateData.company_address || updateData.state_name || updateData.city_name || updateData.postcode){
      if(updateData.company_address){
        data.append("company_address",updateData.company_address);
      }
      if(updateData.state_name){
        data.append("state_name",updateData.state_name);
      }
      if(updateData.city_name){
        data.append("city_name",updateData.city_name);
      }
      if(updateData.postcode){
        data.append("postcode",updateData.postcode);
      }
      if(updateData.latitude !== null){
        data.append("company_latitude",updateData.latitude);
      }
      if(updateData.longitude !== null){
        data.append("company_longitude",updateData.longitude);
      }
    }

    if (updateData.image) {
      data.append("profile_image", updateData.image);
    }
    else {
        if(updateData.image_filename) {
            data.append("profile_image_filename",updateData.image_filename);
        } 
    }

    if (updateData.company_logo) {
      data.append("company_logo",updateData.company_logo);
    }
    else {
        if(updateData.company_logo_filename) {
            data.append("company_logo_filename",updateData.company_logo_filename);
        } 
    }

    updateData.images.forEach((image, index)=>{
      if (image.imagefile) {
        data.append("images["+ index + "][file]", image.imagefile);
        data.append("images[" + index + "][is_banner]", updateData.is_banner == image.id ? 1 : 0);
      }
      else if (image.imagefilename) {
        data.append("images["+ index + "][filename]", image.imagefilename);
        data.append("images[" + index + "][is_banner]", updateData.is_banner == image.id ? 1 : 0); 
      }
    });
    
    updateData.videos.forEach((video,index)=> {
        if (video.videofile) {
          data.append("video",video.videofile);
        }
        else if (video.videofilename) {
          data.append("video_filename",video.videofilename)
        }
      });
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "/" +
            process.env.REACT_APP_API_PREFIX +
            "/ml-namecard/" +
            id +
            "?_method=put",
          data,
          {
          timeout: 60000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
            if (error.response) {
              if (error.response.data) {
                reject({
                  http_code: error.response.status,
                  data: error.response.data,
                });
              } else {
                reject(error.response.data);
              }
            } else {
              reject(error.message);
            }
        })
        .finally();
    });
  }

  export async function deleteBusinessCard(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
        .delete(process.env.REACT_APP_API_URL + "/"
          + process.env.REACT_APP_API_PREFIX
          + "/ml-namecard/" + id, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
  }