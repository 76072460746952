import * as AUTH from "./auth";

const axios = require("axios");

export async function getVoucherCode(page, params) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/voucher-code?" +
      "page=" +
      page +
      "&per_page=10";
    if (params) {
      if (params.code) url += "&code=" + params.code;
      if (params.can_transfer) url += "&can_transfer";
      if (params.user_id) url += "&user_id=" + params.user_id;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getVoucher(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/voucher/" + id,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function transferCode(data) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/voucher/transfer-code",
        {
          code: data.code,
          dealer: data.dealer
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getTransferList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/voucher-transfer-list?" +
      "page=" +
      page +
      "&per_page=10";
    if (params) {
      if (params.transfer_to) url += "&transfer_to=" + params.transfer_to;
      if (params.transfer_from) url += "&transfer_from=" + params.transfer_from;
      if (params.from_time) url += "&from_time=" + params.from_time;
      if (params.to_time) url += "&to_time=" + params.to_time;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getHistoryDetails(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/voucher-transfer-history/" +
          id,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}
