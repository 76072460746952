import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as BUSINESS from "../../services/businessCard";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { Router, Route, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import InputColor from 'react-input-color';
import Geocode from "react-geocode";
import { Controller, useForm } from "react-hook-form";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  select: {
    marginTop: theme.spacing(2),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  dividerColor: {
    backgroundColor: themeColor
  },
  descColor: {
    color: "#1E90FF",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  }
}));

const useStyles = styles;

const inputRef = React.createRef();
const inputRefc = React.createRef();
const inputRefi = React.createRef();
const inputRefv = React.createRef();
let images = "";
let aspectRatio = 1;
let key = "";
let myaddress = "";
let val = "";
let lat = null;
let long = null;

export default function AddBusinessCard(props) {
  const classes = useStyles();
  const { register, handleSubmit, control, formState: { errors }, setError } = useForm();
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [tempSrc, setTempSrc] = useState("");
  const [show, setShow] = React.useState(false);
  const [drag, setDrag] = React.useState(false);
  const [completedProfile, setCompletedProfile] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropImageUrl, setCropImageUrl] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [imageRatio, setImageRatio] = useState("");
  const [imageObject, setImageObject] = useState([]);
  const [videoObject, setVideoObject] = useState([]);
  const [itemid, setItemid] = React.useState(1);
  const [videoid, setVideoid] = React.useState(1);
  const [value, setValue] = React.useState('1');
  const [initial, setInitial] = useState("#000000");
  const [color, setColor] = useState({});
  const [micardDetail, setMiCardDetail] = useState([]);

  const [selectedUserName, setSelectedUserName] = useState("");
  const [userSelection, setUserSelection] = useState([]);

  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onSortby =
    props.location.state && props.location.state.sortby ? props.location.state.sortby : null;
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;
  const [crop, setCrop] = useState({
    unit: 'px',
    aspect: aspectRatio
  });

  const id = props.match.params.id;

  useEffect(() => {
    let img = images;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    if (imageRatio === "profileimage") {
      setCrop({
        unit: "px",
        x: 0,
        y: 0,
        width: 200 / scaleX,
        height: 100 / scaleY
      })
    }
    else if (imageRatio === "imageobject") {
      setCrop({
        unit: "px",
        x: 0,
        y: 0,
        width: 200 / scaleX,
        height: 100 / scaleY
      })
    }

    setLoading(false);

  }, [images]);

  useEffect(() => {
    setLoading(true);

    BUSINESS.getUserList()
      .then(res => {
        setUserSelection(res.data);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err.data === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
          })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);
            });
        }
      });

  },
    []);

  useEffect(() => {
    if (userSelection.length > 0) {
      if (id) {
        getNameCardDetail(id)
      } else {
        setLoading(false)
      }
    }
  }, [userSelection])

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const getNameCardDetail = () => {
    BUSINESS.getBusinessCardDetail(id)
      .then(res => {

        val = userSelection.find(user => {
          return user.id === res['data']['creator_u_id']
        });

        setInitial((res['data']['background_color_hex']));
        setSelectedUserName(val.first_name + " " + val.last_name);
        setProfileUrl(res['data']['profile_image']);
        setCompanyLogoUrl(res['data']['company_logo']);

        let tempid = itemid;
        let a = [];
        for (let i = 0; i < res['data']['images'].length; i++) {
          a.push({
            id: tempid,
            imagefilename: res['data']['images'][i]['filename'],
            url: res['data']['images'][i]['url'],
            value: res['data']['images'][i]['is_banner']
          })
          if (res.data.images[i].is_banner == 1) setValue((tempid).toString());
          tempid = tempid + 1;
        }
        setItemid(tempid);
        setImageObject(a);

        let tempvid = videoid;
        let b = [];
        if (res['data']['video_filename'] != null) {
          for (let j = 0; j < 1; j++) {
            b.push({
              id: tempvid,
              videofilename: res['data']['video_filename'],
              url: res['data']['video_url']
            })
            tempvid = tempvid + 1;
          }
          setVideoid(tempvid);
          setVideoObject(b);
        }
        setMiCardDetail(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
        if (err.data === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
          })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);
            });
        }
        else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found"
            }).then(() => {
              props.history.replace("/miCard");
            });
          }
        }
      });
  }

  const onSubmit = (data) => {
    let formData = {};
    myaddress = data.company_address.replace(/,\s*$/, "") + "," + data.postcode.replace(/,\s*$/, "") + "," + data.city_name.replace(/,\s*$/, "") + "," + data.state_name.replace(/,\s*$/, "");
    if (data.company_address !== "" && data.postcode !== "" && data.city_name !== "" && data.state_name != "") {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.fromAddress(myaddress)
        .then(response => {
          lat = response.results[0].geometry.location.lat;
          long = response.results[0].geometry.location.lng;

          formData = {
            ...data,
            user_id: id ? micardDetail.creator_u_id : undefined,
            image: completedProfile,
            image_filename: id ? micardDetail.profile_image_filename : undefined,
            company_logo: companyLogo,
            company_logo_filename: id ? micardDetail.company_logo_filename : undefined,
            images: imageObject,
            videos: videoObject,
            is_banner: value,
            backgroundColor: color.hex,
            latitude: lat,
            longitude: long
          }
          if (id) {
            updateNameCard(formData)
          } else {
            createNameCard(formData)
          }
        });
    } else {
      formData = {
        ...data,
        user_id: id ? micardDetail.creator_u_id : undefined,
        image: completedProfile,
        image_filename: id ? micardDetail.profile_image_filename : undefined,
        company_logo: companyLogo,
        company_logo_filename: id ? micardDetail.company_logo_filename : undefined,
        images: imageObject,
        videos: videoObject,
        is_banner: value,
        backgroundColor: color.hex,
        latitude: null,
        longitude: null
      }
      if (id) {
        updateNameCard(formData)
      } else {
        createNameCard(formData)
      }
    }
  }

  const createNameCard = (createData) => {
    setApiLoading(true);

    BUSINESS.addBusinessCard(createData, id)
      .then(res => {
        setApiLoading(false);
        Swal.fire({
          icon: "success",
          title: "Business Card created successfully"
        })
          .then(() => {
            props.history.replace("/miCard");
          });
      })
      .catch(err => {
        showErrorMsg(err);
      });
  }

  const updateNameCard = (createData) => {
    setApiLoading(true);
    BUSINESS.updateBusinessCard(createData, id)
      .then(res => {
        setApiLoading(false);
        Swal.fire({
          icon: "success",
          title: "Business Card update successfully"
        })
      })
      .catch(err => {
        showErrorMsg(err);
      });
  }

  const showErrorMsg = (err) => {
    setApiLoading(false);
    console.log(err)
    if (err == "token expired") {
      props.history.replace("/login");
    } else {
      if (err.data === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Session expired. Please login again"
        })
          .then(() => {
            localStorage.removeItem('BZtokenData');
            setLoggedIn(false);
          });
      }

      let errMsg = [];
      if (err.http_code == 422 && err.data.errors) {
        addServerErrors(err.data.errors);
      }
      else {
        if (err == "timeout of 60000ms exceeded") {
          errMsg.push("Request timeout. You may experience a slow network.")
        }
        else if (err.http_code == 500) {
          errMsg.push(err.data.message)
        }
        Swal.fire({
          icon: "error",
          title: id ? "Fail to update business card" : "Fail to add business card",
          text: errMsg
        });
      }
    }
    setApiLoading(false);
    setLoading(false);
  }

  const addServerErrors = (errors) => {
    return Object.keys(errors).forEach((key) => {
      setError(key, {
        type: "server",
        message: errors[key].join(". "),
      });
    });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => { setShow(false); setTempSrc("") };

  const handleImageChange = (e, needCrop, imageType) => {
    e.preventDefault();

    if (e.target.files.length >= 1) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > 5000000) {
        if (imageType === "company_logo") {
          imageErrorAlert(true);
        } else {
          imageErrorAlert();
        }
      }
      else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
        if (imageType === "company_logo") {
          imageFormatErrorAlert(true);
        } else {
          imageFormatErrorAlert();
        }
      }
      else {
        if (needCrop) {
          handleShow();
        }
      }

      reader.onloadend = () => {
        if (needCrop) {
          if (imageType === "profile_image") {
            key = "profileimage";
            setDrag(true);
          }
          else if (imageType === "imageObject") {
            key = "imageobject";
            setDrag(true);
          }
          setImageRatio(key);
          setTempSrc(reader.result);
        }
        else if (imageType === "company_logo") {
          setCompanyLogo(file)
          setCompanyLogoUrl(reader.result)
          inputRefc.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const imageErrorAlert = (companyLogo = false) => {
    Swal.fire({
      icon: "error",
      title: "Oops",
      text: "Only accept image with size less than 5MB."
    })
      .then(() => {
        if (companyLogo) {
          setCompanyLogoUrl(null);
        } else {
          setTempSrc("");
        }
      });
  };

  const imageFormatErrorAlert = (companyLogo = false) => {
    Swal.fire({
      icon: "error",
      title: "Oops",
      text: "Only accept image with .jpeg, .jpg and .png format."
    })
      .then(() => {
        if (companyLogo) {
          setCompanyLogoUrl(null);
        } else {
          setTempSrc("");
        }
      });
  };

  const onImageLoaded = image => {
    images = image
  }

  const onCropComplete = crop => {
    if (images && crop.width && crop.height) {
      getCroppedImg(images, crop);
    }
  }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    let minWidth = "";
    let minHeight = "";
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;

    if (imageRatio === "profileimage") {
      minWidth = image.width;
      minHeight = image.height;
    }
    else if (imageRatio === "imageobject") {
      minWidth = image.width;
      minHeight = image.height;
    }

    canvas.width = minWidth;
    canvas.height = minHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      minWidth,
      minHeight
    )

    const reader = new FileReader();
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        dataURLtoFile(reader.result, 'cropped_image.jpg')
        setCropImageUrl(reader.result);
      }
    }, 'image/jpg', 0.5)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCompletedCrop(croppedImage);

    if (key === "profileimage") {
      setCompletedProfile(croppedImage)
    }
  }

  const saveUrl = () => {
    if (key === "profileimage") {
      setProfileUrl(cropImageUrl);
      inputRef.current.value = "";
    }
    else if (key === "imageobject") {
      setImageObject([...imageObject, {
        id: itemid,
        imagefile: completedCrop,
        url: cropImageUrl
      }]);
      setItemid(itemid + 1);
    }
  }

  const removeImg = (key, id = null) => {
    switch (key) {
      case "profileimage":
        inputRef.current.value = "";
        setProfileUrl(null);
        setCompletedProfile(null);
        break;
      case "companylogo":
        inputRefc.current.value = "";
        setCompanyLogoUrl(null);
        break;
      case "imageobject":
        setImageObject(
          imageObject.filter(image => {
            return image.id !== id;
          })
        );
        setTempSrc("");
        break;
    }
  }

  const handleVideoChange = e => {
    e.preventDefault();
    let videofile = null;
    let url = "";

    if (e.target.files.length >= 1) {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file.size > (Math.pow(2, 20)) * 50) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Only accept video with size less than or equal to 50MB."
        })
          .then(() => {
            removeVid(videoid);
          });
      }

      else if (file.type != "video/mp4") {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Only accept video with .mp4 format."
        })
          .then(() => {
            removeVid(videoid);
          });
      }

      else if (file.type == "video/mp4") {
        reader.onload = function () {
          var media = new Audio(reader.result);
          media.onloadedmetadata = function () {

            if (media.duration > 76) {
              removeVid();
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Maximum video duration is 1 minute 15 seconds."
              })
            }
          };
        };
      }
      videofile = file;

      reader.onloadend = () => {
        url = reader.result;
        setVideoObject([...videoObject, {
          id: videoid,
          videofile: videofile,
          url: url,
        }]);
      };
      reader.readAsDataURL(file);
      setVideoid(videoid + 1)
    }
  };

  const removeVid = (id) => {
    setVideoObject(
      videoObject.filter(video => {
        return video.id !== id;
      })
    );
  };

  const handleCheckbox = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/miCard",
            state: {
              currentPage: page,
              search: search,
              sortby: onSortby,
              sortType: onSortType
              // status: onStatus
            }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
              method="post"
              encType="multipart/form-data">
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>{id ? 'Edit MiCard' : 'Add MiCard'}</h4>
              </CardHeader>
              <CardBody>

                <div style={{ paddingTop: 10, textAlign: "left", paddingBottom: 10 }}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Personal Information
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    Profile Image (only .jpg, .jpeg, .png) *
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "7.5rem",
                            height: "7.5rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted",
                            overflow: "hidden",
                          }}>
                          {profileUrl ? (
                            <img
                              src={profileUrl}
                              ali=""
                              style={{
                                width: "7.5rem",
                                height: "7.5rem",
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <div>
                              <Icon
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                                style={{
                                  marginTop: "32%",
                                  fontSize: 45,
                                  cursor: "pointer"
                                }}>
                                add
                              </Icon>
                            </div>
                          )}
                        </Box>
                      </div>

                      <div style={{ paddingLeft: 20 }}>
                        {profileUrl ? (
                          <Button
                            onClick={e => { removeImg("profileimage"); }}
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              marginLeft: 10,
                              marginTop: "65%"
                            }}
                            variant="contained">
                            Remove
                          </Button>
                        ) : null}
                      </div>
                      <input
                        className="fileInput"
                        type="file"
                        className={`form-control ${errors.profiles == null ? '' : 'is-invalid'}`}
                        hidden
                        name="profiles"
                        ref={(e) => { register(e, { required: profileUrl == null }); inputRef.current = e; }}
                        accept="image/png,image/jpeg,image/jpg"
                        style={{ marginLeft: 10 }}
                        onChange={e => handleImageChange(e, true, "profile_image")}
                      />
                    </div>
                    {errors.profiles && errors.profiles.type === "required" && (<div className={classes.inactiveColor} style={{ fontSize: "11px" }}>Profile image is required.</div>)}
                    <p style={{ fontSize: "10px", margin: "0" }}>We advice image should not more than 300KB</p>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="name"
                        defaultValue={micardDetail.name ? micardDetail.name : ""}
                        rules={{
                          required: { value: true, message: "Name is required" },
                          minLength: { value: 2, message: "Name must be at least 2 characters" },
                          maxLength: { value: 100, message: "Name must not over 100 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.name == null ? "" : "is-invalid"}`}
                            label="Name (max 100 characters)"
                            inputRef={ref}
                            error={!!errors.name}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.name == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.name.message}</div>}
                    </FormControl>
                  </GridItem>

                  <GridItem item xs={12} sm={5} md={5}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="mobile_number"
                        defaultValue={micardDetail.contact_num ? micardDetail.contact_num : ""}
                        rules={{
                          required: { value: true, message: "Contact number is required" },
                          minLength: { value: 6, message: "Contact number must be at least 6 digits" },
                          maxLength: { value: 18, message: "Contact number must be less than 18 digits" },
                          pattern: { value: /^[0-9]+(?:\.[0-9]{1,3})?$/, message: "Contact number is invalid (only number allowed)" }
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.mobile_number == null ? "" : "is-invalid"}`}
                            label="Contact Number (max 18 numbers)"
                            inputRef={ref}
                            error={!!errors.mobile_number}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.mobile_number == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.mobile_number.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="email"
                        defaultValue={micardDetail.email ? micardDetail.email : ""}
                        rules={{
                          required: { value: true, message: "Email is required" },
                          maxLength: { value: 255, message: "Email must be less than 255 characters" },
                          pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email is invalid" }
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.email == null ? "" : "is-invalid"}`}
                            label="Email (max 255 characters)"
                            inputRef={ref}
                            error={!!errors.email}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.email == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.email.message}</div>}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="position"
                        defaultValue={micardDetail.position ? micardDetail.position : ""}
                        rules={{
                          required: { value: true, message: "Position is required" },
                          minLength: { value: 2, message: "Position must be at least 2 characters" },
                          maxLength: { value: 100, message: "Position must be less than 100 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.position == null ? "" : "is-invalid"}`}
                            label="Position (max 100 characters)"
                            inputRef={ref}
                            error={!!errors.position}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.position == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.position.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={id ? { marginLeft: 0, marginTop: 10 } : { marginLeft: 0, marginTop: 15 }}
                      className={classes.formControl}
                    >
                      {id ? <>
                        <Controller
                          control={control}
                          name="user"
                          defaultValue={selectedUserName}
                          render={(
                            { onChange, value, ref },
                          ) => (
                            <TextField
                              className={`form-control ${errors.user == null ? "" : "is-invalid"}`}
                              label="User"
                              inputRef={ref}
                              error={!!errors.user}
                              value={value}
                              inputProps={{ readOnly: true }}
                              onChange={e => { onChange(e) }}
                            >
                            </TextField>
                          )}
                        >
                        </Controller>
                      </> :
                        <>
                          <Controller
                            control={control}
                            name="user"
                            defaultValue={""}
                            rules={{
                              required: { value: true, message: "This field is required" },
                            }}
                            render={(
                              { onChange, value, ref },
                            ) => (
                              <>
                                <InputLabel
                                  className={classes.inputLabel}
                                  classes={{ focused: classes.inputFocused }}>
                                  User
                            </InputLabel>
                                <Select
                                  className={`form-control ${errors.user == null ? "" : "is-invalid"}`}
                                  label="User"
                                  inputRef={ref}
                                  value={value}
                                  error={!!errors.user}
                                  onChange={e => { onChange(e) }}
                                >
                                  {userSelection.map(user => (
                                    <MenuItem key={user.id} value={user.id}>
                                      {user.first_name + " " + user.last_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            )}
                          >
                          </Controller>
                          {errors.user == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.user.message}</div>}</>}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="slug"
                        defaultValue={micardDetail.slug ? micardDetail.slug : ""}
                        rules={{
                          required: { value: true, message: "Name Card Slug is required" },
                          minLength: { value: 1, message: "Name Card Slug must be at least 1 characters" },
                          maxLength: { value: 100, message: "Name Card Slug must be less than 100 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.slug == null ? "" : "is-invalid"}`}
                            label="Name Card Slug (max 100 characters)"
                            inputRef={ref}
                            error={!!errors.slug}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.slug == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.slug.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{ marginTop: 20 }} classes={{ root: classes.dividerColor }} />

                <div style={{ paddingTop: 20, textAlign: "left", paddingBottom: 10 }}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Header Background Color (Default : black)
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
                      <Typography>Header background color (click to change) : </Typography>
                      <InputColor
                        style={{ marginLeft: 10, marginTop: 0 }}
                        initialValue={initial}
                        onChange={setColor}
                        placement="right"
                      />
                      <input
                        hidden
                        type="color"
                        value={color.hex}
                        onChange={e => { setInitial(e.target.value) }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
                      <Typography>Header Color Preview : </Typography>
                      <div
                        style={{
                          width: 300,
                          height: 100,
                          marginLeft: 10,
                          backgroundColor: color.rgba,
                        }}
                      >
                        <Typography style={{ color: 'white', textAlign: "center", marginTop: 10 }}>{color.hex}</Typography>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{ marginTop: 20 }} classes={{ root: classes.dividerColor }} />

                <div style={{ paddingTop: 20, textAlign: "left", paddingBottom: 10 }}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Company Information
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    Company Logo (Max file size 5MB, only .jpg, .jpeg, .png) *
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        height: "8rem"
                      }}>
                      <div style={{ width: "auto" }}>
                        <Box
                          border={1}
                          style={{
                            textAlign: "center",
                            bgcolor: "background.paper",
                            width: "16rem",
                            height: "9rem",
                            borderColor: "text.primary",
                            borderStyle: "dotted",
                            overflow: "hidden",
                          }}>
                          {companyLogoUrl ? (
                            <img
                              src={companyLogoUrl}
                              ali=""
                              style={{
                                width: "16rem",
                                height: "9rem",
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <div>
                              <Icon
                                onClick={() => {
                                  inputRefc.current.click();
                                }}
                                style={{
                                  marginTop: "20%",
                                  fontSize: 45,
                                  cursor: "pointer"
                                }}>
                                add
                              </Icon>
                            </div>
                          )}
                        </Box>
                      </div>

                      <div style={{ paddingLeft: 20 }}>
                        {companyLogoUrl ? (
                          <Button
                            onClick={e => { removeImg("companylogo"); }}
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              marginLeft: 10,
                              marginTop: "65%"
                            }}
                            variant="contained">
                            Remove
                          </Button>
                        ) : null}
                      </div>
                      <input
                        className="fileInput"
                        className={`form-control ${errors.companyLogo == null ? '' : 'is-invalid'}`}
                        type="file"
                        name="companyLogo"
                        hidden
                        ref={(e) => { register(e, { required: companyLogoUrl == null }); inputRefc.current = e; }}
                        accept="image/png,image/jpeg,image/jpg"
                        style={{ marginLeft: 10 }}
                        onChange={e => handleImageChange(e, false, "company_logo")}
                      />
                      {errors.companyLogo && errors.companyLogo.type === "required" && (<div className={classes.inactiveColor} style={{ fontSize: "11px" }}>Company Logo is required.</div>)}
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 20 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="company_name"
                        defaultValue={micardDetail.company_name ? micardDetail.company_name : ""}
                        rules={{
                          required: { value: true, message: "Company Name is required" },
                          minLength: { value: 2, message: "Company Name must be at least 2 characters" },
                          maxLength: { value: 100, message: "Company Name must be less than 100 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.company_name == null ? "" : "is-invalid"}`}
                            label="Company Name (max 100 characters)"
                            inputRef={ref}
                            error={!!errors.company_name}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.company_name == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.company_name.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="company_address"
                        defaultValue={micardDetail.company_address ? micardDetail.company_address : ""}
                        rules={{
                          required: { value: false, message: "Company Address is required" },
                          maxLength: { value: 120, message: "Company Address must be less than 120 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.company_address == null ? "" : "is-invalid"}`}
                            label="Address (max 120 characters)"
                            inputRef={ref}
                            error={!!errors.company_address}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.company_address == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.company_address.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="postcode"
                        defaultValue={micardDetail.postcode ? micardDetail.postcode : ""}
                        rules={{
                          required: { value: false, message: "Company Postcode is required" },
                          minLength: { value: 5, message: "Company Postcode must be at least 5 digits" },
                          maxLength: { value: 6, message: "Company Postcode must be less than 6 digits" },
                          pattern: { value: /^-?[0-9]+(?:\.[0-9]{1,3})?$/, message: "Company Postcode entered is invalid" }
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.postcode == null ? "" : "is-invalid"}`}
                            label="Postcode"
                            inputRef={ref}
                            error={!!errors.postcode}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.postcode == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.postcode.message}</div>}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="state_name"
                        defaultValue={micardDetail.state_name ? micardDetail.state_name : ""}
                        rules={{
                          required: { value: false, message: "Company State is required" },
                          minLength: { value: 2, message: "Company State must be at least 2 characters" },
                          maxLength: { value: 120, message: "Company State must be less than 120 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.state_name == null ? "" : "is-invalid"}`}
                            label="State (max 120 characters)"
                            inputRef={ref}
                            error={!!errors.state_name}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.state_name == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.state_name.message}</div>}
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="city_name"
                        defaultValue={micardDetail.city_name ? micardDetail.city_name : ""}
                        rules={{
                          required: { value: false, message: "Company City is required" },
                          minLength: { value: 2, message: "Company City must be at least 2 characters" },
                          maxLength: { value: 120, message: "Company City must be less than 120 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.city_name == null ? "" : "is-invalid"}`}
                            label="City (max 120 characters)"
                            inputRef={ref}
                            error={!!errors.city_name}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.city_name == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.city_name.message}</div>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={11} md={11}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 10 }}
                      className={classes.formControl}
                    >
                      <Controller
                        control={control}
                        name="company_description"
                        defaultValue={micardDetail.company_description ? micardDetail.company_description : ""}
                        rules={{
                          required: { value: true, message: "Company Description is required" },
                          minLength: { value: 2, message: "Company Description must be at least 2 characters" },
                          maxLength: { value: 2000, message: "Company Description must be less than 2000 characters" },
                        }}
                        render={(
                          { onChange, value, ref },
                        ) => (
                          <TextField
                            className={`form-control ${errors.company_description == null ? "" : "is-invalid"}`}
                            label="Company Description (max 2000 characters)"
                            inputRef={ref}
                            multiline
                            rows="4"
                            error={!!errors.company_description}
                            value={value}
                            onChange={e => { onChange(e) }}
                          >
                          </TextField>
                        )}
                      >
                      </Controller>
                      {errors.company_description == null ? "" : <div className={classes.inactiveColor} style={{ fontSize: "11px" }}>{errors.company_description.message}</div>}
                    </FormControl>
                    <p style={{ fontSize: "10px", margin: "0" }}>You may insert web link with the format https://</p>
                  </GridItem>
                </GridContainer>

                <RadioGroup aria-label="banner"
                  name="banner"
                  value={value}
                  onChange={(e) => handleCheckbox(e)}
                >

                  <div style={{ paddingTop: 10, textAlign: "left", paddingBottom: 10 }}>
                    <Typography style={{ fontWeight: "inherit" }}>
                      Image (Up to 10 images, Max file size 5MB, only accept .jpg, .jpeg and .png format) *
                  </Typography>
                  </div>

                  <GridContainer
                    direction="row"
                    alignItems="center">

                    {imageObject.map((image, index) => {
                      return (
                        <GridItem xs={12} sm={8} md={7} key={image.id}>
                          Image
                          <div
                            style={{
                              display: "inline-flex",
                              width: "100%",
                              height: "8rem"
                            }}>
                            <div style={{ width: "auto" }}>
                              <Box
                                border={1}
                                style={{
                                  textAlign: "center",
                                  bgcolor: "background.paper",
                                  width: "16rem",
                                  height: "9rem",
                                  borderColor: "text.primary",
                                  borderStyle: "dotted"
                                }}>
                                <img
                                  src={image.url}
                                  ali=""
                                  style={{
                                    width: "16rem",
                                    height: "9rem",
                                    cursor: "pointer"
                                  }}
                                />
                              </Box>
                            </div>

                            <div style={{ paddingLeft: 5 }}>
                              <div>
                                <div>
                                  <Radio
                                    name="banner"
                                    style={{
                                      color: themeColor,
                                      padding: 5
                                    }}
                                    checked={value == image.id}
                                    value={image.id}
                                    inputProps={{ "aria-label": "1" }}
                                  />
                                    Set as banner?
                                </div>

                                <div>
                                  <Button
                                    onClick={() => {
                                      removeImg("imageobject", image.id);
                                    }}
                                    style={{
                                      backgroundColor: themeColor,
                                      color: "white",
                                      marginLeft: 5,
                                      marginTop: "15%"
                                    }}
                                    variant="contained">
                                    Remove Image
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      )
                    })}

                    {imageObject.length < 10 ? (
                      <GridItem xs={12} sm={5} md={5}>
                        <div>
                          <Button
                            onClick={() => {
                              inputRefi.current.click();
                            }}
                            style={{
                              backgroundColor: themeColor,
                              color: "white",
                              marginLeft: 5,
                              marginTop: "10%"
                            }}
                            variant="contained">
                            Add Image
                        </Button>

                          <input
                            className="fileInput"
                            type="file"
                            hidden
                            ref={inputRefi}
                            accept="image/png,image/jpeg,image/jpg"
                            style={{ marginLeft: 10 }}
                            onChange={e => handleImageChange(e, true, "imageObject")}
                          />
                        </div>
                      </GridItem>
                    ) : null}

                  </GridContainer>
                </RadioGroup>

                <div style={{ paddingTop: 40, textAlign: "left", paddingBottom: 10 }}>
                  <Typography style={{ fontWeight: "inherit" }}>
                    Video (Max 1 video, Max file size/duration : 50MB/75 seconds, only .mp4 allowed)
                    </Typography>
                </div>

                {videoObject.map((video, index) => {
                  return (
                    <GridContainer>
                      <GridItem xs={12} sm={7} md={7} key={video.id}>
                        Video
                            <div
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            height: "8rem"
                          }}>
                          <div style={{ width: "auto" }}>
                            <Box
                              border={1}
                              style={{
                                textAlign: "center",
                                bgcolor: "background.paper",
                                width: "12rem",
                                height: "7.5rem",
                                borderColor: "text.primary",
                                borderStyle: "dotted"
                              }}>
                              <video
                                src={video.url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            </Box>
                          </div>

                          <div style={{ paddingLeft: 20 }}>
                            <Button
                              onClick={() => {
                                removeVid(video.id);
                              }}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                                </Button>

                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  )
                })}

                {videoObject.length < 1 ? (
                  <div>
                    <Button id="showbutton1"
                      onClick={() => {
                        inputRefv.current.click();
                      }}
                      style={{
                        backgroundColor: themeColor,
                        color: "white",
                        marginLeft: 5
                      }}
                      variant="contained">
                      Add Video
                    </Button>

                    <GridItem>
                      <input
                        className="fileInput"
                        type="file"
                        hidden
                        ref={inputRefv}
                        accept="video/mp4"
                        style={{ marginLeft: 10 }}
                        onChange={e => handleVideoChange(e)}
                      />
                    </GridItem>
                  </div>
                ) : null}

                <Dialog classes={{ paper: classes.dialogPaper }}
                  open={show == true}
                  onClose={handleClose}
                  maxWidth={'lg'}
                  disableBackdropClick={true}
                >
                  <DialogTitle id="simple-dialog-title">Crop the image</DialogTitle>
                  <DialogContent>
                    <ReactCrop
                      locked={drag == false}
                      src={tempSrc}
                      crop={crop}
                      ruleOfThirds
                      imageStyle={{ maxHeight: '70vh' }}
                      onImageLoaded={c => onImageLoaded(c)}
                      onChange={c => { setCrop(c) }}
                      onComplete={c => onCropComplete(c)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Buttons onClick={() => { handleClose(); }}>
                      Close
                    </Buttons>
                    <Buttons onClick={() => { handleClose(); saveUrl(); }}>
                      Save
                    </Buttons>
                  </DialogActions>
                </Dialog>


              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                {apiLoading ? (
                  <Button type="button" style={{ backgroundColor: themeColor }} disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Loading...
                  </Button>
                ) :
                  <Button type="button" style={{ backgroundColor: themeColor }} onClick={handleSubmit(onSubmit)}>
                    Submit
                </Button>}
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div >
  );
}