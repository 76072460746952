import * as AUTH from "./auth";
const axios = require("axios");

export async function getOwnerList(page, params) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/user?role=1&" +
      "page=" +
      page +
      "&per_page=10";
    if (params) {
      if (params.search) url += "&search=" + params.search;
      if (params.sortType) url += "&status=" + params.sortType;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally();
    });
}

export async function getOwnerDetail(id) {
    let accessToken = await AUTH.getAccessToken();
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL +  "/" +
            process.env.REACT_APP_API_PREFIX +
            "/ml-user-profile/" + id, {
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
}

export async function updateCentreOwner(updateData) {
    let accessToken = await AUTH.getAccessToken();
    let data = new FormData();

    data.append("first_name", updateData.first_name);
    data.append("last_name", updateData.last_name);
    data.append("email",updateData.email);
    data.append("mobile_prefix", updateData.mobile_number_prefix);
    data.append("mobile_number", updateData.mobile_number);
    data.append("center_permissions",updateData.center_permissions);
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/ml-user-profile/" +
          updateData.id 
        //   "?_method=put"
          ,
          data,
          {
            timeout: 60000,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + accessToken
            }
          }
        )
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          if (!accessToken) {
            reject("token");
          } else {
            reject(error.message)
          }
        })
        .finally();
    });
}
