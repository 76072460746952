import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import * as BANNER from "../../services/banner";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import defaultCover from "assets/img/default_cover.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from "sweetalert2";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "center",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign:"center",
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function Banner(props) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [indexNumber, setIndexNumber] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState("");
  const [isLoggedIn,setLoggedIn] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSortType, setSelectedSortType] = useState("-- Select --");
  const [selectedStatus, setSelectedStatus] = useState("-- Select --");
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [pageArr, setPageArr] = React.useState([]);
  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      // if (searchInput.length > 0) {
      //   params.search = searchInput;
      // }
      if (selectedStatus != "-- Select --") 
      params.status = selectedStatus;

      if (selectedSortType != "-- Select --") 
      params.sortType = selectedSortType;
    }

    if (props.location.state) {
      if (props.location.state.status == 1 || props.location.state.status == 2) {
        params.status = props.location.state.status;
        setSelectedStatus(params.status);
        props.location.state.status = null;
      }
      if (props.location.state.sortType == "asc" || props.location.state.sortType == "desc") {
        params.sortType = props.location.state.sortType;
        setSelectedSortType(params.sortType);
        props.location.state.sortType = null;
      }
    }
    props.history.replace({
      pathname: "/marketing-banner",
      state: {}
    });

    BANNER.getBannerList(currentPage, params)
      .then(res => {

        let i = 1; 
          res.data.map(datum => {
              datum.indexNumber = (currentPage - 1 ) * 10 + i;
              i++;
              return datum;
          })

        setBannerList(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err === "token expired") {
          props.history.replace("/login");
        }
        if (err === "Unauthorized") {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session expired. Please login again"
            })
            .then(() => {
              localStorage.removeItem('BZtokenData');
              setLoggedIn(false);      
            });
        }
      });
  }, [currentPage, onFilter]);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSortTypeChange = event => {
    const options = event.target.value;
    setSelectedSortType(options);
  };

  const handleUpdate = (event,banner) => {
    Swal.fire({
      title: 'Update this banner status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    .then((result) => {
      if (result.value) {
        BANNER.updateBanner(banner.status.id,banner.id)   
        .then(res => {      
        Swal.fire({
          icon: "success",
          title: "Banner status updated successfully"
        })
        .then(() => {
          window.location.reload();
          props.history.push("/marketing-banner");
        });
        })
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        props.history.replace("/marketing-banner");
      }
    })
     .catch(err => {
      if (err === "token expired") {
        props.history.replace("/login");
      }
      if (err === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Session expired. Please login again"
          })
          .then(() => {
            localStorage.removeItem('BZtokenData');
            setLoggedIn(false);      
          });
      }
    });
  };

  const handleDelete = (event,banner) => {
    Swal.fire({
      title: 'Are you sure to delete this banner?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    .then((result) => {
      if (result.value) {
        const newBannerList = [...bannerList];
        const index = newBannerList.indexOf(banner);
        if (index !== -1) {
          newBannerList.splice(index,1);
          setBannerList(newBannerList);
        }    
        BANNER.deleteBanner(banner.id)   
        .then(res => {
          Swal.fire({
            icon: "success",
            title: "Banner deleted successfully"
          }).then(() => {
            props.history.push("/marketing-banner");
          });
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        props.history.replace("/marketing-banner");
      }
    })
    .catch(err => {
      if (err === "token expired") {
        props.history.replace("/login");
      }
      if (err === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Session expired. Please login again"
          })
          .then(() => {
            localStorage.removeItem('BZtokenData');
            setLoggedIn(false);      
          });
      }
    });
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(selectedStatus + selectedSortType + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    // setSearchInput("");
    setSelectedSortType("-- Select --");
    setSelectedStatus("-- Select --");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const handleStatusChange = event => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleShow = (bannerurl) => {
    setShow(true);
    setSelectedBanner(bannerurl);
  }
  const handleClose = () => setShow(false);

  const paramPass = {
    page: currentPage,
    // search: searchInput,
    status: selectedStatus,
    sortType: selectedSortType
  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/marketing-banner/add",
          state: paramPass
        }}
      />
    );
  } 

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("add")}>
          <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add Marketing Banner
        </Button>
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
              <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem xs={12} sm={4} md={4} style={{ marginTop: 10 }}>
                  Status :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="type"
                    name="type"
                    variant="outlined"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    renderValue={selected =>
                      selected == 1 ? "Active" : selected == 2 ? "Inactive" : selected
                    }>
                    <MenuItem key={"all"} value={"-- Select --"}>
                      <ListItemText primary={"All"} />
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      <ListItemText primary={"Active"} />
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      <ListItemText primary={"Inactive"} />
                    </MenuItem>
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={4} style={{ marginTop: 10 }}>
                  Sort Type :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="sorttype"
                    name="sorttype"
                    variant="outlined"
                    value={selectedSortType}
                    onChange={handleSortTypeChange}
                    renderValue={selected =>
                        selected == "asc" ? "Ascending" : selected == "desc" ? "Descending" : selected
                    }>
                      <MenuItem key={"all"} value={"-- Select --"}>
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      <MenuItem key={1} value={"asc"}>
                        <ListItemText primary={"Ascending"} />
                      </MenuItem>
                      <MenuItem key={2} value={"desc"}>
                        <ListItemText primary={"Descending"} />
                      </MenuItem>                     
                  </Select>
                </GridItem>

                <GridItem xs={12} sm={4} md={4}
                  style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 8
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
              {bannerList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell  className={classes.th}>#</TableCell>
                        {/* <TableCell  className={classes.th}>Banner ID</TableCell> */}
                        <TableCell style={{ width: 80 }} className={classes.th}></TableCell>
                        <TableCell  className={classes.th}>Created At</TableCell>
                        <TableCell  className={classes.th}>Updated At</TableCell>
                        <TableCell  className={classes.th}>Status</TableCell>
                        <TableCell  className={classes.th}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bannerList.map(banner => (
                        <TableRow
                          onClick={() => handleShow(banner.url)}
                          className={classes.tr}
                          key={banner.id}
                          >
                          <TableCell className={classes.td} component="th" scope="row">
                            {banner.indexNumber}
                          </TableCell>
                          <TableCell className={classes.td}>
                            <div style={{ borderRadius: 5, backgroundColor: '#ebebeb', overflow: 'hidden', width:90 }}>
                            <img
                              src={
                                banner.url
                                  ? banner.url
                                  : banner.avatar
                                  ? banner.avatar
                                  : defaultCover
                              }
                              ali=""
                              height="40"
                              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                          </div>

                          </TableCell>
                          <TableCell className={classes.td}>
                            {banner.created_at.substring(0, 10) +
                              " " +
                              banner.created_at.substring(11, 19)}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {banner.updated_at.substring(0, 10) +
                              " " +
                              banner.updated_at.substring(11, 19)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: banner.status.id == 1 ? "#4caf50" : "#f44336",
                              fontWeight: "400"
                            }}
                            className={classes.td}
                            onClick={(event) => {handleUpdate(event.stopPropagation(),banner) }}>
                            {banner.status.name}
                          </TableCell>
                          <TableCell className={classes.td} >
                              <IconButton aria-label="delete"
                                  onClick={(event) => {handleDelete(event.stopPropagation(),banner) }} >
                                  <DeleteIcon />
                              </IconButton> 
                          </TableCell> 
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Dialog classes={{ paper: classes.dialogPaper }}
                    open={show==true}
                    maxWidth={true}
                    onClose={handleClose}
                    maxWidth = {'lg'}
                    disableBackdropClick={true}
                  >
                    <DialogTitle id="simple-dialog-title">Banner Preview</DialogTitle>
                    <DialogContent>
                        <img style={{maxHeight:'70vh'}} src={selectedBanner}/>
                    </DialogContent>
                    <DialogActions>
                      <Buttons onClick={() => {handleClose()}}>
                          Close
                      </Buttons>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No Marketing Banner found</Typography>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
}