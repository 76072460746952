import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import * as TRANSFER from "../../services/transferCode";
import * as USER from "../../services/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Router, Route, Redirect, Link } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  inputFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function Voucher(props) {
  const classes = useStyles();
  const [codeList, setCodeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ableTransfer, setAbleTransfer] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [allUnavailable, setAllUnavailable] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState("-- Select --");
  const [route, setRoute] = React.useState({});
  const [dealerList, setDealerList] = React.useState([]);
  const [selectedCode, setSelectedCode] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [pagination, setPagination] = React.useState([]);
  const [transferData, setTransferData] = React.useState({});
  const [searchInput, setSearchInput] = useState("");
  const [onFilter, setOnFilter] = useState("");
  const [pageArr, setPageArr] = React.useState([]);
  var myRef;
  var myTable = document.getElementById("table");

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      myTable.scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
      if (searchInput.length > 0) {
        params.code = searchInput;
      }
      if (ableTransfer) {
        params.can_transfer = ableTransfer;
      }
      if (selectedDealer != "-- Select --") {
        params.user_id = dealerList.find(dealer => dealer.username == selectedDealer).id;
      }
    }
    USER.getDealerList()
      .then(result => {
        if (props.location.state && props.location.state.dealer) {
          params.user_id = props.location.state.dealer;
          props.location.state.dealer = null;
          setSelectedDealer(result.data.find(dealer => dealer.id == params.user_id).username);
        }
        setDealerList(result.data.filter(dealer => dealer.user_type !== 1));
      })
      .then(() => {
        if (props.location.state) {
          if (props.location.state.search) {
            params.code = props.location.state.search;
            setSearchInput(params.code);
            props.location.state.search = null;
          }
        }
        props.history.replace({
          pathname: "/transferCoupon",
          state: {}
        });
        TRANSFER.getVoucherCode(currentPage, params)
          .then(async result => {
            if (result.pagination) {
              let page = [];
              for (let i = 0; i < result.pagination.last_page; i++) {
                page.push(i + 1);
              }
              setPageArr(page);
            }
            setCodeList(result.data);
            setPagination(result.pagination);
            setLoading(false);
            setAllUnavailable(false);
            setCheckedAll(true);
            let countUnavailable = 0;
            let checkAll = selectedDealer != "-- Select --" ? false : true;
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i].user_id == null && result.data[i].redeemed_at == null) {
                if (selectedCode.find(id => id == result.data[i].id) ? false : true) {
                  checkAll = false;
                  break;
                }
              } else {
                countUnavailable++;
              }
            }
            if (countUnavailable == result.data.length) {
              setAllUnavailable(true);
              setCheckedAll(false);
            }
            if (!checkAll) {
              setCheckedAll(false);
            }
          })
          .catch(err => {
            if (err == "token expired") {
              props.history.replace("/login");
            }
          });
      });
  }, [onFilter, currentPage]);

  function selectedThisCode(event, code) {
    let selectedlist = selectedCode;
    if (event.target.checked) {
      selectedlist.push(code);
      let checkAll = true;
      for (let i = 0; i < codeList.length; i++) {
        if (codeList[i].user_id == null && codeList[i].redeemed_at == null) {
          if (selectedlist.find(id => id == codeList[i].id) ? false : true) {
            checkAll = false;
            break;
          }
        }
      }
      if (!checkAll) {
        setCheckedAll(false);
      } else {
        setCheckedAll(true);
      }
      setSelectedCode([...selectedlist]);
    } else {
      setCheckedAll(false);
      setSelectedCode(selectedlist.filter(id => id != code));
    }
  }

  const handleTypeChange = event => {
    const options = event.target.value;
    setSelectedDealer(options);
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setSelectedCode([]);
    setAllUnavailable(false);
    setOnFilter(searchInput + ableTransfer + selectedDealer + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSelectedDealer("-- Select --");
    setSearchInput("");
    setSelectedCode([]);
    setAbleTransfer(false);
    setCurrentPage(1);
    setAllUnavailable(false);
    setOnFilter(new Date());
  };

  // const clearInput = () => {
  //   setSelectedDealer("-- Select --");
  //   setSearchInput("");
  //   setSelectedCode([]);
  //   setAbleTransfer(false);
  //   setCheckedAll(false);
  //   setAllUnavailable(false);
  // };

  const checkAbleTransfer = () => {
    setAbleTransfer(!ableTransfer);
  };

  function checkAllThis(value) {
    if (!value) {
      let selectedlist = selectedCode;
      for (let i = 0; i < codeList.length; i++) {
        if (codeList[i].user_id == null && codeList[i].redeemed_at == null) {
          if (selectedlist.length > 0) {
            if (selectedlist.find(id => id == codeList[i].id) ? false : true) {
              selectedlist.push(codeList[i].id);
            }
          } else {
            selectedlist.push(codeList[i].id);
          }
        }
      }
    } else {
      let selectedlist = selectedCode;
      for (let i = 0; i < codeList.length; i++) {
        if (codeList[i].user_id == null && codeList[i].redeemed_at == null) {
          selectedlist = selectedlist.filter(id => id != codeList[i].id);
          var index = selectedlist.indexOf(codeList[i].id);
          if (index > -1) {
            selectedlist.splice(index, 1);
          }
        }
      }
      setSelectedCode(selectedlist);
    }
    setCheckedAll(!checkedAll);
  }

  const viewDetails = voucherCode => {
    if (voucherCode.user_id) routeTo("view", voucherCode.id);
    else {
      Swal.fire({
        icon: "error",
        title: "Opps",
        text: "This voucher code don't have any transfer record yet"
      }).then(value => {});
    }
  };

  const transferCode = () => {
    const inputOptions = new Map();
    dealerList
      .filter(dealer => dealer.user_type == 2)
      .forEach(item => inputOptions.set(item.id, item.username));
    Swal.fire({
      title: "Transfer Coupon Code",
      text: "Transfer " + selectedCode.length + " coupon code to...",
      input: "select",
      inputOptions,
      inputPlaceholder: "Select a dealer",
      showCancelButton: true
    }).then(value => {
      if (!value.dismiss && value.value.length > 0) {
        let transferData = {
          code: selectedCode,
          dealer: value.value
        };
        setTransferData(transferData);
        Swal.fire({
          icon: "warning",
          title: "Transfer Confirmation",
          text:
            "Are you sure you want to transfer " +
            selectedCode.length +
            " voucher to " +
            getNameByID(value.value) +
            "?",
          showCancelButton: true
        }).then(value => {
          if (!value.dismiss) {
            setLoading(true);
            TRANSFER.transferCode(transferData)
              .then(result => {
                setLoading(false);
                setSelectedCode([]);
                setCheckedAll(false);
                setOnFilter(transferData);
                Swal.fire({
                  icon: "success",
                  title: "Transfer Successfully"
                }).then(value => {});
              })
              .catch(err => {
                if (err == "token expired") {
                  props.history.replace("/login");
                }
              });
          }
        });
      }
    });
  };

  function getNameByID(id) {
    return dealerList.find(dealer => dealer.id == id).username;
  }

  const paramPass = {
    page: currentPage,
    search: searchInput,
    dealer: dealerList.find(dealer => dealer.username == selectedDealer)
      ? dealerList.find(dealer => dealer.username == selectedDealer).id
      : null
  };

  if (route.action == "history") {
    return (
      <Redirect
        to={{
          pathname: "/transferCoupon/history",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/transferCoupon/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("history")}>
          Transfer Activity
        </Button>
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
              <GridContainer style={{ alignItems: "center" }}>
                <GridItem xs={5} sm={4} md={3}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"voucher code"}
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={7} sm={4} md={3}>
                  Dealer :
                  <Select
                    style={{ marginLeft: 10 }}
                    className={classes.select}
                    id="type"
                    name="type"
                    variant="outlined"
                    value={Array.isArray(selectedDealer) ? selectedDealer[1] : selectedDealer}
                    onChange={handleTypeChange}
                    input={<Input id="dealer" />}
                    MenuProps={MenuProps}
                    renderValue={selected => selected}>
                    <MenuItem key={"all"} value={"-- Select --"}>
                      <ListItemText primary={"All"} />
                    </MenuItem>
                    {dealerList.map(dealer => (
                      <MenuItem key={dealer.id} value={dealer.username}>
                        <ListItemText primary={dealer.username} />
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>
                <GridItem xs={5} sm={5} md={3} style={{ display: "block", marginTop: 30 }}>
                  <Checkbox
                    style={{
                      color: themeColor
                    }}
                    checked={ableTransfer}
                    value={ableTransfer}
                    onChange={checkAbleTransfer}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                  Able Transfer
                </GridItem>
                <GridItem
                  xs={7}
                  sm={7}
                  md={3}
                  style={{ display: "block", textAlign: "right", marginTop: 22 }}>
                  Number of Selected : {selectedCode.length}
                </GridItem>
              </GridContainer>
              <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "block", marginTop: 10, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 5
                    }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Filter
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 5
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: selectedCode.length > 0 ? themeColor : "grey",
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 5
                    }}
                    className={classes.submit}
                    onClick={transferCode}
                    disabled={selectedCode.length > 0 ? false : true}>
                    Transfer
                  </Button>
                </GridItem>
              </GridContainer>
              {codeList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} className={classes.th}>
                          <Checkbox
                            style={{
                              color: allUnavailable ? "grey" : themeColor
                            }}
                            disabled={allUnavailable}
                            checked={checkedAll}
                            value={checkedAll}
                            onChange={() => checkAllThis(checkedAll)}
                            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                          />
                        </TableCell>
                        <TableCell className={classes.th} style={{ padding: 2 }}>
                          ID
                        </TableCell>
                        <TableCell className={classes.th}>Coupon Code</TableCell>
                        <TableCell className={classes.th}>Course</TableCell>
                        <TableCell className={classes.th}>Dealer</TableCell>
                        <TableCell className={classes.th}>Redeemed By</TableCell>
                        <TableCell className={classes.th}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {codeList.map(voucherCode => (
                        <TableRow className={classes.tr} key={voucherCode.id}>
                          <TableCell
                            className={classes.td}
                            style={{ padding: 0 }}
                            component="th"
                            scope="row">
                            <Checkbox
                              style={{
                                color:
                                  voucherCode.user_id || voucherCode.redeemed_at
                                    ? "grey"
                                    : themeColor
                              }}
                              disabled={
                                voucherCode.user_id || voucherCode.redeemed_at ? true : false
                              }
                              checked={
                                selectedCode.find(code => code == voucherCode.id) ? true : false
                              }
                              value={voucherCode.code}
                              onChange={event => selectedThisCode(event, voucherCode.id)}
                              inputProps={{
                                "aria-label":
                                  voucherCode.user_id || voucherCode.redeemed_at
                                    ? "disabled checked checkbox"
                                    : "uncontrolled-checkbox"
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.td} component="th" scope="row">
                            {voucherCode.id}
                          </TableCell>
                          <TableCell className={classes.td}>{voucherCode.code}</TableCell>
                          <TableCell className={classes.td}>
                            {voucherCode.course.map((course, i, arr) => {
                              if (arr.length - 1 === i) {
                                return arr[i].name;
                              } else {
                                return (
                                  <div key={i}>
                                    {arr[i].name}
                                    {"\n"}
                                  </div>
                                );
                              }
                            })}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {voucherCode.user_id ? voucherCode.username : "-"}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {voucherCode.redeemed_by ? voucherCode.redeemed_by_name : "-"}
                          </TableCell>
                          <TableCell
                            style={{ cursor: voucherCode.user_id ? "pointer" : "mouse" }}
                            onClick={() => (voucherCode.user_id ? viewDetails(voucherCode) : {})}>
                            <Icon
                              style={{
                                paddingRight: 2,
                                fontSize: 19,
                                color: voucherCode.user_id ? "black" : "grey"
                              }}>
                              more_horiz
                            </Icon>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div style={{ paddingTop: 30, paddingBottom: 20, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No voucher code found</Typography>
                </div>
              )}
              {codeList.length > 0 ? (
                <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                  <div style={{ fontSize: 13 }}>
                    Page :
                    <Select
                      style={{
                        marginLeft: 10,
                        width: 60,
                        fontSize: 13,
                        textAlign: "center",
                        marginTop: 12
                      }}
                      className={classes.select}
                      id="type"
                      name="type"
                      variant="outlined"
                      value={currentPage}
                      MenuProps={MenuProps}
                      onChange={goToPage}
                      input={<Input id="type" />}
                      renderValue={selected => selected}>
                      {pageArr.map(page => (
                        <MenuItem key={page} value={page}>
                          <ListItemText primary={page} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={pagination.total}
                    rowsPerPage={10}
                    page={currentPage - 1}
                    onChangePage={handleChangePage}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
}
