import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Swal from "sweetalert2";
import * as CENTREOWNER from "../../services/centreowner";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { defaultFont } from "assets/jss/material-dashboard-react.js";
import TablePagination from "@material-ui/core/TablePagination";
import { Redirect } from "react-router-dom";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from '@material-ui/core/Checkbox';

const styles = makeStyles(theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    width: "100%",
    overflowX: "auto"
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: 4,
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400"
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    // border: '1px solid #ddd',
    padding: 6
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7"
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer"
    }
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  select: {
    marginTop: theme.spacing(3),
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  }
}));

const useStyles = styles;
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP
    }
  }
};

export default function CentreOwnerManagement(props) {
  const classes = useStyles();
  const [ownerList, setOwnerList] = useState([]);
  const [indexNumber, setIndexNumber] = useState([]);
  const [isLoggedIn,setLoggedIn] = useState(true)
  const [selectedSortType, setSelectedSortType] = useState("-- Select --");
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage ? props.location.state.currentPage : 1
  );
  const [pagination, setPagination] = React.useState([]);
  const [pageArr, setPageArr] = React.useState([]);
  let myRef;

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = e => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }, 500);
  };

  useEffect(() => {
    let params = {};
    if (onFilter) {
        if (searchInput.length > 0) {
            params.search = searchInput;
          }
          if (selectedSortType != "-- Select --") {
            params.sortType = selectedSortType;
          }
        }

    if (props.location.state) {
        if (props.location.state.search) {
            params.search = props.location.state.search;
            setSearchInput(params.search);
            props.location.state.search = null;
          }
          if (props.location.state.sortType == "asc" || props.location.state.sortType == "desc") {
            params.sortType = props.location.state.sortType;
            setSelectedSortType(params.sortType);
            props.location.state.sortType = null;
          }
        }
        props.history.replace({
          pathname: "/centerowner",
          state: {}
        });

        CENTREOWNER.getOwnerList(currentPage, params)
        .then(result => {
          let page = [];
          for (let i = 0; i < result.pagination.last_page; i++) {
            page.push(i + 1);
          }
          setPageArr(page);
          setPagination(result.pagination);

          let i = 1; 
          result.data.map(datum => {
              datum.indexNumber = (currentPage - 1 ) * 10 + i;
              i++;
              return datum;
          })

          setOwnerList(result.data);
          setLoading(false);
        })
        .catch(err => {
          if (err == "token expired") {
            props.history.replace("/login");
          }
          if (err === "Unauthorized") {
              Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Session expired. Please login again"
                })
                .then(() => {
                  localStorage.removeItem('BZtokenData');
                  setLoggedIn(false);      
                });
            }
        });    
}, [onFilter, currentPage]);

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    myRef.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };

  const handleSortTypeChange = event => {
    const options = event.target.value;
    setSelectedSortType(options);
  };

  const handleSearchInput = event => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const keyPress = e => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(searchInput + selectedSortType + new Date());
  };

  const clearFilter = () => {
    setLoading(true);
    setSelectedSortType("-- Select --");
    setSearchInput("");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    sortType: selectedSortType
  };

  if (route.action == "add") {
    return (
      <Redirect
        to={{
          pathname: "/centerowner/add",
          state: paramPass
        }}
      />
    );
  } else if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/centerowner/" + route.id,
          state: paramPass
        }}
      />
    );
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div
          id="table"
          ref={node => {
            myRef = node;
          }}>
          <Card>
            <CardBody>
              <GridContainer style={{ paddingBottom: 10 }}>
                <GridItem xs={12} sm={6} md={5}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"user name/email/mobile number"}
                    fullWidth
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={4} md={4}
                  style={{ display: "block", marginTop: 25, textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: themeColor, color: "white", marginBottom: 8 }}
                    className={classes.submit}
                    onClick={filterThis}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: themeColor,
                      color: "white",
                      marginLeft: 8,
                      marginBottom: 8
                    }}
                    className={classes.submit}
                    onClick={clearFilter}>
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
              {ownerList.length > 0 ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>#</TableCell>
                      <TableCell className={classes.th}>Name</TableCell>
                      {/* <TableCell className={classes.th}>User ID</TableCell> */}
                      <TableCell className={classes.th}>Email</TableCell>
                      {/* <TableCell className={classes.th}>Mobile Number</TableCell> */}
                      <TableCell className={classes.th}>WhatsApp Link</TableCell>
                      <TableCell className={classes.th}>Map Link</TableCell>
                      <TableCell className={classes.th}>QR Code</TableCell>
                      <TableCell className={classes.th}>Voucher Code</TableCell>
                      <TableCell className={classes.th}>Facebook Link</TableCell>
                      <TableCell className={classes.th}>Business Card</TableCell>
                      <TableCell className={classes.th}>Centre Video</TableCell>
                      {/* <TableCell className={classes.th}>Live Event</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ownerList.map(owner => (
                      <TableRow
                        onClick={() => routeTo("view", owner.id)}
                        className={classes.tr}
                        key={owner.id}>
                        <TableCell className={classes.td} component="th" scope="row">
                          {owner.indexNumber}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {owner.first_name} {owner.last_name}
                        </TableCell>
                        {/* <TableCell className={classes.td} style={{textAlign:"center"}}>{owner.id}</TableCell> */}
                        <TableCell className={classes.td}>{owner.email}</TableCell>
                        {/* <TableCell className={classes.td}>
                          {owner.full_mobile_number ? owner.full_mobile_number : "-"}
                        </TableCell> */}
                        <TableCell >
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canOpenWhatsAppLink == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canOpenMapLink == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canGenerateQrCode == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canGenerateVoucherCode == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canAddFbCode == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canCreateNameCard == true}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                            disabled
                            style={{color:themeColor}}
                            checked={owner.center_permissions.canAddCentreVideo == true}
                            />
                        </TableCell>
                        {/* <TableCell>
                            <Checkbox
                            disabled
                            style={{color:"#4caf50"}}
                            checked={owner.center_permissions.canCreateLiveEvent == true}
                            />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {ownerList.length > 0 ? (
                <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
                  <div style={{ fontSize: 13 }}>
                    Page :
                    <Select
                      style={{
                        marginLeft: 10,
                        width: 60,
                        fontSize: 13,
                        textAlign: "center",
                        marginTop: 12
                      }}
                      className={classes.select}
                      id="page"
                      name="page"
                      variant="outlined"
                      value={currentPage}
                      MenuProps={MenuProps}
                      onChange={e => goToPage(e)}
                      input={<Input id="page" />}
                      renderValue={selected => selected}>
                      {pageArr.map(page => (
                        <MenuItem key={page} value={page}>
                          <ListItemText primary={page} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={pagination.total}
                    rowsPerPage={10}
                    page={currentPage - 1}
                    onChangePage={handleChangePage}
                  />
                </div>
              ):null}
              </div>
              ) : (
                <div style={{ paddingTop: 30, textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>No Centre Owner found</Typography>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
}