import * as AUTH from "./auth";
const axios = require("axios");

export async function getAvailableCourse(params) {
  let accessToken = await AUTH.getAccessToken();
  let url = process.env.REACT_APP_API_URL+ '/'+process.env.REACT_APP_API_PREFIX +'/course?'
  if (params) {
    if (params.is_bundle == 0) {
      url += "&is_bundle=" + params.is_bundle;
    }
    if (params.per_page) {
      url += "&per_page=" + params.per_page;
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
      .finally();
  });
}

export function getCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/course-category")
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
      .finally();
  });
}

export async function getCourseList(page, params) {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/course?" +
    "page=" +
    page +
    "&per_page=10";
  if (params) {
    if (params.search) url += "&search=" + params.search;
    if (params.type) url += "&user_type=" + params.type;
    if (params.status) url += "&status=" + params.status;
    if (params.is_bundle == 1 || params.is_bundle == 0) {
      url += "&is_bundle=" + params.is_bundle;
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
      .finally();
  });
}

export async function addCourseBundle(createData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("bundle", createData.bundle);
  data.append("name", createData.name);
  data.append("category", createData.category);
  for (let i = 0; i < createData.course.length; i++) {
    data.append("course[" + i + "]", createData.course[i]);
  }
  data.append("description", createData.description);
  data.append("retail_price", createData.retail_price);
  data.append("selling_price", createData.selling_price);
  data.append("sales_status", createData.sales_status);
  data.append("commission_amount", createData.commission_amount);
  data.append("custom_commission", createData.custom_commission);

  if (createData.custom_commission == 1) {
    data.append("commission", JSON.stringify(createData.commission));
  }
  if (createData.cover) data.append("cover", createData.cover);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/course", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getCourse(id) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_API_PREFIX + "/course/" + id, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function updateCourse(updateData) {
  let accessToken = await AUTH.getAccessToken();
  let data = new FormData();
  data.append("bundle", updateData.bundle);
  data.append("name", updateData.name);
  data.append("category", updateData.category_id);
  data.append("description", updateData.description);
  data.append("retail_price", updateData.retail_price);
  data.append("selling_price", updateData.selling_price);
  data.append("sales_status", updateData.sales_status);
  data.append("commission_amount", updateData.commission_amount);
  data.append("custom_commission", updateData.custom_commission);

  if (updateData.custom_commission == 1) {
    data.append("commission", JSON.stringify(updateData.commission));
  }
  for (let i = 0; i < updateData.course.length; i++) {
    data.append("course[" + i + "]", updateData.course[i]);
  }
  if (updateData.new_cover) {
    data.append("new_cover", updateData.new_cover);
  } else {
    if (updateData.cover) data.append("cover", updateData.cover);
    else data.append("cover", "");
  }
  if (updateData.bundle == 1) {
    data.course = updateData.course;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/course/" +
          updateData.id +
          "?_method=put",
        data,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}
