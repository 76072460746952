import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as CENTREOWNER from "../../services/centreowner";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import Swal from "sweetalert2";
import { Router, Route, Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const countryCode = [
  {
    value: "673",
    label: "Brunei (+673)"
  },
  {
    value: "855",
    label: "Cambodia (+855)"
  },
  {
    value: "86",
    label: "China (+86)"
  },
  {
    value: "852",
    label: "Hong Kong (+852)"
  },
  {
    value: "62",
    label: "Indonesia (+62)"
  },
  {
    value: "856",
    label: "Lao People's Democratic Republic (+856)"
  },
  {
    value: "60",
    label: "Malaysia (+60)"
  },
  {
    value: "95",
    label: "Myanmar (+95)"
  },
  {
    value: "63",
    label: "Philippines (+63)"
  },
  {
    value: "65",
    label: "Singapore (+65)"
  },
  {
    value: "886",
    label: "Taiwan (+886)"
  },
  {
    value: "66",
    label: "Thailand (+66)"
  },
  {
    value: "84",
    label: "Viet Nam (+84)"
  },
  {
    value: "Others",
    label: "Others"
  }
];

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  dividerColor: {
    backgroundColor: themeColor
  },
  descColor: {
    color: "#1E90FF",
    fontWeight: "400"
  },
  formControl: {
    marginTop:15
  },
  inputFocused: {},
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;
const inputRef = React.createRef();
let center_permission = {};
// let key = "";

export default function UpdateUserDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn,setLoggedIn] = useState(true);
  const [whatsappValue, setWhatsappValue] = React.useState('');
  const [mapValue, setMapValue] = React.useState('');
  const [qrValue, setQrValue] = React.useState('');
  const [voucherValue, setVoucherValue] = React.useState('');
  const [fbValue, setFbValue] = React.useState('');
  const [namecardValue, setNamecardValue] = React.useState('');
  const [centreVideoValue, setCentreVideoValue] = React.useState('');
  const [liveEventValue, setLiveEventValue] = React.useState('');
  const [selectedStatus, setSelectedStatus] = useState("");
  const [whatsappstart, setWhatsappStart] = React.useState("");
  const [whatsappend, setWhatsappEnd] = React.useState("");
  const [mapstart, setMapStart] = React.useState("");
  const [mapend, setMapEnd] = React.useState("");
  const [qrstart, setQrStart] = React.useState("");
  const [qrend, setQrEnd] = React.useState("");
  const [voucherstart, setVoucherStart] = React.useState("");
  const [voucherend, setVoucherEnd] = React.useState("");
  const [fbstart, setFbStart] = React.useState("");
  const [fbend, setFbEnd] = React.useState("");
  const [namecardstart, setNamecardStart] = React.useState("");
  const [namecardend, setNamecardEnd] = React.useState("");
  const [videostart, setCentreVideoStart] = React.useState("");
  const [videoend, setCentreVideoEnd] = React.useState("");
  const [eventstart, setLiveEventStart] = React.useState("");
  const [eventend, setLiveEventEnd] = React.useState("");
  const [emailError, setEmailError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [mobileNumError, setMobileNumError] = useState(false);
  const [mobileNumPrefixError, setMobileNumPrefixError] = useState(false);
  const page = props.location.state && props.location.state.page ? props.location.state.page : 1;
  const search =
    props.location.state && props.location.state.search ? props.location.state.search : "";
  const onSortType =
    props.location.state && props.location.state.sortType ? props.location.state.sortType : null;

  const [formData, setFormData] = useState({
    id: props.match.params.id,
    first_name: "",
    last_name: "",
    center_limitation:"",
    email: "",
    mobile_number_select: "60",
    mobile_number_prefix: "60",
    mobile_number: "",
    status: "",
    image:"",
    image_url:""
  });

  useEffect(() => {
        const id = props.match.params.id;
        CENTREOWNER.getOwnerDetail(id)
          .then(result => {
            setFormData({
              ...formData,
              ["first_name"]: result.data.first_name,
              ["last_name"]: result.data.last_name,
              ["email"]: result.data.email,
              ["mobile_number"]: result.data.mobile_number,
              ["mobile_number_prefix"]: result.data.mobile_number_prefix
                ? result.data.mobile_number_prefix
                : "60",
              ["mobile_number_select"]: result.data.mobile_number_prefix
                ? countryCode.find(cc => cc.value == result.data.mobile_number_prefix) !== undefined
                  ? result.data.mobile_number_prefix
                  : "Others"
                : "60",
              ["center_limitation"]: result.data.center_limitation,
            });
            
            let a = result.data.center_permissions; 
            setMapValue(a.map_start ? "2" : a.canOpenMapLink ? "1" : "3");
            setWhatsappValue(a.whatsapp_start ? "2" : a.canOpenWhatsAppLink ? "1" : "3");
            setQrValue(a.qr_start ? "2" : a.canGenerateQrCode ? "1" : "3");
            setVoucherValue(a.voucher_start ? "2" : a.canGenerateVoucherCode ? "1" : "3");
            setFbValue(a.fb_start ? "2" : a.canAddFbCode ? "1" : "3");
            setNamecardValue(a.namecard_start ? "2" : a.canCreateNameCard ? "1" : "3");
            setCentreVideoValue(a.video_start ? "2" : a.canAddCentreVideo ? "1" : "3");
            setLiveEventValue(a.event_start ? "2" : a.canCreateLiveEvent ? "1" : "3");

            setWhatsappStart(result.data.center_permissions.whatsapp_start == null ? null : result.data.center_permissions.whatsapp_start)
            setWhatsappEnd(result.data.center_permissions.whatsapp_end == null ? null : result.data.center_permissions.whatsapp_end)
            setMapStart(result.data.center_permissions.map_start == null ? null : result.data.center_permissions.map_start)
            setMapEnd(result.data.center_permissions.map_end == null ? null : result.data.center_permissions.map_end)
            setQrStart(result.data.center_permissions.qr_start == null ? null : result.data.center_permissions.qr_start)
            setQrEnd(result.data.center_permissions.qr_end == null ? null : result.data.center_permissions.qr_end)
            setVoucherStart(result.data.center_permissions.voucher_start == null ? null : result.data.center_permissions.voucher_start)
            setVoucherEnd(result.data.center_permissions.voucher_end == null ? null : result.data.center_permissions.voucher_end)
            setFbStart(result.data.center_permissions.fb_start == null ? null : result.data.center_permissions.fb_start)
            setFbEnd(result.data.center_permissions.fb_end == null ? null : result.data.center_permissions.fb_end)
            setNamecardStart(result.data.center_permissions.namecard_start == null ? null : result.data.center_permissions.namecard_start)
            setNamecardEnd(result.data.center_permissions.namecard_end == null ? null : result.data.center_permissions.namecard_end)
            setCentreVideoStart(result.data.center_permissions.video_start == null ? null : result.data.center_permissions.video_start)
            setCentreVideoEnd(result.data.center_permissions.video_end == null ? null : result.data.center_permissions.video_end)
            setLiveEventStart(result.data.center_permissions.event_start == null ? null : result.data.center_permissions.event_start)
            setLiveEventEnd(result.data.center_permissions.event_end == null ? null : result.data.center_permissions.event_end)

            setLoading(false);
          })
          .catch(err => {
            if (err == "token expired") {
              props.history.replace("/login");
            } else {
              if (err.status == "not found") {
                Swal.fire({
                  icon: "error",
                  title: "Opps",
                  text: "Page Not Found"
                }).then(() => {
                  props.history.replace("/centerowner");
                });
              }
              if (err === "Unauthorized") {
                Swal.fire({
                  icon: "error",
                  title: "Oops",
                  text: "Session expired. Please login again"
                  })
                  .then(() => {
                    localStorage.removeItem('BZtokenData');
                    setLoggedIn(false);
                  });
              }
            }
          });
  }, []);

  const updateFormData = event => {
    if (event.target.name === "mobile_number_select") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        ["mobile_number_prefix"]: event.target.value
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const {
    first_name,
    last_name,
    title,
    center_limitation,
    email,
    image_url,
    mobile_number_select,
    mobile_number_prefix,
    mobile_number,
    status
  } = formData;

  function handleSubmit(event) {
    event.preventDefault();
    setMobileNumError(false);
    setEmailError(false);

    let valid = true;
    if (!re.test(mobile_number)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }
    if (isNaN(event.target.mobile_number_prefix.value)) {
      setMobileNumPrefixError(true);
      setLoading(false);
      valid = false;
    }

    if (whatsappValue === "1"){
      center_permission.canOpenWhatsAppLink = true;}
    else if (whatsappValue === "2"){
      center_permission.canOpenWhatsAppLink = true;
      if (whatsappstart != null && whatsappend < whatsappstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.whatsapp_start= moment(whatsappstart).format("YYYY-MM-DD HH:mm:ss");
        center_permission.whatsapp_end= moment(whatsappend).format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
        center_permission.canOpenWhatsAppLink = false;
    }

    if(mapValue === "1"){
      center_permission.canOpenMapLink = true;}
    else if (mapValue === "2"){
      center_permission.canOpenMapLink = true;
      if (mapstart != null && mapend < mapstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.map_start= moment(mapstart).format("YYYY-MM-DD HH:mm:ss");;
        center_permission.map_end= moment(mapend).format("YYYY-MM-DD HH:mm:ss");;
      }
    }else{
      center_permission.canOpenMapLink = false;
    }

    if(qrValue === "1"){
      center_permission.canGenerateQrCode = true;}
    else if (qrValue === "2"){
      center_permission.canGenerateQrCode = true;
      if (qrstart != null && qrend < qrstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.qr_start= moment(qrstart).format("YYYY-MM-DD HH:mm:ss");;
        center_permission.qr_end= moment(qrend).format("YYYY-MM-DD HH:mm:ss");;
      }
    }else{
      center_permission.canGenerateQrCode = false;
    }

    if(voucherValue === "1"){
      center_permission.canGenerateVoucherCode = true;}
    else if (voucherValue === "2"){
      center_permission.canGenerateVoucherCode = true;
      if (voucherstart != null && voucherend < voucherstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.voucher_start= moment(voucherstart).format("YYYY-MM-DD HH:mm:ss");;
        center_permission.voucher_end= moment(voucherend).format("YYYY-MM-DD HH:mm:ss");;
      }
    }else{
      center_permission.canGenerateVoucherCode = false;
    }

    if(fbValue === "1"){
      center_permission.canAddFbCode = true;}
    else if (fbValue === "2"){
      center_permission.canAddFbCode = true;
      if (fbstart != null && fbend < fbstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.fb_start= moment(fbstart).format("YYYY-MM-DD HH:mm:ss");;
        center_permission.fb_end= moment(fbend).format("YYYY-MM-DD HH:mm:ss");;
      }
    }else{
      center_permission.canAddFbCode = false;
    }

    if(namecardValue === "1"){
      center_permission.canCreateNameCard = true;}
    else if (namecardValue === "2"){
      center_permission.canCreateNameCard = true;
      if (namecardstart != null && namecardend < namecardstart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.namecard_start= moment(namecardstart).format("YYYY-MM-DD HH:mm:ss");;
        center_permission.namecard_end= moment(namecardend).format("YYYY-MM-DD HH:mm:ss");;
      }
    }else{
      center_permission.canCreateNameCard = false;
    }

    if (centreVideoValue === "1"){
      center_permission.canAddCentreVideo = true;}
    else if (centreVideoValue === "2"){
      center_permission.canAddCentreVideo = true;
      if (videostart != null && videoend < videostart) {
        dateError();
        setLoading(false);
        valid = false;
      } 
      else {
        center_permission.video_start= moment(videostart).format("YYYY-MM-DD HH:mm:ss");
        center_permission.video_end= moment(videoend).format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
        center_permission.canAddCentreVideo = false;
    }

    center_permission.canCreateLiveEvent = false;
    // if (liveEventValue === "1"){
    //   center_permission.canCreateLiveEvent = true;}
    // else if (liveEventValue === "2"){
    //   center_permission.canCreateLiveEvent = true;
    //   if (eventstart != null && eventend < eventstart) {
    //     dateError();
    //     setLoading(false);
    //     valid = false;
    //   } 
    //   else {
    //     center_permission.event_start= moment(eventstart).format("YYYY-MM-DD HH:mm:ss");
    //     center_permission.event_end= moment(eventend).format("YYYY-MM-DD HH:mm:ss");
    //   }
    // } else {
    //     center_permission.canCreateLiveEvent = false;
    // }

    if (valid) {
      let updateData = {
        id : props.match.params.id,
        ...formData,
        // status:selectedStatus,
        center_permissions:JSON.stringify(center_permission)
      };
      setLoading(true);
      CENTREOWNER.updateCentreOwner(updateData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Centre owner profile update successful"
          })
          // .then(() => {
          //   props.history.replace("/centerowner");
          // });
        })
        .catch(err => {
            if (err == "token expired") {
                props.history.replace("/login");
              } else {
                if (err === "Unauthorized") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops",
                    text: "Session expired. Please login again"
                    })
                    .then(() => {
                      localStorage.removeItem('BZtokenData');
                      setLoggedIn(false);      
                    });
                }

                let errMsg = [];
                if (err.errors) {
                    setLoading(false);
                }
                else if (err == "timeout of 60000ms exceeded") {
                  errMsg.push("Request timeout. You may experience a slow network.")
                }
                else {
                  errMsg.push(err)
                }
                Swal.fire({
                  icon: "error",
                  title: "Fail to Update Owner Profile",
                  text: errMsg
                });             
            }
            setLoading(false);
        })
    }
  }

  const dateError = () => {
    Swal.fire({
      icon: "error",
      title: "End Date cannot be earlier than Start Date"
    });
  } 

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size > 4000000) {
      setImageError(true);
    }

    if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
      setImageFormatError(true);
    }

    reader.onloadend = () => {
      setFormData({
        ...formData,
        ["image"]: file,
        ["image_url"]: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    inputRef.current.value = "";
    setFormData({
      ...formData,
      ["image_filename"]: null,
      ["image"]: null,
      ["image_url"]: null
    });
    setImageError(false);
    setImageFormatError(false);
  };

  if (!isLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/centerowner",
            state: { 
                currentPage: page, 
                search: search,
                sortType : onSortType
            }
          })
        }>
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <form className={classes.form} onSubmit={event => handleSubmit(event)}>
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Edit Centre Owner</h4>
              </CardHeader>
              <CardBody>
                 {/* <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      Image
                    <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "8rem"
                        }}>
                        <div style={{ width: "auto" }}>
                          <Box
                            border={1}
                            style={{
                              textAlign: "center",
                              bgcolor: "background.paper",
                              width: "7.5rem",
                              height: "7.5rem",
                              borderColor: "text.primary",
                              borderStyle: "dotted"
                            }}>
                            {image_url ? (
                              <img
                                onClick={() => {
                                  window.open(image_url, "_blank");
                                  inputRef.current.click();
                                }}
                                src={image_url}
                                ali=""
                                style={{
                                  width: "7.5rem",
                                  height: "7.5rem",
                                  cursor: "pointer"
                                }}
                              />
                            ) : (
                                <div>
                                  <Icon
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                    style={{
                                      marginTop: "32%",
                                      fontSize: 45,
                                      cursor: "pointer"
                                    }}>
                                    add
                              </Icon>
                                </div>
                              )}
                          </Box>
                        </div>
                        {imageError && (
                          <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                            }}>*FILE SIZE MUST BELOW 4MB.</FormHelperText>
                        )}
                        {imageFormatError && (
                          <FormHelperText
                            style={{
                              color: "#f44336",
                              position: "absolute",
                              marginTop: "8rem"
                            }}>*Invalid format.Only accept image with jpg, jpeg, png format.</FormHelperText>
                        )}
                        <div style={{ paddingLeft: 20 }}>
                          {image_url ? (
                            <Button
                              onClick={removeImg}
                              style={{
                                backgroundColor: themeColor,
                                color: "white",
                                marginLeft: 10,
                                marginTop: "65%"
                              }}
                              variant="contained">
                              Remove
                            </Button>
                          ) : null}
                        </div>
                        <input
                          className="fileInput"
                          type="file"
                          hidden
                          ref={inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ marginLeft: 10 }}
                          onChange={e => handleImageChange(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12} sm={5} md={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="First Name (max 100 characters)"
                      name="first_name"
                      value={first_name}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={5} md={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Last Name (max 100 characters)"
                      name="last_name"
                      value={last_name}
                      onChange={e => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1
                      }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={emailError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        onChange={e => updateFormData(e)}
                        value={email}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {emailError && <FormHelperText>THIS FIELD IS REQUIRED</FormHelperText>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem item xs={12} sm={12} sm={5}>
                  <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 , marginTop:15 }}
                    //   error={mobileNumError}
                      className={classes.formControl}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="mobile_number_select"
                      select
                      label="Country"
                      value={mobile_number_select}
                      onChange={e => updateFormData(e)}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}>
                      {countryCode.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={6} sm={3} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 15 }}
                      error={mobileNumPrefixError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        margin="normal"
                        label="Country Code"
                        disabled={mobile_number_select === "Others" ? false : true}
                        name="mobile_number_prefix"
                        value={
                          mobile_number_select === "Others"
                            ? mobile_number_prefix === "Others"
                              ? ""
                              : mobile_number_prefix
                            : mobile_number_select
                        }
                        onChange={e => updateFormData(e)}
                        inputProps={{ maxLength: 4 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumPrefixError && (
                        <FormHelperText>mobile number prefix is invalid.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={12} sm={3} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0 }}
                      error={mobileNumError}
                      className={classes.formControl}>
                      <TextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        name="mobile_number"
                        value={mobile_number}
                        inputProps={{ maxLength: 20, minLength: 6 }}
                        onChange={e => updateFormData(e)}
                        InputProps={{
                          maxLength: 20,
                          minLength: 6,
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumError && <FormHelperText>mobile number is invalid.</FormHelperText>}
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 10, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Permission List
                  </Typography>
                </div>

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    WhatsApp
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="whatsapp" 
                    name="whatsapp" 
                    value={whatsappValue} 
                    onChange={(e) => setWhatsappValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canOpenWhatsAppLink"/>}
                        label="Able to open WhatsApp Link (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canOpenWhatsAppLink"/>}
                        label="Able to open WhatsApp Link (with expired date)"
                    />

                        {whatsappValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={whatsappValue=="2"}
                                value={whatsappstart}
                                name="whatsapp_start"
                                label="Valid From"
                                onChange={(dates) => {setWhatsappStart(dates);}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={whatsappValue=="2"}
                                value={whatsappend}
                                name="whatsapp_end"
                                label="Expired At"
                                onChange={dates => {setWhatsappEnd(dates);}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canOpenWhatsAppLink"/>}
                        label="Unable to open WhatsApp Link"
                    />
                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   
                      
                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Google Map
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="map" 
                    name="map" 
                    value={mapValue} 
                    onChange={(e) => setMapValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canOpenMapLink"/>}
                        label="Able to open Map Link (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canOpenMapLink"/>}
                        label="Able to open Map Link (with expired date)"
                    />

                        {mapValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={mapValue=="2"}
                                value={mapstart}
                                name="map_start"
                                label="Valid From"
                                onChange={(dates) => {setMapStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={mapValue=="2"}
                                value={mapend}
                                name="map_end"
                                label="Expired At"
                                onChange={dates => {setMapEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canOpenMapLink"/>}
                        label="Unable to open Map Link"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Qr Code
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="qrcode" 
                    name="qrcode" 
                    value={qrValue} 
                    onChange={(e) => setQrValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canGenerateQrCode"/>}
                        label="Able to generate Qr code (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canGenerateQrCode"/>}
                        label="Able to generate Qr code (with expired date)"
                    />

                        {qrValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={qrValue=="2"}
                                value={qrstart}
                                name="qr_start"
                                label="Valid From"
                                onChange={(dates) => {setQrStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={qrValue=="2"}
                                value={qrend}
                                name="qr_end"
                                label="Expired At"
                                onChange={dates => {setQrEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canGenerateQrCode"/>}
                        label="Unable to generate Qr code"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Voucher Code
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="vouchercode" 
                    name="vouchercode" 
                    value={voucherValue} 
                    onChange={(e) => setVoucherValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canGenerateVoucherCode"/>}
                        label="Able to generate voucher code (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canGenerateVoucherCode"/>}
                        label="Able to generate voucher code (with expired date)"
                    />

                        {voucherValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={voucherValue=="2"}
                                value={voucherstart}
                                name="voucher_start"
                                label="Valid From"
                                onChange={(dates) => {setVoucherStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={voucherValue=="2"}
                                value={voucherend}
                                name="voucher_end"
                                label="Expired At"
                                onChange={dates => {setVoucherEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canGenerateVoucherCode"/>}
                        label="Unable to generate voucher code"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Facebook Link
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="fblink" 
                    name="fblink" 
                    value={fbValue} 
                    onChange={(e) => setFbValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canAddFbCode"/>}
                        label="Able to add facebook link (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canAddFbCode"/>}
                        label="Able to add facebook link (with expired date)"
                    />

                        {fbValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={fbValue=="2"}
                                value={fbstart}
                                name="fb_start"
                                label="Valid From"
                                onChange={(dates) => {setFbStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={fbValue=="2"}
                                value={fbend}
                                name="fb_end"
                                label="Expired At"
                                onChange={dates => {setFbEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canAddFbCode"/>}
                        label="Unable to add facebook link"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Business Card
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="namecard" 
                    name="namecard" 
                    value={namecardValue} 
                    onChange={(e) => setNamecardValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canCreateNameCard"/>}
                        label="Able to create Business Card (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canCreateNameCard"/>}
                        label="Able to create Business Card (with expired date)"
                    />

                        {namecardValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={namecardValue=="2"}
                                value={namecardstart}
                                name="namecard_start"
                                label="Valid From"
                                onChange={(dates) => {setNamecardStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={namecardValue=="2"}
                                value={namecardend}
                                name="namecard_end"
                                label="Expired At"
                                onChange={dates => {setNamecardEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                         ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canCreateNameCard"/>}
                        label="Unable to create Business Card"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                <Divider variant="fullWidth" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Centre Video
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="namecard" 
                    name="centrevideo" 
                    value={centreVideoValue} 
                    onChange={(e) => setCentreVideoValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canAddCentreVideo"/>}
                        label="Able to add Centre Video (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canAddCentreVideo"/>}
                        label="Able to add Centre Video (with expired date)"
                    />

                        {centreVideoValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={centreVideoValue=="2"}
                                value={videostart}
                                name="video_start"
                                label="Valid From"
                                onChange={(dates) => {setCentreVideoStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={centreVideoValue=="2"}
                                value={videoend}
                                name="video_end"
                                label="Expired At"
                                onChange={dates => {setCentreVideoEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                        ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canAddCentreVideo"/>}
                        label="Unable to add Centre Video"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer>

                {/* <Divider variant="middle" style={{marginTop:20}} classes={{root: classes.dividerColor}}/>   

                <div style={{ paddingTop: 20, textAlign: "left" ,paddingBottom:10}}>
                  <Typography className={classes.descColor} style={{ fontWeight: "bold" }}>
                    Live Event
                  </Typography>
                </div>

                <GridContainer>
                  <GridItem>
                  <RadioGroup aria-label="namecard" 
                    name="liveevent" 
                    value={liveEventValue} 
                    onChange={(e) => setLiveEventValue(e.target.value)}
                  >
                    <FormControlLabel
                        style={{color:"black"}}
                        value="1"
                        control={<Radio name="canCreateLiveEvent"/>}
                        label="Able to create Live Event (without expiration)"
                    />

                    <FormControlLabel
                        style={{color:"black"}}
                        value="2"
                        control={<Radio name="canCreateLiveEvent"/>}
                        label="Able to create Live Event (with expired date)"
                    />

                        {liveEventValue == "2" ? (
                          <div>
                          <GridContainer style={{paddingLeft:20,paddingBottom:10}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8}}>
                              <KeyboardDateTimePicker
                                clearable
                                required={liveEventValue=="2"}
                                value={eventstart}
                                name="event_start"
                                label="Valid From"
                                onChange={(dates) => {setLiveEventStart(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: 8 }}>
                              <KeyboardDateTimePicker
                                clearable
                                required={liveEventValue=="2"}
                                value={eventend}
                                name="event_end"
                                label="Expired At"
                                onChange={dates => {setLiveEventEnd(dates)}}
                                format="dd-MM-yyyy HH:mm:ss"
                                InputProps={{
                                  classes: {
                                    underline: classes.underline,
                                    disabled: classes.disabled
                                  }
                                }}
                              />
                            </GridItem>
                          </MuiPickersUtilsProvider>
                          </GridContainer>
                          </div>
                        ):null}

                    <FormControlLabel
                        style={{color:"black"}}
                        value="3"
                        control={<Radio name="canCreateLiveEvent"/>}
                        label="Unable to create Live Event"
                    />

                    </RadioGroup>
                  </GridItem>
                </GridContainer> */}

              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}