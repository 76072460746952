import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import * as USER from "../../services/user";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

const countryCode = [
  {
    value: "673",
    label: "Brunei (+673)"
  },
  {
    value: "855",
    label: "Cambodia (+855)"
  },
  {
    value: "86",
    label: "China (+86)"
  },
  {
    value: "852",
    label: "Hong Kong (+852)"
  },
  {
    value: "62",
    label: "Indonesia (+62)"
  },
  {
    value: "856",
    label: "Lao People's Democratic Republic (+856)"
  },
  {
    value: "60",
    label: "Malaysia (+60)"
  },
  {
    value: "95",
    label: "Myanmar (+95)"
  },
  {
    value: "63",
    label: "Philippines (+63)"
  },
  {
    value: "65",
    label: "Singapore (+65)"
  },
  {
    value: "886",
    label: "Taiwan (+886)"
  },
  {
    value: "66",
    label: "Thailand (+66)"
  },
  {
    value: "84",
    label: "Viet Nam (+84)"
  },
  {
    value: "Others",
    label: "Others"
  }
];

const useStyles = makeStyles(theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  load: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    marginTop: theme.spacing(2),
    "&:before": {
      borderColor: themeColor
    },
    "&:after": {
      borderColor: themeColor
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#AAAAAA !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: themeColor
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: themeColor
    }
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&$inputFocused": {
      color: themeColor
    }
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400"
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400"
  },
  bannedColor: {
    color: "black",
    fontWeight: "400"
  },
  inputFocused: {}
}));

export default function AddUser(props) {
  const classes = useStyles();
  const [userSelection, setUserSelection] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [mobilePrefix, setMobilePrefix] = useState("");
  const [loading, setLoading] = useState(true);
  const [typeError, setTypeError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [mobileNumError, setMobileNumError] = useState(false);
  const [mobileNumPrefixError, setMobileNumPrefixError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [usernameErr, setUsernameErr] = useState(false);
  const [usernameErrMsg, setUsernameErrMsg] = useState("");
  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const onType =
    props.location.state && props.location.state.type
      ? props.location.state.type
      : null;
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile_number_prefix: "",
    mobile_number: "",
    user_type: "",
    status: ""
  });

  const {
    username,
    first_name,
    last_name,
    email,
    password,
    mobile_number_select,
    mobile_number_prefix,
    mobile_number,
    user_type,
    status
  } = formData;

  const re = /^[0-9\b]+$/;
  useEffect(() => {
    console.log('www')
    USER.getUserSelection()
      .then(result => {
        setUserSelection(result.data);
        setLoading(false);
      })
      .catch(err => {
        if (err == "token expired") {
          props.history.replace("/login");
        }
      });
  }, []);

  const updateFormData = event => {
    if (event.target.name === "mobile_number_select") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        ["mobile_number_prefix"]: event.target.value
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setStatusError(false);
    setTypeError(false);
    setMobileNumError(false);
    setMobileNumPrefixError(false);
    setEmailError(false);
    setUsernameErr(false);

    setLoading(true);
    let valid = true;
    if (selectedType.length == 0) {
      valid = false;
      setTypeError(true);
      setLoading(false);
    }
    if (selectedStatus.length == 0) {
      setStatusError(true);
      setLoading(false);
      valid = false;
    }
    if (!re.test(event.target.mobile_number.value)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }
    if (isNaN(event.target.mobile_number_prefix.value)) {
      setMobileNumPrefixError(true);
      setLoading(false);
      valid = false;
    }

    if (valid) {
      let createData = {
        username: event.target.username.value,
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        email: event.target.email.value,
        password: event.target.password.value,
        mobile_number_prefix: event.target.mobile_number_prefix.value,
        mobile_number: event.target.mobile_number.value,
        user_type: Object.entries(userSelection.user_type).find(
          type => type[1] == event.target.user_type.value
        )[0],
        status: Object.entries(userSelection.status).find(
          status => status[1] == event.target.status.value
        )[0]
      };
      setFormData(createData);
      USER.addUser(createData)
        .then(res => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "User create successful"
          });
          props.history.replace("/user");
        })
        .catch(err => {
          if (err.errors) {
            setLoading(false);
            if (err.errors.email) {
              setEmailError(true);
              setEmailErrorMsg(err.errors.email[0]);
            } else if (err.errors.username) {
              setUsernameErr(true);
              setUsernameErrMsg(err.errors.username[0]);
            }
          }
        });
    }
  }

  const handleCountryChange = event => {
    const options = event.target.value;
    setSelectedCountry(options);
  };

  return loading ? (
    <div className={classes.load}>
      <CircularProgress style={{ fontSize: 30, color: themeColor }} />
    </div>
  ) : (
    <div>
      <Button
        variant="contained"
        // color="primary"
        style={{
          backgroundColor: themeColor,
          color: "white",
          marginBottom: 10,
          alignItems: "center"
        }}
        className={classes.submit}
        onClick={() =>
          props.history.replace({
            pathname: "/user",
            state: {
              currentPage: page,
              search: search,
              type: onType,
              status: onStatus
            }
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={event => handleSubmit(event)}
            >
              <CardHeader color="warning" style={{ width: "fit-content" }}>
                <h4 className={classes.cardTitleWhite}>Add User</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={usernameErr}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        value={username}
                        onChange={e => updateFormData(e)}
                        autoFocus
                        inputProps={{ maxLength: 50 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {usernameErr && (
                        <FormHelperText>{usernameErrMsg}</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="first_name"
                      onChange={e => updateFormData(e)}
                      value={first_name}
                      inputProps={{ maxLength: 100 }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="last_name"
                      onChange={e => updateFormData(e)}
                      value={last_name}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={emailError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        onChange={e => updateFormData(e)}
                        value={email}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {emailError && (
                        <FormHelperText>{emailErrorMsg}</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      onChange={e => updateFormData(e)}
                      value={password}
                      inputProps={{ minLength: 8 }}
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem item xs={6} sm={4} md={4}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="mobileNumberSelect"
                      select
                      label="Country"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                          disabled: classes.disabled
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    >
                      {countryCode.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                  <GridItem item xs={6} sm={3} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={mobileNumPrefixError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        // type="number"
                        required
                        fullWidth
                        margin="normal"
                        label="Country Code"
                        disabled={selectedCountry === "Others" ? false : true}
                        name="mobile_number_prefix"
                        value={
                          selectedCountry === "Others"
                            ? mobilePrefix
                            : selectedCountry
                        }
                        onChange={event => setMobilePrefix(event.target.value)}
                        inputProps={{ maxLength: 4 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumPrefixError && (
                        <FormHelperText>
                          mobile number prefix is invalid.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem item xs={4} sm={12} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={mobileNumError}
                      className={classes.formControl}
                    >
                      <TextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        onChange={e => updateFormData(e)}
                        name="mobile_number"
                        value={mobile_number}
                        inputProps={{ maxLength: 20, minLength: 6 }}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      />
                      {mobileNumError && (
                        <FormHelperText>
                          mobile number is invalid.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5} sm={5} md={4}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={typeError}
                      className={classes.formControl}
                    >
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        name="user_type"
                        select
                        label="Type"
                        value={selectedType}
                        onChange={event => setSelectedType(event.target.value)}
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      >
                        {Object.entries(userSelection.user_type).map(type => (
                          <MenuItem key={type[0]} value={type[1]}>
                            {type[1]}
                          </MenuItem>
                        ))}
                      </TextField>
                      {typeError && (
                        <FormHelperText>user type is required.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={5} sm={5} md={3}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={statusError}
                      className={classes.formControl}
                    >
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        name="status"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={event =>
                          setSelectedStatus(event.target.value)
                        }
                        variant="standard"
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                            disabled: classes.disabled,
                            input:
                              selectedStatus == "Active"
                                ? classes.activeColor
                                : selectedStatus == "Inactive"
                                ? classes.inactiveColor
                                : classes.bannedColor
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }
                        }}
                      >
                        {Object.entries(userSelection.status).map(status => (
                          <MenuItem
                            key={status[0]}
                            value={status[1]}
                            style={{
                              color:
                                status[0] == 1
                                  ? "#4caf50"
                                  : status[0] == 2
                                  ? "#f44336"
                                  : "black"
                            }}
                          >
                            {status[1]}
                          </MenuItem>
                        ))}
                      </TextField>
                      {statusError && (
                        <FormHelperText>status is required.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" style={{ backgroundColor: themeColor }}>
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
